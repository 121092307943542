const initialstate = {
  logoutProcessing: null,
  logoutSuccess: null,
  logoutFail: null,
};

const firebaseLogoutReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FIREBASE_LOGGING_OUT':
      return Object.assign({}, state, {
        logoutProcessing: true,
      });
    case 'FIREBASE_LOGOUT_SUCCESSFULL':
      return Object.assign({}, state, {
        logoutProcessing: false,
        logoutSuccess: true,
        logoutFail: false,
      });
    case 'FIREBASE_LOGOUT_FAIL':
      return Object.assign({}, state, {
        logoutProcessing: false,
        logoutSuccess: false,
        logoutFail: true,
      });
    default:
      return state;
  }
};

export default firebaseLogoutReducer;
