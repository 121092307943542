import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Add, Check, MoreVert } from '@mui/icons-material';
import moment from 'moment';
import ProspectMember from './ProspectMember';
import FormField from './FormField';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';
import updateCompanyProspectDetails from 'redux/actions/Compass/updateCompanyProspectDetails';
import { useDispatch, useSelector } from 'react-redux';
import getCompanyProspects from 'redux/actions/Compass/getCompanyProspects';
import addProspectToCompanyProfile from 'redux/actions/Compass/addProspectToCompanyProfile';
import deleteProspectFromCompanyProfile from 'redux/actions/Compass/deleteProspectFromCompanyProfile';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import getMembersByOrgId from 'redux/actions/Compass/getMembersByOrgId';
import getAllDealActivities from 'redux/actions/Compass/getAllDealActivities';
import DealActivity from './DealActivity';
import addDealActivity from 'redux/actions/Compass/addDealActivity';
import deleteActivity from 'redux/actions/Compass/deleteActivity';
import showAlert from 'redux/actions/Common/showAlert';

const activityValidationSchema = yup.object({
  type: yup.string().trim(),
  downloadedContentNotes: yup.string().trim(),
  websiteNotes: yup.string().trim(),
  numberOfVisits: yup.string().trim(),
  downloadedContents: yup.string().trim(),
  date: yup.number(),
  time: yup.number(),
  notes: yup.string(),
  teamMembers: yup.array(),
  prospectMembers: yup.array(),
  additionalNotes: yup.string(),
});

const teamMemberValidationSchema = yup.object({
  userId: yup.string().trim(),
  name: yup
    .string()
    .trim()
    .required('Name is required.'),
  email: yup
    .string()
    .trim()
    .email()
    .required('Email is required.'),
  designation: yup
    .string()
    .trim()
    .required('Designation is required.'),
  linkedinUrl: yup
    .string()
    .trim()
    .required('LinkedIn URL is required.'),
});

const DealActivities = ({ currentAccount, editMode }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dealActivities = useSelector((state) => state.dealActivities);
  const { members } = useSelector((state) => state.allTeamMembers);
  const { membersByOrgId } = useSelector((state) => state.membersByOrgId);
  const { prospects } = useSelector((state) => state.companyProspects);

  const [availableTeamMembers, setAvailableTeamMembers] = useState([]);
  const [availableProspectMembers, setAvailableProspectMembers] = useState([]);

  const [showAddNewActivity, setShowAddNewActivity] = useState(false);
  const [addingNewActivity, setAddingNewActivity] = useState(false);
  const [updatingActivity, setUpdatingActivity] = useState(false);
  const [
    showConfirmActivityDeleteDialog,
    setShowConfirmActivityDeleteDialog,
  ] = useState(null);

  const dealActivityInitialValues = {
    type: '',
    date: Date.now(),
    time: Date.now(),
    teamMembers: [],
    prospectMembers: [],
    notes: '',
    downloadedContentNotes: '',
    websiteNotes: '',
    numberOfVisits: '',
    downloadedContents: '',
    additionalNotes: '',
  };

  const dealActivityFormSubmit = (values) => {
    console.log(values);
  };

  const formikDealActivity = useFormik({
    initialValues: dealActivityInitialValues,
    validationSchema: activityValidationSchema,
    onSubmit: dealActivityFormSubmit,
  });

  useEffect(() => {
    setAvailableTeamMembers((prev) => {
      return [
        ...(membersByOrgId?.filter((member) => member?.type !== 'prospect') ||
          []),
        ...(members
          ?.filter(
            (m) =>
              !membersByOrgId ||
              !membersByOrgId?.map((mm) => mm?.id)?.includes(m?.id),
          )
          ?.map((m) => {
            return {
              name: m?.fullName,
              userId: m?.id,
              email: m?.email,
              designation: '',
              linkedinUrl: '',
              type: 'existing_team_member',
            };
          }) || []),
      ];
    });
  }, [members, membersByOrgId]);

  useEffect(() => {
    setAvailableProspectMembers((prev) => {
      return [
        ...(prospects
          ?.filter((pr) => pr?.type === 'prospect')
          ?.sort((a, b) => {
            if (a?.createdOn > b?.createdOn) {
              return 1;
            } else if (a?.createdOn < b?.createdOn) {
              return -1;
            } else {
              return 0;
            }
          }) || []),
      ];
    });
  }, [prospects]);

  useEffect(() => {
    if (typeof showAddNewActivity === 'object' && showAddNewActivity) {
      formikDealActivity.setValues({
        type: showAddNewActivity?.type || '',
        date: showAddNewActivity?.date
          ? Number(showAddNewActivity?.date)
          : Date.now(),
        time: showAddNewActivity?.time
          ? Number(showAddNewActivity?.time)
          : Date.now(),
        notes: showAddNewActivity?.notes || '',
        additionalNotes: showAddNewActivity?.additionalNotes || '',
        members: [
          ...(availableTeamMembers || []),
          ...(availableProspectMembers || []),
        ]?.filter((m) =>
          Object.keys(showAddNewActivity?.members || {})?.includes(m?.id),
        ),
      });
    } else if (showAddNewActivity) {
      formikDealActivity.setFieldValue('time', Date.now());
    }
  }, [showAddNewActivity, availableTeamMembers, availableProspectMembers]);

  useEffect(() => {
    if (showAddNewActivity) {
      dispatch(
        getCompanyProspects(currentAccount?.id, 10, null, (data) => {
          // console.log(data);
        }),
      );
      dispatch(getMembersByOrgId(25, null, (data) => {}));
    }
  }, [showAddNewActivity]);

  useEffect(() => {
    dispatch(
      getAllDealActivities(currentAccount?.id, 10, null, (data) => {
        // console.log(data);
      }),
    );
  }, []);

  return (
    <>
      {editMode ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="body2" color={'text.secondary'} fontWeight={700}>
            All deal activities
          </Typography>
          {dealActivities?.dealActivities &&
            dealActivities?.dealActivities.length > 0 &&
            editMode && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setShowAddNewActivity(true)}
                startIcon={<Add />}
              >
                Add new activity
              </Button>
            )}
        </Box>
      ) : null}
      <Grid container spacing={2}>
        {dealActivities?.dealActivities &&
        dealActivities?.dealActivities.length > 0 ? (
          dealActivities?.dealActivities?.map((activity, index) => (
            <Grid
              item
              xs={12}
              key={`${activity?.type}${index}`}
              sm={editMode ? 6 : 4}
              md={editMode ? 6 : 4}
              lg={editMode ? 4 : 3}
            >
              <DealActivity
                activity={activity}
                setShowAddNewActivity={setShowAddNewActivity}
                setShowConfirmActivityDeleteDialog={
                  setShowConfirmActivityDeleteDialog
                }
                editMode={editMode}
              />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              width: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              pt: 4,
              pb: 4,
            }}
          >
            <Typography
              variant="body2"
              color={'text.secondary'}
              fontWeight={700}
            >
              No activity added yet
            </Typography>
            {editMode ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setShowAddNewActivity(true)}
                startIcon={<Add />}
              >
                Add New Activity
              </Button>
            ) : null}
          </Box>
        )}
      </Grid>
      <Dialog
        open={Boolean(showAddNewActivity)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  Activity Type
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormField
                  name={'type'}
                  type={'select'}
                  multiple={false}
                  value={formikDealActivity?.values?.type}
                  onChange={(e) => {
                    formikDealActivity.handleChange(e);
                  }}
                  options={[
                    'Email',
                    'Meeting',
                    'Call',
                    'Website Visits',
                    'Downloaded Content',
                    'Other',
                  ]}
                  placeholder={'Activity Type'}
                />
              </Grid>
              {formikDealActivity?.values?.type !== 'Website Visits' &&
              formikDealActivity?.values?.type !== 'Downloaded Content' ? (
                <>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Date
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'date'}
                      type={'date'}
                      multiple={false}
                      value={formikDealActivity?.values?.date}
                      onChange={(newValue) => {
                        formikDealActivity.setFieldValue(
                          'date',
                          new Date(newValue).getTime(),
                        );
                      }}
                      options={[]}
                      placeholder={'Date'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Time
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'time'}
                      type={'time'}
                      multiple={false}
                      value={formikDealActivity?.values?.time}
                      onChange={(newValue) => {
                        formikDealActivity.setFieldValue(
                          'time',
                          new Date(newValue).getTime(),
                        );
                      }}
                      options={[]}
                      placeholder={'Time'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Prospect Members
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl size="small" sx={{ width: '100%' }}>
                      <Select
                        size="small"
                        labelId={'prospectMembers'}
                        id={'prospectMembers'}
                        name={'prospectMembers'}
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          formikDealActivity.setFieldValue(
                            'prospectMembers',
                            e.target.value,
                          );
                          // handleRelatedMemberChange(e);
                        }}
                        value={
                          formikDealActivity.values.prospectMembers?.filter(
                            (member) => member?.type === 'prospect',
                          ) || []
                        }
                        multiple={true}
                        renderValue={(selected) =>
                          selected
                            .map((value) => {
                              if (typeof value === 'object') {
                                return value?.name;
                              }
                              return value?.name;
                            })
                            .join(', ')
                        }
                      >
                        {availableProspectMembers.map((option) => (
                          <MenuItem value={option} key={option?.id}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              gap={2}
                              width={1}
                            >
                              <Typography variant="subtitle2">
                                {option?.name}
                              </Typography>
                              {formikDealActivity.values.prospectMembers
                                ?.map((member) => member?.id || member?.userId)
                                ?.includes(option?.id) ? (
                                <Check color="primary" sx={{ fontSize: 18 }} />
                              ) : null}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Team Members
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl size="small" sx={{ width: '100%' }}>
                      <Select
                        size="small"
                        labelId={'teamMembers'}
                        id={'teamMembers'}
                        name={'teamMembers'}
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => {
                          // console.log(e.target.value);
                          formikDealActivity.setFieldValue(
                            'teamMembers',
                            e.target.value,
                          );
                          // handleRelatedMemberChange(e);
                        }}
                        value={
                          formikDealActivity.values.teamMembers?.filter(
                            (member) => member?.type !== 'prospect',
                          ) || []
                        }
                        multiple={true}
                        renderValue={(selected) =>
                          selected
                            .map((value) => {
                              if (typeof value === 'object') {
                                return value?.name;
                              }
                              return value?.name;
                            })
                            .join(', ')
                        }
                      >
                        {availableTeamMembers.map((option) => (
                          <MenuItem
                            value={option}
                            key={option?.userId || option?.id}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              gap={2}
                              width={1}
                            >
                              <Typography variant="subtitle2">
                                {option?.name}
                              </Typography>
                              {formikDealActivity.values.teamMembers
                                ?.map((member) => member?.id || member?.userId)
                                ?.includes(option?.userId || option?.id) ? (
                                <Check color="primary" sx={{ fontSize: 18 }} />
                              ) : null}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Historical Relationship
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'notes'}
                      type={'textarea'}
                      multiple={false}
                      value={formikDealActivity?.values?.notes}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={
                        'Historical relationship (document any prior relationship with anyone in the account)'
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Additional Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'additionalNotes'}
                      type={'textarea'}
                      multiple={false}
                      value={formikDealActivity?.values?.additionalNotes}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={'Additional notes(if any)'}
                    />
                  </Grid>
                </>
              ) : formikDealActivity.values.type === 'Website Visits' ? (
                <>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Number of Website Visits
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'numberOfVistis'}
                      type={'text'}
                      multiple={false}
                      value={formikDealActivity?.values?.numberOfVistis}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={'Number of website visits'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Additional Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'websiteNotes'}
                      type={'textarea'}
                      multiple={false}
                      value={formikDealActivity?.values?.websiteNotes}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={'Additional notes(if any)'}
                    />
                  </Grid>
                </>
              ) : formikDealActivity.values.type === 'Downloaded Content' ? (
                <>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Downloaded Contents
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'downloadedContents'}
                      type={'text'}
                      multiple={false}
                      value={formikDealActivity?.values?.downloadedContents}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={'Downloaded Contents'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      color={'text.secondary'}
                      fontWeight={700}
                    >
                      Additional Notes
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormField
                      name={'downloadedContentNotes'}
                      type={'textarea'}
                      multiple={false}
                      value={formikDealActivity?.values?.downloadedContentNotes}
                      onChange={(e) => {
                        formikDealActivity.handleChange(e);
                      }}
                      options={[]}
                      placeholder={'Additional notes(if any)'}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowAddNewActivity(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={addingNewActivity || updatingActivity}
            startIcon={
              addingNewActivity || updatingActivity ? (
                <CircularProgress size={20} />
              ) : null
            }
            onClick={(e) => {
              setAddingNewActivity(true);
              dispatch(
                addDealActivity(
                  currentAccount?.id,
                  {
                    date: formikDealActivity?.values?.date,
                    members:
                      formikDealActivity?.values?.type !== 'Website Visits' &&
                      formikDealActivity?.values?.type !== 'Downloaded Content'
                        ? {
                            ...(formikDealActivity?.values?.prospectMembers?.reduce(
                              (acc, curr) => {
                                if (!acc[curr?.id]) {
                                  acc[curr?.id] = curr?.type;
                                }
                                return acc;
                              },
                              {},
                            ) || {}),
                            ...(formikDealActivity?.values?.teamMembers?.reduce(
                              (acc, curr) => {
                                if (!acc[curr?.id]) {
                                  acc[curr?.id] = curr?.type;
                                }
                                return acc;
                              },
                              {},
                            ) || {}),
                          }
                        : null,
                    notes: formikDealActivity?.values?.notes,
                    additionalNotes:
                      formikDealActivity?.values?.additionalNotes,
                    time: formikDealActivity?.values?.time,
                    type: formikDealActivity?.values?.type,
                    downloadedContentNotes:
                      formikDealActivity?.values?.downloadedContentNotes,
                    downloadedContents:
                      formikDealActivity?.values?.downloadedContents,
                    numberOfVisits: formikDealActivity?.values?.numberOfVistis,
                    websiteNotes: formikDealActivity?.values?.websiteNotes,
                  },
                  (error) => {
                    setAddingNewActivity(false);
                    if (error) {
                      dispatch(
                        showAlert({
                          message:
                            'Oops, Something went wront! Please try again.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    } else {
                      formikDealActivity.resetForm();
                      dispatch(
                        showAlert({
                          message: 'Deal activity added successfully',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                      dispatch(
                        getAllDealActivities(
                          currentAccount?.id,
                          10,
                          null,
                          (data) => {
                            // console.log(data);
                          },
                        ),
                      );
                      setShowAddNewActivity(false);
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            {typeof showAddNewActivity === 'object' && showAddNewActivity
              ? 'Update'
              : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmActivityDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this activity?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmActivityDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteActivity(showConfirmActivityDeleteDialog, (data) => {
                  setShowConfirmActivityDeleteDialog(null);
                  dispatch({
                    type: 'DELETE_ACTIVITY',
                    payload: showConfirmActivityDeleteDialog,
                  });
                  dispatch(
                    showAlert({
                      message: 'Activity deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DealActivities.propTypes = {
  currentAccount: PropTypes.object,
  editMode: PropTypes.bool,
};

export default DealActivities;
