import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { NavItem } from './components';
import {
  Avatar,
  Divider,
  IconButton,
  Link,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Add,
  AutoGraph,
  Badge,
  Business,
  Group,
  Groups,
  ListAlt,
  Logout,
  ManageAccounts,
  PlaylistAddCheck,
  RecentActors,
  RecordVoiceOver,
  SupervisorAccount,
  TaskAlt,
  Troubleshoot,
  VoiceChat,
  VoiceOverOffSharp,
  Work,
  WorkOutline,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useParams } from 'react-router-dom';
import getCompetitionById from 'redux/actions/Common/getCompetitionById';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import packageJSON from '../../../../../../../package.json';
import { uuidRegex } from 'utils';
import getCompetitionBySlug from 'redux/actions/Common/getCompetitionBySlug';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';

const SidebarNav = ({ pages, onClose, expanded }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { competitionIdOrSlug, participationId } = useParams();
  const [activeLink, setActiveLink] = useState('');
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const competitionDetailsById = useSelector(
    (state) => state.competitionDetailsById,
  );
  const { userDetails } = useSelector((state) => state.userDetails);
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  useEffect(() => {
    if (competitionIdOrSlug) {
      if (uuidRegex.test(competitionIdOrSlug)) {
        dispatch(
          getCompetitionById(
            competitionIdOrSlug,
            userInfo?.designations?.[0],
            () => {},
          ),
        );
      } else {
        dispatch(
          getCompetitionBySlug(
            competitionIdOrSlug,
            userInfo?.designations?.[0],
            () => {},
          ),
        );
      }
    }
  }, [competitionIdOrSlug]);

  // console.log(competitionDetails);
  console.log(userDetails);
  return (
    <Box
      height={1}
      // paddingTop={1}
      // paddingBottom={2}
      display={'flex'}
      flexDirection={'column'}
      // justifyContent={'space-between'}
    >
      <Box
        width={1}
        paddingX={3}
        paddingY={2}
        display={'flex'}
        flexDirection={expanded ? 'row' : 'column'}
        // justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        {/* {!isMd ? ( */}
        <MenuIcon
          data-trackid={'toggle_sidebar_expand'}
          onClick={(e) => {
            handleTrackEvent(e, 'toggle_sidebar_expand');
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'dashboardSidebarExpanded',
                value: !dashboardSidebarExpanded,
              },
            });
          }}
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
        />
        {/* ) : null} */}
        <Box
          data-trackid={'logo_clicked'}
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          width={30}
          onClick={(e) => {
            handleTrackEvent(e, 'logo_clicked');
          }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box mt={1}>
        {/* {process.env.REACT_APP_TARGET_ENV === 'local' ||
        process.env.REACT_APP_TARGET_ENV === 'development' ? (
          <Box>
            <NavItem
              data-trackid={'conversationAIv2'}
              title={' Practice Call v2(vapi)'}
              items={[]}
              href={'/practice-calls-vapi'}
              startIcon={
                <RecordVoiceOver
                  style={{
                    color: new RegExp(`/practice-calls-vapi`, 'i').test(
                      pathname,
                    )
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'practice-calls-vapi');
              }}
            />
          </Box>
        ) : null} */}
        {userInfo?.designations?.includes('account_admin') &&
        userInfo?.orgRoles?.includes('SS_ADMIN') ? (
          <>
            <Box>
              <NavItem
                data-trackid={'companyAccounts'}
                title={' Accounts'}
                items={[
                  {
                    name: ` All Accounts`,
                    href: `/accounts`,
                    startIcon: '',
                  },
                  {
                    name: ` Compass Framework`,
                    href: `/compass-framework`,
                    startIcon: '',
                  },
                  {
                    name: ` Compass Helps`,
                    href: `/compass-helps`,
                    startIcon: '',
                  },
                ]}
                href={'/accounts'}
                startIcon={
                  <Business
                    style={{
                      color: new RegExp(`/accounts`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'accounts');
                }}
              />
              <NavItem
                data-trackid={'croInterviews'}
                title={' CRO Interviews'}
                items={[
                  {
                    name: `Interview History`,
                    href: `/cro-interviews`,
                    startIcon: '',
                  },
                ]}
                href={'/cro-interviews'}
                startIcon={
                  <RecentActors
                    style={{
                      color: new RegExp(`/cro-interviews`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'cro_interviews');
                }}
              />
            </Box>
          </>
        ) : null}
        {userInfo?.orgRoles?.includes('SS_ADMIN') ? (
          <Box>
            <NavItem
              data-trackid={'digital-twin'}
              title={
                userInfo?.orgRoles?.includes('SS_ADMIN')
                  ? 'John Stopper'
                  : userInfo?.email === 'jbsales@qualification.ai' ||
                    userInfo?.email === 'vijay+laa1@list2shop.com'
                  ? ' John Barrows'
                  : ' Brynne Tillman'
              }
              items={[
                {
                  name: `Training Data`,
                  href: `/digital-twin?type=training`,
                  startIcon: '',
                },
                {
                  name: `All Conversations`,
                  href: `/digital-twin`,
                  startIcon: '',
                },
                {
                  name: userInfo?.orgRoles?.includes('SS_ADMIN')
                    ? 'Talk to John'
                    : userInfo?.email === 'jbsales@qualification.ai' ||
                      userInfo?.email === 'vijay+laa1@list2shop.com'
                    ? 'Talk to John'
                    : `Talk to Brynne`,
                  href: `/digital-twin?type=talk`,
                  startIcon: '',
                  disabled: userInfo?.orgRoles?.includes('SS_ADMIN'),
                },
                {
                  name: userInfo?.orgRoles?.includes('SS_ADMIN')
                    ? 'Chat with John'
                    : userInfo?.email === 'jbsales@qualification.ai' ||
                      userInfo?.email === 'vijay+laa1@list2shop.com'
                    ? 'Chat with John'
                    : `Chat with Brynne`,
                  href: `/digital-twin?type=chat`,
                  startIcon: '',
                },
              ]}
              href={'/digital-twin'}
              startIcon={
                <Avatar
                  variant="circle"
                  alt={
                    userInfo?.orgRoles?.includes('SS_ADMIN')
                      ? 'John Stopper'
                      : userInfo?.email === 'jbsales@qualification.ai' ||
                        userInfo?.email === 'vijay+laa1@list2shop.com'
                      ? 'John Barrows'
                      : 'Brynne Tillman'
                  }
                  src={
                    userInfo?.orgRoles?.includes('SS_ADMIN')
                      ? '/js.jpeg'
                      : userInfo?.email === 'jbsales@qualification.ai' ||
                        userInfo?.email === 'vijay+laa1@list2shop.com'
                      ? '/jb.jpeg'
                      : '/bt.jpeg'
                  }
                  sx={{
                    width: 26,
                    height: 26,
                  }}
                >
                  {userInfo?.orgRoles?.includes('SS_ADMIN')
                    ? 'John Stopper'
                    : userInfo?.email === 'jbsales@qualification.ai' ||
                      userInfo?.email === 'vijay+laa1@list2shop.com'
                    ? 'John Barrows'
                    : 'Brynne Tillman'}
                </Avatar>
              }
              onClick={(e) => {
                handleTrackEvent(e, 'digital-twin');
              }}
            />
          </Box>
        ) : null}
        {userInfo?.custom?.orgType !== 'w_org' ? (
          <>
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'sales_call_analysis'}
                  title={' Sales Call Analysis'}
                  items={[
                    {
                      name: `Analysis History`,
                      href: `/sales-call-analysis`,
                      startIcon: '',
                    },
                    {
                      name: `Start Analysis`,
                      href: `/sales-call-analysis?new=true`,
                      startIcon: '',
                    },
                  ]}
                  href={'/sales-call-analysis'}
                  startIcon={
                    <Troubleshoot
                      style={{
                        color: new RegExp(`/sales-call-analysis`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'sales_call_analysis');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'practice_call',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'conversation_ai'}
                  title={' Private Roleplays'}
                  items={[
                    {
                      name: `All Roleplays`,
                      href: `/conversation-ai`,
                      startIcon: '',
                    },
                    {
                      name: `Start Roleplay`,
                      href: `/conversation-ai?new=true`,
                      startIcon: '',
                    },
                  ]}
                  href={'/conversation-ai'}
                  startIcon={
                    <RecordVoiceOver
                      style={{
                        color: new RegExp(`/conversation-ai`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'conversation_ai');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) &&
            Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'task',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'tasks'}
                  title={' Tasks'}
                  items={
                    [
                      // {
                      //   name: `Action Items`,
                      //   href: `/action-items`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `Create New Action`,
                      //   href: `/action-items?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/tasks'}
                  startIcon={
                    <TaskAlt
                      style={{
                        color: new RegExp(`/tasks`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'tasks');
                  }}
                />
              </Box>
            ) : null}
            {userInfo?.custom?.orgType !== 'demo_org' ? (
              <Box>
                <NavItem
                  data-trackid={'performance'}
                  title={' My Performance'}
                  items={
                    [
                      // {
                      //   name: `All Conversations`,
                      //   href: `/conversation-ai`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `New Conversation`,
                      //   href: `/conversation-ai?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/performance'}
                  startIcon={
                    <AutoGraph
                      style={{
                        color: new RegExp(`/performance`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'performance');
                  }}
                />
              </Box>
            ) : null}
          </>
        ) : null}
        <Box>
          {userInfo?.orgRoles?.includes('WL_ORG_ADMIN') ? (
            <NavItem
              data-trackid={'open_customer_setings'}
              title={'Clients Dashboard'}
              items={[
                {
                  name: `Manage Clients`,
                  href: `/customer/settings?t=customers`,
                  startIcon: '',
                },
                {
                  name: `Manage Plans`,
                  href: `/customer/settings?t=plans`,
                  startIcon: '',
                },
                {
                  name: `Clients Ask Donna`,
                  href: `/customer/settings?t=clients-ask-donna`,
                  startIcon: '',
                },
                {
                  name: `Clients Playbook`,
                  href: `/customer/settings?t=clients-playbooks`,
                  startIcon: '',
                },
                {
                  name: `Bulk Invite`,
                  href: `/customer/settings?t=bulk-invite`,
                  startIcon: '',
                },
                {
                  name: `Whitelabel Details`,
                  href: `/customer/settings`,
                  startIcon: '',
                },
              ]}
              href={'/customer/settings'}
              startIcon={
                <SupervisorAccount
                  sx={{
                    color: new RegExp(`/customer/settings`, 'i').test(pathname)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'open_customer_setings');
              }}
            />
          ) : null}
        </Box>
        {userInfo?.designations?.includes('account_admin') &&
        userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
          <Box>
            <NavItem
              data-trackid={'teamSettings'}
              title={' My Team'}
              items={[
                {
                  name: `Team Dashboard`,
                  href: `/team-settings`,
                  startIcon: '',
                },
                {
                  name: `Score Dashboard`,
                  href: `/team-settings?scoresboard=true`,
                  startIcon: '',
                },
                {
                  name: `Manage Members`,
                  href: `/team-settings?manageusers=true`,
                  startIcon: '',
                },
              ]}
              href={'/team-settings'}
              startIcon={
                <Groups
                  style={{
                    color: new RegExp(`/team-settings`, 'i').test(pathname)
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                  }}
                />
              }
              onClick={(e) => {
                handleTrackEvent(e, 'team-settings');
              }}
            />
          </Box>
        ) : null}
        {userInfo?.designations?.includes('account_admin') &&
        userInfo?.custom?.orgType === 'w_org' ? (
          <>
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'sales_call_analysis'}
                  title={' Sales Call Analysis'}
                  items={[
                    {
                      name: `Analysis History`,
                      href: `/sales-call-analysis`,
                      startIcon: '',
                    },
                    {
                      name: `Start Analysis`,
                      href: `/sales-call-analysis?new=true`,
                      startIcon: '',
                    },
                  ]}
                  href={'/sales-call-analysis'}
                  startIcon={
                    <Troubleshoot
                      style={{
                        color: new RegExp(`/sales-call-analysis`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'sales_call_analysis');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'practice_call',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'conversation_ai'}
                  title={' Private Roleplays'}
                  items={[
                    {
                      name: `All Roleplays`,
                      href: `/conversation-ai`,
                      startIcon: '',
                    },
                    {
                      name: `Start Roleplay`,
                      href: `/conversation-ai?new=true`,
                      startIcon: '',
                    },
                  ]}
                  href={'/conversation-ai'}
                  startIcon={
                    <RecordVoiceOver
                      style={{
                        color: new RegExp(`/conversation-ai`, 'i').test(
                          pathname,
                        )
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'conversation_ai');
                  }}
                />
              </Box>
            ) : null}
            {Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'call_analysis',
            ) &&
            Object.keys(userDetails?.plan?.features || {})?.find(
              (feature) => feature === 'task',
            ) ? (
              <Box>
                <NavItem
                  data-trackid={'tasks'}
                  title={' Tasks'}
                  items={
                    [
                      // {
                      //   name: `Action Items`,
                      //   href: `/action-items`,
                      //   startIcon: '',
                      // },
                      // {
                      //   name: `Create New Action`,
                      //   href: `/action-items?new=true`,
                      //   startIcon: '',
                      // },
                    ]
                  }
                  href={'/tasks'}
                  startIcon={
                    <TaskAlt
                      style={{
                        color: new RegExp(`/tasks`, 'i').test(pathname)
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                      }}
                    />
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, 'tasks');
                  }}
                />
              </Box>
            ) : null}
            <Box>
              <NavItem
                data-trackid={'performance'}
                title={' My Performance'}
                items={[]}
                href={'/performance'}
                startIcon={
                  <AutoGraph
                    style={{
                      color: new RegExp(`/performance`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'performance');
                }}
              />
            </Box>
          </>
        ) : null}
        <Box>
          <NavItem
            data-trackid={'open_account_settings_sidebar'}
            title={'Account Settings'}
            items={
              userInfo?.designations?.includes('account_admin') &&
              userInfo?.orgRoles?.includes('ORG_ADMIN')
                ? Object.keys(userDetails?.plan?.features || {})?.find(
                    (feature) => feature === 'donna',
                  )
                  ? [
                      {
                        name: `Playbook`,
                        href: `/account/settings?t=playbook`,
                        startIcon: '',
                      },
                      {
                        name: `Bots`,
                        href: `/account/settings?t=bots`,
                        startIcon: '',
                      },
                      {
                        name: `Ask Donna`,
                        href: `/account/settings?t=donna-presets`,
                        startIcon: '',
                      },
                      {
                        name: `Integrations`,
                        href: `/account/settings?t=integrations`,
                        startIcon: '',
                      },
                      {
                        name: `Profile`,
                        href: `/account/settings`,
                        startIcon: '',
                      },
                      {
                        name: `Plans`,
                        href: `/pricing`,
                        startIcon: '',
                      },
                    ]
                  : [
                      {
                        name: `Playbook`,
                        href: `/account/settings?t=playbook`,
                        startIcon: '',
                      },
                      {
                        name: `Bots`,
                        href: `/account/settings?t=bots`,
                        startIcon: '',
                      },
                      {
                        name: `Integrations`,
                        href: `/account/settings?t=integrations`,
                        startIcon: '',
                      },
                      {
                        name: `Profile`,
                        href: `/account/settings`,
                        startIcon: '',
                      },
                      {
                        name: `Plans`,
                        href: `/pricing`,
                        startIcon: '',
                      },
                    ]
                : [
                    {
                      name: `Playbook`,
                      href: `/account/settings?t=playbook`,
                      startIcon: '',
                    },
                    {
                      name: `Ask Donna`,
                      href: `/account/settings?t=donna-presets`,
                      startIcon: '',
                    },
                    {
                      name: `Integrations`,
                      href: `/account/settings?t=integrations`,
                      startIcon: '',
                    },
                    {
                      name: `Bots`,
                      href: `/account/settings?t=bots`,
                      startIcon: '',
                    },
                    {
                      name: `Profile`,
                      href: `/account/settings`,
                      startIcon: '',
                    },
                  ]?.filter(
                    (leftnavoption) =>
                      ((leftnavoption?.name === 'Playbook' ||
                        leftnavoption?.name === 'Profile') &&
                        userInfo?.custom?.orgType === 'demo_org') ||
                      userInfo?.custom?.orgType !== 'demo_org',
                  )
            }
            href={'/account/settings'}
            startIcon={
              <ManageAccounts
                sx={{
                  color: new RegExp(`/account/settings`, 'i').test(pathname)
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                }}
              />
            }
            onClick={(e) => {
              handleTrackEvent(e, 'open_account_settings_sidebar');
            }}
          />
        </Box>
      </Box>
      <Box marginTop={'auto'} display={'flex'} flexDirection={'column'}>
        <Divider
          orientation="horizontal"
          sx={{
            width: '100%',
            height: '1px',
          }}
        />
        <Box p={1} display={'flex'} flexDirection={'column'}>
          {expanded ? (
            (window.location.host !== 'app.qualification.ai' &&
              window.location.host !== 'app.sellingbees.com' &&
              window.location.host !== 'localhost:3000') ||
            userInfo?.custom?.orgType === 'w_org' ? (
              <Box
                sx={
                  {
                    // padding: '4px 8px',
                  }
                }
              >
                <Typography variant="caption" color={'text.primary'}>
                  Powered by{' '}
                  <span style={{ fontWeight: 'bold' }}>Qualification AI</span>
                </Typography>
              </Box>
            ) : null
          ) : null}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {expanded ? (
              <Typography variant="caption" color={'text.secondary'}>
                App Version
              </Typography>
            ) : null}
            <Typography variant="caption" color={'text.secondary'}>
              v{packageJSON.version}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // gap: 1,
            }}
          >
            <Link
              underline="none"
              component="a"
              href="/privacy"
              // color="text.primary"
              variant={'caption'}
            >
              Privacy Policy
            </Link>
            <Link
              underline="none"
              component="a"
              href="/tos"
              // color="text.primary"
              variant={'caption'}
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  expanded: PropTypes.bool,
};

export default SidebarNav;
