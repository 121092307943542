import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getPerformanceDataByUserId = (uId, pastNDays, type, callback) => {
  return async (dispatch) => {
    // if (lastSeen) {
    //   dispatch({ type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID_WITH_PAGINATION' });
    // } else {
    dispatch({ type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID' });
    // }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/cs/dashboard/${uId}`, {
            params: {
              'request-id': reqId,
              pastNDays,
              type,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  // 'current-role': currentRole,
                  // 'x-api-version': 'v2',
                }
              : {},
          });
          if (response.status === 200) {
            // if (lastSeen) {
            //   await dispatch({
            //     type: 'ADD_SALES_CALL_REVIEW_TO_ALL_REVIEWS',
            //     payload: {
            //       analysisReviews: response?.data?.saleReviews,
            //       lastSeen: response?.data?.last_seen,
            //     },
            //   });
            // } else {
            await dispatch({
              type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID_DONE',
              payload: {
                performanceData: response?.data,
              },
            });
            // }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID_FAILED',
              payload: 'Failed to fetch performance data',
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID_FAILED',
            payload: 'Failed to fetch performance data',
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_PERFORMANCE_DATA_By_USER_ID_FAILED',
        payload: 'Failed to fetch performance data',
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getPerformanceDataByUserId;
