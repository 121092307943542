const initialstate = {
  creatingAccount: null,
  accountCreated: null,
  accountCreationFailed: null,
  error: null,
  errorText: null,
};

const firebaseSignupReducer =  (state = initialstate, action) => {
  switch (action.type) {
    case 'FIREBASE_CREATING_USER':
      return Object.assign({}, state, {
        creatingAccount: true,
      });
    case 'FIREBASE_USER_CREATED':
      return Object.assign({}, state, {
        creatingAccount: false,
        accountCreated: true,
        accountCreationFailed: false,
        errorText: '',
        error: false,
      });
    case 'FIREBASE_USER_CREATION_FAILED':
      return Object.assign({}, state, {
        creatingAccount: false,
        accountCreationFailed: true,
        accountCreated: false,
        errorText: action.payload,
        error: true,
      });
    default:
      return state;
  }
};

export default firebaseSignupReducer;
