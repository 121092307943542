const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  applicants: null,
  emptyList: null,
  lastTime: null,
};

const getAllApplicantsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_APPLICANTS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_APPLICANTS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_APPLICANTS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        applicants: action.payload.applicants,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_APPLICANTS_TO_ALL_APPLICANTS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        applicants: Array.isArray(action.payload.applicants)
          ? state.applicants
            ? state.applicants.concat(action.payload.applicants)
            : action.payload.applicants
          : state.applicants,
        emptyList:
          Array.isArray(action.payload.applicants) &&
          (action.payload.applicants.length === 0 ||
            action.payload.applicants.length % 100 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_APPLICANTS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        applicants: [],
        emptyList: false,
        lastTime: '',
      });

    case 'GETTING_ALL_APPLICANTS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllApplicantsReducer;
