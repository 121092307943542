import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Button, Grid, Snackbar, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import AlertPopup from 'components/AlertPopup';
import showAlert from 'redux/actions/Common/showAlert';
import hideAlert from 'redux/actions/Common/hiderAlert';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import getEmailByToken from 'redux/actions/Common/getEmailByToken';
import Container from 'components/Container';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import unsubscribe from 'redux/actions/Common/unsubscribe';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const Unsubscribe = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const alertState = useSelector((state) => state.alertState);

  const params = new URLSearchParams(window.location.search);
  const unsubscribeToken = params.get('token');
  const [unsubscribed, setUnsubscribed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [error, setError] = useState(false);

  const initialValues = {
    email: params.get('email') ? params.get('email').replace(/ /g, '+') : '',
  };

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      unsubscribe(values.email, unsubscribeToken, (err) => {
        if (!err) {
          setLoading(false);
          setUnsubscribed(true);
        } else {
          setLoading(false);
          setError(true);
        }
      }),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (params.get('email')) {
      setFieldDisabled(true);
    }
  }, [params]);

  useEffect(() => {
    if (unsubscribeToken) {
      const allTokens = unsubscribeToken
        .replace('[', '')
        .replace(']', '')
        ?.split(',');
      if (allTokens && Array.isArray(allTokens) && allTokens?.length === 1) {
        setLoading(true);
        dispatch(
          getEmailByToken(
            allTokens[0],
            (email) => {
              formik.setFieldValue('email', email);
              setLoading(false);
              setFieldDisabled(true);
            },
            () => {
              setLoading(false);
            },
          ),
        );
      }
    }
  }, [unsubscribeToken]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        minHeight: 'calc(100vh - 120px)',
      }}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'column' }}
      position={'relative'}
      justifyContent="center"
      alignItems={'center'}
    >
      <Container maxWidth={700}>
        {!unsubscribed ? (
          <>
            <Typography variant="h5">
              We are sad to see you go. Are you sure you want to unsubscribe?
            </Typography>
            <Typography variant="body1" color={'text.secondary'} mt={2}>
              You will stop receiving notifications on events that might be of
              interest.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Enter your email *"
                    placeholder="Enter your email *"
                    variant="outlined"
                    name={'email'}
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled={fieldDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  {error ? (
                    <Typography
                      variant="body1"
                      style={{ color: theme.palette.error.main }}
                    >
                      Failed to unsubscribe. Please enter a valid email.
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      size="large"
                      variant="text"
                      color="primary"
                      disabled={loading}
                      onClick={() => {
                        history.push('https://qualificaiton.ai');
                      }}
                    >
                      No, I&apos;d rather stay on
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading || !formik.values.email}
                    >
                      Yes, please unsubscribe
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>{' '}
          </>
        ) : unsubscribed ? (
          <Box
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              style={{ color: theme.palette.success.main }}
            >
              You have unsubscribed successfully.
            </Typography>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default Unsubscribe;
