import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const updatePlanWL = (planId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATE_WHITELABEL_PLAN' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/us/org/${planId}/plans`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATE_WHITELABEL_PLAN_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'UPDATE_WHITELABEL_PLAN_FAILED',
              payload: `Failed to update wl plan`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE_WHITELABEL_PLAN_FAILED',
            payload: `Failed to update wl plan`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'UPDATE_WHITELABEL_PLAN_FAILED',
        payload: `Failed to update wl plan`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default updatePlanWL;
