import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatSeconds } from 'utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
  PointElement,
);

const LineGraph = ({ labels, data, height }) => {
  const theme = useTheme();

  return (
    <Line
      style={{
        maxHeight: height || 'auto',
      }}
      options={{
        responsive: true,
        layout: {
          padding: 5,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: 'Pace',
          },
          datalabels: {
            font: {
              weight: 'bold',
              size: 14,
            },
            formatter: function(value, context) {
              return ``;
            },
          },
        },
        elements: {
          bar: {
            borderRadius: 12,
            borderSkipped: false,
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              display: true,
              stepSize: Math.floor(labels?.length / 5),
              callback: function(value, index, ticks) {
                // console.log('value', value);
                // console.log('index', index);
                // console.log('ticks', ticks);
                // console.log('label', typeof this.getLabelForValue(value));
                return formatSeconds(Number(this.getLabelForValue(value)));
              },
            },
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Time (MM:SS)',
            },
          },
          y: {
            display: true,
            ticks: {
              display: true,
              precision: false,
            },
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Words per minute',
            },
            min: 0,
            max: 300,
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            cubicInterpolationMode: 'monotone',
            label: 'Number of words',
            data: data,
            borderColor: theme.palette.primary.main,
            pointRadius: 1,
            pointHoverRadius: 1,
          },
        ],
      }}
    />
  );
};

LineGraph.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  // competition: PropTypes.object.isRequired,
  // setShowCalendarModal: PropTypes.func.isRequired,
  // experiment: PropTypes.bool,
  // image: PropTypes.string,
  // blur: PropTypes.bool,
};

export default LineGraph;
