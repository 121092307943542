import update from 'immutability-helper';
const initialstate = {
  processing: null,
  done: null,
  failed: null,
  uploadPercentage: {},
  uploadingFor: {},
  error: null,
  errorText: null,
};

const createSalesAnalysisThreadReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'CREATING_SALES_ANALYSIS_THREAD':
      return Object.assign({}, state, {
        processing: true,
      });
    case 'SET_UPLOAD_AUDIO_PROGRESS':
      return update(state, {
        uploadPercentage: {
          [action.payload.audioId]: { $set: action.payload.percentage },
        },
      });
    case 'SET_UPLOADING_AUDIO_FOR':
      return update(state, {
        uploadingFor: {
          [action.payload.audioId]: { $set: action.payload.show },
        },
      });
    case 'CREATING_SALES_ANALYSIS_THREAD_DONE':
      return Object.assign({}, state, {
        processing: false,
        done: true,
        failed: false,
        error: false,
        errorText: '',
      });
    case 'CREATING_SALES_ANALYSIS_THREAD_FAILED':
      return Object.assign({}, state, {
        processing: false,
        done: false,
        failed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default createSalesAnalysisThreadReducer;
