import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Container from 'components/Container';
import { Form } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import trackEvent from 'redux/actions/Common/trackEvent';
import { useMediaQuery } from '@mui/material';

const SigninCover = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, []);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        minHeight: isMd
          ? 'calc(100vh - 120px)'
          : isSm
          ? 'calc(100vh - 120px)'
          : isXs
          ? 'calc(100vh - 144px)'
          : 'calc(100vh - 120px)',
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 600 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container>
              <Form />
            </Container>
          </Box>
          {/* <Box
              sx={{
                flex: { xs: '0 0 100%', md: '0 0 50%' },
                position: 'relative',
                maxWidth: { xs: '100%', md: '50%' },
                order: { xs: 1, md: 2 },
                minHeight: { xs: 'auto', md: 'calc(100vh - 58px)' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50vw' },
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      left: '0%',
                      width: 1,
                      height: 1,
                      position: { xs: 'relative', md: 'absolute' },
                      clipPath: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                      shapeOutside: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: 'auto', md: 1 },
                        '& img': {
                          objectFit: 'cover',
                        },
                        '& .lazy-load-image-loaded': {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      <Box
                        // component={LazyLoadImage}
                        effect="blur"
                        // src={
                        //   'https://assets.maccarianagency.com/backgrounds/img18.jpg'
                        // }
                        height={{ xs: 1, md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          background: '#f1f1f1',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box> */}
        </Box>
      </Container>
    </Box>
  );
};

export default SigninCover;
