import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const validatePrcInvitationToken = (invitationId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'VALIDATING_PRC_TOKEN' });
    try {
      const reqId = uuidv4();
      let response = await axios.get(`/nf/invite/${invitationId}/verify`, {
        params: {
          'request-id': reqId,
        },
      });
      if (response.status === 200) {
        dispatch({
          type: 'VALIDATING_PRC_TOKEN_DONE',
          payload: {
            email: response.data?.email,
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            joined: response?.data?.joined,
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        dispatch({
          type: 'VALIDATING_PRC_TOKEN_FAILED',
          payload: 'Link is invalid or expired.',
        });
      }
    } catch (e) {
      dispatch({
        type: 'VALIDATING_PRC_TOKEN_FAILED',
        payload: 'Link is invalid or expired.',
      });
    }
  };
};

export default validatePrcInvitationToken;
