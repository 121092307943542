const initialstate = {
  open: false,
  message: '',
  showCross: false,
  title: '',
  type: null,
  autoHideDuration: null,
  vertical: 'top',
  horizontal: 'center',
};

const alertReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return Object.assign({}, state, {
        open: true,
        message:
          action.payload && action.payload.message
            ? action.payload.message
            : '',
        showCross: action.payload ? action.payload.showCross : true,
        title:
          action.payload && action.payload.title ? action.payload.title : '',
        type:
          action.payload && action.payload.type ? action.payload.type : null,
        autoHideDuration:
          action.payload && action.payload.autoHideDuration
            ? action.payload.autoHideDuration
            : null,
        vertical:
          action.payload && action.payload.vertical
            ? action.payload.vertical
            : 'top',
        horizontal:
          action.payload && action.payload.horizontal
            ? action.payload.horizontal
            : 'center',
      });
    case 'HIDE_ALERT':
      return Object.assign({}, state, {
        open: false,
        message: '',
        showCross: false,
        title: '',
        type: null,
        autoHideDuration: null,
        vertical: 'top',
        horizontal: 'center',
      });
    default:
      return state;
  }
};

export default alertReducer;
