const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  teams: null,
  emptyList: null,
  lastSeen: null,
};

const getTeamsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_TEAMS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_TEAMS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_TEAMS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        teams: action.payload.teams,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_TEAMS_TO_ALL_TEAMS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        teams: Array.isArray(action.payload.teams)
          ? state.teams
            ? state.teams.concat(action.payload.teams)
            : action.payload.teams
          : state.teams,
        emptyList:
          Array.isArray(action.payload.teams) &&
          (action.payload.teams.length === 0 ||
            action.payload.teams.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'UPDATE_TEAM_DETAILS': {
      const updatedTeams = state.teams.map((sr) => {
        if (sr?.id === action.payload.id) {
          return {
            ...sr,
            ...action.payload.data,
          };
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        teams: updatedTeams,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'DELETE_TEAM': {
      const updatedTeams = state.teams.filter(
        (ac) => ac?.id !== action.payload,
      );
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        teams: updatedTeams,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.teams.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         speakerScoreMap: action.payload.value,
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     teams: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.teams.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     teams: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_ALL_TEAMS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        teams: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_TEAMS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getTeamsReducer;
