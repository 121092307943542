const initialstate = {
  pitchType: 'asyncInterview',
};

const elevatorPitchReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPDATE_PITCH_TYPE':
      return Object.assign({}, state, {
        ...state,
        [action.payload.key]: action.payload.value,
      });
    default:
      return state;
  }
};

export default elevatorPitchReducer;
