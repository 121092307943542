const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  participations: null,
  emptyList: null,
  lastTime: null,
};

const getAllParticipationsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_PARTICIPATIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_PARTICIPATIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_PARTICIPATIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        participations: action.payload.participations,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_PARTICIPATIONS_TO_ALL_PARTICIPATIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        participations: Array.isArray(action.payload.participations)
          ? state.participations
            ? state.participations.concat(action.payload.participations)
            : action.payload.participations
          : state.participations,
        emptyList:
          Array.isArray(action.payload.interviewsForJob) &&
          (action.payload.interviewsForJob.length === 0 ||
            action.payload.interviewsForJob.length % 10 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'FETCHING_ALL_PARTICIPATIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        participations: [],
        emptyList: false,
        lastTime: '',
      });

    case 'FETCHING_ALL_PARTICIPATIONS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllParticipationsReducer;
