const initialstate = {
  showInfoIconOnLeftPanel: false,
  showSectionIntroduction: true,
  phaseCompleted: false,
  currentPhase: 1,
  currentQuestionIndex: 1,
  completedQuestions: [],
  showProductScenario: false,
  showNamePicker: true,
  fullName: '',
};

const phasedContestSessionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPDATE_PHASED_CONTEST_SESSION_DATA':
      return Object.assign({}, state, {
        ...state,
        [action.payload.key]: action.payload.value,
      });
    default:
      return state;
  }
};

export default phasedContestSessionReducer;
