import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const createNewActionItem = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_NEW_ACTION_ITEM' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/action-items`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': 'candidate',
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'CREATING_NEW_ACTION_ITEM_DONE',
              payload: {
                actionItemId: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'CREATING_NEW_ACTION_ITEM_FAILED',
              payload: `Failed to create new action item`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'CREATING_NEW_ACTION_ITEM_FAILED',
            payload: `Failed to create new action item`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_NEW_ACTION_ITEM_FAILED',
        payload: `Failed to create new action item`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default createNewActionItem;
