import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

const createCustomer = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_CUSTOMER_TEAM' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/wl/org/team`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                }
              : {},
          });
          if (response.status === 200) {
            dispatch({
              type: 'CREATING_CUSTOMER_TEAM_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data, false);
            }
          } else {
            dispatch({
              type: 'CREATING_CUSTOMER_TEAM_FAILED',
              payload: response.data.text,
            });
            if (typeof callback === 'function') {
              callback(null, response.data.text);
            }
          }
        } catch (e) {
          if (
            e?.response?.status === 400 &&
            e?.response?.data?.status === 400
          ) {
            if (typeof callback === 'function') {
              callback(e?.response?.data?.text);
            }
          } else {
            if (typeof callback === 'function') {
              callback(null, true);
            }
          }
          dispatch({
            type: 'CREATING_CUSTOMER_TEAM_FAILED',
            payload:
              e.response && e.response.data.text
                ? e.response.data.text
                : 'Failed to create team',
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_CUSTOMER_TEAM_FAILED',
        payload: `Failed to create team`,
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default createCustomer;
