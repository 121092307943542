const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  analysisReviews: null,
  emptyList: null,
  lastSeen: null,
  lastTime: 0,
};

const getSalesCallReviewsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_SALES_CALL_REVIEWS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        analysisReviews: action.payload.analysisReviews,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_SALES_CALL_REVIEW_TO_ALL_REVIEWS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        analysisReviews: Array.isArray(action.payload.analysisReviews)
          ? state.analysisReviews
            ? state.analysisReviews.concat(action.payload.analysisReviews)
            : action.payload.analysisReviews
          : state.analysisReviews,
        emptyList:
          Array.isArray(action.payload.analysisReviews) &&
          (action.payload.analysisReviews.length === 0 ||
            action.payload.analysisReviews.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'UPDATE_SALES_CALL_ANALYSIS': {
      const updatedSalesReviews = state.analysisReviews.map((sr) => {
        if (sr?.id === action.payload.id) {
          return action.payload.data;
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        analysisReviews: updatedSalesReviews,
        lastTime: state.lastTime,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
      const updatedSalesReviews = state.analysisReviews.map((sr) => {
        if (sr?.id === action.payload.id) {
          return {
            ...sr,
            speakerScoreMap: action.payload.value,
          };
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        analysisReviews: updatedSalesReviews,
        lastTime: state.lastTime,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
      const updatedSalesReviews = state.analysisReviews.map((sr) => {
        if (sr?.id === action.payload.id) {
          return {
            ...sr,
            analysis: {
              ...sr.analysis,
              scoreStatus: action.payload.scoreStatus,
            },
          };
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        analysisReviews: updatedSalesReviews,
        lastTime: state.lastTime,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        analysisReviews: [],
        emptyList: false,
        lastSeen: '',
        lastTime: 0,
      });

    case 'FETCHING_ALL_SALES_CALL_REVIEWS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getSalesCallReviewsReducer;
