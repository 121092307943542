import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const trackEvent = (eventDto, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'TRACKING_EVENT' });
    const reqId = uuidv4();
    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      // console.log(ipResponse);
      let response = null;
      if (process.env.REACT_APP_TARGET_ENV === 'local') {
        response = {
          status: 200,
          data: null,
        };
        // console.log('tracking event', eventDto);
      } else {
        response = await axios.post(
          `/us/events`,
          { ...eventDto, ip: ipResponse?.data?.ip || null },
          {
            params: {
              'request-id': reqId,
            },
            headers: {
              //   Authorization: `Bearer ${token}`,
            },
          },
        );
      }
      if (response.status === 200) {
        await dispatch({
          type: 'TRACKING_EVENT_DONE',
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'TRACKING_EVENT_FAILED',
          payload: `Failed to track event to mixpanel`,
        });
      }
    } catch (e) {
      dispatch({
        type: 'TRACKING_EVENT_FAILED',
        payload: `Failed to track event to mixpanel`,
      });
    }
  };
};

export default trackEvent;
