const initialstate = {
  processing: null,
  done: null,
  failed: null,
  error: null,
  errorText: null,
};

const registerForCompetitionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'REGISTERING_FOR_COMPETITION':
      return Object.assign({}, state, {
        processing: true,
      });
    case 'REGISTERING_FOR_COMPETITION_DONE':
      return Object.assign({}, state, {
        processing: false,
        done: true,
        failed: false,
        error: false,
        errorText: '',
      });
    case 'REGISTERING_FOR_COMPETITION_FAILED':
      return Object.assign({}, state, {
        processing: false,
        done: false,
        failed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default registerForCompetitionReducer;
