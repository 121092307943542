const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  membersByOrgId: null,
  emptyList: null,
  lastSeen: null,
};

const getMembersByOrgIdReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_MEMBERS_BY_ORGANIZATION_ID':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_MEMBERS_BY_ORGANIZATION_ID_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_MEMBERS_BY_ORGANIZATION_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        membersByOrgId: action.payload.membersByOrgId,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_MEMBERS_TO_ALL_MEMBERS_BY_ORGANIZATION_ID':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        membersByOrgId: Array.isArray(action.payload.membersByOrgId)
          ? state.membersByOrgId
            ? state.membersByOrgId.concat(action.payload.membersByOrgId)
            : action.payload.membersByOrgId
          : state.membersByOrgId,
        emptyList:
          Array.isArray(action.payload.membersByOrgId) &&
          (action.payload.membersByOrgId.length === 0 ||
            action.payload.membersByOrgId.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    // case 'UPDATE_SALES_CALL_ANALYSIS': {
    //   const updatedSalesReviews = state.membersByOrgId.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return action.payload.data;
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     membersByOrgId: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.membersByOrgId.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         speakerScoreMap: action.payload.value,
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     membersByOrgId: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.membersByOrgId.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     membersByOrgId: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_MEMBERS_BY_ORGANIZATION_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        membersByOrgId: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_MEMBERS_BY_ORGANIZATION_ID_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getMembersByOrgIdReducer;
