import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const postFeedbackOnEntity = (
  entityId,
  feedbackRequest,
  callback,
  errCallback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'SAVING_FEEDBACK_ON_ENTITY' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/cs/entity/${entityId}/feedback-and-rating`,
            feedbackRequest,
            {
              params: {
                'request-id': reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                    // 'current-role': currentRole,
                  }
                : {},
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'SAVING_FEEDBACK_ON_ENTITY_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'SAVING_FEEDBACK_ON_ENTITY_FAILED',
              payload: `Failed to submit feedback. Please try again!`,
            });
            if (typeof errCallback === 'function') {
              errCallback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'SAVING_FEEDBACK_ON_ENTITY_FAILED',
            payload: `Failed to submit feedback. Please try again!`,
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'SAVING_FEEDBACK_ON_ENTITY_FAILED',
        payload: `Failed to submit feedback. Please try again!`,
      });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default postFeedbackOnEntity;
