import { Box, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange, readOnly, height, placeholder }) => {
  const theme = useTheme();
  const reactQuillRef = useRef(null);
  const [unprivilegedEditor, setUnprevilegedEditor] = useState(null);

  useEffect(() => {
    if (reactQuillRef?.current) {
      const editor = reactQuillRef.current.getEditor();
      setUnprevilegedEditor(
        reactQuillRef.current.makeUnprivilegedEditor(editor),
      );
    }
  }, [reactQuillRef]);

  // console.log(unprivilegedEditor?.getHTML());

  return (
    <Box
      sx={{
        height: readOnly ? (height ? height : 'auto') : '250px',
        '& .ql-container.ql-snow': {
          borderRadius: 0,
          border: readOnly ? 'none' : '1px solid #d3d3d3',
        },
        '& .ql-container': {
          fontSize: 16,
        },
        // '& .quill > .ql-container > .ql-editor.ql-blank::before': {
        //   fontSize: 20,
        //   color: '#333',
        // },
      }}
    >
      <ReactQuill
        style={{
          height: readOnly
            ? height
              ? height
              : 'auto'
            : height
            ? height
            : '190px',
          whiteSpace: 'pre-wrap',
        }}
        ref={reactQuillRef}
        theme="snow"
        value={value}
        readOnly={readOnly}
        onChange={(content, delta, source, editor) => {
          // console.log(content);
          onChange(content);
        }}
        placeholder={placeholder || ''}
        modules={{
          toolbar: readOnly
            ? null
            : [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['blockquote', 'code-block'], // custom button values
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ direction: 'rtl' }], // text direction

                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ['link', 'image'],
              ],
          clipboard: {
            matchVisual: false,
          },
        }}
      />
    </Box>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  height: PropTypes.string,
  placeholder: PropTypes.string,
};

export default RichTextEditor;
