import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

let baseURL = '';

if (process.env.REACT_APP_TARGET_ENV === 'local') {
  baseURL = 'http://localhost:5058/api/';
}

if (process.env.REACT_APP_TARGET_ENV === 'development') {
  baseURL = 'https://app.sellingbees.com/api/';
}

if (process.env.REACT_APP_TARGET_ENV === 'production') {
  baseURL = 'https://app.qualification.ai/api/';
}

const logErrorToSlack = (err, token) => {
  try {
    const requestHeaders = err?.config?.headers;
    const objkeys = Object.keys(requestHeaders);
    const filteredKeys = objkeys.filter((key) => key !== 'Authorization');
    const filteredRequestHeaders = filteredKeys.reduce((curr, acc) => {
      return Object.assign(curr, { [acc]: requestHeaders[acc] });
    }, {});
    const msg = {
      errorText: err?.response?.data?.error,
      errorType: err?.response?.statusText,
      requestBody: err?.config?.data,
      requestMethod: err?.config?.method,
      requestParams: err?.config?.params,
      requestHeaders: filteredRequestHeaders,
      severity: 'error',
      statusCode: err?.response?.status,
      api: err?.response?.data?.path || err?.config?.url,
    };
    const reqId = uuidv4();
    try {
      axios.post(
        `${baseURL}log`,
        {
          message: '',
          data: {
            formatted: msg,
          },
        },
        {
          params: {
            'request-id': reqId,
          },
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
        },
      );
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    console.log(e);
  }
};

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  (res) => {
    // console.log('res', res);
    return res;
  },
  (err) => {
    if (err?.config?.url !== 'https://api.ipify.org?format=json') {
      logErrorToSlack(
        err,
        err?.config?.headers?.Authorization?.split(' ')?.[1],
      );
    }
    return err;
  },
);

export default instance;
