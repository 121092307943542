import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const registerForCompetition = (
  competitionId,
  currentRole,
  source,
  callback,
  errCb,
) => {
  return async (dispatch) => {
    dispatch({ type: 'REGISTERING_FOR_COMPETITION' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/cs/competitions/${competitionId}/participation`,
            {
              source: source,
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                'current-role': currentRole,
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'REGISTERING_FOR_COMPETITION_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'REGISTERING_FOR_COMPETITION_FAILED',
              payload: `Failed to register for the competition with id ${competitionId}`,
            });
            if (typeof errCb === 'function') {
              errCb(response?.response?.data?.message);
            }
          }
        } catch (e) {
          dispatch({
            type: 'REGISTERING_FOR_COMPETITION_FAILED',
            payload: `Failed to register for the competition with id ${competitionId}`,
          });
          if (typeof errCb === 'function') {
            errCb(e?.response?.data?.message);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'REGISTERING_FOR_COMPETITION_FAILED',
        payload: `Failed to register for the competition with id ${competitionId}`,
      });
      if (typeof errCb === 'function') {
        errCb(e?.response?.data?.message);
      }
    }
  };
};

export default registerForCompetition;
