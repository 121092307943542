/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import {
  Alert,
  Avatar,
  CircularProgress,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import { InputLabel } from '@mui/material';
import { auth } from '../../../../../firebase';
import {
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import firebaseSignup from 'redux/actions/Auth/firebaseSignup';
import firebaseSignin from 'redux/actions/Auth/firebaseSignin';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import uploadProfilePicture from 'redux/actions/Auth/uploadProfilePicture';
import { useHistory, useLocation } from 'react-router-dom';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import bookDemo from 'redux/actions/Auth/bookDemo';
import saveElevatorPitchProgress from 'redux/actions/Common/saveElevatorPitchProgress';
import isEmailVerified from 'redux/actions/Auth/isEmailVerified';
import { useDebouncedCallback } from 'use-debounce';
import validateInvitationToken from 'redux/actions/Auth/validateInvitationToken';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]+$/gi, {
      message: 'Special charactes are not allowed.',
      excludeEmptyString: true,
    })
    .trim()
    .min(2, 'Minimum length should be 2')
    .max(50, 'Maximum length should be 50'),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]+$/gi, {
      message: 'Special charactes are not allowed.',
      excludeEmptyString: true,
    })
    .trim()
    .min(2, 'Minimum length should be 2')
    .max(50, 'Maximum length should be 50'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(5, 'The password should have at minimum length of 5'),
});

const validationSchemaBookDemo = yup.object({
  firstName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]+$/gi, {
      message: 'Special charactes are not allowed.',
      excludeEmptyString: true,
    })
    .trim()
    .min(2, 'Minimum length should be 2')
    .max(50, 'Maximum length should be 50'),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]+$/gi, {
      message: 'Special charactes are not allowed.',
      excludeEmptyString: true,
    })
    .trim()
    .min(2, 'Minimum length should be 2')
    .max(50, 'Maximum length should be 50'),
  companyName: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]+$/gi, {
      message: 'Special charactes are not allowed.',
      excludeEmptyString: true,
    })
    .trim()
    .required('Company Name is required.')
    .min(2, 'Minimum length should be 2')
    .max(50, 'Maximum length should be 100'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const Form = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const inputImage = useRef();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invalidImageType, setInvalidImageType] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const invitationValidationData = useSelector(
    (state) => state.invitationValidationData,
  );
  const prcInvitationValidationData = useSelector(
    (state) => state.prcInvitationValidationData,
  );
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const redirectTo = params.get('redirectTo');
  const redirectToId = params.get('redirectToId');
  const elevatorPitchToken = params.get('pt');
  const [signupFor, setSignupFor] = React.useState('employer');
  const [bookDemoStatus, setBookDemoStatus] = useState('');
  const [bookDemoError, setBookDemoError] = useState('');
  const bookdemo = params.get('bookdemo');
  const t = params.get('t');
  const invitationId = params.get('invitationId');
  const epst = params.get('epst');
  const [signupType, setSignupType] = useState('self');
  const [emailDomainError, setEmailDomainError] = useState('');

  const handleChange = (event, newValue) => {
    setSignupFor(newValue);
  };

  const debounced = useDebouncedCallback((e) => {
    if (epst) {
      dispatch(
        validateInvitationToken(
          epst,
          formik.values.email,
          (data) => {
            console.log('validationData', data);
            setEmailDomainError('');
          },
          (error) => {
            console.log('validationError', error);
            if (error === 'public_domain_restricted') {
              setEmailDomainError(
                'Please enter a valid email with valid company domain. Hint: Public domain e.g. gmail.com, ymail.com is not allowed.',
              );
            }
          },
        ),
      );
    }
  }, 1000);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: params.get('email')
      ? params.get('email').replace(/ /g, '+')
      : params.get('continueUrl')
      ? new URL(decodeURIComponent(params.get('continueUrl'))).searchParams
          .get('email')
          .replace(/ /g, '+')
      : '',
    password: '',
    designations: ['candidate'],
  };

  const initialValuesBookDemo = {
    firstName: '',
    lastName: '',
    email: params.get('email')
      ? params.get('email').replace(/ /g, '+')
      : params.get('continueUrl')
      ? new URL(decodeURIComponent(params.get('continueUrl'))).searchParams
          .get('email')
          .replace(/ /g, '+')
      : '',
    companyName: '',
  };

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleGoogleSignup = () => {
    setProcessing(true);
    try {
      let provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    } catch (e) {
      setProcessing(false);
      setError('Something went wrong. Please try again');
    }
  };

  const onFileChange = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError(true);
      } else {
        setSelectedFile(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      inputImage.current.value = null;
      setInvalidImageType(true);
    }
  };

  const onSubmit = (values) => {
    setProcessing('Creating Account ...');
    createUserWithEmailAndPassword(auth, values.email, values.password)
      .then((userCreds) => {
        userCreds.user
          .getIdToken()
          .then((idToken) => {
            // console.log(idToken);
            dispatch(
              firebaseSignup(
                {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  profilePicture: selectedFile,
                  designations: values?.designations,
                  idToken,
                  invitationId: invitationId || epst,
                  slug: redirectTo ? `${redirectTo}/${redirectToId}` : null,
                },
                (createUserResponse) => {
                  setProcessing('Completing Signup ...');
                  auth.currentUser.getIdToken(true).then((idToken) => {
                    // console.log(idToken);
                    dispatch(
                      isEmailVerified(idToken, (data, error) => {
                        if (data) {
                          dispatch(
                            firebaseSignin(
                              idToken,
                              (signinResponse) => {
                                if (signinResponse?.emailVerified) {
                                  setProcessing('');
                                  dispatch(
                                    firebaseCheckSession((isLoggedIn) => {
                                      if (isLoggedIn) {
                                        if (redirectTo) {
                                          if (redirectToId) {
                                            history.push(
                                              `/${redirectTo}/${redirectToId}?comingFromRedirect=true`,
                                            );
                                          } else {
                                            history.push(
                                              `/${redirectTo}?comingFromRedirect=true`,
                                            );
                                          }
                                        } else {
                                          if (
                                            signinResponse?.whitelabelDomain
                                          ) {
                                            if (
                                              signinResponse?.loginCount === 1
                                            ) {
                                              if (
                                                window.location.host !==
                                                signinResponse?.whitelabelDomain
                                              ) {
                                                dispatch(
                                                  firebaseLogout(() => {
                                                    dispatch(
                                                      firebaseCheckSession(),
                                                    );
                                                    history.go();
                                                  }),
                                                );
                                                window.open(
                                                  `https://${signinResponse?.whitelabelDomain}/account/settings?t=playbook`,
                                                );
                                              } else {
                                                history.push(
                                                  `/account/settings?t=playbook`,
                                                );
                                              }
                                            } else {
                                              if (signinResponse?.user?.slug) {
                                                console.log(
                                                  signinResponse?.user?.slug,
                                                );
                                                if (
                                                  window.location.host !==
                                                  signinResponse?.whitelabelDomain
                                                ) {
                                                  dispatch(
                                                    firebaseLogout(() => {
                                                      dispatch(
                                                        firebaseCheckSession(),
                                                      );
                                                      history.go();
                                                    }),
                                                  );
                                                  window.open(
                                                    `https://${signinResponse?.whitelabelDomain}/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                                  );
                                                } else {
                                                  history.push(
                                                    `/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                                  );
                                                }
                                              } else {
                                                if (
                                                  window.location.host !==
                                                  signinResponse?.whitelabelDomain
                                                ) {
                                                  dispatch(
                                                    firebaseLogout(() => {
                                                      dispatch(
                                                        firebaseCheckSession(),
                                                      );
                                                      history.go();
                                                    }),
                                                  );
                                                  window.open(
                                                    `https://${signinResponse?.whitelabelDomain}`,
                                                  );
                                                } else {
                                                  history.push('/');
                                                }
                                              }
                                            }
                                          } else {
                                            if (
                                              signinResponse?.organization
                                                ?.type === 'w_org'
                                            ) {
                                              history.push(
                                                '/customer/settings',
                                              );
                                            } else {
                                              if (
                                                signinResponse?.loginCount === 1
                                              ) {
                                                dispatch({
                                                  type: 'UPDATE_UI_STATE',
                                                  payload: {
                                                    key: 'showOnboardingDialog',
                                                    value: true,
                                                  },
                                                });
                                                history.push(
                                                  `/account/settings?t=playbook`,
                                                );
                                              } else {
                                                if (
                                                  signinResponse?.user?.slug
                                                ) {
                                                  console.log(
                                                    signinResponse?.user?.slug,
                                                  );
                                                  history.push(
                                                    `/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                                  );
                                                } else {
                                                  history.push('/');
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                  );
                                } else {
                                  signOut(auth).then(() => {});
                                  sendEmailVerification(auth.currentUser);
                                  setProcessing('');
                                  setInfo(
                                    "Your account has been created. Please click the link we've emailed you, to complete verification and sign in. Redirecting you to sign in page shortly ...",
                                  );
                                  if (elevatorPitchToken?.trim()) {
                                    dispatch(
                                      saveElevatorPitchProgress(
                                        elevatorPitchToken,
                                        signinResponse?.user?.id,
                                        () => {
                                          setTimeout(
                                            () =>
                                              history.push(
                                                `/auth/signin${search}`,
                                              ),
                                            5000,
                                          );
                                        },
                                      ),
                                    );
                                  } else {
                                    setTimeout(
                                      () => history.push(`/auth/signin`),
                                      5000,
                                    );
                                  }
                                }
                              },
                              (err) => {
                                if (err) {
                                  setError(err);
                                  setProcessing('');
                                } else {
                                  setError('Failed to sign in');
                                  setProcessing('');
                                }
                              },
                            ),
                          );
                        } else if (error || !data) {
                          signOut(auth).then(() => {});
                          sendEmailVerification(auth.currentUser);
                          setProcessing('');
                          setInfo(
                            "Your account has been created. Please click the link we've emailed you, to complete verification and sign in. Redirecting you to sign in page shortly ...",
                          );
                          if (elevatorPitchToken?.trim()) {
                            dispatch(
                              saveElevatorPitchProgress(
                                elevatorPitchToken,
                                createUserResponse?.user?.id,
                                () => {
                                  setTimeout(
                                    () => history.push(`/auth/signin${search}`),
                                    5000,
                                  );
                                },
                              ),
                            );
                          } else {
                            setTimeout(
                              () => history.push(`/auth/signin`),
                              5000,
                            );
                          }
                        }
                      }),
                    );
                  });
                },
              ),
            );
          })
          .catch((err) => {
            setError('Failed to create account. Please try again!');
            setProcessing(false);
          });
      })
      .catch((err) => {
        dispatch(
          logError({
            message: err?.message,
            data: {
              formatted: {
                email: values.email,
                errorText: err?.message,
                errorType: err?.code,
                severity: 'error',
              },
              raw: err,
            },
          }),
        );
        console.log(err.code);
        if (err.code === 'auth/email-already-in-use') {
          setError('You are already registered. Please continue to signin');
        } else {
          setError(err.message);
        }
        setProcessing(false);
      });
  };

  const onSubmitBookDemo = (values) => {
    setProcessing(true);
    dispatch(
      bookDemo(
        {
          domain: domainDetails?.whitelabelDomain || null,
          firstName: formikBookDemo.values.firstName,
          lastName: formikBookDemo.values.lastName,
          email: formikBookDemo.values.email,
          companyName: formikBookDemo.values.companyName,
        },
        () => {
          setProcessing(false);
          setBookDemoStatus('success');
          setBookDemoError('');
        },
        () => {
          setProcessing(false);
          setBookDemoStatus('error');
          setBookDemoError('Something wen wrong. Please try again later!');
        },
      ),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const formikBookDemo = useFormik({
    initialValues: initialValuesBookDemo,
    validationSchema: validationSchemaBookDemo,
    onSubmit: onSubmitBookDemo,
  });

  useEffect(() => {
    if (invitationValidationData?.joined) {
      history.push(
        `/auth/signin${search}&email=${invitationValidationData?.email}`,
      );
    } else {
      if (invitationValidationData?.email) {
        // if (invitationValidationData?.designations?.includes('employer')) {
        //   setSignupFor('employer');
        //   setSignupType('invite');
        //   formik.setFieldValue('email', invitationValidationData?.email || '');
        //   formik.setFieldValue(
        //     'designations',
        //     invitationValidationData.designations,
        //   );
        // } else if (
        //   invitationValidationData?.designations?.includes('candidate')
        // ) {
        //   setSignupType('invite');
        //   setSignupFor('candidate');
        //   formik.setFieldValue('email', invitationValidationData?.email || '');
        //   formik.setFieldValue(
        //     'designations',
        //     invitationValidationData.designations,
        //   );
        // } else
        if (invitationValidationData?.invitedForWhileLabeling) {
          setSignupFor('whitelabel_user');
          setSignupType('invite');
          formik.setFieldValue('email', invitationValidationData?.email || '');
          formik.setFieldValue(
            'designations',
            invitationValidationData.designations,
          );
          formik.setFieldValue(
            'firstName',
            invitationValidationData?.firstName || '',
          );
          formik.setFieldValue(
            'lastName',
            invitationValidationData?.lastName || '',
          );
        } else if (
          invitationValidationData?.designations?.includes('account_admin')
        ) {
          setSignupFor('account_admin');
          setSignupType('invite');
          formik.setFieldValue('email', invitationValidationData?.email || '');
          formik.setFieldValue(
            'designations',
            invitationValidationData.designations,
          );
          formik.setFieldValue(
            'firstName',
            invitationValidationData?.firstName || '',
          );
          formik.setFieldValue(
            'lastName',
            invitationValidationData?.lastName || '',
          );
        } else {
          setSignupFor('account_admin');
          setSignupType('invite');
          formik.setFieldValue('email', invitationValidationData?.email || '');
          formik.setFieldValue('designations', ['account_admin']);
          formik.setFieldValue(
            'firstName',
            invitationValidationData?.firstName || '',
          );
          formik.setFieldValue(
            'lastName',
            invitationValidationData?.lastName || '',
          );
        }
      } else {
        if (invitationValidationData?.designations?.includes('employer')) {
          setSignupFor('employer');
          setSignupType('invite');
          formik.setFieldValue(
            'designations',
            invitationValidationData.designations,
          );
        } else if (
          invitationValidationData?.designations?.includes('account_admin')
        ) {
          setSignupFor('account_admin');
          setSignupType('invite');
          formik.setFieldValue(
            'designations',
            invitationValidationData.designations,
          );
        }
      }
    }
  }, [invitationValidationData]);

  useEffect(() => {
    if (prcInvitationValidationData?.joined) {
      history.push(
        `/auth/signin${search}&email=${prcInvitationValidationData?.email}`,
      );
    } else {
      if (prcInvitationValidationData?.email) {
        setSignupType('invite');
        setSignupFor('candidate');
        formik.setFieldValue('email', prcInvitationValidationData?.email || '');
        formik.setFieldValue(
          'firstName',
          prcInvitationValidationData?.firstName || '',
        );
        formik.setFieldValue(
          'lastName',
          prcInvitationValidationData?.lastName || '',
        );
        formik.setFieldValue('designations', ['candidate']);
      }
    }
  }, [prcInvitationValidationData]);

  useEffect(() => {
    // if (bookdemo) {
    setSignupFor('employer');
    // } else {
    // setSignupFor('account_admin');
    // }
  }, [bookdemo]);

  useEffect(() => {
    const handleRedirectFromGoogle = async () => {
      try {
        const redirectResult = await getRedirectResult(auth);
        if (redirectResult) {
          console.log(redirectResult);
        }
      } catch (e) {
        setProcessing(false);
        setError('Something went wrong.Please try again!');
      }
    };
    handleRedirectFromGoogle();
  }, []);

  // console.log(formik.errors);
  // console.log(formik.touched);
  // console.log(signupFor);
  // console.log(formik.values.designations);
  // console.log(t);

  return (
    <Box>
      <Box
        sx={{
          padding: 2,
        }}
      >
        {signupFor === 'candidate' || signupFor === 'account_admin' ? (
          <>
            <Box marginBottom={2} marginTop={2}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  // textAlign: 'center',
                }}
              >
                Create Account
              </Typography>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'center', sm: 'center' }}
                justifyContent={'flex-start'}
                width={1}
                maxWidth={600}
                // margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Typography variant={'subtitle2'}>
                    Already have an account?{' '}
                    <Link
                      component={'a'}
                      data-trackid={`move_to_sign_in_page`}
                      color={'primary'}
                      href={`/auth/signin${search}`}
                      underline={'none'}
                      onClick={(e) => {
                        handleTrackEvent(e, 'move_to_sign_in_page');
                      }}
                    >
                      Sign In.
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                {/* <Grid item xs={8} sm={8} md={8} alignSelf={'end'}>
            <FormControl
              component="fieldset"
              variant="standard"
              error={!!formik.errors.designations}
            >
              <FormLabel component="legend">Signing Up as</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.designations.includes('employer')}
                      value={'employer'}
                      name="designations"
                    />
                  }
                  label="Employer"
                  onChange={formik.handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.designations.includes('expert')}
                      value={'expert'}
                      name="designations"
                    />
                  }
                  label="Expert"
                  onChange={formik.handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.designations.includes('candidate')}
                      value={'candidate'}
                      name="designations"
                    />
                  }
                  label="Candidate"
                  onChange={formik.handleChange}
                />
              </FormGroup>
              {formik.errors.designations ? (
                <FormHelperText>
                  {formik.errors.designations ?? ' '}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} md={4} alignSelf={'end'}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'column' }}
              alignItems={{ xs: 'center', sm: 'center' }}
              justifyContent={'center'}
              width={1}
              maxWidth={600}
              // margin={'10px auto'}
            >
              <Typography
                color="text.secondary"
                sx={{
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                Profile Picture
              </Typography>
              <InputLabel htmlFor="profileImg" style={{ cursor: 'pointer' }}>
                <Avatar
                  variant="circular"
                  alt="Profile"
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : '/default-profile.png'
                  }
                  sx={{
                    width: isMd ? theme.spacing(10) : theme.spacing(10),
                    height: isMd ? theme.spacing(10) : theme.spacing(10),
                    border: '2px solid #343a40',
                    // boxShadow: '0 0 10px #343a40',
                    // borderRadius: '50%',
                    // margin: 10,
                  }}
                />
                <input
                  style={{ display: 'none' }}
                  id="profileImg"
                  ref={inputImage}
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: isMd ? -3 : -8,
                    right: isMd ? -3 : -8,
                    color: '#000',
                  }}
                >
                  {selectedFile !== null ? (
                    <LoopIcon
                      style={{ fontSize: '2rem', transform: 'rotate(90deg)' }}
                    />
                  ) : (
                    <AddIcon style={{ fontSize: '2rem' }} />
                  )}
                </Box>
              </InputLabel>
            </Box>
          </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={'subtitle2'}
                    sx={{ marginBottom: 1, fontWeight: 'bold' }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    placeholder="Enter your first name"
                    variant="outlined"
                    name={'firstName'}
                    fullWidth
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={'subtitle2'}
                    sx={{ marginBottom: 1, fontWeight: 'bold' }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Enter your last name"
                    variant="outlined"
                    name={'lastName'}
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={'subtitle2'}
                    sx={{ marginBottom: 1, fontWeight: 'bold' }}
                  >
                    Email *
                  </Typography>
                  <TextField
                    placeholder="Enter your email *"
                    variant="outlined"
                    name={'email'}
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={'subtitle2'}
                    sx={{ marginBottom: 1, fontWeight: 'bold' }}
                  >
                    Password *
                  </Typography>
                  <TextField
                    placeholder="Enter your password *"
                    variant="outlined"
                    name={'password'}
                    fullWidth
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    type={passwordVisible ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-trackid={`toggle_password_visibility`}
                            aria-label="toggle password visibility"
                            onClick={(e) => {
                              handleTrackEvent(e, 'toggle_password_visibility');
                              setPasswordVisible(!passwordVisible);
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
                {info && (
                  <Grid item xs={12}>
                    <Alert severity="info">{info}</Alert>
                  </Grid>
                )}
                <Grid item container xs={12}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'center' }}
                    justifyContent={'space-between'}
                    width={1}
                    // maxWidth={600}
                    // margin={'0 auto'}
                  >
                    <Button
                      data-trackid={'cta_sign_up'}
                      fullWidth
                      size={'large'}
                      variant={'contained'}
                      type={'submit'}
                      disabled={processing}
                      startIcon={
                        processing ? <CircularProgress size={18} /> : null
                      }
                      onClick={(e) => {
                        handleTrackEvent(e, 'cta_sign_up');
                      }}
                    >
                      {processing ? processing : 'Sign up'}
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  // justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Typography
                    variant={'subtitle2'}
                    color={'text.secondary'}
                    // align={'center'}
                  >
                    By clicking "Sign up" button you agree with our{' '}
                    <Link
                      component={'a'}
                      data-trackid={`open_terms_of_service`}
                      color={'primary'}
                      target="_blank"
                      href={`https://qualification.ai/tos`}
                      underline={'none'}
                      onClick={(e) => {
                        handleTrackEvent(e, 'open_terms_of_service');
                      }}
                    >
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                      data-trackid={`open_privacy_policy`}
                      component={'a'}
                      color={'primary'}
                      target="_blank"
                      href={`https://qualification.ai/privacy-policy`}
                      underline={'none'}
                      onClick={(e) => {
                        handleTrackEvent(e, 'open_privacy_policy');
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </Grid>
                {/* <Grid
            item
            container
            xs={12}
            // justifyContent={'center'}
            alignItems={'center'}
          >
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'space-around'}
              width={1}
            >
              <Box
                sx={{ background: '#c3c3c3', height: '1px', width: 1 }}
              ></Box>
              <Typography
                color="text.secondary"
                sx={{
                  textAlign: 'center',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                or
              </Typography>
              <Box
                sx={{ background: '#c3c3c3', height: '1px', width: 1 }}
              ></Box>
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Button
              onClick={handleGoogleSignup}
              variant="contained"
              color="primary"
              sx={{
                background: '#f0f0f0',
                color: '#343a40',
                width: 1,
                ':hover': { background: '#f0f0f0' },
              }}
            >
              <img
                width="20px"
                height="20px"
                style={{ marginRight: '20px' }}
                src="/google.svg"
                alt="google"
              />
              Continue with Google
            </Button>
          </Grid> */}
              </Grid>
            </form>
          </>
        ) : signupFor === 'employer' ? (
          signupType === 'invite' ? (
            <>
              <Box marginBottom={2} marginTop={2}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    // textAlign: 'center',
                  }}
                >
                  Create Account
                </Typography>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'center', sm: 'center' }}
                  justifyContent={'flex-start'}
                  width={1}
                  maxWidth={600}
                  // margin={'0 auto'}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={'subtitle2'}>
                      Already have an account?{' '}
                      <Link
                        component={'a'}
                        data-trackid={`move_to_sign_in_page`}
                        color={'primary'}
                        href={`/auth/signin${search}`}
                        underline={'none'}
                        onClick={(e) => {
                          handleTrackEvent(e, 'move_to_sign_in_page');
                        }}
                      >
                        Sign In.
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      First Name
                    </Typography>
                    <TextField
                      placeholder="Enter your first name"
                      variant="outlined"
                      name={'firstName'}
                      fullWidth
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Last Name
                    </Typography>
                    <TextField
                      placeholder="Enter your last name"
                      variant="outlined"
                      name={'lastName'}
                      fullWidth
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Email *
                    </Typography>
                    <TextField
                      placeholder="Enter your email *"
                      variant="outlined"
                      name={'email'}
                      fullWidth
                      value={formik.values.email}
                      onChange={(e) => {
                        formik.handleChange(e);
                        debounced();
                      }}
                      error={
                        (formik.touched.email &&
                          Boolean(formik.errors.email)) ||
                        emailDomainError
                      }
                      helperText={
                        emailDomainError ||
                        (formik.touched.email && formik.errors.email)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Password *
                    </Typography>
                    <TextField
                      placeholder="Enter your password *"
                      variant="outlined"
                      name={'password'}
                      fullWidth
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      type={passwordVisible ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-trackid={`toggle_password_visibility`}
                              aria-label="toggle password visibility"
                              onClick={(e) => {
                                handleTrackEvent(
                                  e,
                                  'toggle_password_visibility',
                                );
                                setPasswordVisible(!passwordVisible);
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {passwordVisible ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Alert severity="error">{error}</Alert>
                    </Grid>
                  )}
                  {info && (
                    <Grid item xs={12}>
                      <Alert severity="info">{info}</Alert>
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      alignItems={{ xs: 'stretched', sm: 'center' }}
                      justifyContent={'space-between'}
                      width={1}
                      // maxWidth={600}
                      // margin={'0 auto'}
                    >
                      <Button
                        data-trackid={'cta_sign_up'}
                        fullWidth
                        size={'large'}
                        variant={'contained'}
                        type={'submit'}
                        disabled={processing}
                        startIcon={
                          processing ? <CircularProgress size={18} /> : null
                        }
                        onClick={(e) => {
                          handleTrackEvent(e, 'cta_sign_up');
                        }}
                      >
                        {processing ? processing : 'Sign up'}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    // justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'subtitle2'}
                      color={'text.secondary'}
                      // align={'center'}
                    >
                      By clicking "Sign up" button you agree with our{' '}
                      <Link
                        component={'a'}
                        data-trackid={`open_terms_of_service`}
                        color={'primary'}
                        target="_blank"
                        href={`/tos`}
                        underline={'none'}
                        onClick={(e) => {
                          handleTrackEvent(e, 'open_terms_of_service');
                        }}
                      >
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link
                        data-trackid={`open_privacy_policy`}
                        component={'a'}
                        color={'primary'}
                        target="_blank"
                        href={`/privacy`}
                        underline={'none'}
                        onClick={(e) => {
                          handleTrackEvent(e, 'open_privacy_policy');
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Grid>
                  {/* <Grid
          item
          container
          xs={12}
          // justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent={'space-around'}
            width={1}
          >
            <Box
              sx={{ background: '#c3c3c3', height: '1px', width: 1 }}
            ></Box>
            <Typography
              color="text.secondary"
              sx={{
                textAlign: 'center',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              or
            </Typography>
            <Box
              sx={{ background: '#c3c3c3', height: '1px', width: 1 }}
            ></Box>
          </Box>
        </Grid>
        <Grid item container xs={12}>
          <Button
            onClick={handleGoogleSignup}
            variant="contained"
            color="primary"
            sx={{
              background: '#f0f0f0',
              color: '#343a40',
              width: 1,
              ':hover': { background: '#f0f0f0' },
            }}
          >
            <img
              width="20px"
              height="20px"
              style={{ marginRight: '20px' }}
              src="/google.svg"
              alt="google"
            />
            Continue with Google
          </Button>
        </Grid> */}
                </Grid>
              </form>
            </>
          ) : bookDemoStatus === 'success' ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              minHeight={250}
            >
              <Check />
              <Typography variant="h5" textAlign="center" mt={2}>
                Your request is recieved. You will get an email invite soon.
              </Typography>
            </Box>
          ) : (
            <>
              <Box marginBottom={2} marginTop={2}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    // textAlign: 'center',
                  }}
                >
                  Request {t === 'n' ? 'Access' : 'a Demo'}
                </Typography>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'center', sm: 'center' }}
                  justifyContent={'flex-start'}
                  width={1}
                  maxWidth={600}
                  // margin={'0 auto'}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={'subtitle2'}>
                      Already have an account?{' '}
                      <Link
                        component={'a'}
                        data-trackid={`move_to_sign_in_page`}
                        color={'primary'}
                        href={`/auth/signin${search}`}
                        underline={'none'}
                        onClick={(e) => {
                          handleTrackEvent(e, 'move_to_sign_in_page');
                        }}
                      >
                        Sign In.
                      </Link>
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'center', sm: 'center' }}
                  justifyContent={'flex-start'}
                  width={1}
                  maxWidth={600}
                  margin={'0 auto'}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={'subtitle2'}>
                      Please fill out the required fields to get{' '}
                      {t === 'n' ? 'signup link' : 'demo scheduled'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <form onSubmit={formikBookDemo.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      First Name
                    </Typography>
                    <TextField
                      placeholder="Enter your first name"
                      variant="outlined"
                      name={'firstName'}
                      fullWidth
                      value={formikBookDemo.values.firstName}
                      onChange={formikBookDemo.handleChange}
                      error={
                        formikBookDemo.touched.firstName &&
                        Boolean(formikBookDemo.errors.firstName)
                      }
                      helperText={
                        formikBookDemo.touched.firstName &&
                        formikBookDemo.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Last Name
                    </Typography>
                    <TextField
                      placeholder="Enter your last name"
                      variant="outlined"
                      name={'lastName'}
                      fullWidth
                      value={formikBookDemo.values.lastName}
                      onChange={formikBookDemo.handleChange}
                      error={
                        formikBookDemo.touched.lastName &&
                        Boolean(formikBookDemo.errors.lastName)
                      }
                      helperText={
                        formikBookDemo.touched.lastName &&
                        formikBookDemo.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Company Name *
                    </Typography>
                    <TextField
                      placeholder="Company Name"
                      variant="outlined"
                      name={'companyName'}
                      fullWidth
                      value={formikBookDemo.values.companyName}
                      onChange={formikBookDemo.handleChange}
                      error={
                        formikBookDemo.touched.companyName &&
                        Boolean(formikBookDemo.errors.companyName)
                      }
                      helperText={
                        formikBookDemo.touched.companyName &&
                        formikBookDemo.errors.companyName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant={'subtitle2'}
                      sx={{ marginBottom: 1, fontWeight: 'bold' }}
                    >
                      Email *
                    </Typography>
                    <TextField
                      placeholder="Enter your email *"
                      variant="outlined"
                      name={'email'}
                      fullWidth
                      value={formikBookDemo.values.email}
                      onChange={formikBookDemo.handleChange}
                      error={
                        formikBookDemo.touched.email &&
                        Boolean(formikBookDemo.errors.email)
                      }
                      helperText={
                        formikBookDemo.touched.email &&
                        formikBookDemo.errors.email
                      }
                    />
                  </Grid>
                  {bookDemoError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{bookDemoError}</Alert>
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      alignItems={{ xs: 'stretched', sm: 'center' }}
                      justifyContent={'space-between'}
                      width={1}
                      maxWidth={600}
                      margin={'0 auto'}
                    >
                      <Button
                        data-trackid={'cta_sign_up'}
                        fullWidth
                        size={'large'}
                        variant={'contained'}
                        type={'submit'}
                        disabled={processing}
                        startIcon={
                          processing ? <CircularProgress size={18} /> : null
                        }
                        onClick={(e) => {
                          handleTrackEvent(e, 'cta_sign_up');
                        }}
                      >
                        Request {t === 'n' ? 'access' : 'a demo'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </>
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default Form;
