/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {
  applyActionCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { auth } from '../../../../../firebase';
import {
  Alert,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Form as SigninForm } from 'views/Authentication/SigninCover/components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(5, 'The password should have at minimum length of 5'),
});

const Form = () => {
  const params = new URLSearchParams(window.location.search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');
  const continueUrl = params.get('continueUrl');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [verifyingOobCode, setVerifyingOobCode] = useState(false);

  const initialValues = {
    email: params.get('email') || '',
    password: '',
  };

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const onSubmit = (values) => {
    setProcessing(true);
    confirmPasswordReset(auth, actionCode, values.password)
      .then(() => {
        setInfo('Password reset is successful. Redirecting back to signin ...');
        setError('');
        setTimeout(() => {
          if (continueUrl) window.location.replace(continueUrl);
          window.location.replace('/auth/signin');
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          logError({
            message: e?.message,
            data: {
              formatted: {
                errorText: e?.message,
                errorType: e?.code,
                severity: 'error',
              },
              raw: e,
            },
          }),
        );
        setProcessing(false);
        setError('Failed to reset password. Pleasetry generating new link.');
        setInfo('');
      });
    // return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (mode === 'resetPassword') {
      setVerifyingOobCode(true);
      verifyPasswordResetCode(auth, actionCode)
        .then((email) => {
          formik.setFieldValue('email', email);
          setVerifyingOobCode(false);
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            logError({
              message: e?.message,
              data: {
                formatted: {
                  errorText: e?.message,
                  errorType: e?.code,
                  severity: 'error',
                },
                raw: e,
              },
            }),
          );
          setError(
            'This link is expired or invalid. Please try generating new link.',
          );
          setVerifyingOobCode(false);
        });
    } else {
      applyActionCode(auth, actionCode)
        .then(() => {
          setEmailVerified(true);
        })
        .catch((e) => {
          if (e.code === 'auth/expired-action-code') {
            setEmailVerificationError(
              'Email verification link expired. Please try to sign in to get a new link.',
            );
          } else {
            setEmailVerificationError(
              'Email verification failed. The email might have been verified already. Please try to sign in.',
            );
          }
          console.log(e);
          dispatch(
            logError({
              message: e?.message,
              data: {
                formatted: {
                  errorText: e?.message,
                  errorType: e?.code,
                  severity: 'error',
                },
                raw: e,
              },
            }),
          );
        });
    }
  }, []);

  return (
    <Box>
      <Box marginBottom={4}>
        {formik.values.email ? (
          <Chip label={formik.values.email} sx={{ marginBottom: '10px' }} />
        ) : null}
        {mode === 'resetPassword' ? (
          <>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'text.secondary'}
            >
              {mode === 'resetPassword'
                ? 'Reset Password'
                : 'Email Verification'}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
              }}
            >
              Set new password
            </Typography>
            <Typography color="text.secondary">
              Enter new password and we'll reset it for you.
            </Typography>
          </>
        ) : null}
      </Box>
      {mode === 'resetPassword' ? (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {info && (
              <Grid item xs={12}>
                <Alert severity="success">{info}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Enter new password *"
                variant="outlined"
                name={'password'}
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                type={passwordVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-trackid={'toggle_password_visibility'}
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          handleTrackEvent(e, 'toggle_password_visibility');
                          setPasswordVisible(!passwordVisible);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                maxWidth={600}
                margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Button
                    data-trackid={'cta_back_to_sign_in'}
                    size={'large'}
                    variant={'outlined'}
                    component={Link}
                    href={'/auth/signin'}
                    fullWidth
                    onClick={(e) => {
                      handleTrackEvent(e, 'cta_back_to_sign_in');
                    }}
                  >
                    Back to sign in
                  </Button>
                </Box>
                <Button
                  data-trackid={'cta_reset_password_submit'}
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  disabled={processing}
                  startIcon={processing ? <CircularProgress size={18} /> : null}
                  onClick={(e) => {
                    handleTrackEvent(e, 'cta_reset_password_submit');
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      ) : (
        <SigninForm
          emailVerificationError={emailVerificationError}
          emailVerified={emailVerified}
        />
      )}
    </Box>
  );
};

export default Form;
