const initialstate = {
  processing: null,
  done: null,
  failed: null,
  error: null,
  errorText: null,
};

const saveToCalendarReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SAVING_TO_CALENDAR':
      return Object.assign({}, state, {
        processing: true,
      });
    case 'SAVING_TO_CALENDAR_DONE':
      return Object.assign({}, state, {
        processing: false,
        done: true,
        failed: false,
        error: false,
        errorText: '',
      });
    case 'SAVING_TO_CALENDAR_FAILED':
      return Object.assign({}, state, {
        processing: false,
        done: false,
        failed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default saveToCalendarReducer;
