const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  prospects: null,
  emptyList: null,
  lastSeen: null,
};

const getCompanyProspectsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_COMPANY_PROSPECTS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_COMPANY_PROSPECTS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_COMPANY_PROSPECTS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        prospects: action.payload.prospects,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_PROSPECTS_TO_ALL_COMPANY_PROSPECTS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        prospects: Array.isArray(action.payload.prospects)
          ? state.prospects
            ? state.prospects.concat(action.payload.prospects)
            : action.payload.prospects
          : state.prospects,
        emptyList:
          Array.isArray(action.payload.prospects) &&
          (action.payload.prospects.length === 0 ||
            action.payload.prospects.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    // case 'UPDATE_SALES_CALL_ANALYSIS': {
    //   const updatedSalesReviews = state.prospects.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return action.payload.data;
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     prospects: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.prospects.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         speakerScoreMap: action.payload.value,
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     prospects: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.prospects.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     prospects: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_ALL_COMPANY_PROSPECTS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        prospects: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_COMPANY_PROSPECTS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getCompanyProspectsReducer;
