import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Avatar,
  Alert,
  ListItemText,
  OutlinedInput,
  Fab,
  Popper,
  ClickAwayListener,
  Autocomplete,
  Popover,
} from '@mui/material';
import { keyframes } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Audiotrack,
  Call,
  CallEnd,
  ChevronRight,
  Circle,
  Close,
  Delete,
  Edit,
  FilterList,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Lock,
  Mic,
  MicOff,
  PlayArrow,
  RecordVoiceOverOutlined,
  Replay,
  StopCircle,
  Subject,
  SupportAgent,
  Troubleshoot,
  Undo,
  VoiceOverOffOutlined,
  VolumeUp,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
//actions
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import getAllTaskItems from 'redux/actions/Candidate/getAllTaskItems';
import getAllConversationsMain from 'redux/actions/Candidate/getAllConversationsMain';

import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import deleteConversation from 'redux/actions/Candidate/deleteConversation';
import logError from 'redux/actions/Common/logError';
import { PropTypes } from 'prop-types';
import trackEvent from 'redux/actions/Common/trackEvent';
import { capitalizeText, formatSeconds } from 'utils';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import createNewActionItem from 'redux/actions/Candidate/createNewActionItem';
import showAlert from 'redux/actions/Common/showAlert';
import updateActionItem from 'redux/actions/Candidate/updateActionItem';
import deleteActionItem from 'redux/actions/Candidate/deleteActionItem';
import updateActionItemSeenStatus from 'redux/actions/Candidate/updateActionItemSeenStatus';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(value, filters, theme) {
  return {
    fontWeight:
      filters.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ActionItems = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const browser = detect();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { userDetails } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const { actionItems, fetching } = useSelector(
    (state) => state.allActionItems,
  );

  const {
    showCompetitionDetailsOnTopbar,
    showBackButtonOnTopbar,
    currentContextData,
    currentNavigationLevel,
  } = useSelector((state) => state.uiStates);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentTab, setCurrentTab] = useState('create');

  const [viewType, setViewType] = useState('list');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const [showCreateTask, setShowCreateTask] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [popperType, setPopperType] = useState('');

  const [creatingActionItem, setCreatingActionItem] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState(new Date().getTime() + 86400000);
  const [priority, setPriority] = useState(50);
  const [sortBy, setSortBy] = useState('priority');
  const [descriptionExpanded, setDescriptionExpanded] = useState(null);

  const [priorityType, setPriorityType] = useState('range');
  const [startPriority, setStartPriority] = useState(0);
  const [endPriority, setEndPriority] = useState(100);
  const [priorityValue, setPriorityValue] = useState(30);
  const [statusFilters, setStatusFilters] = useState(['completed', 'pending']);
  const [assignedByFilters, setAssignedByFilters] = useState([
    'self',
    'admin',
    'auto',
  ]);
  const [sourceFilters, setSourceFilters] = useState([]);

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [showPopperIndex, setShowPopperIndex] = useState(null);
  const [allSources, setAllSources] = useState([]);

  const handleTrackEvent = (e, type) => {
    // console.log(e.target);
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (currentTab === 'list' && viewType === 'list') {
      dispatch(getAllTaskItems(uId || null, null, () => {}));
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    }
  }, [currentTab, viewType]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('list');
      setSourceFilters([]);
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (!showCreateTask) {
      setTitle('');
      setDescription('');
      setDueDate(new Date().getTime() + 86400000);
      setPriority(50);
    }
  }, [showCreateTask]);

  useEffect(() => {
    if (actionItems && actionItems?.length > 0) {
      setAllSources(
        actionItems?.map(
          (at) => at?.customFields?.saleReviewAnalysis?.title || '',
        ),
      );
      const newItemsId = actionItems
        .filter(
          (actionItem) =>
            new Date().getTime() - actionItem.createdOn > 86400000 &&
            actionItem.seen === false &&
            actionItem.source === 'saleReviewAnalysis' &&
            actionItem.status !== 'completed' &&
            ((priorityType === 'range' &&
              actionItem.priority >= startPriority &&
              actionItem.priority <= endPriority) ||
              (priorityType === 'value' &&
                actionItem.priority === priorityValue)) &&
            assignedByFilters.includes(
              actionItem.source === 'selfAssigned'
                ? 'self'
                : actionItem.source === 'teamAssigned'
                ? 'admin'
                : actionItem.source === 'saleReviewAnalysis'
                ? 'auto'
                : null,
            ),
        )
        ?.map((item) => item?.id);
      dispatch(updateActionItemSeenStatus(newItemsId, true, () => {}));
    }
  }, [actionItems]);

  useEffect(() => {
    if (!uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            title: '',
          },
        },
      });
    }
  }, [viewType]);

  useEffect(() => {
    if (member && uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            name: member?.fullName,
            email: member?.email,
          },
        },
      });
    }
  }, [member, uId]);

  useEffect(() => {
    if (
      (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
      (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
    ) {
      setViewType('report');
    } else {
      setViewType('list');
    }
  }, [currentNavigationLevel]);

  useEffect(() => {
    if (params && params.get('new') === 'true') {
      setCurrentTab('create');
      setViewType('list');
    } else {
      setCurrentTab('list');
      setViewType('list');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (showCreateTask && showCreateTask !== 'create') {
      setTitle(showCreateTask?.title);
      setDueDate(showCreateTask?.dueDate);
      setDescription(showCreateTask?.description);
      setPriority(showCreateTask?.priority);
    }
  }, [showCreateTask]);

  useEffect(() => {
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(getUserDetails(() => {}));
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (state?.id && state?.title) {
      setSourceFilters([state?.title]);
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
    } else {
      setSourceFilters([]);
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
    }
  }, [state]);

  console.log(state);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
        marginTop: {
          xs: 1,
          sm: 0,
          md: 0,
        },
      }}
    >
      {member && isXs ? (
        <Box
          p={{ xs: 1, sm: 1, md: 1 }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={
            viewType === 'report'
              ? 'flex-start'
              : isXs
              ? 'space-between'
              : 'flex-end'
          }
        >
          {isXs ? (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showBackButtonOnTopbar',
                    value: false,
                  },
                });
              }}
              color="primary"
              size="small"
            >
              Go Back
            </Button>
          ) : null}
        </Box>
      ) : null}
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: currentTab === 'create' || fetching ? 'center' : 'start',
            sm: 'start',
            md: currentTab === 'create' || fetching ? 'center' : 'start',
          }}
          justifyContent={{
            xs: currentTab === 'create' || fetching ? 'center' : 'start',
            sm: 'start',
            md: currentTab === 'create' || fetching ? 'center' : 'flex-start',
          }}
          height={1}
          gap={1}
          sx={{
            // borderRight: '1px solid #f1f1f1',
            minHeight: 'calc(100vh - 130px)',
            padding: uId ? 0 : 2,
            paddingTop: uId ? 0 : viewType === 'report' ? 0 : 2,
          }}
          position={'relative'}
        >
          {viewType === 'list' && currentTab === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading all tasks ...
                  </Typography>
                </Box>
              ) : actionItems && actionItems?.length > 0 ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      // justifyContent={'space-between'}
                      gap={1}
                    >
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <FormLabel>
                          <Typography variant="subtitle2">Sort By</Typography>
                        </FormLabel>
                        <FormControl size="small">
                          <Select
                            size="small"
                            labelId="sortby-select-label"
                            id="sortby-select"
                            name="sortBy"
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => setSortBy(e.target.value)}
                            // label="sort by"
                            value={sortBy}
                          >
                            <MenuItem value={'priority'}>
                              <Typography variant="subtitle2">
                                Priority
                              </Typography>
                            </MenuItem>
                            <MenuItem value={'title'}>
                              <Typography variant="subtitle2">
                                Task Title
                              </Typography>
                            </MenuItem>
                            <MenuItem value={'duedate'}>
                              <Typography variant="subtitle2">
                                Due Date
                              </Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          Filters
                        </Typography>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={1}
                          sx={{
                            padding: '4px 8px',
                            border: '1px solid #d3d3d3',
                            borderRadius: '4px',
                          }}
                        >
                          <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('priority');
                            }}
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            Priority
                          </Typography>
                          <Box
                            sx={{ padding: '4px 8px', cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('priority');
                            }}
                          >
                            <Typography variant="subtitle2">
                              {priorityType === 'range'
                                ? `${startPriority} - ${endPriority}`
                                : priorityValue}
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('assignedBy');
                            }}
                          >
                            Assigned By
                          </Typography>
                          <Box
                            display={'flex'}
                            gap={1}
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('assignedBy');
                            }}
                          >
                            {assignedByFilters?.map((source) => (
                              <Chip key={source} label={source} size="small" />
                            ))}
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            sx={{ padding: '4px 8px', cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('source');
                            }}
                          >
                            Source
                          </Typography>
                          <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                              setPopperType('source');
                            }}
                          >
                            {sourceFilters && sourceFilters?.length > 0 ? (
                              sourceFilters?.map((source) => (
                                <Chip
                                  key={source}
                                  label={source}
                                  size="small"
                                />
                              ))
                            ) : (
                              <Typography variant="subtitle2">none</Typography>
                            )}
                          </Box>
                        </Box>
                        <Popper
                          open={openMenu}
                          anchorEl={anchorEl}
                          sx={{
                            zIndex: 1300,
                          }}
                          placement={
                            popperType === 'priority' ? 'bottom' : 'bottom-end'
                          }
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              border: '1px solid #d3d3d3',
                              borderRadius: 1,
                              // padding: 2,
                              background: '#fff',
                              minWidth: 300,
                            }}
                          >
                            {popperType === 'priority' && (
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Priority
                                </Typography>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <FormControl>
                                    <Select
                                      size="small"
                                      labelId="priority-select-label"
                                      id="priority-select"
                                      name="priorityType"
                                      MenuProps={{ disableScrollLock: true }}
                                      onChange={(e) =>
                                        setPriorityType(e.target.value)
                                      }
                                      // label="sort by"
                                      value={priorityType}
                                    >
                                      <MenuItem value={'range'}>
                                        <Typography variant="caption">
                                          Range
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem value={'value'}>
                                        <Typography variant="caption">
                                          Equals to
                                        </Typography>
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {priorityType === 'range' ? (
                                    <>
                                      <TextField
                                        placeholder="from"
                                        variant="outlined"
                                        label="from"
                                        color="primary"
                                        size="small"
                                        name="startPriority"
                                        value={startPriority}
                                        onChange={(e) =>
                                          setStartPriority(e.target.value)
                                        }
                                        type="number"
                                        sx={{
                                          maxWidth: 120,
                                        }}
                                      />
                                      <TextField
                                        placeholder="to"
                                        variant="outlined"
                                        label="to"
                                        color="primary"
                                        size="small"
                                        name="endPriority"
                                        value={endPriority}
                                        onChange={(e) =>
                                          setEndPriority(e.target.value)
                                        }
                                        type="number"
                                        sx={{
                                          maxWidth: 120,
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <TextField
                                      placeholder="priority"
                                      variant="outlined"
                                      label="priority"
                                      color="primary"
                                      size="small"
                                      name="priorityValue"
                                      value={priorityValue}
                                      onChange={(e) =>
                                        setPriorityValue(e.target.value)
                                      }
                                      type="number"
                                      sx={{
                                        maxWidth: 120,
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                            )}
                            {popperType === 'assignedBy' && (
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Assigned By
                                </Typography>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Autocomplete
                                    id={'source'}
                                    size="small"
                                    value={assignedByFilters}
                                    onChange={(e, newValue) => {
                                      setAssignedByFilters(newValue);
                                    }}
                                    clearIcon={null}
                                    multiple
                                    options={['self', 'admin', 'auto']}
                                    getOptionLabel={(option) => option || ''}
                                    renderTags={(value, getTagProps) =>
                                      value.map((option, index) => (
                                        <Chip
                                          size="small"
                                          key={index}
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyPress={(e) => {
                                          e.preventDefault();
                                        }}
                                        placeholder={'Select Source'}
                                      />
                                    )}
                                    sx={{
                                      minWidth: 200,
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                            {popperType === 'source' && (
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Source
                                </Typography>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Autocomplete
                                    id={'source'}
                                    size="small"
                                    value={sourceFilters}
                                    onChange={(e, newValue) => {
                                      setSourceFilters(newValue);
                                    }}
                                    clearIcon={null}
                                    multiple
                                    options={Array.from(
                                      new Set(
                                        allSources?.filter((source) => source),
                                      ),
                                    )}
                                    getOptionLabel={(option) => option || ''}
                                    renderTags={(value, getTagProps) =>
                                      value.map((option, index) => (
                                        <Chip
                                          size="small"
                                          key={index}
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onKeyPress={(e) => {
                                          e.preventDefault();
                                        }}
                                        placeholder={'Select Source'}
                                      />
                                    )}
                                    sx={{
                                      minWidth: 200,
                                    }}
                                  />
                                </Box>
                              </Box>
                            )}
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              gap={1}
                              borderTop={'1px solid #d3d3d3'}
                              sx={{
                                padding: '8px 16px',
                              }}
                            >
                              <Button
                                size="small"
                                variant="outlined"
                                startIcon={<Close />}
                                sx={{
                                  padding: '4px 8px',
                                }}
                                onClick={() => {
                                  setAnchorEl(null);
                                }}
                              >
                                Close
                              </Button>
                              <Box
                                display={'flex'}
                                justifyContent={'flex-end'}
                                gap={1}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  sx={{
                                    padding: '4px 8px',
                                  }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setSourceFilters([]);
                                  }}
                                >
                                  Reset
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  sx={{
                                    padding: '4px 8px',
                                  }}
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                >
                                  Apply
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Popper>
                      </Box>
                    </Box>
                  </Box>
                  <TableContainer
                    // component={Paper}
                    sx={{
                      minHeight: uId
                        ? 'calc(100vh - 190px)'
                        : isXs
                        ? 'calc(100vh - 190px)'
                        : 'calc(100vh - 190px)',
                      maxHeight: uId
                        ? 'calc(100vh - 190px)'
                        : isXs
                        ? 'calc(100vh - 190px)'
                        : 'calc(100vh - 190px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      // border: '1px solid #f1f1f1',
                    }}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          bgcolor: 'alternate.dark',
                        }}
                      >
                        <TableRow>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Status
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ width: 450 }}>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Task
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Priority
                              </Typography>
                              <FilterList
                                sx={{
                                  fontSize: 18,
                                  color: theme.palette.primary.main,
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setPopperType('priority');
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Due Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                                align="center"
                              >
                                Source
                              </Typography>
                              <FilterList
                                sx={{
                                  fontSize: 18,
                                  color: theme.palette.primary.main,
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setPopperType('source');
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Assigned By
                              </Typography>
                              <FilterList
                                sx={{
                                  fontSize: 18,
                                  color: theme.palette.primary.main,
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setPopperType('assignedBy');
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Actions
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          sx={{
                            padding: '8px 2px',
                          }}
                        >
                          <Typography
                            variant="caption"
                            fontWeight={'bold'}
                            color={'text.secondary'}
                          >
                            Pending Tasks
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableBody>
                        {actionItems
                          ?.filter(
                            (item) =>
                              item.status !== 'completed' &&
                              ((priorityType === 'range' &&
                                item.priority >= startPriority &&
                                item.priority <= endPriority) ||
                                (priorityType === 'value' &&
                                  item.priority === Number(priorityValue))) &&
                              assignedByFilters.includes(
                                item.source === 'selfAssigned'
                                  ? 'self'
                                  : item.source === 'teamAssigned'
                                  ? 'admin'
                                  : item.source === 'saleReviewAnalysis'
                                  ? 'auto'
                                  : null,
                              ) &&
                              (sourceFilters.includes(
                                item?.customFields?.saleReviewAnalysis?.title,
                              ) ||
                                sourceFilters.length === 0) &&
                              ((item?.customFields?.saleReviewAnalysis
                                ?.title === state?.title &&
                                item?.customFields?.saleReviewAnalysis?.id ===
                                  state?.id) ||
                                !state?.title ||
                                !state?.id),
                          )
                          ?.sort((a, b) => {
                            if (sortBy === 'priority') {
                              return b?.priority - a?.priority;
                            } else if (sortBy === 'title') {
                              if (a?.title > b?.title) {
                                return 1;
                              } else if (a?.title < b?.title) {
                                return -1;
                              } else {
                                return 0;
                              }
                            } else if (sortBy === 'duedate') {
                              if (a?.dueDate > b?.dueDate) {
                                return 1;
                              } else if (a?.dueDate < b?.dueDate) {
                                return -1;
                              } else {
                                return 0;
                              }
                            }
                          }) &&
                        actionItems
                          ?.filter(
                            (item) =>
                              item.status !== 'completed' &&
                              ((priorityType === 'range' &&
                                item.priority >= startPriority &&
                                item.priority <= endPriority) ||
                                (priorityType === 'value' &&
                                  item.priority === Number(priorityValue))) &&
                              assignedByFilters.includes(
                                item.source === 'selfAssigned'
                                  ? 'self'
                                  : item.source === 'teamAssigned'
                                  ? 'admin'
                                  : item.source === 'saleReviewAnalysis'
                                  ? 'auto'
                                  : null,
                              ) &&
                              (sourceFilters.includes(
                                item?.customFields?.saleReviewAnalysis?.title,
                              ) ||
                                sourceFilters.length === 0) &&
                              ((item?.customFields?.saleReviewAnalysis
                                ?.title === state?.title &&
                                item?.customFields?.saleReviewAnalysis?.id ===
                                  state?.id) ||
                                !state?.title ||
                                !state?.id),
                          )
                          ?.sort((a, b) => {
                            if (sortBy === 'priority') {
                              return b?.priority - a?.priority;
                            } else if (sortBy === 'title') {
                              if (a?.title > b?.title) {
                                return 1;
                              } else if (a?.title < b?.title) {
                                return -1;
                              } else {
                                return 0;
                              }
                            } else if (sortBy === 'duedate') {
                              if (a?.dueDate > b?.dueDate) {
                                return 1;
                              } else if (a?.dueDate < b?.dueDate) {
                                return -1;
                              } else {
                                return 0;
                              }
                            }
                          })?.length > 0 ? (
                          actionItems
                            ?.filter(
                              (item) =>
                                item.status !== 'completed' &&
                                ((priorityType === 'range' &&
                                  item.priority >= startPriority &&
                                  item.priority <= endPriority) ||
                                  (priorityType === 'value' &&
                                    item.priority === Number(priorityValue))) &&
                                assignedByFilters.includes(
                                  item.source === 'selfAssigned'
                                    ? 'self'
                                    : item.source === 'teamAssigned'
                                    ? 'admin'
                                    : item.source === 'saleReviewAnalysis'
                                    ? 'auto'
                                    : null,
                                ) &&
                                (sourceFilters.includes(
                                  item?.customFields?.saleReviewAnalysis?.title,
                                ) ||
                                  sourceFilters.length === 0) &&
                                ((item?.customFields?.saleReviewAnalysis
                                  ?.title === state?.title &&
                                  item?.customFields?.saleReviewAnalysis?.id ===
                                    state?.id) ||
                                  !state?.title ||
                                  !state?.id),
                            )
                            ?.sort((a, b) => {
                              if (sortBy === 'priority') {
                                return b?.priority - a?.priority;
                              } else if (sortBy === 'title') {
                                if (a?.title > b?.title) {
                                  return 1;
                                } else if (a?.title < b?.title) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              } else if (sortBy === 'duedate') {
                                if (a?.dueDate > b?.dueDate) {
                                  return 1;
                                } else if (a?.dueDate < b?.dueDate) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              }
                            })
                            .map((task, i) => (
                              <TableRow key={i}>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderLeft: '1px solid #f1f1f1',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      task?.status === 'completed'
                                        ? 'Mark as pending'
                                        : 'Mark as completed'
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={task?.status === 'completed'}
                                      onChange={(e) => {
                                        dispatch(
                                          updateActionItem(
                                            task?.id,
                                            {
                                              status: e.target.checked
                                                ? 'completed'
                                                : 'in_process',
                                            },
                                            () => {
                                              dispatch(
                                                getAllTaskItems(
                                                  uId || null,
                                                  null,
                                                  () => {},
                                                ),
                                              );
                                            },
                                          ),
                                        );
                                      }}
                                      name="taskStatus"
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: 450 }}
                                >
                                  <Box
                                    display={'flex'}
                                    // alignItems={'center'}
                                    // gap={1}
                                    onClick={() => {
                                      if (
                                        descriptionExpanded === `pending-${i}`
                                      ) {
                                        setDescriptionExpanded(null);
                                      } else {
                                        setDescriptionExpanded(`pending-${i}`);
                                      }
                                    }}
                                  >
                                    <Box>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                      >
                                        <Typography
                                          variant={'subtitle2'}
                                          fontWeight={
                                            task.source ===
                                              'saleReviewAnalysis' &&
                                            !task?.seen
                                              ? 700
                                              : 500
                                          }
                                          color={
                                            task?.status === 'completed'
                                              ? 'text.secondary'
                                              : 'text.primary'
                                          }
                                          sx={{
                                            textDecoration:
                                              task?.status === 'completed'
                                                ? 'line-through'
                                                : null,
                                          }}
                                        >
                                          {task?.title}
                                        </Typography>
                                        {task?.description ? (
                                          <Tooltip
                                            arrow
                                            placement="top"
                                            title="View Description"
                                          >
                                            <Subject
                                              sx={{
                                                fontSize: 20,
                                                cursor: 'pointer',
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                        {task?.source ===
                                          'saleReviewAnalysis' &&
                                        !task?.seen ? (
                                          <Tooltip
                                            title="Mark as seen"
                                            arrow
                                            placement="top"
                                          >
                                            <Chip
                                              onClick={() => {
                                                dispatch(
                                                  updateActionItemSeenStatus(
                                                    [task?.id],
                                                    true,
                                                    () => {
                                                      dispatch({
                                                        type:
                                                          'UPDATE_ACTION_ITEMS_SEEN_STATUS',
                                                        payload: {
                                                          ids: [task?.id],
                                                        },
                                                      });
                                                    },
                                                  ),
                                                );
                                              }}
                                              label={
                                                <Typography variant="caption">
                                                  new
                                                </Typography>
                                              }
                                              size="small"
                                              color="primary"
                                              sx={{
                                                padding: '2px 4px',
                                              }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </Box>
                                      {descriptionExpanded ===
                                      `pending-${i}` ? (
                                        <Typography
                                          variant={'subtitle2'}
                                          // fontWeight={700}
                                          color={
                                            task?.status === 'completed'
                                              ? 'text.secondary'
                                              : 'text.primary'
                                          }
                                          sx={{
                                            textDecoration:
                                              task?.status === 'completed'
                                                ? 'line-through'
                                                : null,
                                          }}
                                        >
                                          {task?.description}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {task?.priority || '-'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    sx={{
                                      color:
                                        task?.dueDate < Date.now()
                                          ? theme.palette.error.main
                                          : task?.dueDate > Date.now() &&
                                            task?.dueDate <
                                              Date.now() + 2 * 86400000
                                          ? theme.palette.warning.light
                                          : null,
                                    }}
                                  >
                                    {moment(task?.dueDate).format(
                                      'DD MMMM YYYY',
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center" width={100}>
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    justifyContent={'center'}
                                  >
                                    <Tooltip
                                      title={
                                        task?.source === 'saleReviewAnalysis'
                                          ? task?.customFields
                                              ?.saleReviewAnalysis?.title
                                          : ''
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <Typography
                                        variant={'caption'}
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          width: 100,
                                        }}
                                      >
                                        {task?.source === 'selfAssigned'
                                          ? '-'
                                          : task?.source === 'teamAssigned'
                                          ? task?.customFields?.assignedBy
                                              ?.fullName
                                          : task?.source ===
                                            'saleReviewAnalysis'
                                          ? task?.customFields
                                              ?.saleReviewAnalysis?.title
                                          : '-'}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={0.5}
                                    // onMouseEnter={(e) => {
                                    //   setAnchorElDetails(e.currentTarget);
                                    //   setShowPopperIndex(i);
                                    // }}
                                    // onMouseLeave={() => {
                                    //   setAnchorElDetails(null);
                                    //   setShowPopperIndex(null);
                                    // }}
                                  >
                                    <Typography variant={'caption'}>
                                      {task?.source === 'teamAssigned'
                                        ? 'Admin'
                                        : task?.source === 'selfAssigned'
                                        ? 'You'
                                        : task?.source === 'saleReviewAnalysis'
                                        ? 'Auto'
                                        : '-'}
                                    </Typography>
                                    {/* {task?.source === 'saleReviewAnalysis' ? (
                                    <Info
                                      sx={{
                                        fontSize: 18,
                                        cursor: 'pointer',
                                        color: '#aaa',
                                      }}
                                    />
                                  ) : null} */}
                                    <Popper
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: 'none',
                                      }}
                                      open={
                                        Boolean(anchorElDetails) &&
                                        i === showPopperIndex
                                      }
                                      anchorEl={anchorElDetails}
                                      placement="top-end"
                                      onClose={() => {
                                        setAnchorElDetails(null);
                                        setShowPopperIndex(null);
                                      }}
                                      // disableRestoreFocus
                                    >
                                      <Box
                                        sx={{
                                          border: '1px solid #d3d3d3',
                                          borderRadius: 1,
                                          padding: 1,
                                          background: '#fff',
                                        }}
                                      >
                                        <Typography
                                          variant={'caption'}
                                          color={'text.secondary'}
                                        >
                                          {moment(task?.createdOn).format(
                                            'DD MMMM YYYY',
                                          )}
                                        </Typography>
                                      </Box>
                                    </Popper>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: '1px solid #f1f1f1',
                                  }}
                                >
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={1}
                                  >
                                    <Tooltip
                                      title={'Update Task'}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleTrackEvent(
                                            e,
                                            `edit_action_item`,
                                          );
                                          setShowCreateTask(task);
                                        }}
                                      >
                                        <Edit
                                          color={'primary'}
                                          sx={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {/* {!uId ? ( */}
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={'Delete Task'}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleTrackEvent(
                                            e,
                                            `open_confirm_delete_action_item_dialog`,
                                          );

                                          setShowConfirmDeleteDialog(task?.id);
                                        }}
                                      >
                                        <Delete
                                          sx={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {/* ) : null} */}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              sx={{
                                padding: 4,
                                border: '1px solid #d3d3d3',
                              }}
                              align="center"
                            >
                              <Typography
                                variant="caption"
                                fontWeight={'bold'}
                                color={'text.secondary'}
                                textAlign={'center'}
                              >
                                No Pending Tasks
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            colSpan={8}
                            sx={{
                              padding: '8px 2px',
                            }}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={'bold'}
                              color={'text.secondary'}
                            >
                              Completed Tasks
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {actionItems?.filter(
                          (item) =>
                            item.status === 'completed' &&
                            ((priorityType === 'range' &&
                              item.priority >= startPriority &&
                              item.priority <= endPriority) ||
                              (priorityType === 'value' &&
                                item.priority === Number(priorityValue))) &&
                            assignedByFilters.includes(
                              item.source === 'selfAssigned'
                                ? 'self'
                                : item.source === 'teamAssigned'
                                ? 'admin'
                                : item.source === 'saleReviewAnalysis'
                                ? 'auto'
                                : null,
                            ) &&
                            (sourceFilters.includes(
                              item?.customFields?.saleReviewAnalysis?.title,
                            ) ||
                              sourceFilters.length === 0) &&
                            ((item?.customFields?.saleReviewAnalysis?.title ===
                              state?.title &&
                              item?.customFields?.saleReviewAnalysis?.id ===
                                state?.id) ||
                              !state?.title ||
                              !state?.id),
                        ) &&
                        actionItems?.filter(
                          (item) =>
                            item.status === 'completed' &&
                            ((priorityType === 'range' &&
                              item.priority >= startPriority &&
                              item.priority <= endPriority) ||
                              (priorityType === 'value' &&
                                item.priority === Number(priorityValue))) &&
                            assignedByFilters.includes(
                              item.source === 'selfAssigned'
                                ? 'self'
                                : item.source === 'teamAssigned'
                                ? 'admin'
                                : item.source === 'saleReviewAnalysis'
                                ? 'auto'
                                : null,
                            ) &&
                            (sourceFilters.includes(
                              item?.customFields?.saleReviewAnalysis?.title,
                            ) ||
                              sourceFilters.length === 0) &&
                            ((item?.customFields?.saleReviewAnalysis?.title ===
                              state?.title &&
                              item?.customFields?.saleReviewAnalysis?.id ===
                                state?.id) ||
                              !state?.title ||
                              !state?.id),
                        )?.length > 0 ? (
                          actionItems
                            ?.filter(
                              (item) =>
                                item.status === 'completed' &&
                                ((priorityType === 'range' &&
                                  item.priority >= startPriority &&
                                  item.priority <= endPriority) ||
                                  (priorityType === 'value' &&
                                    item.priority === Number(priorityValue))) &&
                                assignedByFilters.includes(
                                  item.source === 'selfAssigned'
                                    ? 'self'
                                    : item.source === 'teamAssigned'
                                    ? 'admin'
                                    : item.source === 'saleReviewAnalysis'
                                    ? 'auto'
                                    : null,
                                ) &&
                                (sourceFilters.includes(
                                  item?.customFields?.saleReviewAnalysis?.title,
                                ) ||
                                  sourceFilters.length === 0) &&
                                ((item?.customFields?.saleReviewAnalysis
                                  ?.title === state?.title &&
                                  item?.customFields?.saleReviewAnalysis?.id ===
                                    state?.id) ||
                                  !state?.title ||
                                  !state?.id),
                            )
                            ?.sort((a, b) => {
                              if (sortBy === 'priority') {
                                return b?.priority - a?.priority;
                              } else if (sortBy === 'title') {
                                if (a?.title > b?.title) {
                                  return 1;
                                } else if (a?.title < b?.title) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              } else if (sortBy === 'duedate') {
                                if (a?.dueDate > b?.dueDate) {
                                  return 1;
                                } else if (a?.dueDate < b?.dueDate) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              }
                            })
                            ?.map((task, i) => (
                              <TableRow key={i}>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderLeft: '1px solid #f1f1f1',
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      task?.status === 'completed'
                                        ? 'Mark as pending'
                                        : 'Mark as completed'
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={task?.status === 'completed'}
                                      onChange={(e) => {
                                        dispatch(
                                          updateActionItem(
                                            task?.id,
                                            {
                                              status: e.target.checked
                                                ? 'completed'
                                                : 'in_process',
                                            },
                                            () => {
                                              dispatch(
                                                getAllTaskItems(
                                                  uId || null,
                                                  null,
                                                  () => {},
                                                ),
                                              );
                                            },
                                          ),
                                        );
                                      }}
                                      name="taskStatus"
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: 450 }}
                                >
                                  <Box
                                    display={'flex'}
                                    // alignItems={'center'}
                                    // gap={1}
                                    onClick={() => {
                                      if (
                                        descriptionExpanded === `completed-${i}`
                                      ) {
                                        setDescriptionExpanded(null);
                                      } else {
                                        setDescriptionExpanded(
                                          `completed-${i}`,
                                        );
                                      }
                                    }}
                                  >
                                    <Box>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                      >
                                        <Typography
                                          variant={'subtitle2'}
                                          fontWeight={500}
                                          color={
                                            task?.status === 'completed'
                                              ? 'text.secondary'
                                              : 'text.primary'
                                          }
                                          sx={{
                                            textDecoration:
                                              task?.status === 'completed'
                                                ? 'line-through'
                                                : null,
                                          }}
                                        >
                                          {task?.title}
                                        </Typography>
                                        {task?.description ? (
                                          <Tooltip
                                            arrow
                                            placement="top"
                                            title="View Description"
                                          >
                                            <Subject
                                              sx={{
                                                fontSize: 20,
                                                cursor: 'pointer',
                                                color:
                                                  theme.palette.primary.main,
                                              }}
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </Box>
                                      {descriptionExpanded ===
                                      `completed-${i}` ? (
                                        <Typography
                                          variant={'subtitle2'}
                                          // fontWeight={700}
                                          color={
                                            task?.status === 'completed'
                                              ? 'text.secondary'
                                              : 'text.primary'
                                          }
                                          sx={{
                                            textDecoration:
                                              task?.status === 'completed'
                                                ? 'line-through'
                                                : null,
                                          }}
                                        >
                                          {task?.description}
                                        </Typography>
                                      ) : null}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    // color={'text.secondary'}
                                    variant={'subtitle2'}
                                  >
                                    {task?.priority || '-'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant={'caption'}>
                                    {moment(task?.dueDate).format(
                                      'DD MMMM YYYY',
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    justifyContent={'center'}
                                  >
                                    <Tooltip
                                      title={
                                        task?.source === 'saleReviewAnalysis'
                                          ? task?.customFields
                                              ?.saleReviewAnalysis?.title
                                          : ''
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <Typography
                                        variant={'caption'}
                                        sx={{
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          width: 100,
                                        }}
                                      >
                                        {task?.source === 'selfAssigned'
                                          ? '-'
                                          : task?.source === 'teamAssigned'
                                          ? task?.customFields?.assignedBy
                                              ?.fullName
                                          : task?.source ===
                                            'saleReviewAnalysis'
                                          ? task?.customFields
                                              ?.saleReviewAnalysis?.title
                                          : '-'}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={0.5}
                                    // onMouseEnter={(e) => {
                                    //   setAnchorElDetails(e.currentTarget);
                                    //   setShowPopperIndex(i);
                                    // }}
                                    // onMouseLeave={() => {
                                    //   setAnchorElDetails(null);
                                    //   setShowPopperIndex(null);
                                    // }}
                                  >
                                    <Typography variant={'caption'}>
                                      {task?.source === 'teamAssigned'
                                        ? 'Admin'
                                        : task?.source === 'selfAssigned'
                                        ? 'You'
                                        : task?.source === 'saleReviewAnalysis'
                                        ? 'Auto'
                                        : '-'}
                                    </Typography>
                                    <Popper
                                      id="mouse-over-popover"
                                      sx={{
                                        pointerEvents: 'none',
                                      }}
                                      open={
                                        Boolean(anchorElDetails) &&
                                        i === showPopperIndex
                                      }
                                      anchorEl={anchorElDetails}
                                      placement="top-end"
                                      onClose={() => {
                                        setAnchorElDetails(null);
                                        setShowPopperIndex(null);
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          border: '1px solid #d3d3d3',
                                          borderRadius: 1,
                                          padding: 1,
                                          background: '#fff',
                                        }}
                                      >
                                        <Typography
                                          variant={'caption'}
                                          color={'text.secondary'}
                                        >
                                          Generated automatically from the sales
                                          call analysis with title :{' '}
                                          <span
                                            style={{
                                              fontWeight: 700,
                                              color: '#000',
                                            }}
                                          >{`${task?.customFields?.saleReviewAnalysis?.title}`}</span>
                                        </Typography>
                                      </Box>
                                    </Popper>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    borderRight: '1px solid #f1f1f1',
                                  }}
                                >
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={1}
                                  >
                                    <Tooltip
                                      title={'Update Task'}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleTrackEvent(
                                            e,
                                            `edit_action_item`,
                                          );
                                          setShowCreateTask(task);
                                        }}
                                      >
                                        <Edit
                                          color={'primary'}
                                          sx={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {/* {!uId ? ( */}
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={'Delete Task'}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          handleTrackEvent(
                                            e,
                                            `open_confirm_delete_action_item_dialog`,
                                          );

                                          setShowConfirmDeleteDialog(task?.id);
                                        }}
                                      >
                                        <Delete
                                          sx={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {/* ) : null} */}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={8}
                              sx={{
                                padding: 4,
                                border: '1px solid #d3d3d3',
                              }}
                              align="center"
                            >
                              <Typography
                                variant="caption"
                                fontWeight={'bold'}
                                color={'text.secondary'}
                                textAlign={'center'}
                              >
                                No Completed Tasks
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Tooltip arrow placement="top" title="Create new task">
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        // sx={{ position: 'absolute', bottom: 10, right: 10 }}
                        onClick={() => setShowCreateTask('create')}
                        // sx={{ padding: 1 }}
                      >
                        <Add sx={{ fontSize: 20 }} />
                      </Fab>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No tasks found.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // sx={{ marginLeft: 'auto' }}
                    onClick={() => setShowCreateTask('create')}
                  >
                    Create new task
                  </Button>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Drawer
        open={Boolean(showCreateTask)}
        anchor="right"
        onClose={() => setShowCreateTask(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowCreateTask(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {showCreateTask === 'create' ? 'Create New Task' : 'Update Task'}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
            <TextField
              autoFocus
              placeholder="Task"
              variant="outlined"
              label="Task"
              color="primary"
              size="small"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
            <TextField
              // autoFocus
              placeholder="Description"
              variant="outlined"
              label="Description"
              color="primary"
              size="small"
              name="description"
              multiline
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
            <AutoCompleteMUI
              id="priority"
              value={priority}
              label={'Task Priority'}
              onChange={(event, newValue) => {
                setPriority(newValue);
              }}
              options={Array.from({ length: 100 }, (_, i) => i + 1)}
              onTextChange={(e) => setPriority(e.target.value)}
              placeholder="Task Priority"
              onKeyDown={() => {}}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Due Date"
                value={dayjs(dueDate)}
                onChange={(newValue) => {
                  console.log(new Date(newValue).getTime());
                  setDueDate(new Date(newValue).getTime());
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              p={2}
              width={1}
              borderTop={'1px solid #f1f1f1'}
            >
              <Button
                variant="contained"
                size="small"
                disabled={creatingActionItem}
                startIcon={
                  creatingActionItem ? <CircularProgress size={20} /> : null
                }
                onClick={(e) => {
                  if (showCreateTask === 'create') {
                    setCreatingActionItem(true);
                    dispatch(
                      createNewActionItem(
                        {
                          createTaskFor: uId || null,
                          description: description,
                          dueDate: dueDate,
                          priority: priority,
                          // status: 'created',
                          title: title,
                          saleReviewId: state?.id,
                        },
                        (error) => {
                          setCreatingActionItem(false);
                          if (error) {
                            dispatch(
                              showAlert({
                                message:
                                  'Failed to add Task. Please try again later!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 5000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setShowCreateTask(null);
                            dispatch(
                              getAllTaskItems(uId || null, null, () => {}),
                            );
                          }
                        },
                      ),
                    );
                  } else {
                    setCreatingActionItem(true);
                    dispatch(
                      updateActionItem(
                        showCreateTask?.id,
                        {
                          createTaskFor: uId || null,
                          description: description,
                          dueDate: dueDate,
                          priority: priority,
                          title: title,
                        },
                        (error) => {
                          setCreatingActionItem(false);
                          if (error) {
                            dispatch(
                              showAlert({
                                message:
                                  'Failed to update Task. Please try again later!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 5000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setShowCreateTask(null);
                            dispatch(
                              getAllTaskItems(uId || null, null, () => {}),
                            );
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                {showCreateTask === 'create' ? 'Create Task' : 'Update'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this Task?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `confirm_delete_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `delete_action_item`);
              dispatch(
                deleteActionItem(showConfirmDeleteDialog, (error) => {
                  if (error) {
                    dispatch(
                      showAlert({
                        message:
                          'Failed to delete Task. Please try again later!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 5000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  } else {
                    dispatch(getAllTaskItems(uId || null, null, () => {}));
                  }
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ActionItems.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default ActionItems;
