const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  interviews: null,
  emptyList: null,
  lastSeen: null,
};

const getCROInterviewsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_CRO_INTERVIEWS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_CRO_INTERVIEWS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_CRO_INTERVIEWS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        interviews: action.payload.interviews,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_INTERVIEWS_TO_ALL_CRO_INTERVIEWS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        interviews: Array.isArray(action.payload.interviews)
          ? state.interviews
            ? state.interviews.concat(action.payload.interviews)
            : action.payload.interviews
          : state.interviews,
        emptyList:
          Array.isArray(action.payload.interviews) &&
          (action.payload.interviews.length === 0 ||
            action.payload.interviews.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    // case 'UPDATE_SALES_CALL_ANALYSIS': {
    //   const updatedSalesReviews = state.interviews.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return action.payload.data;
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     interviews: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.interviews.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         speakerScoreMap: action.payload.value,
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     interviews: updatedSalesReviews,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.interviews.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     interviews: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     lastSeen: state.lastSeen,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_ALL_CRO_INTERVIEWS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        interviews: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_CRO_INTERVIEWS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getCROInterviewsReducer;
