const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  competitions: null,
  emptyList: null,
  lastTime: null,
};

const getAllCompetitionsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_COMPETITIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_COMPETITIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_COMPETITIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        competitions: action.payload.competitions,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_COMPETITIONS_TO_ALL_COMPETITIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        competitions: Array.isArray(action.payload.competitions)
          ? state.competitions
            ? state.competitions.concat(action.payload.competitions)
            : action.payload.competitions
          : state.competitions,
        emptyList:
          Array.isArray(action.payload.interviewsForJob) &&
          (action.payload.interviewsForJob.length === 0 ||
            action.payload.interviewsForJob.length % 10 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_COMPETITIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        competitions: [],
        emptyList: false,
        lastTime: '',
      });

    case 'GETTING_ALL_COMPETITIONS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllCompetitionsReducer;
