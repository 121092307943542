const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  members: null,
  emptyList: null,
  lastSeen: null,
};

const getAllTeamMembersReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_TEAM_MEMBERS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_TEAM_MEMBERS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_TEAM_MEMBERS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        members: action.payload.members,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_TEAM_MEMBERS_TO_ALL_TEAM_MEMBERS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        members: Array.isArray(action.payload.members)
          ? state.members
            ? state.members.concat(action.payload.members)
            : action.payload.members
          : state.members,
        emptyList:
          Array.isArray(action.payload.members) &&
          (action.payload.members.length === 0 ||
            action.payload.members.length % 25 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    // case 'UPDATE_SALES_CALL_ANALYSIS': {
    //   const updatedSalesReviews = state.members.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return action.payload.data;
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     members: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.members.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           [`${action.payload.scoreKey}Score`]: action.payload.scoreValue,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     members: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.members.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     members: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_ALL_TEAM_MEMBERS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        members: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_TEAM_MEMBERS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllTeamMembersReducer;
