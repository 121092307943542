import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const getSyncRequestStatus = (level, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_SYNC_REQUEST_STATUS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/us/org/integrations/sync`, {
            params: {
              'request-id': reqId,
              type: 'hubspot',
              level: level,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          //   console.log(response.data);
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_SYNC_REQUEST_STATUS_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_SYNC_REQUEST_STATUS_FAILED',
              payload: `Failed to fetch sync status`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_SYNC_REQUEST_STATUS_FAILED',
            payload: `Failed to fetch sync status`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'FETCHING_SYNC_REQUEST_STATUS_FAILED',
        payload: `Failed to fetch sync status`,
      });
    }
  };
};

export default getSyncRequestStatus;
