import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Add } from '@mui/icons-material';

const FormField = ({
  type,
  name,
  multiple,
  value,
  onChange,
  options,
  placeholder,
  label,
  error,
  helperText,
  customOptions,
  ...rest
}) => {
  const theme = useTheme();

  return type === 'text' ||
    type === 'number' ||
    type === 'textarea' ||
    type === 'email' ? (
    <TextField
      {...rest}
      placeholder={placeholder}
      variant="outlined"
      name={name}
      fullWidth
      multiline={type === 'textarea'}
      rows={type === 'textarea' ? 4 : undefined}
      size="small"
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      type={type === 'number' ? 'number' : type === 'email' ? 'email' : 'text'}
    />
  ) : type === 'select' || type === 'multiselect' ? (
    <FormControl size="small" sx={{ width: '100%' }}>
      <Select
        size="small"
        labelId={name}
        id={name}
        name={name}
        MenuProps={{ disableScrollLock: true }}
        onChange={onChange}
        value={value}
        multiple={multiple}
        renderValue={(selected) =>
          multiple
            ? selected
                .map((value) => {
                  if (typeof value === 'object') {
                    return value.name;
                  }
                  return value;
                })
                .join(', ')
            : selected
        }
      >
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            <Typography variant="subtitle2">{option}</Typography>
          </MenuItem>
        ))}
        {customOptions ? (
          <MenuItem value={'new'}>
            <Add />
            <Typography variant="subtitle2">Add new</Typography>
          </MenuItem>
        ) : null}
      </Select>
    </FormControl>
  ) : type === 'date' ? (
    <DatePicker
      value={dayjs(value)}
      onChange={onChange}
      format="DD-MM-YYYY"
      sx={{
        width: '100%',
        '& .MuiInputBase-input': {
          padding: '8px 16px',
        },
      }}
    />
  ) : type === 'time' ? (
    <MobileTimePicker
      value={dayjs(value)}
      onChange={onChange}
      sx={{
        width: '100%',
        '& .MuiInputBase-input': {
          padding: '8px 16px',
        },
      }}
    />
  ) : null;
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  customOptions: PropTypes.bool,
  rest: PropTypes.array,
};

export default FormField;
