import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getAddons = (planId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'GETTING_PLAN_ADD_ONS' });
    // }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/us/plan/addons`, {
            params: {
              'request-id': reqId,
              planId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': 'account_admin',
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            // if (lastTime) {
            //   await dispatch({
            //     type: 'ADD_BOTS_TO_ALL_BOTS',
            //     payload: {
            //       plans: response?.data || [],
            //       lastTime: response?.data?.[response?.data?.length - 1]?.id,
            //     },
            //   });
            // } else {
            await dispatch({
              type: 'GETTING_PLAN_ADD_ONS_DONE',
              payload: {
                plans: response?.data || [],
                // lastTime: response?.data?.[response?.data?.length - 1]?.id,
              },
            });
            // }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GETTING_PLAN_ADD_ONS_FAILED',
              payload: `Failed to fetch plan addons`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GETTING_PLAN_ADD_ONS_FAILED',
            payload: `Failed to fetch plan addons`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GETTING_PLAN_ADD_ONS_FAILED',
        payload: `Failed to fetch plan addons`,
      });
    }
  };
};

export default getAddons;
