import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

const updateHelpSection = (helpSectionId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_HELP_SECTION' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(
            `/us/js/question-framework/help-section/${helpSectionId}`,
            payload,
            {
              params: {
                'request-id': reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
            },
          );
          if (response.status === 200) {
            dispatch({
              type: 'UPDATING_HELP_SECTION_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'UPDATING_HELP_SECTION_FAILED',
              payload: response.data.text,
            });
            if (typeof callback === 'function') {
              callback(response.data.text);
            }
          }
        } catch (e) {
          if (
            e?.response?.status === 400 &&
            e?.response?.data?.status === 400
          ) {
            if (typeof callback === 'function') {
              callback(e?.response?.data?.text);
            }
          } else {
            if (typeof callback === 'function') {
              callback(true);
            }
          }
          dispatch({
            type: 'UPDATING_HELP_SECTION_FAILED',
            payload:
              e.response && e.response.data.text
                ? e.response.data.text
                : 'Failed to update help section',
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_HELP_SECTION_FAILED',
        payload: `Failed to update help section`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default updateHelpSection;
