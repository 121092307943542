import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';
const middlewares = [thunk];

const enhancer =
  process.env.REACT_APP_TARGET_ENV === 'local'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

export const store = createStore(rootReducer, enhancer);

export const persistor = persistStore(store);

export default { store, persistor };
