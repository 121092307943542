import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default class SkillTagInput extends Component {
  state = {
    inputValue: '',
    value: this.props.preSkills,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.preSkills !== this.props.preSkills) {
      this.setState({
        inputValue: '',
        value: this.props.preSkills,
      });
    }
  }

  handleChange = (value, actionMeta) => {
    console.log(value);
    console.log(actionMeta.action);
    if (actionMeta.action == 'clear') {
      this.props.skillsTag([]);
      this.props.setSearchError('');
      this.props.resetSearch();
    }
    console.groupEnd();
    this.setState({ value }, () => this.props.skillsTag(this.state.value));
  };

  handleInputChange = (inputValue) => {
    // console.log('inputValue', inputValue);
    const skills = inputValue;
    this.setState({ inputValue });

    if (inputValue.includes(',') && inputValue.length > 1) {
      let arrayOfSkills = skills.split(',').map((test) => {
        return { label: test, value: test };
      });
      arrayOfSkills = arrayOfSkills.filter((skill) => skill.value !== '');
      const { inputValue, value } = this.state;
      if ([...value, ...arrayOfSkills]?.length > 5) {
        this.props.setSearchError(
          'Please provide no more than 5 keywords at a time. Hit Search when done.',
        );
        this.setState(
          {
            inputValue: '',
            value: [...value],
          },
          () => this.props.skillsTag(this.state.value),
        );
      } else {
        this.props.setSearchError('');
        this.setState(
          {
            inputValue: '',
            value: [...value, ...arrayOfSkills],
          },
          () => this.props.skillsTag(this.state.value),
        );
      }
    }
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // console.group('Value Added');
        // console.groupEnd();
        // console.log(value);
        // console.log(inputValue);
        if ([...value, createOption(inputValue)]?.length > 5) {
          this.props.setSearchError(
            'Please provide no more than 5 keywords at a time. Hit Search when done.',
          );
          this.setState(
            {
              inputValue: '',
              value: [...value],
            },
            () => this.props.skillsTag(this.state.value),
          );
        } else {
          this.props.setSearchError('');
          this.setState(
            {
              inputValue: '',
              value: [...value, createOption(inputValue)],
            },
            () => this.props.skillsTag(this.state.value),
          );
        }
        event.preventDefault();
    }
  };

  onBlurAdd = (inputValue) => {
    const { value } = this.state;
    if (!inputValue) return;
    if ([...value, createOption(inputValue)]?.length > 5) {
      this.props.setSearchError(
        'Please provide no more than 5 keywords at a time. Hit Search when done.',
      );
      this.setState(
        {
          inputValue: '',
          value: [...value],
        },
        () => this.props.skillsTag(this.state.value),
      );
    } else {
      this.props.setSearchError('');
      this.setState(
        {
          inputValue: '',
          value: [...value, createOption(inputValue)],
        },
        () => this.props.skillsTag(this.state.value),
      );
    }
  };
  render() {
    const { inputValue, value } = this.state;
    // console.log(inputValue);
    // console.log(value);
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        onBlur={() => {
          this.onBlurAdd(inputValue);
        }}
        isClearable
        isMulti
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder || ''}
        value={value}
        options={this.props.options}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: this.props.error ? 'red' : 'grey',
            minWidth: 350,
          }),
        }}
      />
    );
  }
}

SkillTagInput.propTypes = {
  preSkills: PropTypes.any,
  skillsTag: PropTypes.any,
  error: PropTypes.bool,
  setSearchError: PropTypes.func,
  resetSearch: PropTypes.func,
  options: PropTypes.any,
  placeholder: PropTypes.string,
};
