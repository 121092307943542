import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { auth } from '../firebase';
import getWhitelabelDetailsByDomain from 'redux/actions/Common/getWhitelableDetailsByDomain';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const LoadingPage = () => (
  <Box
    display={'flex'}
    flexDirection={'column'}
    gap={2}
    justifyContent={'center'}
    alignItems={'center'}
    width={'100vw'}
    height={'100vh'}
  >
    <CircularProgress />
    {window.location.host !== 'app.qualification.ai' &&
    window.location.host !== 'app.sellingbees.com' ? (
      <Typography>Powered by Qualification AI</Typography>
    ) : null}
  </Box>
);

const Observer = ({ children }) => {
  const dispatch = useDispatch();
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );

  const [showLoader, setShowLoader] = useState(
    window.location.host !== 'app.qualification.ai' &&
      window.location.host !== 'app.sellingbees.com'
      ? // &&
        // window.location.host !== 'localhost'
        true
      : false,
  );

  useEffect(() => {
    if (
      window.location.host !== 'app.qualification.ai' &&
      window.location.host !== 'app.sellingbees.com'
    ) {
      dispatch(
        getWhitelabelDetailsByDomain(
          window.location.host,
          () => {
            // setTimeout(() => {
            setShowLoader(false);
            // }, 3000);
          },
          () => {
            setShowLoader(false);
          },
        ),
      );
    }
  }, [window.location.host]);

  useEffect(() => {
    dispatch(firebaseCheckSession());
  }, []);

  // console.log(window.location.host);
  // console.log(domainDetails);
  // console.log(window.location);

  return showLoader ? (
    <LoadingPage />
  ) : (
    <>
      <Helmet>
        <title>{domainDetails?.appTitle}</title>
        <link
          rel="icon"
          href={domainDetails?.favicon || '/favicons/favicon.ico'}
        />
        <meta property="og:title" content={domainDetails?.appTitle} />
        <meta
          property="og:description"
          content={domainDetails?.metaDescription}
        />
        <meta
          property="og:url"
          content={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
        />
        <meta name="description" content={domainDetails?.metaDescription} />
      </Helmet>
      {children}
    </>
  );
};

Observer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Observer;
