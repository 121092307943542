const initialstate = {
  showInfoIconOnLeftPanel: false,
  showSectionIntroduction: true,
  currentQuestionIndex: 1,
  completedQuestions: [],
  interviewCompleted: false,
  interviewInProcess: false,
  showProductScenario: true,
  showNamePicker: true,
  fullName: '',
};

const experimentReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPDATE_EXPERIMENT_DATA':
      return Object.assign({}, state, {
        ...state,
        [action.payload.key]: action.payload.value,
      });
    default:
      return state;
  }
};

export default experimentReducer;
