import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import showAlert from '../Common/showAlert';

const getAllConversationsMain = (
  conversationType,
  size,
  lastSeen,
  callback,
) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({ type: 'FETHING_ALL_CONVERSATIONS_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETHING_ALL_CONVERSATIONS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/cs/conversation`, {
            params: {
              'request-id': reqId,
              lastSeen: lastSeen,
              conversationType,
              limit: size,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': 'account_admin',
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type: 'ADD_CONVERSATIONS_TO_ALL_CONVERSATIONS',
                payload: {
                  conversations: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'FETHING_ALL_CONVERSATIONS_DONE',
                payload: {
                  conversations: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETHING_ALL_CONVERSATIONS_FAILED',
              payload: `Failed to get conversations`,
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETHING_ALL_CONVERSATIONS_FAILED',
            payload: `Failed to get conversations`,
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETHING_ALL_CONVERSATIONS_FAILED',
        payload: `Failed to get conversations`,
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getAllConversationsMain;
