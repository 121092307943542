import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';

const uploadWhitelabelLogo = (signedURL, file, callback, errorCallback) => {
  // console.log(signedURL, 'signedURL', file);
  return async (dispatch) => {
    // let unsubscribe = onAuthStateChanged(auth, async (user) => {
    // const token = await user.getIdToken();
    try {
      const response = await axios.put(signedURL, file, {
        headers: {
          'Content-Type': file?.type?.split(';')?.[0],
        },
      });
      // console.log(response);
      if (response.status === 200) {
        // console.log('im here');
        if (typeof callback === 'function') callback();
      } else {
        if (typeof errorCallback === 'function') errorCallback();
      }
    } catch (e) {
      console.log(e);
    }
    //   unsubscribe();
    // });
  };
};

export default uploadWhitelabelLogo;
