import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const checkCallWithTokenScoreStatus = (tk, pc, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_CALL_WITH_TOKEN_SCORE_STATUS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/cs/conversations/t/${tk}/score`, {
            params: {
              'request-id': reqId,
              pass: pc,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': currentRole,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_CALL_WITH_TOKEN_SCORE_STATUS_DONE',
              payload: {
                details: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_CALL_WITH_TOKEN_SCORE_STATUS_FAILED',
              payload: `Failed to get score info status`,
            });

            if (typeof errCb === 'function') {
              errCb();
            }
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_CALL_WITH_TOKEN_SCORE_STATUS_FAILED',
            payload: `Failed to get score info status`,
          });
          if (typeof errCb === 'function') {
            errCb();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_CALL_WITH_TOKEN_SCORE_STATUS_FAILED',
        payload: `Failed to get score info status`,
      });
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default checkCallWithTokenScoreStatus;
