import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const unsubscribe = (email, tokens, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UNSUBSCRIBING' });
    try {
      const reqId = uuidv4();
      const response = await axios.get(`/nf/unsubscribe`, {
        params: {
          'request-id': reqId,
          email,
          tokens: encodeURIComponent(tokens),
        },
      });
      if (response.status === 200) {
        dispatch({
          type: 'UNSUBSCRIBING_DONE',
          payload: response?.data?.data,
        });
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        dispatch({
          type: 'UNSUBSCRIBING_FAILED',
          payload: response.data.text,
        });
        if (typeof callback === 'function') {
          callback(response.data.text);
        }
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.status === 400) {
        if (typeof callback === 'function') {
          callback(e?.response?.data?.text);
        }
      } else {
        if (typeof callback === 'function') {
          callback(true);
        }
      }
      dispatch({
        type: 'UNSUBSCRIBING_FAILED',
        payload:
          e.response && e.response.data.text
            ? e.response.data.text
            : 'Failed to unsubscribe',
      });
    }
  };
};

export default unsubscribe;
