import { v4 as uuidv4 } from 'uuid';

const showAlert = ({
  message,
  showCross,
  title,
  type,
  autoHideDuration,
  vertical,
  horizontal,
}) => {
  return async (dispatch) => {
    // const alertId = uuidv4();
    dispatch({
      type: 'SHOW_ALERT',
      payload: {
        // id: alertId,
        message,
        showCross,
        title,
        type,
        autoHideDuration,
        vertical,
        horizontal,
      },
    });
  };
};

export default showAlert;
