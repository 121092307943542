const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  userDetails: null,
};

const getUserDetailsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_USER_DETAILS':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_USER_DETAILS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        userDetails: action.payload,
      });
    case 'FETCHING_USER_DETAILS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        userDetails: null,
      });
    default:
      return state;
  }
};

export default getUserDetailsReducer;
