import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const sendOpcLink = (email, country, vertical, designation, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'SENDING_OPC_LINK' });
    try {
      const reqId = uuidv4();
      const response = await axios.post(
        `/cs/conversation/link`,
        { email, country, vertical, designation },
        {
          params: {
            'request-id': reqId,
          },
        },
      );
      if (response.status === 200) {
        dispatch({
          type: 'SENDING_OPC_LINK_DONE',
          payload: response?.data,
        });
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        dispatch({
          type: 'SENDING_OPC_LINK_FAILED',
          payload: response.data.text,
        });
        if (typeof callback === 'function') {
          callback(response.data.text);
        }
      }
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.status === 400) {
        if (typeof callback === 'function') {
          callback(e?.response?.data?.text);
        }
      } else {
        if (typeof callback === 'function') {
          callback(true);
        }
      }
      dispatch({
        type: 'SENDING_OPC_LINK_FAILED',
        payload:
          e.response && e.response.data.text
            ? e.response.data.text
            : 'Failed to send one time practice call link',
      });
    }
  };
};

export default sendOpcLink;
