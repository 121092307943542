import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Switch,
  Menu,
  MenuItem,
  Card,
  Popper,
} from '@mui/material';
import MediaPreview from 'components/@2024/MediaPreview';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from 'redux/actions/Common/trackEvent';
import { detect } from 'detect-browser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logError from 'redux/actions/Common/logError';
import ParticipationPieChart from 'components/@2024/ParticipationPieChart';
import parse from 'html-react-parser';
import LineGraph from 'components/LineGraph';
import { PropTypes } from 'prop-types';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Article,
  AutoAwesome,
  Cancel,
  CheckCircle,
  ChevronRight,
  Close,
  CloseRounded,
  Fullscreen,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MoreVert,
} from '@mui/icons-material';
import RichTextEditor from 'components/RichTextEditor';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import { capitalizeText, getMatches } from 'utils';
import moment from 'moment';
import postFeedbackOnEntity from 'redux/actions/Candidate/postFeedbackOnEntity';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import checkPracticeCallScoreStatus from 'redux/actions/Common/checkPracticeCallScoreStatus';
import checkCallWithTokenScoreStatus from 'redux/actions/Common/checkCallWithTokenScoreStatus';
import ProfileCompletion from 'components/ProfileCompletion';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const CRODetails = function CRODetails({ type, interview, setInterview }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { userDetails } = useSelector((state) => state.userDetails);
  const feedbacksOnEntity = useSelector((state) => state.feedbacksOnEntity);
  const scrollDivMinimizedRef = useRef();
  const scrollDiv1Ref = useRef();
  const scrollDivMaximizedRef = useRef();

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('transcript');
  const browser = detect();

  const checkSalesCallScoreStatusTimer = useRef(null);
  const checkPresetQuestionsStatusTimer = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    {},
  );

  const [currentMethod, setCurrentMethod] = useState(
    interview?.analysis?.summary
      ? 'callSummary'
      : interview?.playbook
      ? 'playbook'
      : '',
  );
  const [showPlaybook, setShowPlaybook] = useState(false);

  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { conversationDetails } = conversationDetailsById;
  const { orgConfigs } = useSelector((state) => state.orgConfigs);

  const [showSyncHubspotDialog, setShowSyncHubspotDialog] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [dateTime, setDateTime] = useState(new Date().getTime());
  const [syncingWithHubspot, setSyncingWithHubspot] = useState(false);

  const [feedbackOnEntity, setFeedbackOnEntity] = useState('');
  const [postingFeedback, setPostingFeedback] = useState(false);
  const [showAllFillers, setShowAllFillers] = useState(false);
  const [showAllRepetitions, setShowAllRepetitions] = useState(false);
  const playbookRef = useRef(null);
  const playbookRef1 = useRef(null);

  const [maximizedPlaybookFeedback, setMaximizedPlaybookFeedback] = useState(
    false,
  );

  const [maximizedTranscriptView, setMaximizedTranscriptView] = useState(false);

  const videoRef = useRef(null);

  const [showUpdateSpeakersDialog, setShowUpdateSpeakersDialog] = useState(
    false,
  );
  const [speaker, setSpeaker] = useState('spk_0');
  const [speakers, setSpeakers] = useState({});
  const [showSpeakerMismatch, setShowSpeakerMismath] = useState(null);
  const [reanalyzing, setReanalyzing] = useState(false);
  const [updatingSpeakers, setUpdatingSpeakers] = useState(false);

  const [scoreType, setScoreType] = useState('spk_0');
  const [matchIds, setMatchIds] = useState({});
  const [matchOffsets, setMatchOffsets] = useState({});

  const [currentDirection, setCurrentDirection] = useState('next');
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [currentKeyIndex, setCurrentKeyIndex] = useState({});
  const [currentTranscriptRef, setCurrentTranscriptRef] = useState('minimized');
  const [showMatchingKeywords, setShowMatchingKeywords] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const [askAIQuestion, setAskAIQuestion] = useState('');
  const chatsScrollview = useRef(null);
  const [generatingResponse, setGeneratingResponse] = useState(false);

  const [showPresetQuestions, setShowPresetQuestions] = useState(true);

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  // const handleHightlightMove = (type, keyword) => {
  //   if (type === 'next') {
  //     if (
  //       currentKeyIndex?.[keyword] ===
  //       matchIds[keyword?.split(' ')?.join('_')]?.length - 1
  //     ) {
  //       setCurrentKeyIndex((prev) => {
  //         return {
  //           ...prev,
  //           [keyword]: 0,
  //         };
  //       });
  //     } else {
  //       setCurrentKeyIndex((prev) => {
  //         return {
  //           ...prev,
  //           [keyword]: prev[keyword] + 1,
  //         };
  //       });
  //     }
  //   } else {
  //     if (currentKeyIndex?.[keyword] === 0) {
  //       setCurrentKeyIndex((prev) => {
  //         return {
  //           ...prev,
  //           [keyword]: matchIds[keyword?.split(' ')?.join('_')]?.length - 1,
  //         };
  //       });
  //     } else {
  //       setCurrentKeyIndex((prev) => {
  //         return {
  //           ...prev,
  //           [keyword]: prev[keyword] - 1,
  //         };
  //       });
  //     }
  //   }
  //   setCurrentKeyword(keyword);
  //   setCurrentDirection(type);
  // };

  // const handleFeedbackListScroll = (e, paginate) => {
  //   if (
  //     (e.target.scrollTop + e.target.clientHeight + 1 >=
  //       e.target.scrollHeight &&
  //       // viewType === 'list' &&
  //       !feedbacksOnEntity?.fetching &&
  //       !feedbacksOnEntity?.fetchingPagination &&
  //       feedbacksOnEntity?.feedbacks &&
  //       feedbacksOnEntity?.feedbacks.length % 10 === 0 &&
  //       feedbacksOnEntity?.emptyList === false) ||
  //     paginate
  //   ) {
  //     dispatch(
  //       getFeedbacksOnEntity(
  //         interview?.id,
  //         10,
  //         feedbacksOnEntity?.lastSeen,
  //         () => {},
  //       ),
  //     );
  //   }
  // };

  // const handleUpdateSalesReview = (reanalyze) => {
  //   if (
  //     speakers &&
  //     Object.keys(speakers || {}).length > 0 &&
  //     Object.keys(speakers || {})?.filter((speaker) => !speakers[speaker])
  //       ?.length > 0
  //   ) {
  //     return;
  //   } else {
  //     if (reanalyze) {
  //       setReanalyzing(true);
  //       handleReanalyze(interview?.id, 'list', null, speakers, speaker, () => {
  //         setShowUpdateSpeakersDialog(false);
  //         setReanalyzing(false);
  //       });
  //     } else {
  //       setUpdatingSpeakers(true);
  //       dispatch(
  //         updateSalesReviewData(
  //           interview?.id,
  //           {
  //             speaker: speaker,
  //             speakers: speakers,
  //           },
  //           (data) => {
  //             setUpdatingSpeakers(false);
  //             setShowUpdateSpeakersDialog(false);
  //             setInterview((prev) => {
  //               return data;
  //             });
  //             dispatch({
  //               type: 'UPDATE_SALES_CALL_ANALYSIS',
  //               payload: {
  //                 id: interview?.id,
  //                 data: data,
  //               },
  //             });
  //           },
  //           () => {
  //             setUpdatingSpeakers(false);
  //           },
  //         ),
  //       );
  //     }
  //   }
  // };

  // const handleSpeakerChange = (value) => {
  //   if (value !== interview?.speaker) {
  //     setShowSpeakerMismath(value);
  //   } else {
  //     setSpeaker(value);
  //   }
  // };

  // useEffect(() => {
  //   if (maximizedTranscriptView) {
  //     setCurrentTranscriptRef('maximized');
  //   } else {
  //     setCurrentTranscriptRef('minimized');
  //   }
  // }, [maximizedTranscriptView]);

  // useEffect(() => {
  //   if (showMatchingKeywords) {
  //     if (
  //       currentKeyIndex &&
  //       Object.keys(currentKeyIndex || {})?.length > 0 &&
  //       currentKeyword
  //     ) {
  //       [].slice
  //         .call(document.getElementsByTagName('mark'))
  //         .forEach((el) => (el.style.backgroundColor = '#4672c8'));
  //       [].slice
  //         .call(document.getElementsByTagName('mark'))
  //         .forEach((el) => (el.style.color = '#fff'));
  //       const highlightElement = document.getElementById(
  //         `${currentKeyword?.split(' ')?.join('_')}${
  //           matchIds[currentKeyword?.split(' ')?.join('_')]?.[
  //             currentKeyIndex?.[currentKeyword]
  //           ]
  //         }`,
  //       );
  //       if (highlightElement) {
  //         if (currentTranscriptRef === 'maximized') {
  //           scrollDivMaximizedRef?.current?.scrollTo({
  //             top:
  //               currentDirection === 'prev'
  //                 ? highlightElement?.offsetTop - 120
  //                 : highlightElement?.offsetTop - 80,
  //             behavior: 'smooth',
  //           });
  //         } else {
  //           scrollDivMinimizedRef?.current?.scrollTo({
  //             top:
  //               currentDirection === 'prev'
  //                 ? highlightElement?.offsetTop - 120
  //                 : highlightElement?.offsetTop - 80,
  //             behavior: 'smooth',
  //           });
  //         }
  //         highlightElement.style.backgroundColor = '#fbf030';
  //         highlightElement.style.color = '#000';
  //       }
  //     }
  //   }
  // }, [
  //   currentKeyIndex,
  //   currentKeyword,
  //   currentTranscriptRef,
  //   showMatchingKeywords,
  // ]);

  // useEffect(() => {
  //   if (scrollDivMinimizedRef && scrollDivMinimizedRef.current) {
  //     scrollDivMinimizedRef.current.scrollTo({
  //       top: 0,
  //     });
  //   }
  //   if (scrollDivMaximizedRef && scrollDivMaximizedRef.current) {
  //     scrollDivMaximizedRef.current.scrollTo({
  //       top: 0,
  //     });
  //   }
  // }, [currentParam]);

  // useEffect(() => {
  //   if (scrollDiv1Ref && scrollDiv1Ref.current) {
  //     scrollDiv1Ref.current.scrollTo({
  //       top: 0,
  //     });
  //   }
  // }, [currentMethod]);

  // useEffect(() => {
  //   if (interview) {
  //     setSpeaker(interview?.speaker);
  //     setScoreType(
  //       Object.keys(interview?.speakers || {})?.find(
  //         (spk) => spk === interview?.speaker,
  //       ) || 'spk_0',
  //     );
  //     setSpeakers(interview?.speakers);
  //     setMeetingTitle(interview?.title);
  //   }
  // }, [interview]);

  // useEffect(() => {
  //   if (
  //     scriptReadingFillerWords &&
  //     Array.isArray(scriptReadingFillerWords) &&
  //     scriptReadingFillerWords.length > 0
  //   ) {
  //     setFillerWordsRegexp(
  //       new RegExp(
  //         scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
  //         'ig',
  //       ),
  //     );
  //   }
  // }, [scriptReadingFillerWords]);

  // useEffect(() => {
  //   if (
  //     scriptRepeatWords &&
  //     Array.isArray(scriptRepeatWords) &&
  //     scriptRepeatWords.length > 0
  //   ) {
  //     setRepeatWordRegexp(
  //       new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
  //     );
  //   }
  // }, [scriptRepeatWords]);

  // useEffect(() => {
  //   if (
  //     interview?.analysis?.fillerInfo?.fillers &&
  //     Object.keys(interview?.analysis?.fillerInfo?.fillers) &&
  //     Object.keys(interview?.analysis?.fillerInfo?.fillers).length > 0
  //   ) {
  //     setScriptReadingFillerWords(
  //       Object.keys(interview?.analysis?.fillerInfo?.fillers),
  //     );
  //   }
  //   if (
  //     interview?.analysis?.repetitionInfo?.repetitions &&
  //     Object.keys(interview?.analysis?.repetitionInfo?.repetitions) &&
  //     Object.keys(interview?.analysis?.repetitionInfo?.repetitions).length > 0
  //   ) {
  //     setScriptRepeatWords(
  //       Object.keys(interview?.analysis?.repetitionInfo?.repetitions),
  //     );
  //   }
  // }, [interview]);

  // useEffect(() => {
  //   if (currentMethod === 'feedback') {
  //     dispatch(getFeedbacksOnEntity(interview?.id, 10, null, () => {}));
  //   } else if (currentMethod === 'askAI') {
  //     dispatch(
  //       getConversationById(
  //         interview?.conversationId,
  //         interview?.speaker,
  //         (data) => {
  //           if (
  //             data?.presetQuestionStatus === 'completed' ||
  //             data?.presetQuestionStatus === 'failed'
  //           ) {
  //             clearInterval(checkPresetQuestionsStatusTimer.current);
  //             if (chatsScrollview?.current) {
  //               chatsScrollview?.current?.scroll({
  //                 top: chatsScrollview?.current?.scrollHeight,
  //                 behaviour: 'smooth',
  //               });
  //             }
  //           } else {
  //             checkPresetQuestionsStatusTimer.current = setInterval(() => {
  //               dispatch(
  //                 getConversationById(
  //                   interview?.conversationId,
  //                   interview?.speaker,
  //                   (data) => {
  //                     if (
  //                       data?.presetQuestionStatus === 'completed' ||
  //                       data?.presetQuestionStatus === 'failed'
  //                     ) {
  //                       clearInterval(checkPresetQuestionsStatusTimer.current);
  //                       if (chatsScrollview?.current) {
  //                         chatsScrollview?.current?.scroll({
  //                           top: chatsScrollview?.current?.scrollHeight,
  //                           behaviour: 'smooth',
  //                         });
  //                       }
  //                     }
  //                   },
  //                 ),
  //               );
  //             }, 5000);
  //           }
  //         },
  //       ),
  //     );
  //   }
  //   return () => clearInterval(checkPresetQuestionsStatusTimer.current);
  // }, [currentMethod]);

  // useEffect(() => {
  //   if (showPlaybook && typeof showPlaybook === 'string') {
  //     if (playbookRef.current && interview?.playbook) {
  //       const sectionId = document.getElementById(showPlaybook);
  //       playbookRef.current.scrollTo({
  //         top: sectionId?.offsetTop || 0,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // }, [showPlaybook]);

  // useEffect(() => {
  //   if (interview?.transcript && searchKeywords && searchKeywords.length > 0) {
  //     const matchIdsMap = searchKeywords.reduce((acc, curr) => {
  //       acc[curr?.split(' ')?.join('_')] =
  //         getMatches(interview?.transcript, curr) || [];
  //       return acc;
  //     }, {});
  //     setMatchIds(matchIdsMap);
  //     setCurrentKeyword(searchKeywords[0]);
  //     setCurrentKeyIndex(
  //       searchKeywords.reduce((acc, curr) => {
  //         acc[curr] = 0;
  //         return acc;
  //       }, {}),
  //     );
  //   }
  // }, [searchKeywords, interview?.transcript]);

  // useEffect(() => {
  //   if (
  //     interview?.speakerScoreMap &&
  //     Object.keys(interview?.speakerScoreMap || {}) &&
  //     Object.keys(interview?.speakerScoreMap || {})?.length > 0
  //   ) {
  //     setScoreCalculationInProcess(
  //       Object.keys(interview?.speakerScoreMap || {})?.reduce((acc, curr) => {
  //         acc[curr] = interview?.speakerScoreMap?.[curr]?.status;
  //         return acc;
  //       }, {}),
  //     );
  //   }
  // }, [interview]);

  // useEffect(() => {
  //   if (
  //     scoreCalculationInProcess[scoreType] !== 'completed' &&
  //     scoreCalculationInProcess[scoreType] !== 'failed'
  //   ) {
  //     if (type === 'salesCall') {
  //       checkSalesCallScoreStatusTimer.current = setInterval(() => {
  //         dispatch(
  //           checkSalesCallScoreStatus(
  //             interview?.id,
  //             (data) => {
  //               if (
  //                 data?.allSpeakers &&
  //                 Object.keys(data?.allSpeakers || {})?.length > 0
  //               ) {
  //                 setScoreCalculationInProcess(
  //                   Object.keys(data?.allSpeakers || {})?.reduce(
  //                     (acc, curr) => {
  //                       acc[curr] = data?.allSpeakers?.[curr]?.status;
  //                       return acc;
  //                     },
  //                     {},
  //                   ),
  //                 );
  //               }
  //               if (
  //                 Object.values(data?.allSpeakers || {}) &&
  //                 Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                 Object.values(data?.allSpeakers || {})?.find(
  //                   (scoreMap) =>
  //                     scoreMap?.status !== 'completed' &&
  //                     scoreMap?.status !== 'failed',
  //                 ) === undefined
  //               ) {
  //                 clearInterval(checkSalesCallScoreStatusTimer.current);
  //                 if (
  //                   Object.values(data?.allSpeakers || {}) &&
  //                   Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                   Object.values(data?.allSpeakers || {})?.find(
  //                     (scoreMap) =>
  //                       scoreMap &&
  //                       Object.keys(scoreMap || {}) &&
  //                       Object.keys(scoreMap || {})?.length > 0 &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       ) &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       )?.length > 0,
  //                   )
  //                 ) {
  //                   setInterview((prev) => {
  //                     return {
  //                       ...prev,
  //                       speakerScoreMap: data?.allSpeakers,
  //                     };
  //                   });
  //                   dispatch({
  //                     type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
  //                     payload: {
  //                       id: interview?.id,
  //                       value: data?.allSpeakers,
  //                     },
  //                   });
  //                 }
  //               }
  //             },
  //             () => {
  //               setScoreCalculationInProcess(false);
  //               clearInterval(checkSalesCallScoreStatusTimer.current);
  //             },
  //           ),
  //         );
  //       }, 10000);
  //     } else if (type === 'practiceCall') {
  //       checkSalesCallScoreStatusTimer.current = setInterval(() => {
  //         dispatch(
  //           checkPracticeCallScoreStatus(
  //             interview?.id,
  //             (data) => {
  //               if (
  //                 data?.allSpeakers &&
  //                 Object.keys(data?.allSpeakers || {})?.length > 0
  //               ) {
  //                 setScoreCalculationInProcess(
  //                   Object.keys(data?.allSpeakers || {})?.reduce(
  //                     (acc, curr) => {
  //                       acc[curr] = data?.allSpeakers?.[curr]?.status;
  //                       return acc;
  //                     },
  //                     {},
  //                   ),
  //                 );
  //               }
  //               if (
  //                 Object.values(data?.allSpeakers || {}) &&
  //                 Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                 Object.values(data?.allSpeakers || {})?.find(
  //                   (scoreMap) =>
  //                     scoreMap?.status !== 'completed' &&
  //                     scoreMap?.status !== 'failed',
  //                 ) === undefined
  //               ) {
  //                 clearInterval(checkSalesCallScoreStatusTimer.current);
  //                 if (
  //                   Object.values(data?.allSpeakers || {}) &&
  //                   Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                   Object.values(data?.allSpeakers || {})?.find(
  //                     (scoreMap) =>
  //                       scoreMap &&
  //                       Object.keys(scoreMap || {}) &&
  //                       Object.keys(scoreMap || {})?.length > 0 &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       ) &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       )?.length > 0,
  //                   )
  //                 ) {
  //                   setInterview((prev) => {
  //                     return {
  //                       ...prev,
  //                       speakerScoreMap: data?.allSpeakers,
  //                     };
  //                   });
  //                   dispatch({
  //                     type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
  //                     payload: {
  //                       id: interview?.id,
  //                       value: data?.allSpeakers,
  //                     },
  //                   });
  //                 }
  //               }
  //             },
  //             () => {
  //               setScoreCalculationInProcess(false);
  //               clearInterval(checkSalesCallScoreStatusTimer.current);
  //             },
  //           ),
  //         );
  //       }, 10000);
  //     } else {
  //       checkSalesCallScoreStatusTimer.current = setInterval(() => {
  //         dispatch(
  //           checkCallWithTokenScoreStatus(
  //             tk,
  //             pc,
  //             (data) => {
  //               if (
  //                 data?.allSpeakers &&
  //                 Object.keys(data?.allSpeakers || {})?.length > 0
  //               ) {
  //                 setScoreCalculationInProcess(
  //                   Object.keys(data?.allSpeakers || {})?.reduce(
  //                     (acc, curr) => {
  //                       acc[curr] = data?.allSpeakers?.[curr]?.status;
  //                       return acc;
  //                     },
  //                     {},
  //                   ),
  //                 );
  //               }
  //               if (
  //                 Object.values(data?.allSpeakers || {}) &&
  //                 Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                 Object.values(data?.allSpeakers || {})?.find(
  //                   (scoreMap) =>
  //                     scoreMap?.status !== 'completed' &&
  //                     scoreMap?.status !== 'failed',
  //                 ) === undefined
  //               ) {
  //                 clearInterval(checkSalesCallScoreStatusTimer.current);
  //                 if (
  //                   Object.values(data?.allSpeakers || {}) &&
  //                   Object.values(data?.allSpeakers || {})?.length > 0 &&
  //                   Object.values(data?.allSpeakers || {})?.find(
  //                     (scoreMap) =>
  //                       scoreMap &&
  //                       Object.keys(scoreMap || {}) &&
  //                       Object.keys(scoreMap || {})?.length > 0 &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       ) &&
  //                       Object.keys(scoreMap || {}).filter(
  //                         (key) => key !== 'status',
  //                       )?.length > 0,
  //                   )
  //                 ) {
  //                   setInterview((prev) => {
  //                     return {
  //                       ...prev,
  //                       speakerScoreMap: data?.allSpeakers,
  //                     };
  //                   });
  //                   dispatch({
  //                     type: 'UPDATE_SALES_CALL_ANALYSIS_SCORES',
  //                     payload: {
  //                       id: interview?.id,
  //                       value: data?.allSpeakers,
  //                     },
  //                   });
  //                 }
  //               }
  //             },
  //             () => {
  //               setScoreCalculationInProcess(false);
  //               clearInterval(checkSalesCallScoreStatusTimer.current);
  //             },
  //           ),
  //         );
  //       }, 10000);
  //     }
  //   }
  //   return () => {
  //     clearInterval(checkSalesCallScoreStatusTimer.current);
  //   };
  // }, [scoreCalculationInProcess, scoreType]);

  useEffect(() => {}, []);

  useEffect(() => {
    const seekVideo = (time) => {
      console.log(time);
      const seconds =
        Number(time?.split(':')?.[0] || 0) * 60 +
        Number(time?.split(':')?.[1] || 0);
      console.log(seconds);
      if (videoRef?.current) {
        videoRef.current.currentTime = seconds;
        videoRef.current.play();
      }
    };
    if (
      [].slice.call(document.getElementsByClassName('highlight')) &&
      [].slice.call(document.getElementsByClassName('highlight'))?.length > 0
    ) {
      [].slice.call(document.getElementsByClassName('highlight'))?.map((el) => {
        el.addEventListener('click', () => {
          seekVideo(el?.innerText);
        });
      });
    }

    return () => {};
  }, [[].slice.call(document.getElementsByClassName('highlight'))]);

  // useEffect(() => {
  //   const onTimeUpdate = () => {
  //     console.log(videoRef.current.currentTime);
  //   };
  //   videoRef.current.addEventListener('timeupdate', onTimeUpdate);
  //   return () =>
  //     videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
  // }, []);

  //   console.log(showSyncHubspotDialog);
  // console.log(getMatches(interview?.transcript, 'attendance management'));
  // console.log(matchIds);
  // console.log(matchOffsets);
  // console.log(currentKeyIndex);
  // console.log(currentTranscriptRef);
  // console.log(scrollDivMaximizedRef);
  // console.log(scrollDivMinimizedRef);
  // console.log(scoreCalculationInProcess);
  // console.log(scoreType);
  // console.log(fetching);
  // console.log(conversationDetails);
  // console.log(interview);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
      <Box
        display={'flex'}
        flex={1}
        sx={{
          border: '1px solid #d3d3d3',
          // height: 'auto',
          // padding: 2,
        }}
      >
        <Box flex={1} display={'flex'} flexDirection={'column'}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={{
              borderBottom: '1px solid #d3d3d3',
            }}
          >
            <Tabs
              value={currentParam}
              onChange={(e, newValue) => {
                handleTrackEvent(e, `change_factors_tab_to_${newValue}`);
                setCurrentParam(newValue);
              }}
              aria-label="Parameter Tabs"
              orientation={'horizontal'}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab
                value={'transcript'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Transcript
                  </Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              {/* <Tab
                value={'fillers'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Filler Words
                  </Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
              <Tab
                value={'repeatitions'}
                label={
                  <Typography variant="caption" fontWeight={'bold'}>
                    Repeated Words
                  </Typography>
                }
                id={`simple-tab-${5}`}
                aria-controls={`simple-tabpanel-${5}`}
                sx={{}}
              /> */}
            </Tabs>
            {/* <Box display={'flex'} alignItems={'center'}>
              <Tooltip title="maximize view" placement="left" arrow>
                <Fullscreen
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                    marginRight: 2,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTrackEvent(e, 'maximize_transcript_view');
                    setMaximizedTranscriptView((prev) => !prev);
                  }}
                />
              </Tooltip>
            </Box> */}
          </Box>
          {currentParam === 'transcript' ? (
            <Box display={'flex'} alignItems={'start'}>
              <Box
                ref={scrollDivMinimizedRef}
                sx={{
                  flex: 2,
                  // borderRadius: 2,
                  mark: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    padding: '2px 6px',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  minHeight: 'calc(100vh - 140px)',
                  maxHeight: 'calc(100vh - 140px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                  borderRight: '1px solid #d3d3d3',
                }}
                position={'relative'}
              >
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                    mark: {
                      background: '#6b8bea',
                    },
                  }}
                >
                  {parse(
                    interview?.transcript?.replace(
                      new RegExp('(([0-5])?[0-9]):([0-5][0-9])', 'gmi'),
                      (highlight) => {
                        return `<button class="highlight">${highlight}</button>`;
                      },
                    ) || '',
                  )}
                </Typography>
              </Box>
              <MediaPreview
                videoRef={videoRef}
                styles={{
                  flex: 1,
                  // borderLeft: '1px solid #d3d3d3',
                  padding: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                url={
                  type === 'croInterviews'
                    ? interview?.customFields?.videoUrl
                    : type === 'practiceCall' || type === 'otpc'
                    ? interview?.customFields?.audioLink || ''
                    : interview?.customData?.url || ''
                }
                mediaType={''}
                handleVideoSeekTo
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

CRODetails.propTypes = {
  interview: PropTypes.object,
  setInterview: PropTypes.func,
  type: PropTypes.string.isRequired,
  handleReanalyze: PropTypes.func,
};

export default CRODetails;
