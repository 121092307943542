import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Container from 'components/Container';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import sendOpcLink from 'redux/actions/Common/sendOpcLink';
import showAlert from 'redux/actions/Common/showAlert';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  country: yup
    .string()
    .trim()
    .required('Please select a country'),
});

const OneTimePracticeCallInvite = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const alertState = useSelector((state) => state.alertState);

  const params = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const initialValues = {
    email: params.get('email') ? params.get('email').replace(/ /g, '+') : '',
    country: 'united states',
    vertical: '',
    designation: '',
  };

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      sendOpcLink(
        values.email,
        values.country,
        values.vertical,
        values.designation,
        (error) => {
          if (error) {
            setLoading(false);
            dispatch(
              showAlert({
                message: 'Failed to send the invitation. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          } else {
            setLoading(false);
            dispatch(
              showAlert({
                message: 'Invitation has been sent successfully.',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        },
      ),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setVerticalOptions(data?.verticals);
        setDesignationOptions(data?.designations);
      }),
    );
  }, []);

  console.log(formik.values);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        minHeight: 'calc(100vh - 120px)',
      }}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'column' }}
      position={'relative'}
      justifyContent="center"
      alignItems={'center'}
    >
      <Container maxWidth={700}>
        {/* <Card sx={{ width: 1, height: 1, padding: 2 }}> */}
        <Typography variant="h4">Qualification AI Preview</Typography>
        <Typography variant="subtitle2">
          Practice a sales call with an AI persona. The bot will act like a
          curious prospect and converse with you interactively. Happy selling!
        </Typography>
        <Typography variant="h5" mt={8}>
          Roleplay invitation
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <TextField
                label="Enter email *"
                placeholder="Enter email *"
                variant="outlined"
                name={'email'}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="caption" color={'text.secondary'}>
                  Prospect Vertical*
                </Typography>
                <br />
                <Typography variant="caption" color={'text.secondary'}>
                  Select or type your own
                </Typography>
              </Box>
              <AutoCompleteMUI
                id="vertical"
                value={formik.values.vertical}
                onChange={(event, newValue) => {
                  formik.setFieldValue('vertical', newValue);
                }}
                options={verticalOptions}
                onTextChange={formik.handleChange}
                onKeyDown={() => {}}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="caption" color={'text.secondary'}>
                  Prospect Designation*
                </Typography>
                <br />
                <Typography variant="caption" color={'text.secondary'}>
                  Select or type your own
                </Typography>
              </Box>
              <AutoCompleteMUI
                id="designation"
                value={formik.values.designation}
                onChange={(event, newValue) => {
                  formik.setFieldValue('designation', newValue);
                }}
                options={designationOptions}
                onTextChange={formik.handleChange}
                onKeyDown={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                variant={'subtitle2'}
                sx={{ marginBottom: 1, fontWeight: 'bold' }}
              >
                Country
              </Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="country-select-label"
                  id="country-select"
                  name="country"
                  MenuProps={{ disableScrollLock: true }}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                >
                  <MenuItem key={0} value="india">
                    India
                  </MenuItem>
                  <MenuItem key={1} value="united states">
                    United States
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {error ? (
                <Typography
                  variant="body1"
                  style={{ color: theme.palette.error.main }}
                >
                  Please enter a valid email.
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/* <Button
                      size="large"
                      variant="text"
                      color="primary"
                      disabled={loading}
                      onClick={() => {
                        history.push('https://qualificaiton.ai');
                      }}
                    >
                      No, I&apos;d rather stay on
                    </Button> */}
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading || !formik.values.email}
                >
                  Invite for roleplay
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
        {/* </Card> */}
      </Container>
    </Box>
  );
};

export default OneTimePracticeCallInvite;
