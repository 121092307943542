import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const verifyWhitelabelLogoUpload = (fileId, callback, errorCallback) => {
  return async (dispatch) => {
    let unsubscribe = onAuthStateChanged(auth, async (user) => {
      const token = await user.getIdToken();
      try {
        const response = await axios.put(`/us/org/logo/${fileId}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'x-api-version': apiVersion || 'v1',
          },
          params: {
            'request-id': uuidv4(),
          },
        });
        // console.log(response);
        if (typeof callback === 'function') callback();
        if (response.status === 200) {
          // console.log('im here');
        } else {
          if (typeof errorCallback === 'function') errorCallback();
        }
      } catch (e) {
        console.log(e);
      }
      unsubscribe();
    });
  };
};

export default verifyWhitelabelLogoUpload;
