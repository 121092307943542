import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import firebaseCheckSession from '../../redux/actions/Auth/firebaseCheckSession';

const AuthenticatedRoutes = (props) => {
  const dispatch = useDispatch();
  const {
    layout: Layout,
    component: Component,
    leftSidebar,
    competeSidebar,
    current,
    noFooter,
    noTopbar,
    ...rest
  } = props;
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  useEffect(() => {
    dispatch(firebaseCheckSession());
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && isLoggedIn) {
      if (userInfo?.designations?.includes('account_admin')) {
        history.push('/sales-call-analysis');
      } else {
        history.push('/contests');
      }
    }
  }, [userInfo]);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isLoggedIn ? (
          <Layout
            current={current}
            noTopbar={noTopbar}
            noFooter={noFooter}
            leftSidebar={leftSidebar}
            competeSidebar={competeSidebar}
          >
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/auth/signin" />
        )
      }
    />
  );
};

AuthenticatedRoutes.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  leftSidebar: PropTypes.bool,
  competeSidebar: PropTypes.bool,
  noFooter: PropTypes.bool,
  noTopbar: PropTypes.bool,
  current: PropTypes.string,
  path: PropTypes.string,
};

export default AuthenticatedRoutes;
