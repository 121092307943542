const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  competitionDetails: null,
};

const getCompetitionByIdReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_COMPETITION_BY_ID':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_COMPETITION_BY_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        competitionDetails: action.payload.details,
      });
    case 'UPDATE_COMPETITION_DETAILS_STARTED_ON': {
      let updatedCompetitionDetails = { ...state.competitionDetails };
      if (updatedCompetitionDetails?.participation !== undefined) {
        if (updatedCompetitionDetails?.participation?.startedOn !== undefined) {
          updatedCompetitionDetails.participation.startedOn = action.payload;
        } else {
          updatedCompetitionDetails.participation = {
            ...updatedCompetitionDetails.participation,
            startedOn: action.payload,
          };
        }
      } else {
        updatedCompetitionDetails = {
          ...updatedCompetitionDetails,
          participation: {
            startedOn: action.payload,
          },
        };
      }
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }
    case 'UPDATE_COMPETITION_DETAILS_STARTED_ON_PHASE_2': {
      let updatedCompetitionDetails = { ...state.competitionDetails };
      if (updatedCompetitionDetails?.participation !== undefined) {
        if (
          updatedCompetitionDetails?.participation?.phase2StartedOn !==
          undefined
        ) {
          updatedCompetitionDetails.participation.phase2StartedOn =
            action.payload;
        } else {
          updatedCompetitionDetails.participation = {
            ...updatedCompetitionDetails.participation,
            phase2StartedOn: action.payload,
          };
        }
      } else {
        updatedCompetitionDetails = {
          ...updatedCompetitionDetails,
          participation: {
            phase2StartedOn: action.payload,
          },
        };
      }
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }
    case 'UPDATE_COMPETITION_DETAILS_PARTICIPATION_NAME': {
      const updatedCompetitionDetails = { ...state.competitionDetails };
      if (updatedCompetitionDetails?.participation)
        updatedCompetitionDetails.participation.candidateName = action.payload;
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }

    case 'UPDATE_COMPETITION_DETAILS_READ_SCRIPT': {
      const updatedCompetitionDetails = { ...state.competitionDetails };
      if (updatedCompetitionDetails?.sections?.[0] !== undefined) {
        updatedCompetitionDetails.sections[0].script = action.payload;
      }
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }

    case 'UPDATE_COMPETITION_DETAILS_PRODUCT_KNOWLEDGE_COMPLETED': {
      const updatedCompetitionDetails = { ...state.competitionDetails };
      if (
        updatedCompetitionDetails?.participation?.participationSections?.[1] !==
        undefined
      ) {
        updatedCompetitionDetails.participation.participationSections[1].pdtKnowledgeQuestionCompleted =
          action.payload;
      }
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }

    case 'UPDATE_COMPETITION_DETAILS_MCQs': {
      const updatedCompetitionDetails = { ...state.competitionDetails };
      if (updatedCompetitionDetails?.sections?.[1] !== undefined) {
        updatedCompetitionDetails.sections[1].sectionQuestion = action.payload;
      }
      return Object.assign({}, state, {
        ...state,
        competitionDetails: updatedCompetitionDetails,
      });
    }

    case 'FETCHING_COMPETITION_BY_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        competitionDetails: null,
      });
    default:
      return state;
  }
};

export default getCompetitionByIdReducer;
