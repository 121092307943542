const initialstate = {
  uploading: null,
  uploadingDone: null,
  uploadingFailed: null,
  error: null,
  errorText: null,
};

const uploadProfilePictureReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPLOADING_PROFILE_PICTURE':
      return Object.assign({}, state, {
        uploading: true,
      });
    case 'PROFILE_PICTURE_UPLOADED':
      return Object.assign({}, state, {
        uploading: false,
        uploadingDone: true,
        uploadingFailed: false,
        error: false,
        errorText: '',
      });
    case 'PROFILE_PICTURE_UPLOAD_FAILED':
      return Object.assign({}, state, {
        uploading: false,
        uploadingDone: false,
        uploadingFailed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default uploadProfilePictureReducer;
