import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const deleteProspectFromCompanyProfile = (personId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'DELETING_PROSPECT_FROM_ACCOUNT' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.delete(
            `/us/js/company-profile/person/${personId}`,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'current-role': 'candidate',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'DELETING_PROSPECT_FROM_ACCOUNT_DONE',
            });
            if (typeof callback === 'function') {
              callback(personId);
            }
          } else {
            dispatch({
              type: 'DELETING_PROSPECT_FROM_ACCOUNT_FAILED',
              payload: `Failed to delete prospect`,
            });
            if (typeof callback === 'function') {
              callback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'DELETING_PROSPECT_FROM_ACCOUNT_FAILED',
            payload: `Failed to delete prospect`,
          });
          if (typeof callback === 'function') {
            callback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'DELETING_PROSPECT_FROM_ACCOUNT_FAILED',
        payload: `Failed to delete prospect`,
      });
      if (typeof callback === 'function') {
        callback();
      }
    }
  };
};

export default deleteProspectFromCompanyProfile;
