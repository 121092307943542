const initialstate = {
  processing: null,
  done: null,
  failed: null,
  error: null,
  errorText: null,
};

const updateParticipationStatusForTimeoutReducer = (
  state = initialstate,
  action,
) => {
  switch (action.type) {
    case 'UPDATING_PARTICIPATION_FOR_TIMEOUT':
      return Object.assign({}, state, {
        processing: true,
      });
    case 'UPDATING_PARTICIPATION_FOR_TIMEOUT_DONE':
      return Object.assign({}, state, {
        processing: false,
        done: true,
        failed: false,
        error: false,
        errorText: '',
      });
    case 'UPDATING_PARTICIPATION_FOR_TIMEOUT_FAILED':
      return Object.assign({}, state, {
        processing: false,
        done: false,
        failed: true,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default updateParticipationStatusForTimeoutReducer;
