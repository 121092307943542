const initialstate = {
  timerSs: 0,
};

const globalTimerReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SET_TIMER_SS':
      return Object.assign({}, state, {
        timerSs: action.payload,
      });
    case 'RESET_TIMER_SS':
      return Object.assign({}, state, {
        timerSs: 0,
      });
    default:
      return state;
  }
};

export default globalTimerReducer;
