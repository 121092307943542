import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Avatar,
  Alert,
  ListItemText,
  OutlinedInput,
  Autocomplete,
} from '@mui/material';
import { keyframes } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowBack,
  Article,
  Audiotrack,
  Call,
  CallEnd,
  ChevronRight,
  Circle,
  Close,
  Delete,
  Favorite,
  Info,
  Lock,
  Mic,
  MicOff,
  Mood,
  PlayArrow,
  RecordVoiceOverOutlined,
  Replay,
  SentimentVeryDissatisfied,
  StopCircle,
  SupportAgent,
  Troubleshoot,
  Undo,
  VoiceOverOffOutlined,
  VolumeUp,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import uploadAudio from 'redux/actions/Common/uploadAudio';
import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
import updateConversationStatusForPracticeCall from 'redux/actions/Candidate/updateConversationStatusForPracticeCall';
import logError from 'redux/actions/Common/logError';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import parse from 'html-react-parser';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import validateCallPracticeToken from 'redux/actions/Common/validateCallPracticeToken';
import getResponseByPracticeToken from 'redux/actions/Candidate/getResponseByPracticeToken';
import verifyAudioUploadForPracticeCall from 'redux/actions/Common/verifyAudioUploadForPracticeCall';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import analyzeCallWithToken from 'redux/actions/Common/analyzeCallWithToken';
import showAlert from 'redux/actions/Common/showAlert';
import checkCallWithTokenTranscriptionStatus from 'redux/actions/Common/checkCallWithTokenTranscriptionStatus';
import checkCallWithTokenMergeAudioStatus from 'redux/actions/Common/checkCallWithTokenMergeAudioStatus';
import getMergedConversation from 'redux/actions/Candidate/getMergedConversation';
import getMergedCallWithToken from 'redux/actions/Candidate/getMergedCallWithToken';
import checkCallWithTokenScoreStatus from 'redux/actions/Common/checkCallWithTokenScoreStatus';
import createNewConversationWithToken from 'redux/actions/Candidate/createNewConversationWithToken';
import trackEvent from 'redux/actions/Common/trackEvent';
import { capitalizeText } from 'utils';
import saveCandidateFeedbackOnPlatformExperience from 'redux/actions/Candidate/saveCandidateFeedbackOnPlatformExperience';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import ReportPage from 'pages/ReportPage';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CallPractice = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const pc = params.get('pc');
  const tk = params.get('tk');
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentConversation, setCurrentConversation] = useState(null);

  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);
  const [mediaStream, setMediaStream] = useState(null);
  const [sampleRate, setSampleRate] = useState(48000);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);
  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [autoPlay, setAutoPlay] = useState(false);
  const [autoRecord, setAutoRecord] = useState(true);
  const [autoStart, setAutoStart] = useState(false);
  const [message, setMessage] = useState('');
  const [microphoneLevel, setMicrophoneLevel] = useState(0);

  const [conversationStarted, setConversationStarted] = useState(false);
  const [numberOfDots, setNumberOfDots] = useState(0);
  const [numberOfAltDots, setNumberOfAltDots] = useState(0);
  const dotsTimerRef = useRef(null);
  const dots2TimerRef = useRef(null);
  const [playingResponse, setPlayingResponse] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState('');

  const [gettingResponse, setGettingResponse] = useState(false);

  const [showEmptySpeech, setShowEmptySpeech] = useState(false);

  const responseAudioRef = useRef(null);

  const checkPauseTimerRef = useRef(null);
  const [pauseTimer, setPauseTimer] = useState(0);
  const [pauseTimeLimit, setPauseTimeLimit] = useState(2500);

  const [analysisInProgress, setAnalysisInProgress] = useState([]);

  const [endConversationAlert, setEndConversationAlert] = useState(false);

  const [validatingLink, setValidatingLink] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  const [viewType, setViewType] = useState('conversation');

  const [showReAnalyzeDrawer, setShowReAnalyzeDrawer] = useState(null);
  const [methods, setMethods] = useState([
    'meddpic',
    'spiced',
    'spin',
    'challenger',
    'bant',
  ]);
  const [playbook, setPlaybook] = useState('');
  const [speaker, setSpeaker] = useState('spk_0');
  const [speakers, setSpeakers] = useState({});
  const checkTranscriptionProgressTimer = useRef(null);
  const [transcriptReady, setTranscriptReady] = useState(false);
  const [transcriptionFailed, setTranscriptionFailed] = useState(false);
  const [salesReview, setSalesReview] = useState(null);

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('transcript');
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [currentMethod, setCurrentMethod] = useState('meddpic');
  const checkMergedAudioProgressTimerRef = useRef(null);

  const checkPracticeCallScoreStatusTimer = useRef(null);

  const scrollDivRef = useRef(null);
  const scrollDiv1Ref = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    true,
  );

  const [conversationCreated, setConversationCreated] = useState(false);
  const [bots, setBots] = useState([]);
  const [botId, setBotId] = useState('');
  const [vertical, setVertical] = useState('');
  const [designation, setDesignation] = useState('');

  const [conversationTitle, setConversationTitle] = useState('');
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const [creatingConversation, setCreatingConversation] = useState(false);
  const [competitors, setCompetitors] = useState('');

  const [salesPersonName, setSalesPersonName] = useState('');
  const [createError, setCreateError] = useState('');
  const browser = detect();

  const [sellerName, setSellerName] = useState('');
  const [sellerCompanyName, setSellerCompanyName] = useState('');
  const [isNotSupported, setIsNotSupported] = useState(false);

  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [rating, setRating] = useState(0);
  const [improvements, setImprovements] = useState('');
  const [likes, setLikes] = useState('');
  const [
    showFeedbackSuccessfulDialog,
    setShowFeedbackSuccessfulDialog,
  ] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      const sampleRateBlock = stream.getAudioTracks()[0].getSettings()
        .sampleRate;
      setSampleRate(sampleRateBlock);
      // console.log('sampleRateBlock', sampleRateBlock);
      setMediaStream(stream);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      console.log(e);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleRecordClick = async () => {
    setConversationStarted(true);
    setAutoStart(false);
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      try {
        setSelectedAudio(null);
        setRecordedBlobs([]);
        setRecording(true);
        startTimer();
        if (playBackRef && playBackRef.current) {
          playBackRef.current.src = null;
        }
        SpeechRecognition.startListening({ continuous: true });
      } catch (error) {
        handleRecordStopClick();
        alert('An error occurred while recording: ' + error.message);
      }
    }
  };

  const handleRecordStopClick = async (end) => {
    try {
      await recorder.stopRecording();
      let blob = await recorder.getBlob();
      // console.log('blob', blob);
      setSelectedAudio(blob);
      stopTimer();
      setRecording(false);
      SpeechRecognition.stopListening();
      handleGetResponse(blob, end);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    setMessage('');
    resetTranscript();
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    getRecordingPermission();
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  // const handleGetResponse = () => {};

  const handleGetResponse = (audio, end) => {
    if (message.trim()) {
      setGettingResponse(true);
      dispatch(
        getResponseByPracticeToken(
          tk,
          pc,
          currentConversation?.id,
          message,
          end ? 'completed' : null,
          async (data) => {
            if (data?.userAudioUploadLink && data?.userAudioId && audio) {
              dispatch(
                uploadAudio(data?.userAudioUploadLink, audio, () => {
                  dispatch(
                    verifyAudioUploadForPracticeCall(
                      tk,
                      pc,
                      data?.userAudioUploadLink && data?.userAudioId,
                      null,
                      () => {
                        setSelectedAudio(null);
                      },
                    ),
                  );
                }),
              );
            }
            setGettingResponse(false);
            setMessage('');
            resetTranscript();
            // if (audio) {
            //   playBackRef.current.src = null;
            // }
            if (streamRef && streamRef.current)
              streamRef.current.getTracks().forEach((track) => track.stop());
            // if (browser?.name !== 'safari') {
            getRecordingPermission();
            // setCurrentConversation(data);
            if (end) {
              if (autoRecord) {
                setAutoStart(false);
              }
              dispatch(
                updateConversationStatusForPracticeCall(
                  tk,
                  pc,
                  'completed',
                  () => {
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        status: 'completed',
                      };
                    });
                  },
                ),
              );
            } else {
              setAutoPlay(true);
              setPlayingResponse(true);
              if (autoRecord) {
                setAutoStart(true);
              }
            }
            if (currentConversation?.audioService.includes('ELEVEN_LABS')) {
              setResponseAudioUrl(data?.elevenLabsAudioLink);
            } else {
              setResponseAudioUrl(data?.gcpAudioLink);
            }
          },
          () => {
            // console.log('coming here');
            setGettingResponse(false);
            setMessage('');
            resetTranscript();
          },
        ),
      );
    } else {
      setShowEmptySpeech(true);
      dispatch(
        updateConversationStatusForPracticeCall(tk, pc, 'in_process', () => {
          setCurrentConversation((prev) => {
            return {
              ...prev,
              status: 'in_process',
            };
          });
        }),
      );
      if (end) {
        if (autoRecord) {
          setAutoStart(false);
        }
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setMethods((prev) => [...prev, e.target.value]);
    } else {
      setMethods((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleAnalyze = (srId, mode, ar) => {
    setAnalysisInProgress((prev) => [...prev, srId]);
    console.log('checking merged audio status');
    const mergedAudioStatus = currentConversation?.mergedAudioStatus;
    if (mergedAudioStatus === 'completed') {
      console.log('merged audio available');
      console.log('checking audio transcription status');
      if (currentConversation?.transcriptStatus === 'available') {
        console.log('transcript available');
        console.log('analysing conversation');
        dispatch(
          analyzeCallWithToken(
            tk,
            pc,
            {
              analysisMethods: methods,
              playbookText: playbook,
              speakers: {
                spk_0: salesPersonName,
                spk_1: currentConversation?.customFields?.botTitle,
              },
            },
            (data) => {
              console.log('anaysed conversation response', data);
              const updatedAnalysisInProgress = analysisInProgress.filter(
                (ap) => ap !== srId,
              );
              setAnalysisInProgress(updatedAnalysisInProgress);
              setShowReAnalyzeDrawer(null);
              setSalesReview(data);
              setViewType('report');
            },
            () => {
              const updatedAnalysisInProgress = analysisInProgress.filter(
                (ap) => ap !== srId,
              );
              dispatch(
                showAlert({
                  message: 'Something went wrong. Please try again!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
              setAnalysisInProgress(updatedAnalysisInProgress);
            },
          ),
        );
      } else if (currentConversation?.transcriptStatus === 'in_process') {
        console.log('transcription is in process');
        console.log('checking audio transcription status');
        checkTranscriptionProgressTimer.current = setInterval(() => {
          dispatch(
            checkCallWithTokenTranscriptionStatus(
              tk,
              pc,
              (data) => {
                console.log('transcriptionStatus', data);
                if (data?.status === 'available') {
                  console.log('transcript available');
                  console.log('analysing conversation');
                  clearInterval(checkTranscriptionProgressTimer.current);
                  setTranscriptionFailed(false);
                  setTranscriptReady(true);
                  setCurrentConversation((prev) => {
                    return {
                      ...prev,
                      transcriptStatus: 'available',
                      transcript: data?.transcript,
                    };
                  });
                  dispatch(
                    analyzeCallWithToken(
                      tk,
                      pc,
                      {
                        analysisMethods: methods,
                        playbookText: playbook,
                        speakers: {
                          spk_0: salesPersonName,
                          spk_1: currentConversation?.customFields?.botTitle,
                        },
                      },
                      (data) => {
                        console.log('anaysed conversation response', data);
                        const updatedAnalysisInProgress = analysisInProgress.filter(
                          (ap) => ap !== srId,
                        );
                        setAnalysisInProgress(updatedAnalysisInProgress);
                        setShowReAnalyzeDrawer(null);
                        setSalesReview(data);
                        setViewType('report');
                      },
                      () => {
                        const updatedAnalysisInProgress = analysisInProgress.filter(
                          (ap) => ap !== srId,
                        );
                        dispatch(
                          showAlert({
                            message: 'Something went wrong. Please try again!',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                        setAnalysisInProgress(updatedAnalysisInProgress);
                      },
                    ),
                  );
                } else if (data?.status === 'failed') {
                  clearInterval(checkTranscriptionProgressTimer.current);
                  setTranscriptReady(false);
                  setTranscriptionFailed(true);
                  dispatch(
                    showAlert({
                      message: 'Something went wrong. Please try again!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                }
              },
              () => {
                clearInterval(checkTranscriptionProgressTimer.current);
                setTranscriptReady(false);
                setTranscriptionFailed(true);
                dispatch(
                  showAlert({
                    message: 'Something went wrong. Please try again!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
                const updatedAnalysisInProgress = analysisInProgress.filter(
                  (ap) => ap !== srId,
                );
                setAnalysisInProgress(updatedAnalysisInProgress);
              },
            ),
          );
        }, 5000);
      } else if (currentConversation?.transcriptStatus === null) {
        console.log('checking audio transcription status');
        checkTranscriptionProgressTimer.current = setInterval(() => {
          dispatch(
            checkCallWithTokenTranscriptionStatus(
              tk,
              pc,
              (data) => {
                console.log('transcriptionStatus', data);
                if (data?.status === 'available') {
                  clearInterval(checkTranscriptionProgressTimer.current);
                  setTranscriptionFailed(false);
                  setTranscriptReady(true);
                  setCurrentConversation((prev) => {
                    return {
                      ...prev,
                      transcriptStatus: 'available',
                      transcript: data?.transcript,
                    };
                  });
                  console.log('analysing conversation');
                  dispatch(
                    analyzeCallWithToken(
                      tk,
                      pc,
                      {
                        analysisMethods: methods,
                        playbookText: playbook,
                        speakers: {
                          spk_0: salesPersonName,
                          spk_1: currentConversation?.customFields?.botTitle,
                        },
                      },
                      (data) => {
                        console.log('anaysed conversation response', data);
                        const updatedAnalysisInProgress = analysisInProgress.filter(
                          (ap) => ap !== srId,
                        );
                        setAnalysisInProgress(updatedAnalysisInProgress);
                        setShowReAnalyzeDrawer(null);
                        setSalesReview(data);
                        setViewType('report');
                      },
                      () => {
                        const updatedAnalysisInProgress = analysisInProgress.filter(
                          (ap) => ap !== srId,
                        );
                        dispatch(
                          showAlert({
                            message: 'Something went wrong. Please try again!',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                        setAnalysisInProgress(updatedAnalysisInProgress);
                      },
                    ),
                  );
                } else if (data?.status === 'failed') {
                  clearInterval(checkTranscriptionProgressTimer.current);
                  setTranscriptReady(false);
                  setTranscriptionFailed(true);
                  dispatch(
                    showAlert({
                      message: 'Something went wrong. Please try again!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                }
              },
              () => {
                clearInterval(checkTranscriptionProgressTimer.current);
                setTranscriptReady(false);
                setTranscriptionFailed(true);
                dispatch(
                  showAlert({
                    message: 'Something went wrong. Please try again!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
                const updatedAnalysisInProgress = analysisInProgress.filter(
                  (ap) => ap !== srId,
                );
                setAnalysisInProgress(updatedAnalysisInProgress);
              },
            ),
          );
        }, 5000);
      }
    } else {
      console.log('merge audio not available');
      console.log('merging audio');
      dispatch(
        getMergedCallWithToken(
          tk,
          pc,
          (data) => {
            console.log('mergeConversationStatus', data);
            console.log('checking merged audio status');
            dispatch(
              checkCallWithTokenMergeAudioStatus(
                tk,
                pc,
                (status) => {
                  if (status?.status === 'completed') {
                    console.log('merged audio available');
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        mergedAudioStatus: 'completed',
                      };
                    });
                    console.log('checking audio transcription status');
                    checkTranscriptionProgressTimer.current = setInterval(
                      () => {
                        dispatch(
                          checkCallWithTokenTranscriptionStatus(
                            tk,
                            pc,
                            (data) => {
                              console.log('transcriptionStatus', data);
                              if (data?.status === 'available') {
                                clearInterval(
                                  checkTranscriptionProgressTimer.current,
                                );
                                setTranscriptReady(true);
                                setTranscriptionFailed(false);
                                setCurrentConversation((prev) => {
                                  return {
                                    ...prev,
                                    transcriptStatus: 'available',
                                    transcript: data?.transcript,
                                  };
                                });
                                console.log('analysing conversation');
                                dispatch(
                                  analyzeCallWithToken(
                                    tk,
                                    pc,
                                    {
                                      analysisMethods: methods,
                                      playbookText: playbook,
                                      speakers: {
                                        spk_0: salesPersonName,
                                        spk_1:
                                          currentConversation?.customFields
                                            ?.botTitle,
                                      },
                                    },
                                    (data) => {
                                      console.log(
                                        'anaysed conversation response',
                                        data,
                                      );
                                      const updatedAnalysisInProgress = analysisInProgress.filter(
                                        (ap) => ap !== srId,
                                      );
                                      setAnalysisInProgress(
                                        updatedAnalysisInProgress,
                                      );
                                      setShowReAnalyzeDrawer(null);
                                      setSalesReview(data);
                                      setViewType('report');
                                    },
                                    () => {
                                      const updatedAnalysisInProgress = analysisInProgress.filter(
                                        (ap) => ap !== srId,
                                      );
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                      setAnalysisInProgress(
                                        updatedAnalysisInProgress,
                                      );
                                    },
                                  ),
                                );
                              } else if (data?.status === 'failed') {
                                clearInterval(
                                  checkTranscriptionProgressTimer.current,
                                );
                                setTranscriptReady(false);
                                setTranscriptionFailed(true);
                                dispatch(
                                  showAlert({
                                    message:
                                      'Something went wrong. Please try again!',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 2000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                                const updatedAnalysisInProgress = analysisInProgress.filter(
                                  (ap) => ap !== srId,
                                );
                                setAnalysisInProgress(
                                  updatedAnalysisInProgress,
                                );
                              }
                            },
                            () => {
                              clearInterval(
                                checkTranscriptionProgressTimer.current,
                              );
                              setTranscriptReady(false);
                              setTranscriptionFailed(true);
                              dispatch(
                                showAlert({
                                  message:
                                    'Something went wrong. Please try again!',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                (ap) => ap !== srId,
                              );
                              setAnalysisInProgress(updatedAnalysisInProgress);
                            },
                          ),
                        );
                      },
                      5000,
                    );
                  } else {
                    console.log('merged audio not available');
                    console.log('checking merged audio status');
                    checkMergedAudioProgressTimerRef.current = setInterval(
                      () => {
                        dispatch(
                          checkCallWithTokenMergeAudioStatus(
                            tk,
                            pc,
                            (status) => {
                              console.log(status);
                              if (status?.status === 'completed') {
                                console.log('merged audio available now');
                                clearInterval(
                                  checkMergedAudioProgressTimerRef.current,
                                );
                                setCurrentConversation((prev) => {
                                  return {
                                    ...prev,
                                    mergedAudioStatus: 'completed',
                                    transcript: data?.transcript,
                                  };
                                });
                                console.log('checking transcription status');
                                checkTranscriptionProgressTimer.current = setInterval(
                                  () => {
                                    dispatch(
                                      checkCallWithTokenTranscriptionStatus(
                                        tk,
                                        pc,
                                        (data) => {
                                          console.log(
                                            'transcriptionStatus',
                                            data,
                                          );
                                          if (data?.status === 'available') {
                                            console.log('transcript available');
                                            clearInterval(
                                              checkTranscriptionProgressTimer.current,
                                            );
                                            setTranscriptReady(true);
                                            setTranscriptionFailed(false);
                                            setCurrentConversation((prev) => {
                                              return {
                                                ...prev,
                                                transcriptStatus: 'available',
                                                transcript: data?.transcript,
                                              };
                                            });
                                            console.log(
                                              'analysing conversation',
                                            );
                                            dispatch(
                                              analyzeCallWithToken(
                                                tk,
                                                pc,
                                                {
                                                  analysisMethods: methods,
                                                  playbookText: playbook,
                                                  speakers: {
                                                    spk_0: salesPersonName,
                                                    spk_1:
                                                      currentConversation
                                                        ?.customFields
                                                        ?.botTitle,
                                                  },
                                                },
                                                (data) => {
                                                  console.log(
                                                    'anaysed conversation response',
                                                    data,
                                                  );
                                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                                    (ap) => ap !== srId,
                                                  );
                                                  setAnalysisInProgress(
                                                    updatedAnalysisInProgress,
                                                  );
                                                  setShowReAnalyzeDrawer(null);
                                                  setSalesReview(data);
                                                  setViewType('report');
                                                },
                                                () => {
                                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                                    (ap) => ap !== srId,
                                                  );
                                                  dispatch(
                                                    showAlert({
                                                      message:
                                                        'Something went wrong. Please try again!',
                                                      showCross: true,
                                                      title: null,
                                                      type: 'error',
                                                      autoHideDuration: 2000,
                                                      vertical: 'top',
                                                      horizontal: 'center',
                                                    }),
                                                  );
                                                  setAnalysisInProgress(
                                                    updatedAnalysisInProgress,
                                                  );
                                                },
                                              ),
                                            );
                                          } else if (
                                            data?.status === 'failed'
                                          ) {
                                            clearInterval(
                                              checkTranscriptionProgressTimer.current,
                                            );
                                            setTranscriptReady(false);
                                            setTranscriptionFailed(true);
                                            dispatch(
                                              showAlert({
                                                message:
                                                  'Something went wrong. Please try again!',
                                                showCross: true,
                                                title: null,
                                                type: 'error',
                                                autoHideDuration: 2000,
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }),
                                            );
                                            const updatedAnalysisInProgress = analysisInProgress.filter(
                                              (ap) => ap !== srId,
                                            );
                                            setAnalysisInProgress(
                                              updatedAnalysisInProgress,
                                            );
                                          }
                                        },
                                        () => {
                                          clearInterval(
                                            checkTranscriptionProgressTimer.current,
                                          );
                                          setTranscriptReady(false);
                                          setTranscriptionFailed(true);
                                          dispatch(
                                            showAlert({
                                              message:
                                                'Something went wrong. Please try again!',
                                              showCross: true,
                                              title: null,
                                              type: 'error',
                                              autoHideDuration: 2000,
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }),
                                          );
                                          const updatedAnalysisInProgress = analysisInProgress.filter(
                                            (ap) => ap !== srId,
                                          );
                                          setAnalysisInProgress(
                                            updatedAnalysisInProgress,
                                          );
                                        },
                                      ),
                                    );
                                  },
                                  5000,
                                );
                              } else if (status?.status === 'failed') {
                                clearInterval(
                                  checkMergedAudioProgressTimerRef.current,
                                );
                                dispatch(
                                  showAlert({
                                    message:
                                      'Something went wrong. Please try again!',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 2000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                                const updatedAnalysisInProgress = analysisInProgress.filter(
                                  (ap) => ap !== srId,
                                );
                                setAnalysisInProgress(
                                  updatedAnalysisInProgress,
                                );
                              }
                            },
                            () => {
                              clearInterval(
                                checkMergedAudioProgressTimerRef.current,
                              );
                              dispatch(
                                showAlert({
                                  message:
                                    'Something went wrong. Please try again!',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                (ap) => ap !== srId,
                              );
                              setAnalysisInProgress(updatedAnalysisInProgress);
                            },
                          ),
                        );
                      },
                      5000,
                    );
                  }
                },
                () => {
                  dispatch(
                    showAlert({
                      message: 'Something went wrong. Please try again!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                },
              ),
            );
          },
          () => {
            dispatch(
              showAlert({
                message: 'Something went wrong. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
            const updatedAnalysisInProgress = analysisInProgress.filter(
              (ap) => ap !== srId,
            );
            setAnalysisInProgress(updatedAnalysisInProgress);
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (transcript) {
      if (recording) {
        checkPauseTimerRef.current = setInterval(() => {
          if (recording) {
            handleRecordStopClick();
          }
        }, pauseTimeLimit + 200);
      } else {
        if (checkPauseTimerRef.current) {
          clearInterval(checkPauseTimerRef.current);
          setPauseTimer(0);
        }
      }
    }
    return () => {
      if (checkPauseTimerRef.current) {
        clearInterval(checkPauseTimerRef.current);
        setPauseTimer(0);
      }
    };
  }, [transcript, recording]);

  // useEffect(() => {
  //   if (pauseTimer > pauseTimeLimit) {

  //   }
  // }, [pauseTimer]);

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    } else {
      setMessage('');
    }
  }, [transcript]);

  useEffect(() => {
    if (salesReview) {
      // setActiveStep(2);
      setSpeakers(salesReview?.speakers);
    }
  }, [salesReview]);

  useEffect(() => {
    if (
      salesReview?.analysis?.fillerInfo?.fillers &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers) &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(salesReview?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      salesReview?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions) &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions).length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions),
      );
    }
  }, [salesReview]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  // useEffect(() => {
  //   if (viewType === 'report') {
  //     if (
  //       salesReview?.analysis?.scoreStatus === 'completed' ||
  //       salesReview?.analysis?.scoreStatus === 'failed'
  //     ) {
  //       setScoreCalculationInProcess(false);
  //     } else {
  //       setScoreCalculationInProcess(true);
  //       checkPracticeCallScoreStatusTimer.current = setInterval(() => {
  //         dispatch(
  //           checkCallWithTokenScoreStatus(
  //             tk,
  //             pc,
  //             (data) => {
  //               console.log(data);
  //               if (data?.status === 'completed') {
  //                 setScoreCalculationInProcess(false);
  //                 clearInterval(checkPracticeCallScoreStatusTimer.current);
  //                 if (
  //                   Object.keys(data) &&
  //                   Object.keys(data).filter((key) => key !== 'status') &&
  //                   Object.keys(data).filter((key) => key !== 'status').length >
  //                     0
  //                 ) {
  //                   Object.keys(data)
  //                     .filter((key) => key !== 'status')
  //                     .map((key) => {
  //                       setSalesReview((prev) => {
  //                         return {
  //                           ...prev,
  //                           analysis: {
  //                             ...prev.analysis,
  //                             [`${key}Score`]: data[key],
  //                           },
  //                         };
  //                       });
  //                     });
  //                 }
  //                 setCurrentConversation((prev) => {
  //                   return {
  //                     ...prev,
  //                     analysis: {
  //                       ...prev.analysis,
  //                       scoreStatus: 'completed',
  //                     },
  //                   };
  //                 });
  //               } else if (data?.status === 'failed') {
  //                 setScoreCalculationInProcess(false);
  //                 clearInterval(checkPracticeCallScoreStatusTimer.current);
  //               }
  //             },
  //             () => {
  //               setScoreCalculationInProcess(false);
  //               clearInterval(checkPracticeCallScoreStatusTimer.current);
  //             },
  //           ),
  //         );
  //       }, 10000);
  //     }
  //   }
  //   return () => {
  //     clearInterval(checkPracticeCallScoreStatusTimer.current);
  //   };
  // }, [viewType]);

  useEffect(() => {
    if (selectedAudio && recording === false) {
      let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
      // setRecording(false);
      if (playBackRef.current) {
        playBackRef.current.src = recordedVideoUrl;
        // playBackRef.current.play();
      }
    }
    // if (streamRef && streamRef.current)
    //   streamRef.current.getTracks().forEach((track) => track.stop());
  }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (
      timerSs % 15 === 0 &&
      (timerSs !== 0 || (timerSs === 0 && timerMs !== 0))
    ) {
      (async function() {
        await SpeechRecognition.stopListening();
        await SpeechRecognition.startListening({ continuous: true });
      })();
      // console.log('restart');
    }
  }, [timerSs, timerMs]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  useEffect(() => {
    if (
      browser?.os === 'Android OS' ||
      browser?.os === 'iOS' ||
      browser?.name === 'firefox' ||
      browser?.name === 'safari'
    ) {
      setIsNotSupported(true);
    } else {
      setIsNotSupported(false);
    }
  }, [browser]);

  useEffect(() => {
    getRecordingPermission();
    return () => {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    };
  }, []);

  // useEffect(() => {
  //   if (currentConversation && mediaStream) {
  //     getMicrophoneLevel(mediaStream);
  //   }
  // }, [currentConversation, mediaStream]);

  useEffect(() => {
    if (recording) {
      dotsTimerRef.current = setInterval(() => {
        setNumberOfDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dotsTimerRef.current);
    }
  }, [recording]);

  useEffect(() => {
    if (playingResponse) {
      dots2TimerRef.current = setInterval(() => {
        setNumberOfAltDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dots2TimerRef.current);
    }
  }, [playingResponse]);

  useEffect(() => {
    if (message) {
      setShowEmptySpeech(false);
    }
  }, [message]);

  useEffect(() => {
    if (salesReview) {
      if (salesReview?.analysis?.summary) {
        setCurrentMethod('summary');
      } else if (salesReview?.playbook) {
        setCurrentMethod('playbook');
      }
    }
  }, [salesReview]);

  useEffect(() => {
    setValidatingLink(true);
    dispatch(
      validateCallPracticeToken(
        {
          pass: pc,
          token: tk,
        },
        (data, error) => {
          // console.log(data);
          // console.log(error);
          if (error) {
            setInvalidLink(true);
          } else {
            if (data?.conversationCreated) {
              setCurrentConversation(data?.conversation);
              setConversationCreated(true);
            } else {
              setBots(data?.bots);
              setDesignationOptions(data?.designations);
              setVerticalOptions(data?.verticals);
              setConversationCreated(false);
              setConversationTitle(`Preview Call for ${data?.email || ''}`);
              setBotId(data?.bots?.[0]?.id);
              setVertical(data?.vertical);
              setDesignation(data?.designation);
            }
            setInvalidLink(false);
          }
          setValidatingLink(false);
        },
      ),
    );
  }, []);

  // console.log(browser);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: 1,
          md: 1,
        },
        marginLeft: {
          xs: '0px',
          // sm: isLoggedIn ? '80px' : 0,
          // md: isLoggedIn ? (dashboardSidebarExpanded ? '240px' : '80px') : 0,
        },
        height: 1,
        minHeight: '100vh',
      }}
    >
      <Container
        paddingX={2}
        paddingY={2}
        maxWidth={{ xs: 1, sm: 1, md: 1360 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pb: 1,
            alignItems: 'center',
          }}
        >
          <Box
            display={'flex'}
            component="a"
            href={
              domainDetails?.organizationWebsite
                ? `https://${domainDetails?.organizationWebsite}`
                : 'https://qualification.ai'
            }
            title={domainDetails?.appTitle || 'Qualification AI'}
            width={{ xs: 40, md: 50 }}
          >
            <Box
              component={'img'}
              src={
                theme.palette.mode === 'dark'
                  ? '/dark_theme_logo.svg'
                  : domainDetails?.textInLogo
                  ? domainDetails?.logo || '/light_theme_logo.svg'
                  : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
              }
              height={1}
              width={1}
            />
          </Box>
          {domainDetails?.textInLogo ? null : (
            <Box ml={2}>
              <Typography color="text.primary" variant="h6" fontWeight={'600'}>
                {domainDetails?.appTitle || 'Qualification AI'}
              </Typography>
              <Divider
                sx={{ border: `0.5px solid ${theme.palette.text.primary}` }}
              />
              <Typography
                variant="caption"
                sx={{ fontSize: '0.85rem' }}
                color="text.primary"
              >
                {domainDetails?.appSubTitle || 'Makes Practice Perfect'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: !conversationCreated || validatingLink ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: !conversationCreated || validatingLink ? 'center' : 'start',
          }}
          height={1}
          gap={2}
          sx={{
            border: '1px solid #f1f1f1',
            minHeight: 'calc(100vh - 110px)',
            padding: { xs: 1, sm: 2, md: 2 },
          }}
          position={'relative'}
        >
          {validatingLink ? (
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              flexDirection="column"
              height={1}
              width={1}
            >
              <CircularProgress size={20} />
              <Typography
                variant="p"
                sx={{
                  fontWeight: 700,
                  textAlign: 'center',
                }}
                color={'text.secondary'}
                marginTop="20px"
              >
                Please wait while we validate the link ...{' '}
              </Typography>
            </Box>
          ) : invalidLink ? (
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              flexDirection="column"
              height={1}
              width={1}
            >
              <Alert severity="error">
                Link is invalid or expired. Please request for a new link
              </Alert>
            </Box>
          ) : conversationCreated ? (
            viewType === 'conversation' ? (
              <>
                <Box
                  p={1}
                  width={1}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={1}
                >
                  <Box display={'flex'} justifyContent={'center'} p={1}>
                    <Typography variant="body1" fontWeight={'bold'}>
                      {currentConversation?.title}
                    </Typography>
                  </Box>
                  {showEmptySpeech &&
                  currentConversation?.status !== 'completed' ? (
                    <Alert severity="info">
                      Please speak something to get the response
                    </Alert>
                  ) : null}
                  <Box
                    display={'flex'}
                    gap={2}
                    position={'relative'}
                    flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
                  >
                    <Box
                      flex={1}
                      sx={{
                        border: '1px solid #d3d3d3',
                        borderRadius: 2,
                        minHeight: isXs ? 'auto' : 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: { xs: 1, sm: 2, md: 2 },
                        position: 'relative',
                      }}
                    >
                      <Typography
                        color={'text.secondary'}
                        variant="subtitle2"
                        sx={{
                          visibility: recording ? 'visible' : 'hidden',
                        }}
                      >
                        You are speaking
                        {Array.from(
                          { length: numberOfDots },
                          (_, id) => id,
                        ).map((item) => (
                          <span key={item}>.</span>
                        ))}
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        variant="subtitle2"
                        sx={{
                          visibility: playingResponse ? 'visible' : 'hidden',
                        }}
                      >
                        You are listening
                        {Array.from(
                          { length: numberOfAltDots },
                          (_, id) => id,
                        ).map((item) => (
                          <span key={item}>.</span>
                        ))}
                      </Typography>
                      <Avatar
                        alt={'A'}
                        src={''}
                        sx={{
                          width: { xs: 60, sm: 70, md: 90 },
                          height: { xs: 60, sm: 70, md: 90 },
                          border: recording
                            ? `4px solid ${theme.palette.primary.main}`
                            : 'none',
                          // border: `${microphoneLevel}px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        <Typography color={'#fff'} sx={{ fontSize: 40 }}>
                          {'Y'}
                        </Typography>
                      </Avatar>
                      <Typography
                        variant="body1"
                        color={'text.secondary'}
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          position: 'absolute',
                          top: 15,
                          left: 20,
                        }}
                      >
                        You
                      </Typography>
                      <IconButton
                        size="small"
                        disabled={playingResponse || gettingResponse}
                        onClick={() => {
                          if (!(currentConversation?.status === 'completed')) {
                            if (recording) {
                              handleRecordStopClick();
                            } else {
                              handleRecordClick();
                            }
                          }
                        }}
                      >
                        {recording ? (
                          <Tooltip title="Mute" arrow placement="top">
                            <Mic
                              fontSize="medium"
                              color={
                                playingResponse || gettingResponse
                                  ? 'default'
                                  : 'primary'
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              currentConversation?.status === 'completed'
                                ? 'Conversation has ended'
                                : 'Unmute'
                            }
                            arrow
                            placement="top"
                          >
                            <MicOff
                              fontSize="medium"
                              color={
                                playingResponse ||
                                gettingResponse ||
                                currentConversation?.status === 'completed'
                                  ? 'default'
                                  : 'primary'
                              }
                            />
                          </Tooltip>
                        )}
                      </IconButton>
                      {recording && (
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 10,
                            right: 15,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Circle sx={{ fontSize: 10 }} color="error" />
                          <Typography
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {`0${timerMs} `}:
                            {timerSs > 9
                              ? timerSs < 0
                                ? '00'
                                : ` ${timerSs}`
                              : ` 0${timerSs}`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      flex={1}
                      sx={{
                        border: '1px solid #d3d3d3',
                        borderRadius: 2,
                        minHeight: isXs ? 'auto' : 300,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: { xs: 1, sm: 2, md: 2 },
                        position: 'relative',
                      }}
                    >
                      <Typography
                        color={'text.secondary'}
                        variant="subtitle2"
                        sx={{
                          visibility: recording ? 'visible' : 'hidden',
                        }}
                      >
                        Listening
                        {Array.from(
                          { length: numberOfDots },
                          (_, id) => id,
                        ).map((item) => (
                          <span key={item}>.</span>
                        ))}
                      </Typography>
                      <Typography
                        color={'text.secondary'}
                        variant="subtitle2"
                        sx={{
                          visibility: playingResponse ? 'visible' : 'hidden',
                        }}
                      >
                        Speaking
                        {Array.from(
                          { length: numberOfAltDots },
                          (_, id) => id,
                        ).map((item) => (
                          <span key={item}>.</span>
                        ))}
                      </Typography>
                      <Box position={'relative'}>
                        <SupportAgent
                          sx={{
                            background: '#bdbdbd',
                            borderRadius: '50%',
                            fontSize: { xs: 60, sm: 70, md: 90 },
                            padding: 2,
                            color: '#fff',
                            border: playingResponse
                              ? `4px solid ${theme.palette.primary.main}`
                              : 'none',
                          }}
                        />
                        {gettingResponse ? (
                          <CircularProgress
                            size={isXs ? 60 : 90}
                            sx={{
                              background: 'transparent',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                            }}
                          />
                        ) : null}
                      </Box>
                      {responseAudioUrl ? (
                        <audio
                          // controls
                          autoPlay={autoPlay && playingResponse}
                          preload={'true'}
                          ref={responseAudioRef}
                          src={responseAudioUrl}
                          style={{
                            maxWidth: '220px',
                            padding: '10px 20px',
                          }}
                          onLoadedMetadata={() =>
                            responseAudioRef.current.play()
                          }
                          onEnded={() => {
                            // console.log('audio played');
                            if (
                              autoStart &&
                              autoRecord &&
                              conversationStarted
                            ) {
                              handleRecordClick();
                            }
                            setPlayingResponse(false);
                            setResponseAudioUrl('');
                          }}
                          onError={(e) => {
                            console.log(e);
                            setPlayingResponse(false);
                            dispatch(
                              logError({
                                message: e?.message,
                                data: {
                                  formatted: {
                                    errorText: e?.message,
                                    severity: 'error',
                                  },
                                  raw: e,
                                },
                              }),
                            );
                          }}
                        />
                      ) : null}
                      <Typography
                        variant="body1"
                        color={'text.secondary'}
                        sx={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          position: 'absolute',
                          top: { xs: 7, sm: 15, md: 15 },
                          left: { xs: 7, sm: 20, md: 20 },
                        }}
                      >
                        AI Persona{' '}
                        <span style={{ fontWeight: 500, fontSize: 14 }}>
                          ({currentConversation?.customFields?.botTitle || ''}
                        </span>
                        {currentConversation?.configs ? (
                          <span style={{ fontWeight: 500, fontSize: 14 }}>
                            {currentConversation?.configs?.DESIGNATION?.[
                              '<%>designation<%>'
                            ]
                              ? ` - works as 
                        ${currentConversation?.configs?.DESIGNATION?.['<%>designation<%>']}`
                              : null}
                            {currentConversation?.configs?.VERTICAL?.[
                              '<%>vertical<%>'
                            ]
                              ? ` in 
                        ${currentConversation?.configs?.VERTICAL?.['<%>vertical<%>']}
                        vertical`
                              : null}
                          </span>
                        ) : null}
                        <span style={{ fontWeight: 500, fontSize: 14 }}>)</span>
                      </Typography>
                      <IconButton
                        size="small"
                        disabled={recording || gettingResponse}
                        sx={{ visibility: 'hidden' }}
                        onClick={() => {
                          if (playingResponse) {
                            // handlePlayResponse();
                            responseAudioRef?.current?.pause();
                            setPlayingResponse(false);
                          } else {
                            // handleStopResponsePlayback();
                            if (responseAudioUrl && responseAudioRef?.current) {
                              responseAudioRef?.current?.play();
                              setPlayingResponse(true);
                            }
                          }
                        }}
                      >
                        {playingResponse ? (
                          <Tooltip title="Pause Playback" arrow placement="top">
                            <VolumeUp fontSize="medium" color="primary" />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Play Response" arrow placement="top">
                            <PlayArrow
                              fontSize="medium"
                              color={responseAudioUrl ? 'primary' : 'default'}
                            />
                          </Tooltip>
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {currentConversation?.status !== 'completed' ? (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={1}
                      >
                        <Button
                          startIcon={
                            conversationStarted ? <CallEnd /> : <Call />
                          }
                          variant="contained"
                          size="small"
                          color={conversationStarted ? 'error' : 'primary'}
                          disabled={gettingResponse || playingResponse}
                          onClick={(e) => {
                            if (conversationStarted) {
                              handleTrackEvent(e, `end_conversation_otpc`);
                              if (playingResponse) {
                                setPlayingResponse(false);
                                responseAudioRef?.current?.pause();
                              }
                              setConversationStarted(false);
                              handleRecordStopClick(true);
                            } else {
                              if (
                                currentConversation?.status === 'in_process'
                              ) {
                                handleTrackEvent(
                                  e,
                                  `continue_conversation_otpc`,
                                );
                              } else {
                                handleTrackEvent(e, `start_conversation_otpc`);
                              }
                              handleRecordClick();
                            }
                          }}
                        >
                          {conversationStarted
                            ? 'End Conversation'
                            : currentConversation?.status === 'in_process'
                            ? 'Continue Conversation'
                            : 'Start Conversation'}
                        </Button>
                        {currentConversation?.status === 'in_process' &&
                        !conversationStarted ? (
                          <Button
                            startIcon={<CallEnd />}
                            variant="contained"
                            size="small"
                            color={'error'}
                            // disabled={gettingResponse}
                            onClick={(e) => {
                              handleTrackEvent(e, `end_conversation_otpc`);
                              dispatch(
                                updateConversationStatusForPracticeCall(
                                  tk,
                                  pc,
                                  'completed',
                                  () => {
                                    setCurrentConversation((prev) => {
                                      return {
                                        ...prev,
                                        status: 'completed',
                                      };
                                    });
                                  },
                                ),
                              );
                            }}
                          >
                            End Conversation
                          </Button>
                        ) : null}
                      </Box>
                    ) : (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Typography variant="body1" color={'text.secondary'}>
                          This conversation has ended.
                        </Typography>
                        {analysisInProgress.includes(
                          currentConversation?.id,
                        ) ? (
                          <Typography variant="body1" color={'text.secondary'}>
                            Call analysis is in progress. The report will appear
                            here shortly.
                          </Typography>
                        ) : (
                          <Typography variant="body1" color={'text.secondary'}>
                            Do you want to analyze the call?
                          </Typography>
                        )}
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          {currentConversation?.analysis ? (
                            <Button
                              // data-trackid={'CTA_sign_in'}
                              size={'small'}
                              variant="contained"
                              // fullWidth
                              startIcon={<Article />}
                              onClick={(e) => {
                                handleTrackEvent(e, 'view_report_otpc');
                                console.log('view report');
                                setViewType('report');
                                setSalesReview(currentConversation);
                              }}
                            >
                              View Report
                            </Button>
                          ) : null}
                          <Button
                            // data-trackid={'CTA_sign_in'}
                            size={'small'}
                            variant="contained"
                            // fullWidth
                            disabled={
                              analysisInProgress.includes(
                                currentConversation?.id,
                              )
                                ? true
                                : false
                            }
                            startIcon={
                              analysisInProgress.includes(
                                currentConversation?.id,
                              ) ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Troubleshoot />
                              )
                            }
                            onClick={(e) => {
                              handleTrackEvent(e, `analyze_conversation_otpc`);
                              handleAnalyze(currentConversation?.id, 'list');
                            }}
                          >
                            {currentConversation?.analysis
                              ? 'Analyze Again'
                              : 'Analyze Conversation'}
                          </Button>
                        </Box>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          mt={2}
                        >
                          Sign up for more realistic conversations with
                          comprehensive analysis.
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                          <Box>
                            <Button
                              data-trackid={'CTA_sign_up'}
                              size={'large'}
                              variant="contained"
                              fullWidth
                              component="a"
                              href={`/auth/signup?t=n`}
                              onClick={(e) => {
                                // handleTrackEvent(e, 'CTA_sign_up');
                              }}
                            >
                              Sign Up
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            ) : viewType === 'report' ? (
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  alignItems={'start'}
                >
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBack />}
                    onClick={() => {
                      // if (conversationStarted || gettingResponse) {
                      //   setEndConversationAlert(true);
                      // } else {
                      setViewType('conversation');
                      // }
                    }}
                    color="primary"
                    size="small"
                  >
                    Go Back
                  </Button>
                  <ReportPage
                    type="otpc"
                    report={salesReview}
                    setSalesReview={setSalesReview}
                    tk={tk}
                    pc={pc}
                  />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    alignItems={'center'}
                    width={1}
                  >
                    <Typography
                      variant="body1"
                      color={'text.secondary'}
                      mt={2}
                      textAlign={'center'}
                    >
                      Sign up for more realistic conversations with
                      comprehensive analysis.
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Box>
                        <Button
                          data-trackid={'CTA_sign_up'}
                          size={'large'}
                          variant="contained"
                          fullWidth
                          component="a"
                          href={`/auth/signup?t=n`}
                          onClick={(e) => {
                            // handleTrackEvent(e, 'CTA_sign_up');
                          }}
                        >
                          Sign Up
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : null
          ) : (
            <>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={{ xs: 1, sm: 2, md: 2 }}
                alignItems={'center'}
              >
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  fontWeight={'bold'}
                  fontSize={20}
                >
                  Welcome to a preview of the cutting edge in sales call
                  practice from {domainDetails?.appTitle || 'Qualification AI'}.
                </Typography>
                <Typography variant="subtitle1" color={'text.secondary'}>
                  Just fill out the fields below, our bot will assume the
                  persona of that prospect, and you can start pitching right
                  away.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: { xs: 0, sm: 1, md: 2 },
                  minWidth: '50%',
                  // maxHeight: '60vh',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                  width: { xs: 1, sm: 'auto', md: 'auto' },
                }}
              >
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Conversation Title *
                </Typography>
                <TextField
                  autoFocus
                  placeholder="Title*"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="title"
                  value={conversationTitle}
                  onChange={(e) => setConversationTitle(e.target.value)}
                  fullWidth
                />
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                  justifyContent={'space-between'}
                >
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    About Yourself
                  </Typography>
                  <Divider sx={{ width: 0.7 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 2,
                    gap: 1,
                  }}
                >
                  <Typography variant="caption" color={'text.secondary'}>
                    Your Name *
                  </Typography>
                  <TextField
                    // autoFocus
                    placeholder="Your Name *"
                    variant="outlined"
                    // label={'Your Name'}
                    color="primary"
                    size="small"
                    name="sellerName"
                    value={sellerName}
                    onChange={(e) => setSellerName(e.target.value)}
                    fullWidth
                  />
                  <Typography variant="caption" color={'text.secondary'}>
                    Your Company Name *
                  </Typography>
                  <TextField
                    // autoFocus
                    placeholder="Your Company Name *"
                    variant="outlined"
                    // label={'Your Company Name'}
                    color="primary"
                    size="small"
                    name="sellerCompanyName"
                    value={sellerCompanyName}
                    onChange={(e) => setSellerCompanyName(e.target.value)}
                    fullWidth
                  />
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                  justifyContent={'space-between'}
                >
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    About Your Target (Prospect you will be selling to)
                  </Typography>
                  <Divider sx={{ width: 0.3 }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 2,
                    gap: 1,
                  }}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <Typography variant="caption" color={'text.secondary'}>
                        AI Persona
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={botId}
                      onChange={(e) => {
                        handleTrackEvent(e, `selected_bot_change`);
                        setBotId(e.target.value);
                      }}
                      sx={{
                        p: 0,
                      }}
                    >
                      {bots && bots?.length > 0
                        ? bots?.map((pb) => (
                            <FormControlLabel
                              key={pb?.id}
                              value={pb?.id}
                              control={<Radio size="small" />}
                              label={
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'center'}
                                  sx={{
                                    border: '1px solid #d3d3d3',
                                    borderRadius: 1,
                                    padding: 1,
                                    minWidth: 160,
                                    maxWidth: 160,
                                    minHeight: 60,
                                  }}
                                  p={1}
                                >
                                  <Typography variant="subtitle2">
                                    {pb?.title}
                                  </Typography>
                                  {pb?.description ? (
                                    <Typography
                                      variant="caption"
                                      color={'text.secondary'}
                                      sx={{}}
                                    >
                                      {pb?.description}
                                    </Typography>
                                  ) : null}
                                </Box>
                              }
                            />
                          ))
                        : null}
                    </RadioGroup>
                  </FormControl>
                  <Box>
                    <Typography variant="caption" color={'text.secondary'}>
                      Your Target&apos;s Vertical (The vertical your prospect
                      belongs to)*
                    </Typography>
                    <br />
                    <Typography variant="caption" color={'text.secondary'}>
                      Select or type your own
                    </Typography>
                  </Box>
                  <AutoCompleteMUI
                    id="vertical"
                    value={vertical}
                    onChange={(event, newValue) => {
                      setVertical(newValue);
                    }}
                    options={verticalOptions}
                    onTextChange={(e) => setVertical(e.target.value)}
                    onKeyDown={() => {}}
                  />
                  <Box>
                    <Typography variant="caption" color={'text.secondary'}>
                      Your Target&apos;s Designation (Designation of your
                      prospect)*
                    </Typography>
                    <br />
                    <Typography variant="caption" color={'text.secondary'}>
                      Select or type your own
                    </Typography>
                  </Box>
                  <AutoCompleteMUI
                    id="designation"
                    value={designation}
                    onChange={(event, newValue) => {
                      setDesignation(newValue);
                    }}
                    options={designationOptions}
                    onTextChange={(e) => setDesignation(e.target.value)}
                    onKeyDown={() => {}}
                  />
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={2}
                  justifyContent={'space-between'}
                >
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Your Competitors
                  </Typography>
                  <Divider sx={{ width: 0.7 }} />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={1}
                  alignItems={'start'}
                  marginLeft={2}
                >
                  <Box>
                    <Typography variant="caption" color={'text.secondary'}>
                      Products competing with yours (comma separated)
                    </Typography>
                  </Box>
                  <TextField
                    placeholder="Name the competitors(comma seperated)"
                    variant="outlined"
                    name={'competitors'}
                    // fullWidth
                    multiline
                    rows={3}
                    size="small"
                    sx={{
                      minWidth: { xs: 1, sm: 1, md: 400 },
                    }}
                    value={competitors}
                    onChange={(e) => setCompetitors(e.target.value)}
                    type="text"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: createError ? 'space-between' : 'flex-end',
                  }}
                >
                  {createError && <Alert severity="error">{createError}</Alert>}
                  <Button
                    variant="contained"
                    size="small"
                    // sx={{ alignSelf: 'end' }}
                    disabled={
                      !conversationTitle?.trim() || creatingConversation
                    }
                    onClick={(e) => {
                      if (
                        !conversationTitle?.trim() ||
                        !vertical?.trim() ||
                        !designation?.trim() ||
                        !sellerName?.trim() ||
                        !sellerCompanyName?.trim()
                      ) {
                        setCreateError('Please fill out the required details.');
                      } else {
                        handleTrackEvent(e, `create_conversation_otpc`);
                        setError('');
                        setCreatingConversation(true);
                        dispatch(
                          createNewConversationWithToken(
                            tk,
                            {
                              description: '',
                              title: conversationTitle?.trim(),
                              botId,
                              sellerCompanyName,
                              sellerName: sellerName?.split(' ')?.[0],
                              configs: {
                                Basic_Conversation_Instructions: {
                                  '<%>sales_person_name<%>': sellerName?.split(
                                    ' ',
                                  )?.[0],
                                  '<%>company_name<%>': sellerCompanyName,
                                },
                                VERTICAL: {
                                  '<%>vertical<%>': vertical,
                                },
                                DESIGNATION: {
                                  '<%>designation<%>': designation,
                                },
                                COMPETITORS: {
                                  '<%>competitors<%>': competitors,
                                },
                              },
                              pass: pc,
                            },
                            (data) => {
                              dispatch(
                                validateCallPracticeToken(
                                  {
                                    pass: pc,
                                    token: tk,
                                  },
                                  (data, error) => {
                                    // console.log(data);
                                    // console.log(error);
                                    if (error) {
                                      setInvalidLink(true);
                                    } else {
                                      setInvalidLink(false);
                                      if (data?.conversationCreated) {
                                        setCurrentConversation(
                                          data?.conversation,
                                        );
                                        setConversationCreated(true);
                                      } else {
                                        setBots(data?.bots);
                                        setDesignationOptions(
                                          data?.designations,
                                        );
                                        setVerticalOptions(data?.verticals);
                                        setConversationCreated(false);
                                        setConversationTitle(
                                          `Preview Call for ${data?.email ||
                                            ''}`,
                                        );
                                        setBotId(data?.bots?.[0]?.id);
                                      }
                                    }
                                  },
                                ),
                              );
                              setCreatingConversation(false);
                              setConversationTitle('');
                              setResponseAudioUrl('');
                              setPlayingResponse(false);
                              setConversationStarted(false);
                            },
                          ),
                        );
                      }
                    }}
                  >
                    Start Conversation
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {viewType === 'conversation' ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 5,
                right: 10,
              }}
              display={'flex'}
              alignItems={'center'}
            >
              <Typography variant="caption" color={'text.secondary'}>
                Powered by
              </Typography>
              <img src="/eleven-labs.png" height={20} />
            </Box>
          ) : null}
        </Box>
        {/* )} */}
        <Drawer
          open={Boolean(showReAnalyzeDrawer)}
          anchor="right"
          onClose={() => setShowReAnalyzeDrawer(null)}
        >
          <Box
            p={1}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setShowReAnalyzeDrawer(null)}>
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography color={'text.secondary'}>
              Analyze Conversation
            </Typography>
          </Box>
          <Box
            sx={{
              width: '40vw',
              minHeight: 'calc(100vh - 58px)',
              maxHeight: 'calc(100vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box p={2}>
              {/* <Typography
                variant="subtitle2"
                color={'text.secondary'}
                sx={{ marginBottom: 1 }}
              >
                Enter your name (for proper transcript and feedback readability)
              </Typography>
              <TextField
                autoFocus
                placeholder="Enter your name"
                variant="outlined"
                color="primary"
                size="small"
                name="title"
                value={salesPersonName}
                onChange={(e) => setSalesPersonName(e.target.value)}
                fullWidth
                sx={{ marginBottom: 1 }}
              /> */}
              {currentConversation?.transcript ? (
                <Box
                  sx={{
                    borderRadius: 3,
                    border: '1px solid #e3e3e3',
                  }}
                >
                  <Box
                    sx={{
                      background: '#e3e3e3',
                      padding: 2,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                    }}
                  >
                    <Typography color={'text.secondary'} fontWeight={'bold'}>
                      Transcript
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      minHeight: '260px',
                      maxHeight: '260px',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      whiteSpace: 'pre-wrap',
                      padding: 2,
                    }}
                  >
                    {currentConversation?.transcript ||
                      'Transcript is not available yet'}
                  </Typography>
                </Box>
              ) : null}
              <FormControl
                component="fieldset"
                variant="standard"
                // sx={{ marginTop: 2 }}
              >
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies (Specify methodologies to analyze the call)
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    checked={methods?.includes('meddpic')}
                    control={
                      <Checkbox size="small" value={'meddpic'} name="methods" />
                    }
                    label="MEDDICC"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spin')}
                    control={
                      <Checkbox size="small" value={'spin'} name="methods" />
                    }
                    label="SPIN"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('spiced')}
                    control={
                      <Checkbox size="small" value={'spiced'} name="methods" />
                    }
                    label="SPICED"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('bant')}
                    control={
                      <Checkbox size="small" value={'bant'} name="methods" />
                    }
                    label="BANT"
                    onChange={(e) => handleChange(e)}
                  />
                  <FormControlLabel
                    checked={methods?.includes('challenger')}
                    control={
                      <Checkbox
                        size="small"
                        value={'challenger'}
                        name="methods"
                      />
                    }
                    label="CHALLENGER"
                    onChange={(e) => handleChange(e)}
                  />
                </FormGroup>
              </FormControl>
              {/* <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box
                  sx={{
                    background: '#e3e3e3',
                    padding: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  }}
                >
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Playbook
                  </Typography>
                  <Typography color={'text.secondary'}>
                    You can provide your custom playbook to analyze your call. A
                    default playbook will be used otherwise.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // minHeight: '260px',
                    maxHeight: '260px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <RichTextEditor
                    value={playbook}
                    onChange={(content) => {
                      setPlaybook(content);
                    }}
                    placeholder="You can add your Playbook here."
                    // readOnly
                  />
                </Box>
              </Box> */}
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                p={2}
                width={1}
                borderTop={'1px solid #f1f1f1'}
              >
                <Button
                  variant="contained"
                  size="small"
                  disabled={
                    analysisInProgress.includes(showReAnalyzeDrawer)
                      ? true
                      : false
                  }
                  startIcon={
                    analysisInProgress.includes(showReAnalyzeDrawer) ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                  onClick={(e) => {
                    handleTrackEvent(e, `analyze_conversation_otpc`);
                    handleAnalyze(showReAnalyzeDrawer, 'list');
                  }}
                >
                  Analyze
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
        <Dialog
          open={showFeedbackDialog}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
              setShowFeedbackDialog(false);
            else return;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box
              display={'flex'}
              flexDirection="column"
              gap={2}
              justifyContent={'center'}
              alignItems={'center'}
              width={1}
              // height={'400px'}
              sx={{
                borderRadius: 2,
                padding: 2,
                background: `url('/vector1.png') left bottom no-repeat, url('/vector2.png') right bottom no-repeat, linear-gradient(to right, #053549, #1D9CD3)`,
              }}
              position={'relative'}
            >
              <img style={{ width: 30 }} src="/congratulations1.png" />
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: 24,
                  fontWeight: 'bold',
                }}
              >
                Congratulations
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="body2"
                  color={'#fff'}
                  width={1}
                  textAlign="center"
                >
                  Your entry has been submitted!
                </Typography>
                <Typography
                  variant="body2"
                  color={'#fff'}
                  width={1}
                  textAlign="center"
                >
                  Your feedback will be avaiable in a few minutes. Check
                  &quot;My Activity&quot; for this and previous reports.
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={1} mt={2}>
              <Typography>We</Typography>
              <Favorite sx={{ color: theme.palette.error.main }} />
              <Typography>Feedback</Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{}}>
              How likely are you to recommend this contest to a friend?
            </DialogContentText>
            <Box display={'flex'} gap={1} flexDirection={'column'} p={2}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                justifyContent={'space-between'}
              >
                {[
                  {
                    value: 1,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 2,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 3,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 4,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 5,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 6,
                    color: 'rgb(254,108,97)',
                  },
                  {
                    value: 7,
                    color: 'rgb(255,191,26)',
                  },
                  {
                    value: 8,
                    color: 'rgb(255,191,26)',
                  },
                  {
                    value: 9,
                    color: 'rgb(25,216,92)',
                  },
                  {
                    value: 10,
                    color: 'rgb(25,216,92)',
                  },
                ].map((item, i) => (
                  <Box
                    data-trackid={`set_rating_${item.value}`}
                    key={item.value}
                    sx={{
                      background: item.color,
                      padding: rating === item.value ? '10px 20px' : '4px 12px',
                      borderRadius: 2,
                      cursor: 'pointer',
                      border:
                        rating === item.value
                          ? `2px solid ${theme.palette.text.secondary}`
                          : 'none',
                      boxShadow: `0 0 3px ${theme.palette.text.secondary}`,
                    }}
                    onClick={(e) => {
                      handleTrackEvent(e, `set_rating_${item.value}`);
                      setRating(item?.value);
                    }}
                  >
                    <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box>
                  <SentimentVeryDissatisfied
                    sx={{
                      display: 'block',
                      fontSize: 40,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="caption" color={'text.secondary'}>
                    1 - Not Likely
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'end'}
                >
                  <Mood
                    sx={{
                      display: 'block',
                      fontSize: 40,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="caption" color={'text.secondary'}>
                    10 - Highly Likely
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{ marginTop: 2 }}
              variant="caption"
              color={'text.secondary'}
            >
              Please share 1 or 2 things that you would like us to improve.
            </Typography>
            <TextField
              sx={{ marginTop: 1 }}
              label="Suggested improvements"
              variant="outlined"
              name={'improvements'}
              multiline
              rows={3}
              fullWidth
              value={improvements}
              onChange={(e) => setImprovements(e.target.value)}
            />
            <Typography
              sx={{ marginTop: 2 }}
              variant="caption"
              color={'text.secondary'}
            >
              Please share 1 or 2 things that you liked about the product.
            </Typography>
            <TextField
              sx={{ marginTop: 1 }}
              label="Things you liked"
              variant="outlined"
              name={'likes'}
              multiline
              rows={3}
              fullWidth
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 4,
            }}
          >
            {/* <Button onClick={() => setShowTimesUpDialog(false)}>Cancel</Button> */}
            <Button
              size="small"
              variant="outlined"
              data-trackid={'clode_feedback_dialog'}
              startIcon={<Close />}
              onClick={(e) => {
                handleTrackEvent(e, 'close_feedback_dialog');
                dispatch(
                  saveCandidateFeedbackOnPlatformExperience(
                    {
                      recommendationRating: rating,
                      improvements: [improvements],
                      likes: [likes],
                      type: 'otpc',
                      linkToken: tk,
                    },
                    () => {
                      setShowFeedbackDialog(false);
                      // setShowFeedbackSuccessfulDialog(true);
                    },
                    () => {
                      dispatch(
                        showAlert({
                          message:
                            'Failed to submit feedback. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              autoFocus
            >
              Close
            </Button>
            <Tooltip
              title={
                rating === 0 ? 'Please pick one of the emotions above.' : ''
              }
              placement="top"
              arrow
            >
              <Box>
                <Button
                  size="small"
                  data-trackid={'submit_feedback_dialog'}
                  variant="contained"
                  onClick={(e) => {
                    handleTrackEvent(e, 'submit_feedback_dialog');
                    dispatch(
                      saveCandidateFeedbackOnPlatformExperience(
                        {
                          recommendationRating: rating,
                          improvements: [improvements],
                          likes: [likes],
                          type: 'otpc',
                          linkToken: tk,
                        },
                        () => {
                          setShowFeedbackDialog(false);
                          setShowFeedbackSuccessfulDialog(true);
                        },
                        () => {
                          dispatch(
                            showAlert({
                              message:
                                'Failed to submit feedback. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                  }}
                  autoFocus
                  // disabled={rating === 0}
                >
                  Submit
                </Button>
              </Box>
            </Tooltip>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showFeedbackSuccessfulDialog}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
              setShowFeedbackSuccessfulDialog(false);
            else return;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Thank you so much for your feedback, we really appreciate it!
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: 4,
            }}
          >
            {/* <Button onClick={() => setShowTimesUpDialog(false)}>Cancel</Button> */}
            <Button
              size="small"
              variant="outlined"
              data-trackid={'close_feedback_successful_dialog'}
              startIcon={<Close />}
              onClick={(e) => {
                handleTrackEvent(e, 'close_feedback_successful_dialog');
                setShowFeedbackSuccessfulDialog(false);
              }}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showPermissionDeniedError}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            '& .MuiPaper-root-MuiDialog-paper': {
              maxWidth: '70vw',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Camera and microphone are blocked
          </DialogTitle>
          <DialogContent>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <Box flex={1}>
                <img src="/camerahelp.png" width={'100%'} height={'100%'} />
              </Box>
              <DialogContentText id="alert-dialog-description" flex={1}>
                To record your video we need permission to use your camera and
                microphone. Click the <Lock /> Lock Icon (usually in your
                browser&apos;s address bar) to allow permissions and start
                recording.
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                handleTrackEvent(e, `cta_permission_denied_dialog_close`);
                setShowPermissionDeniedError(false);
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={endConversationAlert}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            '& .MuiPaper-root-MuiDialog-paper': {
              maxWidth: '70vw',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <DialogContentText id="alert-dialog-description" flex={1}>
                This will end up the conversation. Do you want to continue?
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                handleTrackEvent(e, `cta_permission_denied_dialog_close`);
                setEndConversationAlert(false);
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                dispatch(
                  updateConversationStatusForPracticeCall(
                    tk,
                    pc,
                    'completed',
                    () => {
                      setConversationStarted(false);
                      setEndConversationAlert(false);
                      setCurrentConversation((prev) => {
                        return {
                          ...prev,
                          status: 'completed',
                        };
                      });
                      if (recording) {
                        handleRecordStopClick(true);
                      }
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default CallPractice;
