import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import ProfileCompletion from 'components/ProfileCompletion/ProfileCompletion';

const NavItem = ({
  title,
  items,
  href,
  startIcon,
  action,
  showSecondaryItems,
  disabled,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { competitionIdOrSlug, participationId } = useParams();
  const { dashboardSidebarExpanded, currentContextData } = useSelector(
    (state) => state.uiStates,
  );
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const browser = detect();
  const { contestReport, fetching } = useSelector(
    (state) => state.contestReportData,
  );
  const { orgConfigs } = useSelector((state) => state.orgConfigs);

  const { percentCompleted } = useSelector(
    (state) => state.profileCompletionState,
  );

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const pathRegex = new RegExp(`^${href}$`, 'i');

  // console.log(orgConfigs);

  return (
    <Box>
      <Tooltip
        title={dashboardSidebarExpanded ? '' : title}
        arrow
        placement="right"
      >
        <Box
          data-trackid={`open_${title}`}
          sx={{
            padding: '12px 24px',
            textAlign: 'center',
            background: pathRegex.test(pathname)
              ? theme.palette.background.level1
              : 'transparent',
            borderRight: pathRegex.test(pathname)
              ? `2px solid ${theme.palette.primary.light}`
              : 'none',
            // marginBottom: 1,
            cursor: 'pointer',
            ':hover': {
              background: theme.palette.background.level1,
            },
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={(e) => {
            handleTrackEvent(e, `open_${title}`);
            if (href) {
              history.push(href);
            } else {
              action();
            }
          }}
        >
          {typeof startIcon === 'string' ? (
            <img
              src={startIcon}
              alt=""
              style={{
                filter: pathRegex.test(pathname) ? 'none' : 'invert(0.5)',
                width: 20,
                height: 20,
              }}
            />
          ) : (
            startIcon
          )}
          {dashboardSidebarExpanded ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={1}
              gap={1}
            >
              <Typography
                sx={{
                  color: pathRegex.test(pathname)
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                  fontWeight: pathRegex.test(pathname) ? 600 : 500,
                  marginLeft: href === '/posted-jobs' ? 1.5 : 1,
                  display: 'flex',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {title?.replace(
                  Object.keys(orgConfigs?.navBarTitles || {})?.find((feature) =>
                    title?.includes(feature),
                  ),
                  orgConfigs?.navBarTitles?.[
                    Object.keys(
                      orgConfigs?.navBarTitles || {},
                    )?.find((feature) => title?.includes(feature))
                  ]?.title || title,
                )}
              </Typography>
              {title === 'Profile' && percentCompleted < 100 ? (
                <ProfileCompletion
                  percentCompleted={percentCompleted}
                  circleSize={30}
                  circleTextSize={8}
                  fontSize={14}
                  thickness={4}
                  dontShowExtraText={true}
                />
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Tooltip>
      {(pathname === `/practice/interview` ||
        pathname === `/practice/pitch` ||
        pathname === `/practice/script` ||
        pathname === `/sales-call-analysis` ||
        pathname === `/cro-interviews` ||
        pathname === `/accounts` ||
        pathname === `/compass-framework` ||
        pathname === `/compass-helps` ||
        pathname === `/tasks` ||
        pathname === `/digital-twin` ||
        pathname === `/conversation-ai` ||
        pathname === `/account/settings` ||
        pathname === `/customer/settings` ||
        pathname === `/performance` ||
        pathname === `/team-settings` ||
        pathname === `/pricing` ||
        pathname === `/practice-calls-vapi` ||
        pathname === `/practice/contests`) &&
      (title === 'Practice' ||
        title.trim() === 'CRO Interviews' ||
        title.trim() === 'Accounts' ||
        title.trim() === 'Compass Framework' ||
        title.trim() === 'Compass Helps' ||
        title.trim() === 'Sales Call Analysis' ||
        title.trim() === 'Tasks' ||
        title.trim() === 'John Barrows' ||
        title.trim() === 'Brynne Tillman' ||
        title.trim() === 'John Stopper' ||
        title.trim() === 'Private Roleplays' ||
        title.trim() === 'My Performance' ||
        title.trim() === 'My Team' ||
        title.trim() === 'Clients Dashboard' ||
        title.trim() === 'Practice Call v2(vapi)' ||
        title.trim() === 'Account Settings') &&
      dashboardSidebarExpanded
        ? items?.map((item) => {
            // console.log(item);
            return (
              <Box
                key={item?.name}
                sx={{
                  display: item?.disabled ? 'none' : 'block',
                }}
              >
                <Tooltip
                  key={item?.name}
                  title={dashboardSidebarExpanded ? '' : item?.name}
                  arrow
                  placement="right"
                >
                  <Box
                    sx={{
                      padding: '8px 32px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 1,
                      gap: 1,
                    }}
                    data-trackid={`open_${item?.name}`}
                    onClick={(e) => {
                      handleTrackEvent(e, `open_${item?.name}`);
                      if (item?.href) {
                        history.push(item?.href);
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'currentContextData',
                            value: null,
                          },
                        });
                      } else {
                        action();
                      }
                    }}
                  >
                    {typeof item?.startIcon === 'string' ? (
                      item?.startIcon?.trim() ? (
                        <img
                          src={item?.startIcon}
                          alt=""
                          style={{
                            filter: pathRegex.test(pathname)
                              ? 'none'
                              : 'invert(0.5)',
                            width: 20,
                            height: 20,
                          }}
                        />
                      ) : (
                        <>&nbsp;</>
                      )
                    ) : (
                      item?.startIcon
                    )}
                    {dashboardSidebarExpanded ? (
                      <Tooltip
                        title={
                          item?.name?.split('')?.length > 20 ? item?.name : ''
                        }
                        arrow
                        placement="bottom"
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` === item?.href
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` === item?.href ? 600 : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            '&::after': {
                              content:
                                item?.name?.split('')?.length > 20
                                  ? '"..."'
                                  : '""',
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              padding: '0 5px',
                              background:
                                `${pathname}${search}` === item?.href
                                  ? theme.palette.background.level1
                                  : 'transparent',
                            },
                          }}
                        >
                          {item?.name?.replace(
                            Object.keys(
                              orgConfigs?.navBarTitles?.[
                                Object.keys(
                                  orgConfigs?.navBarTitles || {},
                                )?.find((feature) => title?.includes(feature))
                              ]?.subTitles || {},
                            )?.find((subFeature) =>
                              item?.name?.includes(subFeature),
                            ),
                            orgConfigs?.navBarTitles?.[
                              Object.keys(
                                orgConfigs?.navBarTitles || {},
                              )?.find((feature) => title?.includes(feature))
                            ]?.subTitles?.[
                              Object.keys(
                                orgConfigs?.navBarTitles?.[
                                  Object.keys(
                                    orgConfigs?.navBarTitles || {},
                                  )?.find((feature) => title?.includes(feature))
                                ]?.subTitles || {},
                              )?.find((subFeature) =>
                                item?.name?.includes(subFeature),
                              )
                            ]?.title || item?.name,
                          )}
                        </Typography>
                      </Tooltip>
                    ) : null}
                  </Box>
                </Tooltip>
                {pathname === `/accounts` &&
                item?.name?.trim() === 'All Accounts' &&
                dashboardSidebarExpanded ? (
                  currentContextData?.title ? (
                    <>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded
                            ? ''
                            : currentContextData?.title
                        }
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 4,
                            gap: 1,
                          }}
                          data-trackid={`open_${currentContextData?.title}`}
                          onClick={(e) => {
                            handleTrackEvent(
                              e,
                              `open_${currentContextData?.title}`,
                            );
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'view',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color: currentContextData?.title
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                              fontWeight: currentContextData?.title ? 600 : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              '&::after': {
                                content:
                                  currentContextData?.title?.split('')?.length >
                                  20
                                    ? '"..."'
                                    : '""',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                padding: '0 5px',
                                background: currentContextData?.title
                                  ? theme.palette.background.level1
                                  : 'transparent',
                              },
                            }}
                          >
                            {currentContextData?.title}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Overview'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_overview`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_overview`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'view',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'view'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'view' ? 600 : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              // '&::after': {
                              //   content:
                              //     currentContextData?.tab ===
                              //     'view'?.split('')?.length > 20
                              //       ? '"..."'
                              //       : '""',
                              //   position: 'absolute',
                              //   right: 0,
                              //   top: 0,
                              //   padding: '0 5px',
                              //   background:
                              //     currentContextData?.tab === 'view'
                              //       ? theme.palette.background.level1
                              //       : 'transparent',
                              // },
                            }}
                          >
                            Overview
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Compass'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_compass`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_compass`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'manage',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'manage'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'manage'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              // '&::after': {
                              //   content:
                              //     currentContextData?.title?.split('')?.length >
                              //     20
                              //       ? '"..."'
                              //       : '""',
                              //   position: 'absolute',
                              //   right: 0,
                              //   top: 0,
                              //   padding: '0 5px',
                              //   background: currentContextData?.title
                              //     ? theme.palette.background.level1
                              //     : 'transparent',
                              // },
                            }}
                          >
                            Compass
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded ? '' : 'Organization Chart'
                        }
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_org_chart`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_org_chart`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'orgchart',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'orgchart'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'orgchart'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              // '&::after': {
                              //   content:
                              //     currentContextData?.title?.split('')?.length >
                              //     20
                              //       ? '"..."'
                              //       : '""',
                              //   position: 'absolute',
                              //   right: 0,
                              //   top: 0,
                              //   padding: '0 5px',
                              //   background: currentContextData?.title
                              //     ? theme.palette.background.level1
                              //     : 'transparent',
                              // },
                            }}
                          >
                            Org Chart
                          </Typography>
                        </Box>
                      </Tooltip>
                    </>
                  ) : null
                ) : null}
              </Box>
            );
          })
        : null}
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  startIcon: PropTypes.any,
  onClose: PropTypes.func,
  action: PropTypes.func,
  showSecondaryItems: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NavItem;
