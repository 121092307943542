import axios from '../../../utils/axios';
import { v4 as uuidv4 } from 'uuid';

const firebaseSignup = (
  {
    firstName,
    lastName,
    designations,
    profilePicture,
    idToken,
    invitationId,
    // companyInviteId,
    // verifyEmail,
    // candidateToken,
    // medium,
    slug,
  },
  callback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'FIREBASE_CREATING_USER' });
    try {
      let response = await axios.post(
        '/us/users',
        {
          firstName,
          lastName,
          profilePicture: profilePicture
            ? {
                filename: profilePicture?.name,
                contentType: profilePicture?.type,
              }
            : null,
          designations: designations,
          authToken: idToken,
          slug,
        },
        {
          params: {
            invitationId: invitationId || null,
          },
        },
      );
      if (response.status === 200) {
        dispatch({
          type: 'FIREBASE_USER_CREATED',
          payload: 'New user created',
        });
        callback(response.data);
      } else {
        dispatch({
          type: 'FIREBASE_USER_CREATION_FAILED',
          payload: 'Error creating account',
        });
      }
    } catch (e) {
      dispatch({
        type: 'FIREBASE_USER_CREATION_FAILED',
        payload: 'Error creating account',
      });
    }
  };
};

export default firebaseSignup;
