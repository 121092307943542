import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const saveElevatorPitchProgress = (videoId, userId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'SAVING_ELEVATOR_PITCH_PROGRESS' });
    // try {
    //   let unsubscribe = onAuthStateChanged(auth, async (user) => {
    //     const token = await user?.getIdToken();
    //     const userInfo = localStorage.getItem('userInfo');
    //     const userId = JSON.parse(userInfo)?.id;
    const reqId = uuidv4();
    try {
      const response = await axios.get(`/cs/elevations/${videoId}/save`, {
        params: {
          'request-id': reqId,
          userId,
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      if (response.status === 200) {
        await dispatch({
          type: 'SAVING_ELEVATOR_PITCH_PROGRESS_DONE',
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'SAVING_ELEVATOR_PITCH_PROGRESS_FAILED',
          payload: `Failed to save pitch analysis progress`,
        });
      }
    } catch (e) {
      dispatch({
        type: 'SAVING_ELEVATOR_PITCH_PROGRESS_FAILED',
        payload: `Failed to save pitch analysis progress`,
      });
    }
    //     unsubscribe();
    //   });
    // } catch (e) {
    //   console.log(e);
    //   dispatch({
    //     type: 'SAVING_ELEVATOR_PITCH_PROGRESS_FAILED',
    //     payload: `Failed to save pitch analysis progress`,
    //   });
    // }
  };
};

export default saveElevatorPitchProgress;
