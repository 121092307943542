const initialstate = {
  progressIds: [],
};

const checkMergedAudioStatusReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'DELETE_MERGED_AUDIO_PROGRESS_ID': {
      const updatedProgressIds = state.progressIds.filter(
        (id) => id !== action.payload,
      );
      return Object.assign({}, state, {
        progressIds: updatedProgressIds,
      });
    }
    case 'ADD_MERGED_AUDIO_PROGRESS_ID': {
      return Object.assign({}, state, {
        progressIds: state.progressIds.concat(action.payload),
      });
    }
    default:
      return state;
  }
};

export default checkMergedAudioStatusReducer;
