import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const checkMergedAudioStatus = (conversationId, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'CHECKING_MERGED_ADUIO_STATUS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/cs/conversation/${conversationId}/audio/merge-status`,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'current-role': currentRole,
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'CHECKING_MERGED_ADUIO_STATUS_DONE',
              payload: {
                details: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'CHECKING_MERGED_ADUIO_STATUS_FAILED',
              payload: `Failed to get merged audio status`,
            });
            dispatch(
              showAlert({
                message: 'Something went wrong. Please try again',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );

            if (typeof errCb === 'function') {
              errCb();
            }
          }
        } catch (e) {
          dispatch({
            type: 'CHECKING_MERGED_ADUIO_STATUS_FAILED',
            payload: `Failed to get merged audio status`,
          });
          dispatch(
            showAlert({
              message: 'Something went wrong. Please try again',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
          if (typeof errCb === 'function') {
            errCb();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CHECKING_MERGED_ADUIO_STATUS_FAILED',
        payload: `Failed to get merged audio status`,
      });
      dispatch(
        showAlert({
          message: 'Something went wrong. Please try again',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default checkMergedAudioStatus;
