import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase';

const firebaseLogout = (callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FIREBASE_LOGGING_OUT' });
    try {
      signOut(auth)
        .then(function() {
          localStorage.removeItem('userInfo');
          dispatch({ type: 'FIREBASE_LOGOUT_SUCCESSFULL' });
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch(function(error) {
          // console.log(error);
        });
    } catch (e) {
      dispatch({ type: 'FIREBASE_LOGOUT_FAIL' });
    }
  };
};

export default firebaseLogout;
