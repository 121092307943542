import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AlertPopup = ({
  open,
  action,
  handleClose,
  autoHideDuration,
  message,
  type,
  verticalLocation,
  horizontalLocation,
}) => {
  const defaultAction = (
    <div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  return type ? (
    <Snackbar
      anchorOrigin={
        verticalLocation && horizontalLocation
          ? {
              vertical: verticalLocation,
              horizontal: horizontalLocation,
            }
          : null
      }
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}
        action={action || defaultAction}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      anchorOrigin={
        verticalLocation && horizontalLocation
          ? {
              vertical: verticalLocation,
              horizontal: horizontalLocation,
            }
          : {}
      }
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={action || defaultAction}
      message={message}
    ></Snackbar>
  );
};

AlertPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  action: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  verticalLocation: PropTypes.string,
  horizontalLocation: PropTypes.string,
};

export default AlertPopup;
