import axios from '../../../utils/axios';
import { v4 as uuidv4 } from 'uuid';
import { auth } from '../../../firebase';

const firebaseSignin = (idToken, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'FIREBASE_LOGGING_IN' });
    try {
      let response = await axios.get('/us/users/login', {
        params: {
          idToken,
        },
      });
      // console.log(response);
      if (
        response.status === 200 &&
        response.data !== undefined
        // &&
        // response.data.isAuthenticated === true
      ) {
        const userInfo = response.data.user;
        try {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          // localStorage.setItem('organization', JSON.stringify(organization));
          dispatch({
            type: 'FIREBASE_LOGIN_SUCCESSFUL',
            payload: response.data,
          });
          callback(response.data);
        } catch (e) {
          dispatch({
            type: 'FIREBASE_LOGIN_FAIL',
            payload: 'Unable to signin',
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
      } else if (
        response.status === 200 &&
        response.data.isAuthenticated === false
      ) {
        let createUserResponse2 = await axios.post('/user/create', null, {
          params: {
            idToken,
            'request-id': uuidv4(),
          },
        });
        const token = await auth?.currentUser?.getIdToken(true);
        if (
          createUserResponse2.status === 200 &&
          createUserResponse2.data.status === 201
        ) {
          let verifyTokenResponse3 = await axios.get('/verifyToken', {
            params: {
              idToken: token,
            },
          });
          if (
            verifyTokenResponse3.status === 200 &&
            verifyTokenResponse3.data.data.isAuthenticated === true
          ) {
            const userInfo = verifyTokenResponse3.data.data.user;
            try {
              // localStorage.setItem('token', idToken);
              localStorage.setItem('userInfo', JSON.stringify(userInfo));
              dispatch({
                type: 'FIREBASE_LOGIN_SUCCESSFUL',
                payload: verifyTokenResponse3.data.data,
              });
              callback(createUserResponse2.data.data);
            } catch (e) {
              dispatch({
                type: 'FIREBASE_LOGIN_FAIL',
                payload: 'Unable to signin',
              });
              if (typeof errCallback === 'function') {
                errCallback();
              }
            }
          }
        } else if (
          createUserResponse2.status === 200 &&
          createUserResponse2.data.status === 403
        )
          dispatch({
            type: 'FIREBASE_LOGIN_FAIL',
            payload:
              "You don't have an account with us. Please request a demo and get an invitation link for signup",
          });
        if (typeof errCallback === 'function') {
          errCallback(
            "You don't have an account with us. Please request a demo and get an invitation link for signup",
          );
        }
      } else {
        dispatch({
          type: 'FIREBASE_LOGIN_FAIL',
          payload: 'Unable to signin',
        });
        if (typeof errCallback === 'function') {
          errCallback();
        }
      }
    } catch (e) {
      dispatch({ type: 'FIREBASE_LOGIN_FAIL', payload: 'Unable to Login' });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default firebaseSignin;
