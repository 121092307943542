const initialstate = {
  validating: null,
  done: null,
  failed: null,
  email: null,
  joined: null,
  emailVerified: null,
  designations: [],
  firstName: '',
  lastName: '',
  error: null,
  errorText: null,
};

const validateInvitationTokenReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'VALIDATING_TOKEN':
      return Object.assign({}, state, {
        validating: true,
      });
    case 'VALIDATING_TOKEN_DONE':
      return Object.assign({}, state, {
        validating: false,
        done: true,
        failed: false,
        joined: action.payload.joined,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified,
        designations: action.payload.designations,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        errorText: '',
        error: false,
      });
    case 'VALIDATING_TOKEN_FAILED':
      return Object.assign({}, state, {
        validating: false,
        failed: true,
        done: false,
        joined: null,
        email: null,
        emailVerified: null,
        designations: [],
        firstName: '',
        lastName: '',
        errorText: action.payload,
        error: true,
      });
    default:
      return state;
  }
};

export default validateInvitationTokenReducer;
