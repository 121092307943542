const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  analysisReviews: null,
  emptyList: null,
  lastSeen: null,
};

const getSalesCallReviewsByUserIdReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_BY_USER_ID':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_BY_USER_ID_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_BY_USER_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        analysisReviews: action.payload.analysisReviews,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_SALES_CALL_REVIEW_TO_ALL_REVIEWS_BY_USER_ID':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        analysisReviews: Array.isArray(action.payload.analysisReviews)
          ? state.analysisReviews
            ? state.analysisReviews.concat(action.payload.analysisReviews)
            : action.payload.analysisReviews
          : state.analysisReviews,
        emptyList:
          Array.isArray(action.payload.analysisReviews) &&
          (action.payload.analysisReviews.length === 0 ||
            action.payload.analysisReviews.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'FETCHING_ALL_SALES_CALL_REVIEWS_BY_USER_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        analysisReviews: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_SALES_CALL_REVIEWS_BY_USER_ID_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getSalesCallReviewsByUserIdReducer;
