import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getEmailByToken = (token, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_USER_EMAIL_BY_TOKEN' });
    const reqId = uuidv4();
    try {
      const response = await axios.get(`/nf/users/${token}/email`, {
        params: {
          'request-id': reqId,
        },
      });
      if (response.status === 200) {
        await dispatch({
          type: 'FETCHING_USER_EMAIL_BY_TOKEN_DONE',
          payload: {
            details: response?.data?.data,
          },
        });
        if (typeof callback === 'function') {
          callback(response?.data?.data);
        }
      } else {
        dispatch({
          type: 'FETCHING_USER_EMAIL_BY_TOKEN_FAILED',
          payload: `Failed to get email for token ${token}`,
        });
        dispatch(
          showAlert({
            message: 'Something went wrong. Please try again',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );

        if (typeof errCb === 'function') {
          errCb();
        }
      }
    } catch (e) {
      dispatch({
        type: 'FETCHING_USER_EMAIL_BY_TOKEN_FAILED',
        payload: `Failed to get email for token ${token}`,
      });
      dispatch(
        showAlert({
          message: 'Something went wrong. Please try again',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default getEmailByToken;
