import axios from '../../../utils/axios';
import { v4 as uuidv4 } from 'uuid';
import { auth } from '../../../firebase';

const isEmailVerified = (idToken, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CHECKING_EMAIL_VERIFIED' });
    try {
      let response = await axios.get('/us/users/verify', {
        params: {
          idToken,
        },
      });
      // console.log(response);
      if (response.status === 200) {
        dispatch({
          type: 'CHECKING_EMAIL_VERIFICATION_SUCCESSFUL',
        });
        callback(response.data);
      } else {
        dispatch({
          type: 'CHECKING_EMAIL_VERIFICATION_FAIL',
          payload: 'Unable to check email verification',
        });
        if (typeof callback === 'function') {
          callback(null, true);
        }
      }
    } catch (e) {
      dispatch({
        type: 'CHECKING_EMAIL_VERIFICATION_FAIL',
        payload: 'Unable to check email verification',
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default isEmailVerified;
