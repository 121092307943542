import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getOrgChart = (accountId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'GETTING_ORG_CHART' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/us/js/org-chart/account/${accountId}`,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'GETTING_ORG_CHART_DONE',
              payload: {
                bots: response?.data || [],
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GETTING_ORG_CHART_FAILED',
              payload: `Failed to fetch org chart`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'GETTING_ORG_CHART_FAILED',
            payload: `Failed to fetch org chart`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GETTING_ORG_CHART_FAILED',
        payload: `Failed to fetch org chart`,
      });
    }
  };
};

export default getOrgChart;
