import axios from 'utils/axios';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  DialogContentText,
  Button,
  Link,
} from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, ticketId: '' };
  }

  static getDerivedStateFromError(error) {
    // console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/us/ticket`,
            {
              description: 'string',
              type: 'string',
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          // console.log(response);
          this.setState({
            ticketId: response?.data,
          });
        } catch (e) {
          console.log(e);
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
    }
    // You can also log the error to an error reporting service
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          axios.post(
            `/log`,
            {
              severity: 'error',
              statusCode: '0',
              api: 'none',
              message: `Something went wrong ${JSON.stringify(
                errorInfo,
                null,
                2,
              )} with message ${error.message}`,
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
            },
          );
        } catch (e) {
          console.log(e);
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
    }
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    // console.log(document?.referrer);
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Dialog
          open={true}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              return;
            } else return;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Typography variant="h6">
                Oops, this is embarrsing, something went wrong.
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{}}>
              We have created a ticket for it. Here is the ticket ID :{' '}
              {this.state.ticketId} . We will get back to you as soon as we have
              fixed the issue.
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              style={{ marginTop: 4 }}
            >
              If you are blocked on something and not able to proceed further.
              Please reach us at support@qualification.ai
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              // padding: 4,
              padding: '8px 16px',
            }}
          >
            <Box
              display={'flex'}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              <Button
                data-trackid={'go_back_to_home'}
                component={Link}
                variant="contained"
                color="primary"
                size="large"
                href={'/'}
              >
                Go to home
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
