const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  actionItems: null,
};

const getAllTaskItemsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_ACTION_ITEMS':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_ALL_ACTION_ITEMS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        actionItems: action.payload.actionItems,
      });
    case 'UPDATE_ACTION_ITEMS_SEEN_STATUS': {
      const updatedActionItems = state.actionItems.map((sr) => {
        if (action.payload.ids.includes(sr.id)) {
          return {
            ...sr,
            seen: true,
          };
        } else {
          return sr;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        actionItems: updatedActionItems,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'FETCHING_ALL_ACTION_ITEMS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        actionItems: [],
      });
    default:
      return state;
  }
};

export default getAllTaskItemsReducer;
