import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import showAlert from '../Common/showAlert';

const getFeedbacksOnEntity = (entityId, size, lastSeen, callback) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({ type: 'FETCHING_FEEDBACKS_ON_ENTITY_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_FEEDBACKS_ON_ENTITY' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/cs/entity/${entityId}/feedback-and-rating`,
            {
              params: {
                'request-id': reqId,
                lastSeen: lastSeen,
                size: size,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'current-role': 'account_admin',
                // 'x-api-version': 'v2',
              },
            },
          );
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type: 'ADD_FEEDBACKS_TO_ALL_FEEDBACKS_ON_ENTITY',
                payload: {
                  feedbacks: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_FEEDBACKS_ON_ENTITY_DONE',
                payload: {
                  feedbacks: response?.data,
                  lastSeen: response?.data?.[response?.data?.length - 1]?.id,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_FEEDBACKS_ON_ENTITY_FAILED',
              payload: `Failed to get feedbacks`,
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_FEEDBACKS_ON_ENTITY_FAILED',
            payload: `Failed to get feedbacks`,
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_FEEDBACKS_ON_ENTITY_FAILED',
        payload: `Failed to get feedbacks`,
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getFeedbacksOnEntity;
