import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import Page from './components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import Observer from 'components/Observer';
import { v4 as uuidv4 } from 'uuid';
import Tracker from '@openreplay/tracker';
// import { messaging } from 'firebase';
// import { getToken, onMessage } from 'firebase/messaging';

const tracker = new Tracker({
  projectKey: 'OchCWFYOg6bErjB3RXHQ',
});

const App = () => {
  const browserHistory = createBrowserHistory();

  const intializeOpenReplayTracker = () => {
    if (process.env.REACT_APP_TARGET_ENV === 'production') {
      tracker.start();
      if (sessionStorage.getItem('trackingSessionId')) {
        // console.log(sessionStorage.getItem('trackingSessionId'));
      } else {
        const trackingSessionId = uuidv4();
        sessionStorage.setItem('trackingSessionId', trackingSessionId);
      }
    }
  };

  useEffect(() => {
    intializeOpenReplayTracker();
    return () => {
      if (process.env.REACT_APP_TARGET_ENV === 'production') {
        tracker.stop();
      }
    };
  }, []);

  return (
    <Observer>
      <Page>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </Page>
    </Observer>
  );
};

export default App;
