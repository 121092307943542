import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import firebaseCheckSessionReducer from './Auth/firebaseCheckSessionReducer';
import firebaseLogoutReducer from './Auth/firebaseLogoutReducer';
import firebaseSigninReducer from './Auth/firebaseSigninReducer';
import firebaseSignupReducer from './Auth/firebaseSignupReducer';
import uploadProfilePictureReducer from './Auth/uploadProfilePictureReducer';
import validateInvitationTokenReducer from './Auth/validateInvitationTokenReducer';
import alertReducer from './Common/alertReducer';
import experimentReducer from './Common/experimentReducer';
import globalTimerReducer from './Common/globalTimerReducer';
import getAllCompetitionsReducer from './Common/getAllCompetitionsReducer';
import getCompetitionByIdReducer from './Common/getCompetitionByIdReducer';
import registerForCompetitionReducer from './Candidate/registerForCompetitionReducer';
import attemptCompetitionReducer from './Candidate/attemptCompetitionReducer';
import saveToCalendarReducer from './Candidate/saveToCalendarReducer';
import getParticipantsByCompetitionIdReducer from './Employer/getParticipantsByCompetitionIdReducer';
import competitionSessionReducer from './Candidate/competitionSessionReducer';
import saveParticipationNameReducer from './Candidate/saveParticipationNameReducer';
import saveParticipationStartTimeReducer from './Candidate/saveParticipationStartTimeReducer';
import updateParticipationStatusForTimeoutReducer from './Candidate/updateParticipationStatusForTimeoutReducer';
import uiReducer from './Candidate/uiReducer';
import getUserDetailsReducer from './Common/getUserDetailsReducer';
import getAllParticipationsReducer from './Candidate/getAllParticipationsReducer';
import getContestReportReducer from './Candidate/getContestReportReducer';
import submitElevatorPitchReducer from './Common/submitElevatorPitchReducer';
import getElevatorPitchReportsReducer from './Candidate/getElevatorPitchReportsReducer';
import elevatorPitchReducer from './Common/elevatorPitchReducer';
import getAsyncInterviewQuestionsReducer from './Common/getAsyncInterviewQuestionsReducer';
import searchJobsReducer from './Common/searchJobsReducer';
import fetchAllJobsReducer from './Employer/fetchAllJobsReducer';
import getCandidateDetailsReducer from './Candidate/getCandidateDetailsReducer';
import candidateProfileCompletionReducer from './Candidate/candidateProfileCompletionReducer';
import getCandidatePublicProfileReducer from './Common/getCandidatePublicProfileReducer';
import getAllApplicantsReducer from './Employer/getAllApplicantsReducer';
import getInvitationsByContestIdReducer from './Employer/getInvitationsByContestIdReducer';
import validatePrcInvitationTokenReducer from './Auth/validatePrcInvitationTokenReducer';
import getCandidatesDetailsReducer from './Employer/getCandidatesDetailsReducer';
import getJobByIdReducer from './Common/getJobByIdReducer';
import getPitchSectionThumbnailReducer from './Employer/getPitchSectionThumbnailReducer';
import phasedContestSessionReducer from './Candidate/phasedContestSessionReducer';
import getAllConversationsReducer from './Candidate/getAllConversationsReducer';
import createSalesAnalysisThreadReducer from './Candidate/createSalesAnalysisThreadReducer';
import getSalesCallReviewsReducer from './Common/getSalesCallReviewsReducer';
import getAllConversationsMainReducer from './Common/getAllConversationsMainReducer';
import checkMergedAudioStatusReducer from './Common/checkMergedAudioStatusReducer';
import getAllInvitationsReducer from './Common/getAllInvitationsReducer';
import getAllTeamMembersReducer from './Common/getAllTeamMembersReducer';
import getAllConversationsMainByUserIdReducer from './Common/getAllConversationsMainByUserIdReducer';
import getSalesCallReviewsByUserIdReducer from './Common/getSalesCallReviewsByUserIdReducer';
import getFeedbacksOnEntityReducer from './Common/getFeedbacksOnEntityReducer';
import getOrganizationConfigsReducer from './Common/getOrganizationConfigsReducer';
import getWhitelabelDetailsByDomainReducer from './Common/getWhitelabelDetailsByDomainReducer';
import getWlCustomersReducer from './Common/getWlCustomersReducer';
import getLeaderboardReducer from './Common/getLeaderboardReducer';
import getConversationByIdReducer from './Candidate/getConversationByIdReducer';
import getAllGeneratedPlaybooksReducer from './Common/getAllGeneratedPlaybooksReducer';
import getAllTaskItemsReducer from './Candidate/getAllTaskItemsReducer';
import getCROInterviewsReducer from './Compass/getCROInterviewsReducer';
import getAccountsReducer from './Compass/getAccountsReducer';
import getCompanyProspectsReducer from './Compass/getCompanyProspectsReducer';
import getMembersByOrgIdReducer from './Compass/getMembersByOrgIdReducer';
import getAllDealActivitesReducer from './Compass/getAllDealActivitesReducer';
import getTeamsReducer from './Common/getTeamsReducer';
import getOutreachInvitationsReducer from './Common/getOutreachInvitationsReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['firebaseSession', 'firebaseSigninState', 'uiStates'],
};

const appReducer = combineReducers({
  firebaseSession: firebaseCheckSessionReducer,
  firebaseLogoutState: firebaseLogoutReducer,
  firebaseSigninState: firebaseSigninReducer,
  firebaseSignupState: firebaseSignupReducer,
  profilePictureUpload: uploadProfilePictureReducer,
  invitationValidationData: validateInvitationTokenReducer,
  alertState: alertReducer,
  experimentState: experimentReducer,
  globalTimer: globalTimerReducer,
  allCompetitions: getAllCompetitionsReducer,
  competitionDetailsById: getCompetitionByIdReducer,
  competitionRegistration: registerForCompetitionReducer,
  competitionAttemptState: attemptCompetitionReducer,
  saveToCalendarState: saveToCalendarReducer,
  getParticipantsByCompetitionIdState: getParticipantsByCompetitionIdReducer,
  competitionSession: competitionSessionReducer,
  saveParticipationState: saveParticipationNameReducer,
  saveParticipationStartTimeState: saveParticipationStartTimeReducer,
  updateParticipationStatusForTimeoutState: updateParticipationStatusForTimeoutReducer,
  uiStates: uiReducer,
  userDetails: getUserDetailsReducer,
  allParticipations: getAllParticipationsReducer,
  contestReportData: getContestReportReducer,
  elevatorPitchSubmissionState: submitElevatorPitchReducer,
  elevatorPitchReports: getElevatorPitchReportsReducer,
  elevatorPitchState: elevatorPitchReducer,
  asyncInterviewQuestionsState: getAsyncInterviewQuestionsReducer,
  allJobs: searchJobsReducer,
  candidateDetails: getCandidateDetailsReducer,
  profileCompletionState: candidateProfileCompletionReducer,
  employerJobs: fetchAllJobsReducer,
  candidatePublicProfile: getCandidatePublicProfileReducer,
  allApplicants: getAllApplicantsReducer,
  allInvitations: getInvitationsByContestIdReducer,
  prcInvitationValidationData: validatePrcInvitationTokenReducer,
  candidatesDetailsState: getCandidatesDetailsReducer,
  jobById: getJobByIdReducer,
  pitchSectionThumbnails: getPitchSectionThumbnailReducer,
  phasedContestSession: phasedContestSessionReducer,
  allConversations: getAllConversationsReducer,
  createSalesAnalysisThreadState: createSalesAnalysisThreadReducer,
  salesAnalysisReviews: getSalesCallReviewsReducer,
  allConversationsMain: getAllConversationsMainReducer,
  mergedAudioProgressIds: checkMergedAudioStatusReducer,
  allTeamInvitations: getAllInvitationsReducer,
  allTeamMembers: getAllTeamMembersReducer,
  allConversationsByUserId: getAllConversationsMainByUserIdReducer,
  salesAnalysisReviewsByUserId: getSalesCallReviewsByUserIdReducer,
  feedbacksOnEntity: getFeedbacksOnEntityReducer,
  orgConfigs: getOrganizationConfigsReducer,
  whitelabelDetails: getWhitelabelDetailsByDomainReducer,
  wlCustomers: getWlCustomersReducer,
  leaderboard: getLeaderboardReducer,
  conversationDetailsById: getConversationByIdReducer,
  allGeneratedPlaybooks: getAllGeneratedPlaybooksReducer,
  allActionItems: getAllTaskItemsReducer,
  croInterviews: getCROInterviewsReducer,
  companyAccounts: getAccountsReducer,
  companyProspects: getCompanyProspectsReducer,
  membersByOrgId: getMembersByOrgIdReducer,
  dealActivities: getAllDealActivitesReducer,
  teamsGroup: getTeamsReducer,
  outreachInvitations: getOutreachInvitationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_REQUEST') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
