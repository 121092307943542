import {
  Box,
  Card,
  Typography,
  Button,
  useTheme,
  IconButton,
  Tooltip,
  Slider,
  FormControlLabel,
  Checkbox,
  Modal,
  TextField,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Circle,
  Close,
  LiveHelp,
  PauseCircle,
  PlayCircle,
  Replay,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { detect } from 'detect-browser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';

const ProfileCompletion = ({
  percentCompleted,
  thickness,
  circleSize,
  circleTextSize,
  fontSize,
  dontShowExtraText,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const browser = detect();
  const { pathname } = useLocation();

  return (
    <Tooltip arrow placement="bottom" title={''}>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={0.5}
        justifyContent={'center'}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            textAlign: 'center',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={percentCompleted}
            size={circleSize || 26}
            thickness={thickness || 5}
            sx={{
              position: 'relative',
              zIndex: 1,
            }}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={thickness || 5}
            size={circleSize || 26}
            sx={{
              position: 'absolute',
              zIndex: 0,
              right: 0,
              '& .MuiCircularProgress-circle': {
                stroke: '#d3d3d3',
                circle: {
                  strokeDashoffset: '0px !important',
                },
              },
            }}
          />
          <Box
            sx={{
              top: 2,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              style={{
                fontSize: circleTextSize || 9,
              }}
            >
              {percentCompleted}%
            </Typography>
          </Box>
        </Box>
        {!dontShowExtraText ? (
          <Typography
            variant="caption"
            color={'text.secondary'}
            sx={{ fontSize: fontSize || 12 }}
          >
            Complete
          </Typography>
        ) : null}
      </Box>
    </Tooltip>
  );
};

ProfileCompletion.propTypes = {
  percentCompleted: PropTypes.number.isRequired,
  circleSize: PropTypes.number,
  circleTextSize: PropTypes.number,
  fontSize: PropTypes.number,
  thickness: PropTypes.number,
  rest: PropTypes.any,
  dontShowExtraText: PropTypes.bool,
};

export default ProfileCompletion;
