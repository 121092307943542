const initialstate = {
  percentCompleted: 0,
};

const candidateProfileCompletionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SET_PROFILE_COMPLETION_PERCENT':
      return Object.assign({}, state, {
        ...state,
        percentCompleted: action.payload,
      });
    default:
      return state;
  }
};

export default candidateProfileCompletionReducer;
