import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getWhitelabelDetailsByDomain = (domain, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_WHITELABEL_DETAILS_BY_DOMAIN' });
    const reqId = uuidv4();
    try {
      const response = await axios.post(
        `/us/org/domain`,
        {
          domain,
        },
        {
          params: {
            'request-id': reqId,
          },
          headers: {
            // Authorization: `Bearer ${token}`,
            // 'current-role': currentRole,
          },
        },
      );
      if (response.status === 200) {
        await dispatch({
          type: 'FETCHING_WHITELABEL_DETAILS_BY_DOMAIN_DONE',
          payload: {
            details: response?.data,
          },
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'FETCHING_WHITELABEL_DETAILS_BY_DOMAIN_FAILED',
          payload: `Failed to get whitelabel details`,
        });
        // dispatch(
        //   showAlert({
        //     message: 'Something went wrong. Please try again',
        //     showCross: true,
        //     title: null,
        //     type: 'error',
        //     autoHideDuration: 2000,
        //     vertical: 'top',
        //     horizontal: 'center',
        //   }),
        // );

        if (typeof errCb === 'function') {
          errCb();
        }
      }
    } catch (e) {
      dispatch({
        type: 'FETCHING_WHITELABEL_DETAILS_BY_DOMAIN_FAILED',
        payload: `Failed to get whitelabel details`,
      });
      // dispatch(
      //   showAlert({
      //     message: 'Something went wrong. Please try again',
      //     showCross: true,
      //     title: null,
      //     type: 'error',
      //     autoHideDuration: 2000,
      //     vertical: 'top',
      //     horizontal: 'center',
      //   }),
      // );
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default getWhitelabelDetailsByDomain;
