import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import getPlans from 'redux/actions/Common/getPlans';
import { capitalizeText } from 'utils';
import { Check, Close } from '@mui/icons-material';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import sendUpgradeRequest from 'redux/actions/Common/sendUpgradeRequest';
import showAlert from 'redux/actions/Common/showAlert';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

const PlanBox = ({
  key,
  plan,
  handleOnClick,
  selectedPlan,
  setShowContactSalesDialog,
}) => {
  const theme = useTheme();

  return (
    <Box
      key={key}
      display={'flex'}
      flexDirection={'column'}
      minWidth={200}
      border={'1px solid #d3d3d3'}
      sx={{
        cursor: 'pointer',
        borderRadius: 2,
      }}
      onClick={handleOnClick}
      // gap={2}
    >
      {/* {selectedPlan?.id === plan?.id && ( */}
      <Button
        variant="outlined"
        size="small"
        color="primary"
        // sx={{
        //   border: '1px solid #f1f1f1',
        //   color: '#000',
        //   background: '#fff',
        //   '&:hover': {
        //     border: '1px solid #f1f1f1',
        //     color: '#fff',
        //     background: theme.palette.primary.main,
        //   },
        // }}
        onClick={() => {
          setShowContactSalesDialog(plan?.id);
        }}
      >
        Contact Support
      </Button>
      {/* )} */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          background:
            selectedPlan?.id === plan?.id
              ? theme.palette.primary.main
              : 'transparent',
          color: selectedPlan?.id === plan?.id ? 'white' : 'black',
          paddingY: 2,
        }}
        gap={2}
      >
        <Typography
          sx={{ fontWeight: 'bold' }}
          variant="body1"
          textAlign={'center'}
        >
          ${plan?.price?.amount || 0}
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant="subtitle2" textAlign={'center'}>
            {plan?.price?.seatsOrCredits || 0}
          </Typography>
          <Typography variant="subtitle2" textAlign={'center'}>
            1 Month
          </Typography>
          <Typography variant="subtitle2" textAlign={'center'}>
            0
          </Typography>
        </Box>
        <Typography
          sx={{ fontWeight: 'bold' }}
          variant="body1"
          textAlign={'center'}
        >
          Credits Consumption
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant="subtitle2" textAlign={'center'}>
            {`${plan?.features?.['call_analysis']?.restrictions?.['number_of_sale_call_recording_analysis']?.['creditRequirement']?.['timeValue']} mins / credit`}
          </Typography>
          <Typography
            textAlign={'center'}
            variant="subtitle2"
          >{`${plan?.features?.['practice_call']?.restrictions?.['duration_of_practice_call']?.['creditRequirement']?.['timeValue']} mins / credit`}</Typography>
          <Typography textAlign={'center'} variant="subtitle2">
            {`${plan?.features?.['ai_playbook_generation']?.restrictions?.['generate_ai_playbook']?.['creditRequirement']?.['creditValue']} credit`}
          </Typography>
          <Typography textAlign={'center'} variant="subtitle2">
            1 credit
          </Typography>
        </Box>
        <Typography
          textAlign={'center'}
          sx={{ fontWeight: 'bold' }}
          variant="body1"
        >
          Additional Features
        </Typography>
        <Typography textAlign={'center'} variant="subtitle2">
          {plan?.planType === 'monthly_commitment'
            ? 'Available'
            : 'Not Available'}
        </Typography>
        <Typography textAlign={'center'} variant="subtitle2">
          {plan?.features?.donna?.restrictions?.[
            'get_response_for_ask_ai_conversation'
          ]?.limit || 0}
        </Typography>
        <Typography textAlign={'center'} variant="subtitle2">
          {plan?.planType === 'monthly_commitment'
            ? 'Available'
            : 'Not Available'}
        </Typography>
      </Box>
    </Box>
  );
};

const Pricing = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const [showContactSalesDialog, setShowContactSalesDialog] = useState(false);

  const [plans, setPlans] = useState([]);
  const [usageBasedPlans, setUsageBasedPlans] = useState([]);
  const [creditBasedPlans, setCreditBasedPlans] = useState([]);
  const [planType, setPlanType] = useState('seat_based');

  const [planGroups, setPlanGroups] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);

  // useEffect(()=>{
  //   if(creditBasedPlans && creditBasedPlans?.length>0){
  //     setPlanGroups(Object.groupBy)
  //   }
  // }, [creditBasedPlans])

  useEffect(() => {
    if (planType === 'seat_based') {
      setUsageBasedPlans(
        plans?.filter((plan) => plan?.price?.pricingScheme === 'seat_based') ||
          [],
      );
    } else {
      setCreditBasedPlans(
        plans?.filter((plan) => plan?.price?.pricingScheme === 'usage_based') ||
          [],
      );
    }
  }, [plans, planType]);

  useEffect(() => {
    dispatch(getUserDetails((data) => {}));
    dispatch(
      getPlans(
        window.location.host !== 'app.qualification.ai' &&
          window.location.host !== 'app.sellingbees.com' &&
          window.location.host !== 'localhost:3000'
          ? window.location.host
          : '',
        (ps) => {
          setPlans(ps);
        },
      ),
    );
  }, []);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md: isLoggedIn
            ? dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn ? (dashboardSidebarExpanded ? '240px' : '80px') : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
      // maxWidth={800}
      p={2}
    >
      <Box
        maxWidth={planType === 'seat_based' ? '90%' : '100%'}
        margin={'0 auto'}
      >
        <Box marginBottom={1}>
          <Box marginBottom={1}>
            <Typography
              sx={{
                // textTransform: 'uppercase',
                fontWeight: 'medium',
                fontSize: 18,
              }}
              color={'primary'}
              align={'center'}
            >
              Plans and Subscriptions
            </Typography>
          </Box>
          <Typography
            sx={{ fontWeight: 700 }}
            variant={'h5'}
            align={'center'}
            gutterBottom
          >
            Startup, SMB or Enterprise, we have a plan for you.
          </Typography>
          <Typography
            variant={'subtitle2'}
            component={'p'}
            color={'text.secondary'}
            align={'center'}
          >
            Flexible plans that can be adapted to your needs.
          </Typography>
          {process.env.REACT_APP_TARGET_ENV === 'local' ||
          process.env.REACT_APP_TARGET_ENV === 'development' ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={1}
            >
              <Typography>Seat Based</Typography>
              <Switch
                color="primary"
                checked={planType === 'usage_based'}
                onChange={() =>
                  setPlanType((prev) =>
                    prev === 'usage_based' ? 'seat_based' : 'usage_based',
                  )
                }
              />
              <Typography>Usage Based</Typography>
            </Box>
          ) : null}
        </Box>
        {planType === 'seat_based' ? (
          <Grid container spacing={isMd ? 2 : 2}>
            {usageBasedPlans.map((plan, i) => (
              <Grid
                item
                container
                // alignItems={'center'}
                xs={12}
                md={12 / usageBasedPlans?.length}
                key={plan?.id}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Card}
                  display={'flex'}
                  flexDirection={'column'}
                  border={0}
                  width={1}
                  position={'relative'}
                  // paddingY={item.isHighlighted && isMd ? 8 : 2}
                >
                  <CardContent
                    sx={{
                      background:
                        plan?.id === userDetails?.custom?.plan?.id
                          ? theme.palette.primary.main
                          : '#fff',
                      color:
                        plan?.id === userDetails?.custom?.plan?.id
                          ? '#fff'
                          : theme.palette.primary.main,
                    }}
                  >
                    <Box
                      marginBottom={4}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <Typography variant={'h6'}>
                        <Box component={'span'} fontWeight={600}>
                          {capitalizeText(plan.title)}
                        </Box>
                      </Typography>
                      {userDetails?.custom?.plan?.id === plan?.id &&
                      !(
                        userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                        userDetails?.plan?.currentPaymentStatus === 'paid'
                      ) ? (
                        <Typography
                          gutterBottom
                          sx={{
                            // background: 'transparent',
                            fontWeight: 'bold',
                            color: theme.palette.warning.dark,
                            // ':hover': {
                            //   color: theme.palette.error.dark,
                            //   background: 'transparent',
                            // },
                          }}
                          variant={'body1'}
                        >
                          Plan Expired
                        </Typography>
                      ) : null}
                      {userDetails?.custom?.plan?.id === plan?.id &&
                      (userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                        userDetails?.currentPaymentStatus === 'paid') ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            cursor: 'default',
                            background: '#fff',
                            color: theme.palette.primary.main,
                            ':hover': {
                              background: '#fff',
                              color: theme.palette.primary.main,
                            },
                          }}
                        >
                          Active Plan
                        </Button>
                      ) : (
                        <>
                          {plan?.title !== 'free' ? (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              sx={{
                                background:
                                  userDetails?.custom?.plan?.id === plan?.id
                                    ? '#fff'
                                    : theme.palette.primary.main,
                                color:
                                  userDetails?.custom?.plan?.id === plan?.id
                                    ? theme.palette.primary.main
                                    : '#fff',
                                ':hover': {
                                  background:
                                    userDetails?.custom?.plan?.id === plan?.id
                                      ? '#fff'
                                      : theme.palette.primary.main,
                                  color:
                                    userDetails?.custom?.plan?.id === plan?.id
                                      ? theme.palette.primary.main
                                      : '#fff',
                                },
                              }}
                              onClick={() => {
                                setShowContactSalesDialog(plan?.id);
                              }}
                            >
                              Contact Support
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              // sx={{ visibility: 'hidden' }}
                              onClick={() => {
                                setShowContactSalesDialog(plan?.id);
                              }}
                            >
                              Contact Support
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                    <Grid container spacing={1}>
                      {Object.values(plan.features || {})
                        .sort((a, b) => {
                          if (a.feature > b.feature) {
                            return 1;
                          } else if (b.feature > a.feature) {
                            return -1;
                          } else {
                            return 0;
                          }
                        })
                        .map((feature, j) => (
                          <Grid item xs={12} key={j}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={2}
                              justifyContent={'space-between'}
                              mb={2}
                            >
                              <Typography
                                component={'p'}
                                sx={{ fontSize: 18, fontWeight: 'bold' }}
                              >
                                {capitalizeText(
                                  feature.feature?.split('_')?.join(' '),
                                ) === 'Practice Call'
                                  ? 'Roleplay'
                                  : capitalizeText(
                                      feature.feature?.split('_')?.join(' '),
                                    )}
                                {feature.feature === 'practice_call' ||
                                feature.feature === 'task'
                                  ? 's'
                                  : null}
                              </Typography>
                            </Box>
                            {feature.feature === 'call_analysis' ? (
                              <Box
                                ml={2}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    {`${
                                      Object.values(
                                        feature?.restrictions || {},
                                      )?.find(
                                        (res) =>
                                          res.restriction ===
                                          'number_of_sale_call_recording_analysis',
                                      )?.unlimited
                                        ? 'Unlimited'
                                        : Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction ===
                                              'number_of_sale_call_recording_analysis',
                                          )?.limit
                                    } analysis / user / month`}
                                  </Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    {`${Math.round(
                                      (Object.values(
                                        feature?.restrictions || {},
                                      )?.find(
                                        (res) =>
                                          res.restriction ===
                                          'duration_of_sale_call_recording_analysis',
                                      )?.unlimited
                                        ? 'Unlimited'
                                        : Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction ===
                                              'duration_of_sale_call_recording_analysis',
                                          )?.limit) / 60000,
                                    )} minutes / file upload`}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : feature.feature === 'practice_call' ? (
                              <Box
                                ml={2}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    {`${
                                      Object.values(
                                        feature?.restrictions || {},
                                      )?.find(
                                        (res) =>
                                          res.restriction ===
                                          'number_of_practice_call',
                                      )?.unlimited
                                        ? 'Unlimited'
                                        : Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction ===
                                              'number_of_practice_call',
                                          )?.limit
                                    } calls / user / month`}
                                  </Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    {`${(Object.values(
                                      feature?.restrictions || {},
                                    )?.find(
                                      (res) =>
                                        res.restriction ===
                                        'duration_of_practice_call',
                                    )?.unlimited
                                      ? 'Unlimited'
                                      : Object.values(
                                          feature?.restrictions || {},
                                        )?.find(
                                          (res) =>
                                            res.restriction ===
                                            'duration_of_practice_call',
                                        )?.limit) / 60000} minutes / call`}
                                  </Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    {`${
                                      Object.values(
                                        feature?.restrictions || {},
                                      )?.find(
                                        (res) =>
                                          res.restriction === 'standard_bots',
                                      )?.unlimited
                                        ? 'Unlimited'
                                        : Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction ===
                                              'standard_bots',
                                          )?.limit
                                    } standard bots`}
                                  </Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  {Object.values(
                                    feature?.restrictions || {},
                                  )?.find(
                                    (res) => res.restriction === 'custom_bots',
                                  )?.unlimited ||
                                  Object.values(
                                    feature?.restrictions || {},
                                  )?.find(
                                    (res) => res.restriction === 'custom_bots',
                                  )?.limit > 0 ? (
                                    <Check />
                                  ) : (
                                    <Close />
                                  )}
                                  <Typography>
                                    {`${
                                      Object.values(
                                        feature?.restrictions || {},
                                      )?.find(
                                        (res) =>
                                          res.restriction === 'custom_bots',
                                      )?.unlimited
                                        ? 'Unlimited'
                                        : Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction === 'custom_bots',
                                          )?.limit
                                    } customized bots`}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : feature.feature === 'donna' ? (
                              <Box
                                ml={2}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    Your own ChatGPT like AI Assistant
                                  </Typography>
                                </Box>
                              </Box>
                            ) : feature.feature === 'task' ? (
                              <Box
                                ml={2}
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>
                                    Automatic task creation from calls
                                  </Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Check />
                                  <Typography>Task management</Typography>
                                </Box>
                              </Box>
                            ) : null}
                          </Grid>
                        ))}
                      <Grid item xs={12}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                          justifyContent={'space-between'}
                          mb={2}
                        >
                          <Typography
                            component={'p'}
                            sx={{ fontSize: 18, fontWeight: 'bold' }}
                          >
                            Team Account
                          </Typography>
                        </Box>
                        <Box
                          ml={2}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>
                              Centralized Playbook and Bot Management
                            </Typography>
                          </Box>
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>
                              Performance Tracking Dashboard
                            </Typography>
                          </Box>
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>Roleplays Scheduler</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                          justifyContent={'space-between'}
                          mb={2}
                        >
                          <Typography
                            component={'p'}
                            sx={{ fontSize: 18, fontWeight: 'bold' }}
                          >
                            Integrations
                          </Typography>
                        </Box>
                        <Box
                          ml={2}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>
                              Integrations with CRMs like HubSpot, LeadSquared
                              and others
                            </Typography>
                          </Box>
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>Calendar Integration</Typography>
                          </Box>
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>
                              Auto-analysis of meeting recordings
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                          justifyContent={'space-between'}
                          mb={2}
                        >
                          <Typography
                            component={'p'}
                            sx={{ fontSize: 18, fontWeight: 'bold' }}
                          >
                            Dedicated Support
                          </Typography>
                        </Box>
                        <Box
                          ml={2}
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                        >
                          <Box display={'flex'} gap={1}>
                            {plan?.title === 'free' ? <Close /> : <Check />}
                            <Typography>
                              Support for customizations, automations,
                              onboarding and training
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Grid>
            ))}
            <Grid
              item
              container
              // alignItems={'center'}
              xs={12}
              md={4}
              data-aos={'fade-up'}
              data-aos-delay={usageBasedPlans?.length * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            ></Grid>
            <Grid
              item
              container
              // alignItems={'center'}
              xs={12}
              md={4}
              data-aos={'fade-up'}
              data-aos-delay={usageBasedPlans?.length * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                component={Card}
                display={'flex'}
                flexDirection={'column'}
                border={0}
                width={1}
                position={'relative'}
                // paddingY={item.isHighlighted && isMd ? 8 : 2}
              >
                <CardContent
                  sx={{
                    background:
                      plans
                        ?.map((plan) => plan?.id)
                        ?.includes(userDetails?.custom?.plan?.id) ||
                      !userDetails?.custom?.plan?.id
                        ? '#fff'
                        : theme.palette.primary.main,
                    color:
                      plans
                        ?.map((plan) => plan?.id)
                        ?.includes(userDetails?.custom?.plan?.id) ||
                      !userDetails?.custom?.plan?.id
                        ? theme.palette.primary.main
                        : '#fff',
                  }}
                >
                  <Box
                    marginBottom={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography variant={'h6'} gutterBottom>
                      <Box component={'span'} fontWeight={600}>
                        Custom Plan
                      </Box>
                    </Typography>
                    {/* <Typography varirant={'body1'}>
                    {capitalizeText(userDetails?.custom?.plan?.title)}
                  </Typography> */}
                    {!(
                      plans
                        ?.map((plan) => plan?.id)
                        ?.includes(userDetails?.custom?.plan?.id) ||
                      !userDetails?.custom?.plan?.id
                    ) &&
                    !(
                      userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                      userDetails?.plan?.currentPaymentStatus === 'paid'
                    ) ? (
                      <Typography
                        gutterBottom
                        sx={{
                          // background: 'transparent',
                          fontWeight: 'bold',
                          color: theme.palette.warning.dark,
                          // ':hover': {
                          //   color: theme.palette.error.dark,
                          //   background: 'transparent',
                          // },
                        }}
                        variant={'body1'}
                      >
                        Plan Expired
                      </Typography>
                    ) : null}
                    {plans
                      ?.map((plan) => plan?.id)
                      ?.includes(userDetails?.custom?.plan?.id) ||
                    !userDetails?.custom?.plan?.id ? (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setShowContactSalesDialog('custom');
                        }}
                      >
                        Contact Support
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          cursor: 'default',
                          background: '#fff',
                          color: theme.palette.primary.main,
                          ':hover': {
                            background: '#fff',
                            color: theme.palette.primary.main,
                          },
                        }}
                      >
                        Active Plan
                      </Button>
                    )}
                    {/* {plans
                    ?.map((plan) => plan?.id)
                    ?.includes(userDetails?.custom?.plan?.id) ||
                  !userDetails?.custom?.plan?.id ? null : (
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        color: '#fff',
                        textDecoration: 'underline',
                        ':hover': {
                          color: theme.palette.text.primary,
                          textDecoration: 'underline',
                        },
                      }}
                      onClick={() => {
                        history.push('/account/settings');
                      }}
                    >
                      See Details
                    </Button>
                  )} */}
                  </Box>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} border={'1px solid #d3d3d3'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                minWidth={300}
                borderRight={'1px solid #d3d3d3'}
                paddingLeft={2}
                gap={1}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{
                    visibility: 'hidden',
                  }}
                  // onClick={() => {
                  //   setShowContactSalesDialog(plan?.id);
                  // }}
                >
                  Contact Support
                </Button>
                <Typography
                  sx={{ fontWeight: 'bold', visibility: 'hidden', padding: 1 }}
                  variant="body1"
                >
                  Fee
                </Typography>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    Fee
                  </Typography>
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant="subtitle2">
                      Credits Available
                    </Typography>
                    <Typography variant="subtitle2">
                      Credits validity / refresh interval
                    </Typography>
                    <Typography variant="subtitle2">
                      Unused credits rollover cap
                    </Typography>
                  </Box>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    Credits consumption
                  </Typography>
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant="subtitle2">
                      Call Analysis / re-analysis and Meet bot
                    </Typography>
                    <Typography variant="subtitle2">Roleplay</Typography>
                    <Typography variant="subtitle2">
                      AI Playbook generation
                    </Typography>
                    <Typography variant="subtitle2">
                      Addtional 10 Ask Donna questions
                    </Typography>{' '}
                  </Box>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body1">
                    Additional Features
                  </Typography>
                  <Typography variant="subtitle2">Custom Bot</Typography>
                  <Typography variant="subtitle2">
                    Ask Donna Questions Included
                  </Typography>
                  <Typography variant="subtitle2">
                    Custom Call Scoring
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  maxWidth: 'calc(100vw - 500px)',
                  overflowX: 'scroll',
                  '&::-webkit-scrollbar': {
                    height: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  paddingX={2}
                  sx={{
                    borderRight: '1px solid #d3d3d3',
                    // borderLeft: '1px solid #d3d3d3',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      // border: `1px solid ${theme.palette.primary.main}`,
                      width: 1,
                      textAlign: 'center',
                      padding: 1,
                    }}
                    variant="body1"
                    color={'primary'}
                  >
                    {'Pay as you go'}
                  </Typography>
                  <Box display={'flex'} gap={2}>
                    {creditBasedPlans
                      ?.filter((plan) => plan?.planType === 'pay_as_you_go')
                      ?.sort((a, b) => a?.price?.amount - b?.price?.amount)
                      ?.map((plan) => {
                        return (
                          <PlanBox
                            key={plan?.id}
                            plan={plan}
                            handleOnClick={() => setSelectedPlan(plan)}
                            selectedPlan={selectedPlan}
                            setShowContactSalesDialog={
                              setShowContactSalesDialog
                            }
                          />
                        );
                      })}
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  paddingX={2}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      // border: `1px solid ${theme.palette.primary.main}`,
                      // borderLeft: '1px solid #d3d3d3',
                      width: 1,
                      textAlign: 'center',
                      padding: 1,
                    }}
                    variant="body1"
                    color={'primary'}
                  >
                    {'Monthly Commitment'}
                  </Typography>
                  <Box display={'flex'} gap={2}>
                    {creditBasedPlans
                      ?.filter(
                        (plan) => plan?.planType === 'monthly_commitment',
                      )
                      ?.sort((a, b) => a?.price?.amount - b?.price?.amount)
                      ?.map((plan) => {
                        return (
                          <PlanBox
                            key={plan?.id}
                            plan={plan}
                            handleOnClick={() => setSelectedPlan(plan)}
                            selectedPlan={selectedPlan}
                            setShowContactSalesDialog={
                              setShowContactSalesDialog
                            }
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              component={Card}
              display={'flex'}
              flexDirection={'column'}
              border={0}
              // width={1}
              position={'relative'}
              alignItems={'center'}
              alignSelf={'center'}
              sx={{ width: '250px' }}
            >
              <CardContent
                sx={{
                  background:
                    plans
                      ?.map((plan) => plan?.id)
                      ?.includes(userDetails?.custom?.plan?.id) ||
                    !userDetails?.custom?.plan?.id
                      ? '#fff'
                      : theme.palette.primary.main,
                  color:
                    plans
                      ?.map((plan) => plan?.id)
                      ?.includes(userDetails?.custom?.plan?.id) ||
                    !userDetails?.custom?.plan?.id
                      ? theme.palette.primary.main
                      : '#fff',
                }}
              >
                <Box
                  marginBottom={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography variant={'h6'} gutterBottom>
                    <Box component={'span'} fontWeight={600}>
                      Custom Plan
                    </Box>
                  </Typography>
                  {!(
                    plans
                      ?.map((plan) => plan?.id)
                      ?.includes(userDetails?.custom?.plan?.id) ||
                    !userDetails?.custom?.plan?.id
                  ) &&
                  !(
                    userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                    userDetails?.plan?.currentPaymentStatus === 'paid'
                  ) ? (
                    <Typography
                      gutterBottom
                      sx={{
                        // background: 'transparent',
                        fontWeight: 'bold',
                        color: theme.palette.warning.dark,
                        // ':hover': {
                        //   color: theme.palette.error.dark,
                        //   background: 'transparent',
                        // },
                      }}
                      variant={'body1'}
                    >
                      Plan Expired
                    </Typography>
                  ) : null}
                  {plans
                    ?.map((plan) => plan?.id)
                    ?.includes(userDetails?.custom?.plan?.id) ||
                  !userDetails?.custom?.plan?.id ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        setShowContactSalesDialog('custom');
                      }}
                    >
                      Contact Support
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        cursor: 'default',
                        background: '#fff',
                        color: theme.palette.primary.main,
                        ':hover': {
                          background: '#fff',
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Active Plan
                    </Button>
                  )}
                  {/* {plans
              ?.map((plan) => plan?.id)
              ?.includes(userDetails?.custom?.plan?.id) ||
            !userDetails?.custom?.plan?.id ? null : (
              <Button
                variant="text"
                size="small"
                sx={{
                  color: '#fff',
                  textDecoration: 'underline',
                  ':hover': {
                    color: theme.palette.text.primary,
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => {
                  history.push('/account/settings');
                }}
              >
                See Details
              </Button>
            )} */}
                </Box>
              </CardContent>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={Boolean(showContactSalesDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Sending your request to the support team.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `confirm_delete_dialog_close`);
              setShowContactSalesDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `delete_conversation`);
              dispatch(
                sendUpgradeRequest(showContactSalesDialog, (error) => {
                  if (error) {
                    dispatch(
                      showAlert({
                        message:
                          'Oops! Something went wrong. Please try again later',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  } else {
                    setShowContactSalesDialog(null);
                    dispatch(
                      showAlert({
                        message:
                          'We have received your request and will get in touch shortly.',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  }
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Pricing;

PlanBox.propTypes = {
  key: PropTypes.string,
  plan: PropTypes.object,
  handleOnClick: PropTypes.func,
  selectedPlan: PropTypes.object,
  setShowContactSalesDialog: PropTypes.func,
};
