const initialstate = {
  checkingSession: null,
  isLoggedIn: null,
};

const firebaseCheckSessionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FIREBASE_CHECKING_SESSION':
      return Object.assign({}, state, {
        checkingSession: true,
      });
    case 'FIREBASE_LOGGED_IN':
      return Object.assign({}, state, {
        checkingSession: false,
        isLoggedIn: true,
      });
    case 'FIREBASE_NOT_LOGGED_IN':
      return Object.assign({}, state, {
        checkingSession: false,
        isLoggedIn: false,
      });
    default:
      return state;
  }
};

export default firebaseCheckSessionReducer;
