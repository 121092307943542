import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const getSalesCallReviewByToken = (reportToken, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_REPORT_BY_TOKEN' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/cs/sale-reviews/t/${reportToken}`,
            {
              params: {
                'request-id': reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_REPORT_BY_TOKEN_DONE',
              payload: response?.data,
            });
            await dispatch({
              type: 'FETCHING_CONTEST_REPORT_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_REPORT_BY_TOKEN_FAILED',
              payload: `Failed to fetch report`,
            });
            if (typeof errCb === 'function') {
              errCb();
            }
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_REPORT_BY_TOKEN_FAILED',
            payload: `Failed to fetch report`,
          });
          if (typeof errCb === 'function') {
            errCb();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'FETCHING_REPORT_BY_TOKEN_FAILED',
        payload: `Failed to fetch report`,
      });
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default getSalesCallReviewByToken;
