const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  invitations: null,
  emptyList: null,
  lastSeen: null,
};

const getAllInvitationsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_ALL_TEAM_INVITATIONS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_ALL_TEAM_INVITATIONS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_ALL_TEAM_INVITATIONS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        invitations: action.payload.invitations,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_TEAM_INVITATIONS_IN_ALL_INVITATIONS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        invitations: Array.isArray(action.payload.invitations)
          ? state.invitations
            ? state.invitations.concat(action.payload.invitations)
            : action.payload.invitations
          : state.invitations,
        emptyList:
          Array.isArray(action.payload.invitations) &&
          (action.payload.invitations.length === 0 ||
            action.payload.invitations.length % 25 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    // case 'UPDATE_SALES_CALL_ANALYSIS': {
    //   const updatedSalesReviews = state.invitations.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return action.payload.data;
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     invitations: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORES': {
    //   const updatedSalesReviews = state.invitations.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           [`${action.payload.scoreKey}Score`]: action.payload.scoreValue,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     invitations: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    // case 'UPDATE_SALES_CALL_ANALYSIS_SCORE_STATUS': {
    //   const updatedSalesReviews = state.invitations.map((sr) => {
    //     if (sr?.id === action.payload.id) {
    //       return {
    //         ...sr,
    //         analysis: {
    //           ...sr.analysis,
    //           scoreStatus: action.payload.scoreStatus,
    //         },
    //       };
    //     } else {
    //       return sr;
    //     }
    //   });
    //   return Object.assign({}, state, {
    //     fetching: false,
    //     fetchingPagination: false,
    //     fetchingSuccessful: true,
    //     fetchingFailed: false,
    //     invitations: updatedSalesReviews,
    //     lastTime: state.lastTime,
    //     emptyList: state.emptyList,
    //     error: state.error,
    //     errorText: state.errorText,
    //   });
    // }
    case 'FETCHING_ALL_TEAM_INVITATIONS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        invitations: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_ALL_TEAM_INVITATIONS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getAllInvitationsReducer;
