const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  feedbacks: null,
  emptyList: null,
  lastSeen: null,
};

const getFeedbacksOnEntityReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_FEEDBACKS_ON_ENTITY':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_FEEDBACKS_ON_ENTITY_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_FEEDBACKS_ON_ENTITY_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        feedbacks: action.payload.feedbacks,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_FEEDBACKS_TO_ALL_FEEDBACKS_ON_ENTITY':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        feedbacks: Array.isArray(action.payload.feedbacks)
          ? state.feedbacks
            ? state.feedbacks.concat(action.payload.feedbacks)
            : action.payload.feedbacks
          : state.feedbacks,
        emptyList:
          Array.isArray(action.payload.feedbacks) &&
          (action.payload.feedbacks.length === 0 ||
            action.payload.feedbacks.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'FETCHING_FEEDBACKS_ON_ENTITY_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        feedbacks: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_FEEDBACKS_ON_ENTITY_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getFeedbacksOnEntityReducer;
