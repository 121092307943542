import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const deleteTrainingData = (docId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'DELETING_TRAINING_DATA' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.delete(
            `/cs/expert-bots/train/doc/${docId}`,
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'DELETING_TRAINING_DATA_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'DELETING_TRAINING_DATA_FAILED',
              payload: `Failed to delete training data`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'DELETING_TRAINING_DATA_FAILED',
            payload: `Failed to delete training data`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'DELETING_TRAINING_DATA_FAILED',
        payload: `Failed to delete training data`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default deleteTrainingData;
