const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  customers: null,
  emptyList: null,
  lastSeen: null,
};

const getWlCustomersReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_WHITELABEL_CUSTOMERS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_WHITELABEL_CUSTOMERS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_WHITELABEL_CUSTOMERS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        customers: action.payload,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_FEEDBACKS_TO_ALL_FEEDBACKS_ON_ENTITY':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        customers: Array.isArray(action.payload.customers)
          ? state.customers
            ? state.customers.concat(action.payload.customers)
            : action.payload.customers
          : state.customers,
        emptyList:
          Array.isArray(action.payload.customers) &&
          (action.payload.customers.length === 0 ||
            action.payload.customers.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'FETCHING_WHITELABEL_CUSTOMERS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        customers: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_WHITELABEL_CUSTOMERS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getWlCustomersReducer;
