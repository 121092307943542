const initialstate = {
  dashboardSidebarExpanded: true,
  showCompetitionDetailsOnTopbar: false,
  showBackButtonOnTopbar: false,
  currentContextData: null,
  currentNavigationLevel: 1,
  showPlanExpired: false,
  showOnboardingDialog: false,
  onBoardingStep: 0,
  onBoardingCompletionPercentage: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
  },
  showFeatureRestricted: false,
  questionFrameworkSteps: [],
  activeQuestionFrameworkStep: 0,
  resetQuestionFrameworkStep: false,
};

const uiReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPDATE_UI_STATE':
      return Object.assign({}, state, {
        ...state,
        [action.payload.key]: action.payload.value,
      });
    case 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE':
      return Object.assign({}, state, {
        ...state,
        onBoardingCompletionPercentage: {
          ...state.onBoardingCompletionPercentage,
          [action.payload.key]: action.payload.value,
        },
      });
    default:
      return state;
  }
};

export default uiReducer;
