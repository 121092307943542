import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const bookDemo = (requestDto, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'BOOKING_DEMO' });
    try {
      const reqId = uuidv4();
      const response = await axios.post(`/us/demo-request`, requestDto, {
        params: {
          'request-id': reqId,
        },
      });
      if (response.status === 200) {
        await dispatch({
          type: 'BOOKING_DEMO_DONE',
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'BOOKING_DEMO_FAILED',
          payload: `Failed to book the demo`,
        });
        if (typeof errCb === 'function') {
          errCb();
        }
      }
    } catch (e) {
      dispatch({
        type: 'BOOKING_DEMO_FAILED',
        payload: `Failed to book the demo`,
      });
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default bookDemo;
