import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Add, MoreVert } from '@mui/icons-material';
import moment from 'moment';

const DealActivity = ({
  activity,
  setShowAddNewActivity,
  setShowConfirmActivityDeleteDialog,
  editMode,
}) => {
  const theme = useTheme();

  const [anchorElActivity, setAnchorElActivity] = useState(null);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(null);

  const handleActivityMenuClose = () => {
    setAnchorElActivity(null);
    setCurrentActivityIndex(null);
  };

  return (
    <Card
      sx={{
        padding: 2,
        position: 'relative',
        paddingBottom: 6,
      }}
    >
      <Typography variant="body1" fontWeight={'bold'} color={'text.secondary'}>
        {activity?.type}
      </Typography>
      {activity?.type === 'Downloaded Content' ||
      activity?.type === 'Website Visits' ? (
        <Typography variant="subtitle2" color={'text.secondary'}>
          {activity?.type === 'Downloaded Content'
            ? 'Downloaded Contents:'
            : 'Number of visits:'}{' '}
          {activity?.type === 'Downloaded Content'
            ? activity?.downloadedContents
            : activity?.type === 'Website Visits'
            ? activity?.numberOfVisits
            : '-'}
        </Typography>
      ) : (
        <Typography variant="subtitle2" color={'text.secondary'}>
          {moment(Number(activity?.date)).format('DD MMMM YYYY')}
        </Typography>
      )}
      {activity?.type === 'Downloaded Content' ||
      activity?.type === 'Website Visits' ? null : (
        <Typography variant="subtitle2" color={'text.secondary'}>
          {moment(Number(activity?.time)).format('hh:mm A')}
        </Typography>
      )}
      <Typography variant="subtitle2" color={'text.secondary'} mt={2}>
        {activity?.customFields?.members?.map((m) => m?.name)?.join(', ')}
      </Typography>
      <Typography
        variant="caption"
        color={'text.secondary'}
        sx={{
          position: 'absolute',
          bottom: 18,
          right: 18,
        }}
      >
        {activity?.createdOn
          ? `Created On
                                  ${moment(activity?.createOn).format(
                                    'DD MMM YYYY',
                                  )}`
          : '-'}
      </Typography>
      {editMode ? (
        <IconButton
          size="small"
          onClick={(e) => {
            setAnchorElActivity(e.target);
            setCurrentActivityIndex(activity?.id);
          }}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <MoreVert />
        </IconButton>
      ) : null}
      <Menu
        id="simple-menu"
        anchorEl={anchorElActivity}
        keepMounted
        open={
          Boolean(anchorElActivity) && currentActivityIndex === activity?.id
        }
        onClose={handleActivityMenuClose}
        sx={{
          '& .MuiMenu-paper': {
            boxShadow: '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setShowAddNewActivity(activity);
            handleActivityMenuClose();
          }}
        >
          Update Activity
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowConfirmActivityDeleteDialog(activity?.id);
            setAnchorElActivity(null);
          }}
        >
          Delete Activity
        </MenuItem>
      </Menu>
    </Card>
  );
};

DealActivity.propTypes = {
  activity: PropTypes.object,
  setShowAddNewActivity: PropTypes.func,
  setShowConfirmActivityDeleteDialog: PropTypes.func,
  editMode: PropTypes.bool,
};

export default DealActivity;
