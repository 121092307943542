const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  jobs: null,
  emptyList: null,
  lastTime: null,
};

const fetchAllJobsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'GETTING_ALL_EMPLOYER_JOBS':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'GETTING_ALL_EMPLOYER_JOBS_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'GETTING_ALL_EMPLOYER_JOBS_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        jobs: action.payload.jobs,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'ADD_JOBS_TO_ALL_JOBS':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        jobs: Array.isArray(action.payload.jobs)
          ? state.jobs
            ? state.jobs.concat(action.payload.jobs)
            : action.payload.jobs
          : state.jobs,
        emptyList:
          Array.isArray(action.payload.jobs) &&
          (action.payload.jobs.length === 0 ||
            action.payload.jobs.length % 10 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        error: false,
        errorText: '',
      });
    case 'GETTING_ALL_EMPLOYER_JOBS_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        jobs: [],
        emptyList: false,
        lastTime: '',
      });

    case 'GETTING_ALL_EMPLOYER_JOBS_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default fetchAllJobsReducer;
