import axios from '../../../utils/axios';
import { v4 as uuidv4 } from 'uuid';

const uploadProfilePicture = (payload, signedUrl, userId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPLOADING_PROFILE_PICTURE' });
    try {
      const gcpUploadResponse = await axios.put(signedUrl, payload, {
        headers: {
          'Content-Type': payload.type,
        },
      });
      if (gcpUploadResponse.status === 200) {
        const reqIdForConfirm = uuidv4();
        try {
          const confirmUploadResponse = await axios.put(
            `/us/users/${userId}/profile-picture`,
            null,
            {
              params: {
                // 'request-id': reqIdForConfirm,
                // forCompany: payload.forCompany,
              },
              //   headers: {
              //     Authorization: `Bearer ${token}`,
              //   },
            },
          );
          // console.log(confirmUploadResponse);
          if (confirmUploadResponse.status === 200) {
            await dispatch({ type: 'PROFILE_PICTURE_UPLOADED' });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'PROFILE_PICTURE_UPLOAD_FAILED',
              payload: 'Failed to upload picture',
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          // console.log(e);
          dispatch({
            type: 'PROFILE_PICTURE_UPLOAD_FAILED',
            payload: 'Failed to upload picture',
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
      }
    } catch (e) {
      // console.log(e);
      dispatch({
        type: 'PROFILE_PICTURE_UPLOAD_FAILED',
        payload: 'Failed to upload picture',
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default uploadProfilePicture;
