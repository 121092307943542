import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Chip,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Add, MoreVert } from '@mui/icons-material';
import moment from 'moment';

const ProspectMember = ({
  member,
  setShowAddNewMember,
  setShowConfirmDeleteProspectDialog,
  setShowMakeAsPrimaryDialog,
  editMode,
}) => {
  const theme = useTheme();

  const [anchorElProspect, setAnchorElProspect] = useState(null);
  const [currentProspectIndex, setCurrentProspectIndex] = useState(null);

  const handleProspectMenuClose = () => {
    setAnchorElProspect(null);
    setCurrentProspectIndex(null);
  };

  return (
    <Card
      sx={{
        padding: 2,
        position: 'relative',
        paddingBottom: 8,
      }}
    >
      <Typography variant="body1" fontWeight={'bold'} color={'text.secondary'}>
        {member?.name}
      </Typography>
      <Typography
        variant="body2"
        // fontWeight={'bold'}
        color={'text.secondary'}
      >
        {member?.contacts?.find((obj) => obj.type === 'email')?.value}
      </Typography>
      <Typography variant="subtitle2" color={'text.secondary'}>
        {member?.orgLevel || ''}
      </Typography>
      <Typography variant="subtitle2" color={'text.secondary'}>
        {member?.designation}
      </Typography>
      <Typography variant="subtitle2" color={'text.secondary'}>
        {member?.links?.linkedin || ''}
      </Typography>
      {member?.primaryContact ? (
        <Chip
          label={'Primary Contact'}
          size="small"
          color="primary"
          sx={{ mt: 1, mr: 1 }}
        />
      ) : null}
      {member?.keyStackHolder ? (
        <Chip
          label={'Stakeholder'}
          size="small"
          color="primary"
          sx={{ mt: 1 }}
        />
      ) : null}
      <Typography
        variant="caption"
        color={'text.secondary'}
        sx={{
          position: 'absolute',
          bottom: 18,
          right: 18,
        }}
      >
        Created On {moment(member?.createOn).format('DD MMM YYYY')}
      </Typography>
      {editMode ? (
        <IconButton
          size="small"
          onClick={(e) => {
            setAnchorElProspect(e.target);
            setCurrentProspectIndex(member?.id);
          }}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          <MoreVert />
        </IconButton>
      ) : null}
      <Menu
        id="simple-menu"
        anchorEl={anchorElProspect}
        keepMounted
        open={Boolean(anchorElProspect) && currentProspectIndex === member?.id}
        onClose={handleProspectMenuClose}
        sx={{
          '& .MuiMenu-paper': {
            boxShadow: '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
          },
        }}
      >
        <MenuItem
          // disabled
          onClick={() => {
            setShowMakeAsPrimaryDialog(member);
            handleProspectMenuClose();
          }}
        >
          Make as Primary Contact
        </MenuItem>
        <MenuItem
          // disabled
          onClick={() => {
            setShowAddNewMember(member);
            handleProspectMenuClose();
          }}
        >
          Update Prospect Details
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowConfirmDeleteProspectDialog(member?.id);
            setAnchorElProspect(null);
          }}
        >
          Delete Prospect
        </MenuItem>
      </Menu>
    </Card>
  );
};

ProspectMember.propTypes = {
  member: PropTypes.object,
  setShowAddNewMember: PropTypes.func,
  setShowConfirmDeleteProspectDialog: PropTypes.func,
  setShowMakeAsPrimaryDialog: PropTypes.func,
  editMode: PropTypes.bool,
};

export default ProspectMember;
