import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const updateActionItemSeenStatus = (actionItemIds, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_ACTION_ITEM_SEEN_STATUS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(
            `/us/action-items/seen`,
            actionItemIds,
            {
              params: {
                'request-id': reqId,
                seen: payload,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'application/json',
                // 'current-role': currentRole,
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_ACTION_ITEM_SEEN_STATUS_DONE',
            });
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            dispatch({
              type: 'UPDATING_ACTION_ITEM_SEEN_STATUS_FAILED',
              payload: `Failed to update action items`,
            });
            if (typeof callback === 'function') {
              callback(true);
            }
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_ACTION_ITEM_SEEN_STATUS_FAILED',
            payload: `Failed to update action items`,
          });
          if (typeof callback === 'function') {
            callback(true);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'UPDATING_ACTION_ITEM_SEEN_STATUS_FAILED',
        payload: `Failed to update action items`,
      });
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };
};

export default updateActionItemSeenStatus;
