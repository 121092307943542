const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  participantsDetails: null,
  competitionDetails: null,
  config: null,
  emptyList: null,
  lastTime: null,
};

const getParticipantsByCompetitionIdReducer = (
  state = initialstate,
  action,
) => {
  switch (action.type) {
    case 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        participantsDetails: action.payload.participations,
        competitionDetails: action.payload.competitionDetails,
        config: action.payload.config,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        emptyList: false,
      });
    case 'UPDATE_PARTICIPANT_OF_ALL_PARTICIPANTS': {
      const updatedParticipantsDetails = [...state.participantsDetails];
      const index = state.participantsDetails.findIndex(
        (pd) => pd.participationId === action.payload.participationId,
      );
      if (updatedParticipantsDetails?.[index]?.bookmarked !== undefined) {
        updatedParticipantsDetails[index]['bookmarked'] = action.payload.value;
      } else {
        updatedParticipantsDetails[index]['bookmarked'] = action.payload.value;
      }
      return Object.assign({}, state, {
        ...state,
        participantsDetails: updatedParticipantsDetails,
      });
    }

    case 'ADD_PARTICIPANTS_TO_ALL_PARTICIPANTS_COMPETITION_BY_ID':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        participantsDetails: Array.isArray(action.payload.participations)
          ? state.participantsDetails
            ? state.participantsDetails.concat(action.payload.participations)
            : action.payload.participations
          : state.participantsDetails,
        emptyList:
          Array.isArray(action.payload.participations) &&
          (action.payload.participations.length === 0 ||
            action.payload.participations.length % 100 > 0)
            ? true
            : false,
        lastTime: action.payload.lastTime
          ? action.payload.lastTime
          : state.lastTime,
        competitionDetails: state.competitionDetails,
        error: false,
        errorText: '',
      });
    case 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        fetchingPagination: false,
        error: true,
        errorText: action.payload,
        participantsDetails: null,
        competitionDetails: null,
        emptyList: false,
        lastTime: '',
      });
    case 'FETCHING_PARTICIPANTS_COMPETITION_BY_ID_WITH_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getParticipantsByCompetitionIdReducer;
