import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const createSalesAnalysisThread = (
  payload,
  audioData,
  callback,
  progressCallback,
  errCallback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_SALES_ANALYSIS_THREAD' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/cs/sale-review/upload`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': currentRole,
            },
          });
          if (response.status === 200) {
            const audioId = response?.data?.fileId;
            const signedUrl = response?.data?.url;
            if (signedUrl) {
              try {
                progressCallback(audioId);
                const gcpResponse = await axios.put(signedUrl, audioData, {
                  headers: {
                    'Content-Type': audioData?.type?.split(';')?.[0],
                  },
                  onUploadProgress: (progress) => {
                    dispatch({
                      type: 'SET_UPLOADING_AUDIO_FOR',
                      payload: {
                        audioId: audioId,
                        show: true,
                      },
                    });
                    dispatch({
                      type: 'SET_UPLOAD_AUDIO_PROGRESS',
                      payload: {
                        audioId: audioId,
                        percentage: Math.round(
                          (progress.loaded * 100) / progress.total,
                        ),
                      },
                    });
                  },
                });
                if (gcpResponse.status === 200) {
                  setTimeout(() => {
                    dispatch({
                      type: 'SET_UPLOADING_AUDIO_FOR',
                      payload: {
                        audioId: audioId,
                        show: false,
                      },
                    });
                  }, 2000);
                  dispatch({
                    type: 'SET_UPLOAD_AUDIO_PROGRESS',
                    payload: { audioId: audioId, percentage: 100 },
                  });
                  const reqIdForConfirm = uuidv4();
                  try {
                    const confirmVideoUploadResponse = await axios.get(
                      `/cs/sale-review/${audioId}/confirm`,
                      {
                        params: {
                          'request-id': reqIdForConfirm,
                          // crop: true,
                        },
                        headers: {
                          Authorization: `Bearer ${token}`,
                          // 'Content-Type': 'text/plain',
                        },
                      },
                    );
                    if (confirmVideoUploadResponse.status === 200) {
                      await dispatch({
                        type: 'CREATING_SALES_ANALYSIS_THREAD_DONE',
                      });
                      if (typeof callback === 'function') {
                        callback(response.data);
                      }
                    } else {
                      dispatch({
                        type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
                      });
                      if (typeof errCallback === 'function') {
                        errCallback();
                      }
                    }
                  } catch (e) {
                    dispatch({
                      type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
                      payload: 'Failed to upload video',
                    });
                    if (typeof errCallback === 'function') {
                      errCallback();
                    }
                  }
                }
              } catch (e) {
                dispatch({
                  type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
                  payload: 'Failed to upload video',
                });
                if (typeof errCallback === 'function') {
                  errCallback();
                }
              }
            } else {
              await dispatch({
                type: 'CREATING_SALES_ANALYSIS_THREAD_DONE',
              });
              if (typeof callback === 'function') {
                callback();
              }
            }
          } else {
            dispatch({
              type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
              payload: `Failed to create sales analysis thread`,
            });
            if (typeof errCallback === 'function') {
              errCallback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
            payload: `Failed to create sales analysis thread`,
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_SALES_ANALYSIS_THREAD_FAILED',
        payload: `Failed to create sales analysis thread`,
      });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default createSalesAnalysisThread;
