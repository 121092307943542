import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Typography,
  Modal,
  IconButton,
  Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Circle, ExitToApp, Home, Close, Bolt } from '@mui/icons-material';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { useHistory } from 'react-router-dom';
import registerForCompetition from 'redux/actions/Candidate/registerForCompetition';
import { useLocation } from 'react-router-dom';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { NavItem } from './components';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';

// import { NavItem } from './components';

const Topbar = ({
  onSidebarOpen,
  //  pages,
  colorInvert = false,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const competitionType =
    location?.pathname?.split('/')?.[1] === 'contests'
      ? 'real_competition'
      : location?.pathname?.split('/')?.[1] === 'practice'
      ? 'practice_competition'
      : null;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [anchorEl, setAnchorEl] = useState(null);
  const competitionDetailsById = useSelector(
    (state) => state.competitionDetailsById,
  );
  const { userDetails } = useSelector((state) => state.userDetails);
  const { competitionDetails, fetching } = competitionDetailsById;
  const {
    showCompetitionDetailsOnTopbar,
    dashboardSidebarExpanded,
  } = useSelector((state) => state.uiStates);
  const [showRegistrationClosed, setShowRegistrationClosed] = useState(false);
  const [showContestInactive, setShowContestInactive] = useState(false);

  const openRegistrationClosed = Boolean(showRegistrationClosed);
  const openContestInactive = Boolean(showContestInactive);
  const { domainDetails } = useSelector((state) => state.whitelabelDetails);

  const { pathname } = useLocation();
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleProfileMenuClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          height={{ xs: 40, md: 50 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.textInLogo
                ? domainDetails?.logo || '/light_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
        {!showCompetitionDetailsOnTopbar &&
        (isLoggedIn ||
          (!isLoggedIn &&
            !dashboardSidebarExpanded &&
            pathname === '/practice/contests')) &&
        !domainDetails?.textInLogo ? (
          <Box ml={2}>
            <Typography color="text.primary" variant="h6" fontWeight={'600'}>
              {domainDetails?.appTitle || 'Qualification AI'}
            </Typography>
            <Divider
              sx={{ border: `0.5px solid ${theme.palette.text.primary}` }}
            />
            <Typography variant="caption" color="text.primary">
              {domainDetails?.appSubTitle || 'Makes Practice Perfect'}
            </Typography>
          </Box>
        ) : null}
        {showCompetitionDetailsOnTopbar ? (
          <Box ml={4}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 'bold' }}
                color={'text.primary'}
              >
                {competitionDetails?.title}
              </Typography>
              {competitionDetails?.live ? (
                <Box
                  sx={{
                    pr: 1,
                    pl: 1,
                    pt: 0.5,
                    pb: 0.5,
                    borderRadius: 1,
                  }}
                  display="flex"
                  justifyContent={'center'}
                  alignItems="center"
                >
                  <Circle
                    sx={{
                      fontSize: 12,
                      color: theme.palette.error.dark,
                    }}
                  />
                  <Typography
                    variant="caption"
                    // color={theme.palette.background.default}
                    ml={1}
                  >
                    Live
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Typography ml={1} variant="body2" color={'text.secondary'}>
              Hosted by {competitionDetails?.host?.name || 'Qualification AI'}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {isLoggedIn && userInfo ? (
          <Box>
            <Button
              data-trackid={'open_profile_menu'}
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleTrackEvent(e, 'open_profile_menu');
                handleProfileMenuClick(e);
              }}
            >
              <Avatar
                alt={userInfo?.firstName?.toUpperCase()}
                src={userDetails?.profilePicture?.url}
              >
                {userInfo?.firstName?.[0]?.toUpperCase()}
              </Avatar>
            </Button>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock={true}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              style={{
                marginTop: '15px',
              }}
            >
              <MenuItem
                data-trackid={'profile_menu_home_option'}
                style={{}}
                onClick={(e) => {
                  handleTrackEvent(e, 'profile_menu_home_option');
                  history.push('/');
                }}
              >
                <Home style={{ marginRight: '10px' }} />
                Home
              </MenuItem>
              <MenuItem
                data-trackid={'profile_menu_logout_option'}
                style={{}}
                onClick={(e) => {
                  handleTrackEvent(e, 'profile_menu_logout_option');
                  handleLogoutClick();
                }}
              >
                <ExitToApp style={{ marginRight: '10px' }} />
                Logout
              </MenuItem>
              <MenuItem
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  pointerEvents: 'none',
                }}
              >
                {userInfo?.email}
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <>
            {/* <Box marginLeft={2}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/contests'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                }}
                data-trackid={'move_to_contests_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={location.pathname === '/contests' ? null : '/contests'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_contests_page');
                }}
              >
                <Typography>Contests</Typography>
              </Button>
            </Box> */}
            {/* <Box marginLeft={2} marginRight={1} position={'relative'}>
              <NavItem
                title={'Practice'}
                id={'practice-options'}
                items={[
                  { title: 'Practice Interview', href: '/practice/interview' },
                  { title: 'Practice Pitch', href: '/practice/pitch' },
                  { title: 'Practice Script', href: '/practice/script' },
                  { title: 'Practice Contests', href: '/practice/contests' },
                ]}
                colorInvert={colorInvert}
                href={'/practice'}
              />
              <Chip
                // variant="outlined"
                size="small"
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Bolt sx={{ fontSize: 12 }}></Bolt>
                    <Typography variant="caption" sx={{ fontSize: 10 }}>
                      New
                    </Typography>
                  </Box>
                }
                color="primary"
                sx={{
                  position: 'absolute',
                  right: -15,
                  top: -20,
                  padding: '1px',
                }}
              ></Chip>
            </Box> */}
            {/* <Box marginLeft={1}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/jobs'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                }}
                data-trackid={'move_to_jobs_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={location.pathname === '/jobs' ? null : '/jobs'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_jobs_page');
                }}
              >
                <Typography>Jobs</Typography>
              </Button>
            </Box> */}
            {/* <Box marginLeft={2}>
              <Button
                sx={{
                  borderBottom:
                    location.pathname === '/pitch-practice'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  paddingRight: 3,
                }}
                data-trackid={'move_to_pitch_practice'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={
                  location.pathname === '/pitch-practice'
                    ? null
                    : '/pitch-practice'
                }
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_pitch_practice');
                }}
              >
                <Typography>Pitch Practice</Typography>
                <Chip
                  size="small"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Bolt sx={{ fontSize: 12 }}></Bolt>
                      <Typography variant="caption" sx={{ fontSize: 10 }}>
                        New
                      </Typography>
                    </Box>
                  }
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -15,
                    padding: '1px',
                  }}
                ></Chip>
              </Button>
            </Box> */}
            {!showCompetitionDetailsOnTopbar &&
            pathname !== '/pitch-practice' ? (
              <>
                {/* <Box marginLeft={2}>
                  <Button
                    data-trackid={'CTA_for_employers'}
                    size={'small'}
                    variant="outlined"
                    fullWidth
                    component="a"
                    href="/auth/signup?bookdemo=true"
                    onClick={(e) => {
                      handleTrackEvent(e, 'CTA_for_employers');
                    }}
                  >
                    For Employers
                  </Button>
                </Box> */}
                <Box marginLeft={2}>
                  <Button
                    data-trackid={'CTA_sign_in'}
                    size={'small'}
                    variant="outlined"
                    fullWidth
                    component="a"
                    href="/auth/signin"
                    onClick={(e) => {
                      handleTrackEvent(e, 'CTA_sign_in');
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
                <Box marginLeft={2}>
                  <Button
                    data-trackid={'CTA_sign_up'}
                    size={'small'}
                    variant="contained"
                    fullWidth
                    component="a"
                    href="/auth/signup"
                    onClick={(e) => {
                      handleTrackEvent(e, 'CTA_sign_up');
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </>
            ) : null}
          </>
        )}
        {showCompetitionDetailsOnTopbar ? (
          <Box display={'flex'} alignItems={'center'} ml={2}>
            {isLoggedIn &&
            competitionDetails?.competitionType === 'practice_competition' &&
            competitionDetails?.participation?.totalAttempts !==
              competitionDetails?.maxNumberOfAttempt &&
            competitionDetails?.participationStatus !==
              'completed_on_time_out' &&
            competitionDetails?.participationStatus !== 'created' &&
            competitionDetails?.participationStatus !== 'un_registered' ? (
              <Button
                data-trackid={'retry_contest'}
                color={'primary'}
                variant="text"
                size="medium"
                component="a"
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  handleTrackEvent(e, 'retry_contest');
                  if (isLoggedIn) {
                    dispatch(
                      registerForCompetition(
                        competitionDetails?.competitionId,
                        'candidate',
                        'individual',
                        () => {
                          setTimeout(() => {
                            history.push(
                              `/start/${
                                competitionType === 'real_competition'
                                  ? 'contests'
                                  : competitionType === 'practice_competition'
                                  ? 'practice'
                                  : 'contests'
                              }/${competitionDetails?.slug}`,
                            );
                          }, 500);
                        },
                      ),
                    );
                  } else {
                    history.push(
                      `/auth/signin?redirectTo=${
                        competitionDetails?.competitionType ===
                        'real_competition'
                          ? 'contests'
                          : competitionDetails?.competitionType ===
                            'practice_competition'
                          ? 'practice'
                          : ''
                      }&redirectToId=${competitionDetails?.slug}`,
                    );
                  }
                }}
              >
                Retry
              </Button>
            ) : null}
            <Button
              data-trackid={`${
                userInfo?.designations?.includes('employer')
                  ? 'View'
                  : competitionDetails?.participationStatus === 'created'
                  ? 'Start'
                  : competitionDetails?.participationStatus === 'completed'
                  ? 'Completed'
                  : competitionDetails?.participationStatus === 'in_process'
                  ? 'Continue'
                  : competitionDetails?.participationStatus ===
                    'completed_on_time_out'
                  ? competitionDetails?.participation?.totalAttempts ===
                    competitionDetails?.maxNumberOfAttempt
                    ? 'Entry Submitted'
                    : 'Retry'
                  : 'Register'
              }_${competitionType?.split('_')[0]}_contest`}
              color={
                competitionDetails?.participationStatus === 'created'
                  ? 'primary'
                  : competitionDetails?.participationStatus === 'completed'
                  ? 'success'
                  : competitionDetails?.participationStatus === 'in_process'
                  ? 'warning'
                  : 'primary'
              }
              variant="contained"
              size="medium"
              style={{
                cursor:
                  competitionDetails?.participationStatus === 'completed' ||
                  competitionDetails?.participation?.totalAttempts ===
                    competitionDetails?.maxNumberOfAttempt
                    ? 'default'
                    : 'pointer',
              }}
              onClick={(e) => {
                handleTrackEvent(
                  e,
                  `${
                    userInfo?.designations?.includes('employer')
                      ? 'View'
                      : competitionDetails?.participationStatus === 'created'
                      ? 'Start'
                      : competitionDetails?.participationStatus === 'completed'
                      ? 'Completed'
                      : competitionDetails?.participationStatus === 'in_process'
                      ? 'Continue'
                      : competitionDetails?.participationStatus ===
                        'completed_on_time_out'
                      ? competitionDetails?.participation?.totalAttempts ===
                        competitionDetails?.maxNumberOfAttempt
                        ? 'Entry Submitted'
                        : 'Retry'
                      : 'Register'
                  }_${competitionType?.split('_')[0]}_contest`,
                );
                if (userInfo?.designations?.includes('employer')) {
                  history.push(
                    `/contests/${competitionDetails?.slug}/participants`,
                  );
                  return;
                }
                if (
                  competitionDetails?.participationStatus === 'completed' ||
                  (competitionDetails?.participation?.totalAttempts ===
                    competitionDetails?.maxNumberOfAttempt &&
                    competitionDetails?.competitionType ===
                      'practice_competition')
                ) {
                  return;
                } else {
                  if (isLoggedIn) {
                    if (
                      !competitionDetails?.participationStatus ||
                      competitionDetails?.participationStatus ===
                        'un_registered' ||
                      competitionDetails?.participationStatus ===
                        'completed_on_time_out'
                    ) {
                      dispatch(
                        registerForCompetition(
                          competitionDetails?.competitionId,
                          'candidate',
                          'individual',
                          () => {
                            setTimeout(() => {
                              history.push(
                                `/start/${
                                  competitionType === 'real_competition'
                                    ? 'contests'
                                    : competitionType === 'practice_competition'
                                    ? 'practice'
                                    : 'contests'
                                }/${competitionDetails?.slug}`,
                              );
                            }, 500);
                          },

                          (error) => {
                            if (
                              error ===
                              'action : create_participation not allowed, reason : entries count reached limit'
                            ) {
                              setShowRegistrationClosed(true);
                            } else if (
                              error ===
                              'action : create_participation not allowed, reason : participation can only be created for active competition'
                            ) {
                              setShowContestInactive(true);
                            }
                          },
                        ),
                      );
                    } else {
                      history.push(
                        `/start/${
                          competitionType === 'real_competition'
                            ? 'contests'
                            : competitionType === 'practice_competition'
                            ? 'practice'
                            : 'contests'
                        }/${competitionDetails?.slug}`,
                      );
                    }
                  } else {
                    history.push(
                      `/auth/signup?redirectTo=${
                        competitionType === 'real_competition'
                          ? 'contests'
                          : competitionType === 'practice_competition'
                          ? 'practice'
                          : 'contests'
                      }&redirectToId=${competitionDetails?.slug}`,
                    );
                  }
                }
              }}
            >
              {userInfo?.designations?.includes('employer')
                ? 'View'
                : competitionDetails?.participationStatus === 'created'
                ? 'Start'
                : competitionDetails?.participationStatus === 'completed'
                ? 'Completed'
                : competitionDetails?.participationStatus === 'in_process'
                ? 'Continue'
                : competitionDetails?.participationStatus ===
                  'completed_on_time_out'
                ? competitionDetails?.participation?.totalAttempts ===
                  competitionDetails?.maxNumberOfAttempt
                  ? 'Entry Submitted'
                  : 'Retry'
                : competitionDetails?.competitionType === 'real_competition' ||
                  competitionDetails?.competitionType === 'private_competition'
                ? 'Register'
                : 'Start'}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          data-trackid={'toggle_sidebar'}
          onClick={(e) => {
            handleTrackEvent(e, 'toggle_sidebar');
            onSidebarOpen();
          }}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <Modal
        open={openContestInactive}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowContestInactive(false);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Contest is not live.</Typography>
            <IconButton
              data-trackid={'close_contest_inactive_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_contest_inactive_dialog');
                setShowContestInactive(false);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Oops! This contest has ended.
            </Typography>
            <Typography variant="body1" mt={1}>
              You can try participating in other live contests.
            </Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'contest_inactive_dialog_closed'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'contest_inactive_dialog_closed');
                setShowRegistrationClosed(false);
                history.push('/contests');
              }}
            >
              Go to Contests
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openRegistrationClosed}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowRegistrationClosed(false);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Registration Closed!</Typography>
            <IconButton
              data-trackid={'close_save_to_calendar_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_save_to_calendar_dialog');
                setShowRegistrationClosed(false);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Oops, this contest is not accepting new entries at this point as
              it is packed to capacity. We’ve added you to the waitlist and will
              let you know as soon as we can accommodate you.
            </Typography>
            <Typography variant="body1" mt={1}>
              In the meantime you can hone your skills with one of our practice
              contests.
            </Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'registration_closed_dialog_close'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'registration_closed_dialog_close');
                setShowRegistrationClosed(false);
                history.push('/practice');
              }}
            >
              Go to Practice
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
