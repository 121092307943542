let actionCodeBaseUrl = '';
if (process.env.REACT_APP_TARGET_ENV === 'local') {
  actionCodeBaseUrl = 'http://localhost:3000/';
}

if (process.env.REACT_APP_TARGET_ENV === 'development') {
  actionCodeBaseUrl = 'https://app.sellingbees.com/';
}

if (process.env.REACT_APP_TARGET_ENV === 'production') {
  actionCodeBaseUrl = 'https://app.qualification.ai/';
}

export default actionCodeBaseUrl;
