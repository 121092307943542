import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from 'redux/actions/Common/trackEvent';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import logError from 'redux/actions/Common/logError';
import {
  Avatar,
  Chip,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ArrowBack,
  Bolt,
  HelpOutline,
  Logout,
  Menu,
  RecordVoiceOver,
  VoiceChat,
} from '@mui/icons-material';

import NavItem from './components/NavItem';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';

const SidebarNav = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { practiceType } = useParams();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();
  const browser = detect();
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [showToolDescription, setShowToolDescription] = useState(true);
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  // const { mode } = theme.palette;

  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleLogoutClick = () => {
    dispatch(
      firebaseLogout(() => {
        dispatch(firebaseCheckSession());
        history.go();
      }),
    );
  };

  return (
    <Box>
      <Box
        width={1}
        paddingX={2}
        paddingY={1}
        display={'flex'}
        flexDirection={dashboardSidebarExpanded ? 'column' : 'column'}
        // justifyContent={'center'}
        alignItems={'start'}
        gap={2}
      >
        <Menu
          data-trackid={'toggle_sidebar_expand'}
          onClick={(e) => {
            handleTrackEvent(e, 'toggle_sidebar_expand');
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'dashboardSidebarExpanded',
                value: !dashboardSidebarExpanded,
              },
            });
          }}
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
        />
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            display={'flex'}
            component="a"
            href={
              domainDetails?.organizationWebsite
                ? `https://${domainDetails?.organizationWebsite}`
                : 'https://qualification.ai'
            }
            title={domainDetails?.appTitle || 'Qualification AI'}
            height={{ xs: 40, md: 50 }}
          >
            <Box
              component={'img'}
              src={
                theme.palette.mode === 'dark'
                  ? '/dark_theme_logo.svg'
                  : domainDetails?.textInLogo
                  ? domainDetails?.logo || '/light_theme_logo.svg'
                  : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
              }
              height={1}
              width={1}
            />
          </Box>
          {dashboardSidebarExpanded && !domainDetails?.textInLogo ? (
            <Box ml={2}>
              <Typography color="text.primary" variant="h6" fontWeight={'600'}>
                {domainDetails?.appTitle || 'Qualification AI'}
              </Typography>
              <Divider
                sx={{ border: `0.5px solid ${theme.palette.text.primary}` }}
              />
              <Typography variant="caption" color="text.primary">
                {domainDetails?.appSubTitle || 'Makes Practice Perfect'}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box paddingX={1} paddingY={1}>
        {pathname.startsWith('/practice') ? (
          <>
            <Box marginTop={1}>
              <Button
                size="small"
                component={'a'}
                startIcon={<ArrowBack />}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  history.push('/');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography fontWeight={400} color={'text.primary'}>
                    Go Back
                  </Typography>
                ) : null}
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                startIcon={<img src={'/video-interviews.png'} width={'20px'} />}
                size="small"
                component={'a'}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  color:
                    practiceType === 'interview'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    practiceType === 'interview'
                      ? alpha(theme.palette.primary.main, 0.1)
                      : 'transparent',
                  fontWeight: practiceType === 'interview' ? 600 : 400,
                }}
                onClick={() => {
                  history.push('/practice/interview');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography fontWeight={400} color={'text.primary'}>
                    Practice Interview
                  </Typography>
                ) : null}
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                startIcon={
                  <VoiceChat
                  // style={{
                  //   color: new RegExp(`/pitch-practice`, 'i').test(pathname)
                  //     ? theme.palette.text.primary
                  //     : theme.palette.text.secondary,
                  // }}
                  />
                }
                size="small"
                component={'a'}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  color:
                    practiceType === 'pitch'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    practiceType === 'pitch'
                      ? alpha(theme.palette.primary.main, 0.1)
                      : 'transparent',
                  fontWeight: practiceType === 'pitch' ? 600 : 400,
                }}
                onClick={() => {
                  history.push('/practice/pitch');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography fontWeight={400} color={'text.primary'}>
                    Practice Pitch
                  </Typography>
                ) : null}
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                startIcon={<RecordVoiceOver />}
                size="small"
                component={'a'}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  color:
                    practiceType === 'script'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    practiceType === 'script'
                      ? alpha(theme.palette.primary.main, 0.1)
                      : 'transparent',
                  fontWeight: practiceType === 'script' ? 600 : 400,
                }}
                onClick={() => {
                  history.push('/practice/script');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography fontWeight={400} color={'text.primary'}>
                    Practice Script
                  </Typography>
                ) : null}
              </Button>
            </Box>
            <Box marginTop={1}>
              <Button
                startIcon={<img src={'/practice1.svg'} width={'20px'} />}
                size="small"
                component={'a'}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  color:
                    practiceType === 'contests'
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  backgroundColor:
                    practiceType === 'contests'
                      ? alpha(theme.palette.primary.main, 0.1)
                      : 'transparent',
                  fontWeight: practiceType === 'contests' ? 600 : 400,
                }}
                onClick={() => {
                  history.push('/practice/contests');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography fontWeight={400} color={'text.primary'}>
                    Practice Contest
                  </Typography>
                ) : null}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box marginTop={2}>
              <Button
                sx={{
                  borderRight:
                    pathname === '/contests'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  justifyContent: 'flex-start',
                }}
                data-trackid={'move_to_contests_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={pathname === '/contests' ? null : '/contests'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_contests_page');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography>Contests</Typography>
                ) : null}
              </Button>
            </Box>
            <Box marginTop={2}>
              <Button
                sx={{
                  borderRight:
                    pathname === '/practice'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  justifyContent: 'flex-start',
                }}
                data-trackid={'move_to_practice_page'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={pathname === '/practice' ? null : '/practice'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_practice_page');
                }}
              >
                {dashboardSidebarExpanded ? (
                  <Typography>Practice</Typography>
                ) : null}
              </Button>
            </Box>
            {/* <Box marginTop={2}>
              <Button
                sx={{
                  borderRight:
                    pathname === '/pitch-practice'
                      ? `2px solid ${theme.palette.primary.main}`
                      : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  paddingRight: 3,
                  justifyContent: 'flex-start',
                }}
                data-trackid={'move_to_pitch_practice'}
                size={'small'}
                variant="text"
                fullWidth
                component="a"
                href={pathname === '/pitch-practice' ? null : '/pitch-practice'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_pitch_practice');
                }}
              >
                <Typography>Pitch Practice</Typography>
                <Chip
                  size="small"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Bolt sx={{ fontSize: 12 }}></Bolt>
                      <Typography variant="caption" sx={{ fontSize: 10 }}>
                        New
                      </Typography>
                    </Box>
                  }
                  color="primary"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: -15,
                    padding: '1px',
                  }}
                ></Chip>
              </Button>
            </Box> */}
          </>
        )}
        {!isLoggedIn && dashboardSidebarExpanded ? (
          <Box marginTop={2}>
            <Button
              data-trackid={'cta_sign_in_sign_up'}
              size={'small'}
              variant="outlined"
              fullWidth
              component="a"
              href="/auth/signin"
              onClick={(e) => {
                handleTrackEvent(e, 'cta_sign_in_sign_up');
              }}
            >
              Sign In / Sign Up
            </Button>
          </Box>
        ) : null}
        {pathname.startsWith('/practice') ? (
          <Box marginTop={2}>
            <Button
              sx={{
                borderRight:
                  pathname === '/contests'
                    ? `2px solid ${theme.palette.primary.main}`
                    : 'none',
              }}
              data-trackid={'move_to_contests_page'}
              size={'small'}
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              href={pathname === '/contests' ? null : '/contests'}
              onClick={(e) => {
                handleTrackEvent(e, 'move_to_contests_page');
              }}
              startIcon={
                <img
                  src="/contest.svg"
                  width={'20px'}
                  style={{ filter: 'invert(100)' }}
                />
              }
            >
              {dashboardSidebarExpanded ? (
                <Typography>Live Contests</Typography>
              ) : null}
            </Button>
          </Box>
        ) : null}
        <Box
          mt={2}
          sx={{
            maxHeight: '280px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '2px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            padding: 1,
          }}
        >
          {pathname.startsWith('/practice') &&
          showToolDescription &&
          dashboardSidebarExpanded ? (
            practiceType === 'interview' ? (
              <>
                <Typography variant="body1">
                  Practice your interview skills with our AI Coach. Just like a
                  real interview, it will ask you a question, you answer on
                  camera and the AI will analyze your response and give you
                  instant feedback.
                </Typography>
                <Typography variant="body1" mt={1}>
                  Gain practical experience and boost your confidence for
                  interview success.
                </Typography>
              </>
            ) : practiceType === 'pitch' ? (
              <Typography variant="body1">
                Polish your pitch by delivering it on camera and receiving
                instant feedback from our AI. Our advanced technology provides
                precise advice on eliminating fillers, minimizing repetitions,
                and maintaining an optimal pace, allowing you to craft the
                perfect pitch.
              </Typography>
            ) : practiceType === 'script' ? (
              <Typography variant="body1">
                Master your sales call with our AI Coach. Utilize our
                teleprompter to read your script aloud and receive instant
                feedback on your pace, clarity, and fluency. Enhance your
                delivery and ensure a compelling performance that leaves a
                lasting impression.
              </Typography>
            ) : practiceType === 'contests' ? (
              <Typography variant="body1">
                Elevate your sales prowess through our cutting-edge practice
                contests. These contests comprehensively evaluate your pitch,
                product knowledge, and customer understanding. Engage in
                simulated contests that provide valuable preparation
                opportunities, allowing you to refine your skills and excel in
                competitive environments. When you are ready, participate in
                employer hosted live contests and land multiple job offers.
              </Typography>
            ) : null
          ) : null}
        </Box>
        {pathname.startsWith('/practice') && dashboardSidebarExpanded ? (
          <HelpOutline
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowToolDescription((pre) => !pre);
            }}
          />
        ) : null}
        {/* <Box marginTop={2}>
          <Typography variant="body1" color={'text.primary'}>
            Record yourself, like you would pitch to your prospect and we will
            give you our feedback on it.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
