const initialstate = {
  loggingIn: null,
  loginSuccessful: null,
  loginFail: null,
  data: null,
  error: null,
  errorText: null,
};

const firebaseSigninReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FIREBASE_LOGGING_IN':
      return Object.assign({}, state, {
        loggingIn: true,
      });
    case 'FIREBASE_LOGIN_SUCCESSFUL':
      return Object.assign({}, state, {
        loggingIn: false,
        loginSuccessful: true,
        loginFail: false,
        data: action.payload,
        error: false,
        errorText: '',
      });
    case 'FIREBASE_LOGIN_FAIL':
      return Object.assign({}, state, {
        loggingIn: false,
        loginSuccessful: false,
        loginFail: true,
        data: null,
        error: true,
        errorText: action.payload,
      });
    default:
      return state;
  }
};

export default firebaseSigninReducer;
