import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Button, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import AlertPopup from 'components/AlertPopup';
import showAlert from 'redux/actions/Common/showAlert';
import hideAlert from 'redux/actions/Common/hiderAlert';
import { Redirect } from 'react-router-dom';

const Home = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const alertState = useSelector((state) => state.alertState);

  useEffect(() => {
    // dispatch(
    //   showAlert({
    //     message: 'This is alert coming from custom dispatch action',
    //     showCross: true,
    //     title: null,
    //     type: 'success',
    //     autoHideDuration: 2000,
    //     vertical: 'top',
    //     horizontal: 'center',
    //   }),
    // );
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'column' }}
      position={'relative'}
      justifyContent="center"
      alignItems={'center'}
    >
      <Redirect to={'/auth/signin'} />
      {/* <Typography
        variant="h4"
        component={'h4'}
        align={isMd ? 'left' : 'center'}
        sx={{ fontWeight: 700 }}
      >
        Selling Bees
      </Typography>
      <Button
        size="large"
        variant="contained"
        onClick={() => {
          dispatch(
            firebaseLogout(() => {
              dispatch(firebaseCheckSession());
            }),
          );
        }}
      >
        Logout
      </Button> */}
    </Box>
  );
};

export default Home;
