import {
  Box,
  Typography,
  Button,
  useTheme,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Check } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import trackEvent from 'redux/actions/Common/trackEvent';
import { detect } from 'detect-browser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logError from 'redux/actions/Common/logError';

const Mcqs = ({
  submittingResponse,
  setSubmittingResponse,
  mcqs,
  activeMCQIndex,
  setActiveMCQIndex,
  submitResponse,
  experiment,
  previewMode,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mcqAnswers, setMcqAnswers] = useState({});
  const mcqOptionLabel = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const browser = detect();
  const { pathname } = useLocation();
  // console.log(mcqs);
  const { currentQuestionIndex } = useSelector(
    (state) => state.competitionSession,
  );

  const handleTrackEvent = (e, type) => {
    // console.log(e.target.dataset);
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  return (
    <Box>
      <Stepper
        activeStep={activeMCQIndex}
        // connector={<QontoConnector />}
        orientation="horizontal"
      >
        {mcqs &&
          mcqs.length > 0 &&
          mcqs.map((mcq, index) => {
            return (
              <Step
                key={mcq?.questionId}
                disabled={true}
                // completed={index < currentQuestionIndex - 1}
              >
                <StepButton disabled>
                  <StepLabel
                    StepIconComponent={({
                      active,
                      completed,
                      className,
                      ...rest
                    }) => (
                      <Box
                        className={className}
                        sx={{
                          backgroundColor: completed
                            ? theme.palette.primary.main
                            : active
                            ? theme.palette.primary.main
                            : theme.palette.background.level1,
                          width: 40,
                          height: 40,
                          display: 'flex',
                          borderRadius: '50%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: completed
                            ? '#fff'
                            : active
                            ? '#fff'
                            : theme.palette.primary.main,
                        }}
                      >
                        {completed ? (
                          <Check />
                        ) : (
                          <Typography variant="body2">{index + 1}</Typography>
                        )}
                      </Box>
                    )}
                  ></StepLabel>
                </StepButton>
              </Step>
            );
          })}
      </Stepper>

      <Box mt={8}>
        <Typography variant="h6" fontWeight={'bold'}>
          Question{' '}
          {activeMCQIndex > 8
            ? `${activeMCQIndex + 1}`
            : `0${activeMCQIndex + 1}`}
        </Typography>
        <Typography variant="body1" mt={2} width={{ md: 0.7, sm: 1 }}>
          {mcqs?.[activeMCQIndex]?.question}
        </Typography>
        <Typography variant="h6" fontWeight={'bold'} mt={4}>
          Options
        </Typography>
        {Object.values(mcqs?.[activeMCQIndex]?.options || {})?.map(
          (option, index) => {
            // console.log(option);
            return (
              <Box
                key={index}
                sx={{
                  background:
                    mcqAnswers?.[activeMCQIndex] === index
                      ? theme.palette.primary.main
                      : theme.palette.background.default,
                  cursor: 'pointer',
                  ':hover': {
                    background:
                      mcqAnswers?.[activeMCQIndex] === index
                        ? theme.palette.primary.main
                        : 'rgba(29,156,211,0.1)',
                  },
                  color:
                    mcqAnswers?.[activeMCQIndex] === index
                      ? '#fff'
                      : theme.palette.text.primary,
                }}
                width={{ md: 0.5, sm: 1 }}
                p={2}
                borderRadius={1}
                mt={2}
                data-trackid={`select_mcq_option_${index + 1}`}
                onClick={(e) => {
                  handleTrackEvent(e, `select_mcq_option_${index + 1}`);
                  setMcqAnswers({
                    ...mcqAnswers,
                    [activeMCQIndex]: index,
                  });
                }}
              >
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {mcqOptionLabel[index]}
                    {'). '}
                  </span>
                  {option}
                </Typography>
              </Box>
            );
          },
        )}
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={{ md: 0.5, sm: 1 }}
        >
          <Button
            data-trackid={'move_to_previous_mcq'}
            variant="contained"
            color="primary"
            size="large"
            disabled={activeMCQIndex === 0}
            onClick={(e) => {
              handleTrackEvent(e, 'move_to_previous_mcq');
              setActiveMCQIndex(activeMCQIndex - 1);
            }}
          >
            Previous
          </Button>
          <Tooltip
            title={
              mcqAnswers?.[activeMCQIndex] === undefined
                ? 'Please select an option'
                : ''
            }
            arrow
            placement="top"
          >
            <Box>
              <Button
                data-trackid={
                  activeMCQIndex === mcqs.length - 1
                    ? 'submit_mcqs'
                    : 'move_to_next_mcq'
                }
                variant="contained"
                color="primary"
                size="large"
                onClick={(e) => {
                  handleTrackEvent(
                    e,
                    activeMCQIndex === mcqs.length - 1
                      ? 'submit_mcqs'
                      : 'move_to_next_mcq',
                  );
                  if (experiment || previewMode) {
                    if (activeMCQIndex === mcqs.length - 1) {
                      dispatch({
                        type: 'UPDATE_COMPETITION_SESSION_DATA',
                        payload: {
                          key: 'currentQuestionIndex',
                          value: currentQuestionIndex + 1,
                        },
                      });
                      setSubmittingResponse(false);
                      dispatch({
                        type: 'UPDATE_COMPETITION_SESSION_DATA',
                        payload: {
                          key: 'showSectionIntroduction',
                          value: true,
                        },
                      });
                      setActiveMCQIndex(0);
                      if (currentQuestionIndex === 1) {
                        dispatch({
                          type:
                            'UPDATE_COMPETITION_DETAILS_PRODUCT_KNOWLEDGE_COMPLETED',
                          payload: true,
                        });
                      }
                    } else {
                      setActiveMCQIndex(activeMCQIndex + 1);
                    }
                  } else {
                    submitResponse(
                      null,
                      'mcq',
                      mcqAnswers?.[activeMCQIndex],
                      () => {},
                    );
                  }
                }}
                disabled={
                  mcqAnswers?.[activeMCQIndex] === undefined ||
                  submittingResponse
                }
              >
                {activeMCQIndex === mcqs.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

Mcqs.propTypes = {
  // item: PropTypes.object.isRequired,
  submittingResponse: PropTypes.bool.isRequired,
  setSubmittingResponse: PropTypes.func.isRequired,
  mcqs: PropTypes.array.isRequired,
  activeMCQIndex: PropTypes.number,
  setActiveMCQIndex: PropTypes.func,
  submitResponse: PropTypes.func,
  experiment: PropTypes.bool,
  previewMode: PropTypes.bool,
};

export default Mcqs;
