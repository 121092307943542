import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
import showAlert from './showAlert';

const getSalesCallReviews = (payload, callback) => {
  return async (dispatch) => {
    if (payload?.lastSeen || payload?.lastTime) {
      dispatch({ type: 'FETCHING_ALL_SALES_CALL_REVIEWS_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_ALL_SALES_CALL_REVIEWS' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/cs/sale-reviews/search/v2`,
            payload,
            {
              params: {
                'request-id': reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                    // 'current-role': currentRole,
                    // 'x-api-version': 'v2',
                  }
                : {},
            },
          );
          if (response.status === 200) {
            if (payload?.lastSeen || payload?.lastTime) {
              await dispatch({
                type: 'ADD_SALES_CALL_REVIEW_TO_ALL_REVIEWS',
                payload: {
                  analysisReviews: response?.data?.saleReviews,
                  lastSeen: response?.data?.last_seen,
                  lastTime: response?.data?.last_seen_time,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_ALL_SALES_CALL_REVIEWS_DONE',
                payload: {
                  analysisReviews: response?.data?.saleReviews,
                  lastSeen: response?.data?.last_seen,
                  lastTime: response?.data?.last_seen_time,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data?.saleReviews);
            }
          } else {
            dispatch({
              type: 'FETCHING_ALL_SALES_CALL_REVIEWS_FAILED',
              payload: 'Failed to fetch analysis reviews',
            });
            dispatch(
              showAlert({
                message: 'Oops! Something went wrong. Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_ALL_SALES_CALL_REVIEWS_FAILED',
            payload: 'Failed to fetch analysis reviews',
          });
          dispatch(
            showAlert({
              message: 'Oops! Something went wrong. Please try again.',
              showCross: true,
              title: null,
              type: 'error',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_ALL_SALES_CALL_REVIEWS_FAILED',
        payload: 'Failed to fetch analysis reviews',
      });
      dispatch(
        showAlert({
          message: 'Oops! Something went wrong. Please try again.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };
};

export default getSalesCallReviews;
