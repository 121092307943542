const initialstate = {
  validating: null,
  done: null,
  failed: null,
  email: null,
  firstName: null,
  lastName: null,
  joined: null,
  error: null,
  errorText: null,
};

const validatePrcInvitationTokenReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'VALIDATING_PRC_TOKEN':
      return Object.assign({}, state, {
        validating: true,
      });
    case 'VALIDATING_PRC_TOKEN_DONE':
      return Object.assign({}, state, {
        validating: false,
        done: true,
        failed: false,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        joined: action.payload.joined,
        errorText: '',
        error: false,
      });
    case 'VALIDATING_PRC_TOKEN_FAILED':
      return Object.assign({}, state, {
        validating: false,
        failed: true,
        done: false,
        email: null,
        firstName: null,
        lastName: null,
        joined: null,
        errorText: action.payload,
        error: true,
      });
    default:
      return state;
  }
};

export default validatePrcInvitationTokenReducer;
