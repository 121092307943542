import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import trackEvent from 'redux/actions/Common/trackEvent';
import { detect } from 'detect-browser';
import logError from 'redux/actions/Common/logError';

const NavItem = ({ title, items, href, startIcon, action }) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  return (
    <Box mr={2}>
      <Tooltip
        title={dashboardSidebarExpanded ? '' : title}
        arrow
        placement="top"
      >
        <Box
          data-trackid={`open_${title}_bottombar`}
          sx={{
            padding: '8px 8px',
            textAlign: 'center',
            background:
              pathname === href
                ? theme.palette.background.level1
                : 'transparent',
            borderTop:
              pathname === href
                ? `2px solid ${theme.palette.primary.light}`
                : 'none',
            // marginBottom: 1,
            cursor: 'pointer',
            ':hover': {
              background: theme.palette.background.level1,
            },
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
          }}
          onClick={(e) => {
            handleTrackEvent(e, `open_${title}_bottombar`);
            if (href) {
              history.push(href);
            } else {
              action();
            }
          }}
        >
          {typeof startIcon === 'string' ? (
            <img
              src={startIcon}
              alt=""
              style={{
                filter: pathname === href ? 'none' : 'invert(0.5)',
                width: 20,
                height: 20,
              }}
            />
          ) : (
            startIcon
          )}
          {dashboardSidebarExpanded ? (
            <Typography
              sx={{
                color:
                  pathname === href
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                fontWeight: pathname === href ? 600 : 500,
                marginLeft: 1,
                display: 'flex',
              }}
            >
              {title}
            </Typography>
          ) : null}
        </Box>
      </Tooltip>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  startIcon: PropTypes.any,
  onClose: PropTypes.func,
  action: PropTypes.func,
};

export default NavItem;
