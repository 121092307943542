import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const getQuestionFramework = (accountId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_QUESTION_FRAMEWORK' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/us/js/accounts/question-framework`,
            {
              params: {
                'request-id': reqId,
                accountId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_QUESTION_FRAMEWORK_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_QUESTION_FRAMEWORK_FAILED',
              payload: `Failed to fetch question framework`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_QUESTION_FRAMEWORK_FAILED',
            payload: `Failed to fetch question framework`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'FETCHING_QUESTION_FRAMEWORK_FAILED',
        payload: `Failed to fetch question framework`,
      });
    }
  };
};

export default getQuestionFramework;
