import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

let firebaseConfig = {};

// console.log(process.env.REACT_APP_TARGET_ENV);

if (process.env.REACT_APP_TARGET_ENV === 'local') {
  firebaseConfig = {
    apiKey: 'AIzaSyClsAtMF6rHhoNWgYfBigX35fcIs0Zojwg',
    authDomain: 'app-dev-381401.firebaseapp.com',
    projectId: 'app-dev-381401',
    storageBucket: 'app-dev-381401.appspot.com',
    messagingSenderId: '1090205382087',
    appId: '1:1090205382087:web:a0a74e4cf2c15073b515e2',
  };
}

if (process.env.REACT_APP_TARGET_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyClsAtMF6rHhoNWgYfBigX35fcIs0Zojwg',
    authDomain: 'app-dev-381401.firebaseapp.com',
    projectId: 'app-dev-381401',
    storageBucket: 'app-dev-381401.appspot.com',
    messagingSenderId: '1090205382087',
    appId: '1:1090205382087:web:a0a74e4cf2c15073b515e2',
  };
}

if (process.env.REACT_APP_TARGET_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyDmiC0R8jEqkIQnn83iqEm-Av3TirPCBr4',
    authDomain: 'app-prod-383715.firebaseapp.com',
    projectId: 'app-prod-383715',
    storageBucket: 'app-prod-383715.appspot.com',
    messagingSenderId: '14575999557',
    appId: '1:14575999557:web:62787b4c543335f399212c',
    measurementId: 'G-WDEYED32JP',
  };
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const messaging = getMessaging(app);

// analytics();
// if (process.env.REACT_APP_TARGET_ENV === 'development') {
//   firebase.analytics().setAnalyticsCollectionEnabled(false);
// }

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export default app;
