import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// import NavItem from './components/NavItem';

const SidebarNav = () => {
  const theme = useTheme();
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );
  // const { mode } = theme.palette;

  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          width={{ xs: 60, md: 70 }}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/auth/signin"
          >
            Sign In / Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
