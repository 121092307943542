import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
  Fab,
  Menu,
  ListSubheader,
  Stepper,
  Step,
  StepButton,
  Switch,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  AddLink,
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowRight,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  Cached,
  Chat,
  ChevronRight,
  Close,
  CloudUpload,
  ContentPaste,
  Delete,
  Edit,
  ErrorOutline,
  FindReplace,
  FitnessCenter,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  MoreVert,
  PlayArrow,
  Redo,
  Refresh,
  Search,
  Send,
  StopCircle,
  Subject,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getAccounts from 'redux/actions/Compass/getAccounts';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';

import getQuestionFramework from 'redux/actions/Compass/getQuestionFramework';
import addQuestionFrameworkSection from 'redux/actions/Compass/addQuestionFrameworkSection';
import addSectionQuestions from 'redux/actions/Compass/addSectionQuestions';
import deleteSection from 'redux/actions/Compass/deleteSection';
import updateQuestionFrameworkSection from 'redux/actions/Compass/updateQuestionFrameworkSection';
import deleteQuestion from 'redux/actions/Compass/deleteQuestion';
import updateQuestion from 'redux/actions/Compass/updateQuestion';
import deleteOption from 'redux/actions/Compass/deleteOption';
import updateOption from 'redux/actions/Compass/updateOption';
import addOption from 'redux/actions/Compass/addOption';
import addSectionReference from 'redux/actions/Compass/addSectionReference';
import getSectionsByLevel from 'redux/actions/Compass/getSectionsByLevel';
import getUploadUrlForHelpVideo from '../../../redux/actions/Common/getUploadUrlForHelpVideo';
import uploadHelpVideo from 'redux/actions/Common/uploadHelpVideo';
import verifyHelpVideoUpload from 'redux/actions/Common/verifyHelpVideoUpload';
import getHelpSections from 'redux/actions/Compass/getHelpSections';
import createHelpSection from 'redux/actions/Compass/createHelpSection';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const validationSchema = yup.object({
  companyName: yup
    .string()
    .trim()
    .required('Please enter company name')
    .min(3, 'Company name should have at least 3 characters'),
  primaryContactEmail: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  primaryContactName: yup
    .string()
    .required('Please specify primary contact name')
    .min(3, 'Primary contact name should have at least 3 characters'),
  primaryContactDesignation: yup
    .string()
    .required('Please specify primary contact designation')
    .min(3, 'Primary contact designation should have at least 3 characters'),
  primaryContactLinkedInurl: yup
    .string()
    .required('Please specify primary contact linkedIn url')
    .min(3, 'Primary contact linkedin url should have at least 3 characters'),
  organizationLevel: yup.string(),
  title: yup.string(),
  description: yup.string(),
  additionalNotes: yup.string(),
});

const profileValidationSchema = yup.object({
  industrySegment: yup.string().trim(),
  size: yup.string().trim(),
  location: yup.string().trim(),
  fundingSources: yup.array(),
  lengthInBusiness: yup.string().trim(),
  dealType: yup.string(),
  stageOfGrowth: yup.string(),
  technologyAdoptionProfile: yup.string(),
  hasManagementConsultant: yup.bool(),
  hasInterimCLevelExecutive: yup.bool(),
  hasPeOrVentureAdvisor: yup.bool(),
  outsourceFunction: yup.bool(),
  managementConsultantDetails: yup.string().trim(),
  outsourcedFunctionDetails: yup.string().trim(),
  peOrVentureAdvisorDetails: yup.string().trim(),
  interimCLevelExecutiveDetails: yup.string().trim(),
  // relevantHistoricalOrPresetActivity: yup.string().trim(),
});

const VideoPreview = memo(
  function VideoPreview({ video, onDurationChange }) {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {video ? (
          <Box width={'90%'} display={'flex'} flexDirection={'column'} gap={1}>
            <video
              style={{ width: '100%' }}
              preload={false}
              src={
                typeof video === 'string'
                  ? video
                  : window.URL.createObjectURL(video)
              }
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onDurationChange={onDurationChange}
            />
          </Box>
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.video === post?.video;
  },
);

const Questions = ({
  index,
  section,
  level,
  sectionTitle,
  questions,
  setShowAddNewQuestionDialog,
  setQuestionLevel,
  parentQuestionId,
  setParentQuestionId,
  questionOption,
  setQuestionOption,
  sections,
  setShowAddNewSectionDialog,
  setParentSectionId,
  setShowConfirmDeleteSection,
  setShowConfirmDeleteQuestion,
  setShowUpdateQuestionDialog,
  setShowConfirmDeleteOption,
  setShowUpdateOptionDialog,
  setShowAddNewOptionDialog,
  setSectionIds,
  sectionContentType,
  isSection,
  expandedSections,
  setExpandedSections,
  expandedQuestions,
  setExpandedQuestions,
  setShowAddHelpReference,
}) => {
  const theme = useTheme();
  // useEffect(() => {
  //   if (
  //     questionOption &&
  //     questionOption?.sectionReferences &&
  //     questionOption?.sectionReferences?.length > 0
  //   ) {
  //     setSectionIds(questionOption?.sectionReferences?.map((ref) => ref?.id));
  //   }
  // }, [questionOption]);

  return (
    <Box>
      {isSection ? (
        section?.level === 'level' ||
        section?.level === 'domain' ||
        section?.level === 'discipline' ||
        section?.level === 'element' ? (
          <Box display={'flex'} alignItems={'center'} gap={1} pb={1}>
            <Divider sx={{ width: 20 }} />
            <Box display={'flex'} alignItems={'center'} gap={1}>
              {expandedSections?.[section?.id] ? (
                <ArrowDropDown
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setExpandedSections((prev) => {
                      return {
                        ...prev,
                        [section?.id]: false,
                      };
                    });
                  }}
                />
              ) : (
                <ArrowRight
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setExpandedSections((prev) => {
                      return {
                        ...prev,
                        [section?.id]: true,
                      };
                    });
                  }}
                />
              )}
              <Typography variant="body1">
                {capitalizeText(section?.level)} {index + 1}:{' '}
                {capitalizeText(section?.title?.split('_')?.join(' '))}
              </Typography>
              {section?.mandatory ? (
                <Chip
                  label={
                    <Typography variant="caption" color={'text.secondary'}>
                      mandatory
                    </Typography>
                  }
                  size="small"
                />
              ) : null}
            </Box>
            {section?.level !== 'level' && section?.level !== 'domain' ? (
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                borderRadius={'4px'}
              >
                {section?.scoreConfig?.weight ? (
                  <Tooltip arrow placement="top" title="Weight">
                    <Chip
                      size="small"
                      label={
                        <Box display={'flex'} alignItems={'center'}>
                          <FitnessCenter sx={{ fontSize: 16, color: '#888' }} />
                          <Typography variant="subtitle2" ml={1}>
                            {section?.scoreConfig?.weight}
                          </Typography>
                        </Box>
                      }
                    />
                  </Tooltip>
                ) : null}
                <Tooltip arrow placement="top" title="Update Section">
                  <IconButton
                    sx={{
                      borderRadius: '4px',
                      border: '1px solid #d3d3d3',
                      padding: '4px',
                    }}
                    onClick={() => {
                      setShowAddNewSectionDialog(section);
                    }}
                  >
                    <Edit sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="top" title="Delete Section">
                  <IconButton
                    sx={{
                      borderRadius: '4px',
                      border: '1px solid #d3d3d3',
                      padding: '4px',
                    }}
                    onClick={() => {
                      setShowConfirmDeleteSection({
                        sectionId: section?.id,
                        type: level,
                      });
                    }}
                  >
                    <Delete sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    section?.helpSectionId
                      ? 'Update Help Reference'
                      : 'Add Help Reference'
                  }
                >
                  <IconButton
                    sx={{
                      borderRadius: '4px',
                      padding: '4px',
                      border: section?.helpSectionId
                        ? `1px solid ${theme.palette.primary.main}`
                        : '1px solid #d3d3d3',
                      color: theme.palette.text.secondary,
                    }}
                    onClick={() => {
                      setShowAddHelpReference({
                        type: 'section',
                        data: section,
                      });
                    }}
                  >
                    <AddLink sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : null}
          </Box>
        ) : null
      ) : null}
      {isSection &&
      (section?.level === 'level' ||
        section?.level === 'domain' ||
        section?.level === 'discipline') &&
      (sectionContentType === 'domains' ||
        sectionContentType === 'disciplines' ||
        sectionContentType === 'elements' ||
        sectionContentType === 'elementsandprospects' ||
        sectionContentType === 'elementsandactivities') ? (
        section?.sections &&
        section?.sections?.length > 0 &&
        expandedSections?.[section?.id] ? (
          <>
            {section?.level !== 'level' && expandedSections?.[section?.id] ? (
              <Box
                width={1}
                display={'flex'}
                alignItems={'start'}
                flexDirection={'column'}
                paddingLeft={4}
              >
                <Button
                  startIcon={<Add />}
                  size="small"
                  onClick={() => {
                    setShowAddNewSectionDialog(level);
                    setParentSectionId(section?.id);
                  }}
                >
                  Add new {level}
                </Button>
              </Box>
            ) : null}
            <Box pt={0.5} pb={0.5}>
              {section?.sections?.map((section, index) => {
                return (
                  <Box key={section?.id} display={'flex'} ml={5} gap={1}>
                    {/* {index !== sections?.length ? ( */}
                    <Divider orientation="vertical" flexItem />
                    {/* ) : null} */}
                    <Questions
                      index={index}
                      sections={section?.sections}
                      questions={section?.questions}
                      section={section}
                      sectionTitle={section?.title}
                      setShowAddNewQuestionDialog={setShowAddNewQuestionDialog}
                      level={
                        section?.level === 'level'
                          ? 'domain'
                          : section?.level === 'domain'
                          ? 'discipline'
                          : section?.level === 'discipline'
                          ? 'element'
                          : section?.level === 'element'
                          ? 'process'
                          : ''
                      }
                      sectionContentType={section?.sectionContentType}
                      setQuestionLevel={setQuestionLevel}
                      setParentQuestionId={setParentQuestionId}
                      setQuestionOption={setQuestionOption}
                      setShowAddNewSectionDialog={setShowAddNewSectionDialog}
                      setParentSectionId={setParentSectionId}
                      setShowConfirmDeleteSection={setShowConfirmDeleteSection}
                      setShowConfirmDeleteQuestion={
                        setShowConfirmDeleteQuestion
                      }
                      setShowUpdateQuestionDialog={setShowUpdateQuestionDialog}
                      setShowConfirmDeleteOption={setShowConfirmDeleteOption}
                      setShowUpdateOptionDialog={setShowUpdateOptionDialog}
                      setShowAddNewOptionDialog={setShowAddNewOptionDialog}
                      setSectionIds={setSectionIds}
                      isSection={true}
                      expandedSections={expandedSections}
                      setExpandedSections={setExpandedSections}
                      expandedQuestions={expandedQuestions}
                      setExpandedQuestions={setExpandedQuestions}
                      setShowAddHelpReference={setShowAddHelpReference}
                    />
                  </Box>
                );
              })}
            </Box>
          </>
        ) : expandedSections?.[section?.id] ? (
          <Box
            width={1}
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            p={level === 'level' ? 2 : 0}
            paddingLeft={5}
          >
            <Typography variant="caption" color={'text.secondary'}>
              No {level}s present
            </Typography>
            <Button
              startIcon={<Add />}
              size="small"
              onClick={() => {
                setShowAddNewSectionDialog(level);
                setParentSectionId(section?.id);
              }}
            >
              Add new {level}
            </Button>
          </Box>
        ) : null
      ) : null}
      {expandedSections?.[section?.id] && section?.level === 'element' ? (
        questions && questions?.length > 0 ? (
          <>
            <Button
              startIcon={<Add />}
              size="small"
              onClick={() => {
                setShowAddNewQuestionDialog(section);
                setQuestionLevel(isSection ? level : 'question');
                setParentQuestionId(parentQuestionId);
                setQuestionOption(questionOption);
              }}
              sx={{
                marginLeft: 4,
              }}
            >
              {isSection
                ? 'Add new question'
                : 'Add new question or refer a section'}
            </Button>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              mb={level === 'section' ? 2 : 0}
            >
              {questions?.map((question, qi) => (
                <Box
                  key={question?.id}
                  display={'flex'}
                  gap={1}
                  alignItems={'start'}
                  ml={5}
                >
                  <Divider orientation="vertical" flexItem />
                  <Box display={'flex'} gap={1} alignItems={'start'}>
                    <Divider sx={{ width: 20, marginTop: '18px' }} />
                    <Box>
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        {expandedQuestions?.[question?.id] ? (
                          <ArrowDropDown
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setExpandedQuestions((prev) => {
                                return {
                                  ...prev,
                                  [question?.id]: false,
                                };
                              });
                            }}
                          />
                        ) : (
                          <ArrowRight
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setExpandedQuestions((prev) => {
                                return {
                                  ...prev,
                                  [question?.id]: true,
                                };
                              });
                            }}
                          />
                        )}
                        <Typography variant="body1">
                          {qi + 1}. {question?.questionText}
                        </Typography>
                        <Chip
                          label={
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              {question.type}
                            </Typography>
                          }
                          size="small"
                        />
                        {question?.mandatory ? (
                          <Chip
                            label={
                              <Typography
                                variant="caption"
                                color={'text.secondary'}
                              >
                                mandatory
                              </Typography>
                            }
                            size="small"
                          />
                        ) : null}
                        {question?.questionWeight ? (
                          <Tooltip arrow placement="top" title="Weight">
                            <Chip
                              size="small"
                              label={
                                <Box display={'flex'} alignItems={'center'}>
                                  <FitnessCenter
                                    sx={{ fontSize: 16, color: '#888' }}
                                  />
                                  <Typography variant="subtitle2" ml={1}>
                                    {question?.questionWeight}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Tooltip>
                        ) : null}
                        <Tooltip arrow placement="top" title="Update Question">
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: '1px solid #d3d3d3',
                              padding: '4px',
                            }}
                            onClick={() => {
                              setShowUpdateQuestionDialog(question);
                            }}
                          >
                            <Edit sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Delete Question">
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: '1px solid #d3d3d3',
                              padding: '4px',
                            }}
                            onClick={() => {
                              setShowConfirmDeleteQuestion(question?.id);
                            }}
                          >
                            <Delete sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            question?.helpSectionId
                              ? 'Update Help Reference'
                              : 'Add Help Reference'
                          }
                        >
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: question?.helpSectionId
                                ? `1px solid ${theme.palette.primary.main}`
                                : '1px solid #d3d3d3',
                              color: theme.palette.text.secondary,
                              padding: '4px',
                            }}
                            onClick={() => {
                              setShowAddHelpReference({
                                type: 'question',
                                data: question,
                              });
                            }}
                          >
                            <AddLink sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {(question?.type === 'select' ||
                        question?.type === 'multiselect' ||
                        question?.type === 'radio' ||
                        question?.type === 'checkbox') &&
                      expandedQuestions?.[question?.id] ? (
                        <Box ml={1} display={'flex'} gap={2}>
                          <Box>
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                            >
                              Options
                            </Typography>
                            <br />
                            {question?.options &&
                            question?.options?.length > 0 ? (
                              <>
                                {question?.options
                                  ?.sort((a, b) => a?.order - b?.order)
                                  ?.map((option) => {
                                    return (
                                      <Box key={option?.id}>
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                        >
                                          <Typography>
                                            {option?.text}
                                          </Typography>
                                          {option?.selected ? (
                                            <Chip
                                              label={
                                                <Typography
                                                  variant="caption"
                                                  color={'text.secondary'}
                                                >
                                                  default
                                                </Typography>
                                              }
                                              size="small"
                                            />
                                          ) : null}
                                          {option?.scoreConfig?.score ? (
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title="Score"
                                            >
                                              <Chip
                                                size="small"
                                                label={
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                  >
                                                    <ContentPaste
                                                      sx={{
                                                        fontSize: 16,
                                                        color: '#888',
                                                      }}
                                                    />
                                                    <Typography
                                                      variant="subtitle2"
                                                      ml={1}
                                                    >
                                                      {
                                                        option?.scoreConfig
                                                          ?.score
                                                      }
                                                    </Typography>
                                                  </Box>
                                                }
                                              />
                                            </Tooltip>
                                          ) : null}
                                          {/* {option?.scoreConfig?.weight ? (
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title="Weight"
                                            >
                                              <Chip
                                                size="small"
                                                label={
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                  >
                                                    <FitnessCenter
                                                      sx={{
                                                        fontSize: 16,
                                                        color: '#888',
                                                      }}
                                                    />
                                                    <Typography
                                                      variant="subtitle2"
                                                      ml={1}
                                                    >
                                                      {
                                                        option?.scoreConfig
                                                          ?.weight
                                                      }
                                                    </Typography>
                                                  </Box>
                                                }
                                              />
                                            </Tooltip>
                                          ) : null} */}
                                          {option?.sectionReferences &&
                                          option?.sectionReferences?.length > 0
                                            ? option?.sectionReferences?.map(
                                                (ref) => (
                                                  <Chip
                                                    size="small"
                                                    color="primary"
                                                    key={ref?.id}
                                                    label={capitalizeText(
                                                      ref?.title
                                                        ?.split('_')
                                                        ?.join(' '),
                                                    )}
                                                  />
                                                ),
                                              )
                                            : null}
                                        </Box>
                                        <Box display={'flex'} ml={1}>
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                          <Questions
                                            index={index}
                                            section={section}
                                            setShowAddNewQuestionDialog={
                                              setShowAddNewQuestionDialog
                                            }
                                            level={
                                              section?.level === 'level'
                                                ? 'domain'
                                                : section?.level === 'domain'
                                                ? 'discipline'
                                                : section?.level ===
                                                  'discipline'
                                                ? 'element'
                                                : section?.level === 'element'
                                                ? 'process'
                                                : ''
                                            }
                                            sectionTitle={section?.sectionTitle}
                                            questions={option?.questions}
                                            sectionContentType={
                                              sectionContentType
                                            }
                                            setQuestionLevel={setQuestionLevel}
                                            parentQuestionId={question?.id}
                                            setParentQuestionId={
                                              setParentQuestionId
                                            }
                                            questionOption={option}
                                            setQuestionOption={
                                              setQuestionOption
                                            }
                                            setShowConfirmDeleteQuestion={
                                              setShowConfirmDeleteQuestion
                                            }
                                            setShowUpdateQuestionDialog={
                                              setShowUpdateQuestionDialog
                                            }
                                            setShowConfirmDeleteOption={
                                              setShowConfirmDeleteOption
                                            }
                                            setShowUpdateOptionDialog={
                                              setShowUpdateOptionDialog
                                            }
                                            setShowAddNewOptionDialog={
                                              setShowAddNewOptionDialog
                                            }
                                            setSectionIds={setSectionIds}
                                            isSection={false}
                                            expandedSections={expandedSections}
                                            setExpandedSections={
                                              setExpandedSections
                                            }
                                            expandedQuestions={
                                              expandedQuestions
                                            }
                                            setExpandedQuestions={
                                              setExpandedQuestions
                                            }
                                            setShowAddHelpReference={
                                              setShowAddHelpReference
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    );
                                  })}
                              </>
                            ) : (
                              <Box
                                width={1}
                                display={'flex'}
                                alignItems={'start'}
                                // justifyContent={'center'}
                                flexDirection={'column'}
                                // gap={1}
                                // minHeight={level === 'section' ? 150 : null}
                                p={level === 'section' ? 2 : 0}
                                paddingLeft={2}
                              >
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                >
                                  No option present
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        ) : section?.sectionType !== 'power' &&
          section?.sectionType !== 'deal_activity' ? (
          <Box
            width={1}
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            p={level === 'section' ? 2 : 0}
            paddingLeft={2}
          >
            <Typography variant="caption" color={'text.secondary'}>
              No questions present
            </Typography>
            <Button
              startIcon={<Add />}
              size="small"
              onClick={() => {
                setShowAddNewQuestionDialog(section);
                setQuestionLevel(isSection ? level : 'question');
                setParentQuestionId(parentQuestionId);
                setQuestionOption(questionOption);
              }}
            >
              {isSection
                ? 'Add new question'
                : 'Add new question or refer a section'}
            </Button>
          </Box>
        ) : null
      ) : null}
    </Box>
  );
};

const QuestionsFramework = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    accounts,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.companyAccounts);
  const showControls =
    localStorage.getItem('showControls') === 'true' ? true : false;

  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const browser = detect();
  const [viewType, setViewType] = useState('list');
  const [questionFramework, setQuestionFramework] = useState(null);
  const [sections, setSections] = useState([]);
  const [showAddNewSectionDialog, setShowAddNewSectionDialog] = useState(false);
  const [questionLevel, setQuestionLevel] = useState('section');
  const [showAddNewQuestionDialog, setShowAddNewQuestionDialog] = useState(
    false,
  );
  const [parentQuestionId, setParentQuestionId] = useState('');
  const [questionOption, setQuestionOption] = useState(null);
  const [parentSectionId, setParentSectionId] = useState('');

  const [questionTypes, setQuestionTypes] = useState([
    'text',
    'textarea',
    'select',
    'multiselect',
    'checkbox',
    'radio',
    'date',
  ]);

  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionWeight, setSectionWeight] = useState('10');
  const [sectionMandatory, setSectionMandatory] = useState(false);
  const [questionMandatory, setQuestionMandatory] = useState(false);
  const [optionDefault, setOptionDefault] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [questionWeight, setQuestionWeight] = useState('10');
  const [textAnswerScore, setTextAnswerScore] = useState('5');
  const [questionType, setQuestionType] = useState('text');
  const [questionOptions, setQuestionOptions] = useState(['', '', '', '']);
  const [optionScores, setOptionScores] = useState(['1', '2', '3', '4', '5']);
  const [selectedOption, setSelectedOption] = useState(0);

  const [showConfirmDeleteSection, setShowConfirmDeleteSection] = useState(
    null,
  );
  const [showConfirmDeleteQuestion, setShowConfirmDeleteQuestion] = useState(
    null,
  );
  const [showUpdateQuestionDialog, setShowUpdateQuestionDialog] = useState(
    false,
  );
  const [showConfirmDeleteOption, setShowConfirmDeleteOption] = useState(null);
  const [showUpdateOptionDialog, setShowUpdateOptionDialog] = useState(null);

  const [showAddNewOptionDialog, setShowAddNewOptionDialog] = useState(null);

  const [optionText, setOptionText] = useState('');
  const [updateQuestionOptions, setUpdateQuestionOptions] = useState([]);
  const [deleteQuestionOptionIds, setDeleteQuestionOptionIds] = useState([]);

  const [questionOrSection, setQuestionOrSection] = useState('question');
  const [sectionIds, setSectionIds] = useState([]);

  const [showParserDialog, setShowParserDialog] = useState(false);
  const [textToBeParsed, setTextToBeParsed] = useState('');

  const [listOfGroups, setListOfGroups] = useState([]);
  const [listOfDomains, setListOfDomains] = useState([]);
  const [listOfDisciplines, setListOfDisciplines] = useState([]);
  const [listOfElements, setListOfElements] = useState([]);
  const [expandedSections, setExpandedSections] = useState(null);
  const [expandedQuestions, setExpandedQuestions] = useState(null);

  const [showAddHelpReference, setShowAddHelpReference] = useState(false);
  const [helpSectionTitle, setHelpSectionTitle] = useState('');
  const [helpText, setHelpText] = useState('');
  const [helpVideo, setHelpVideo] = useState(null);
  const helpVideoRef = useRef(null);
  const [invalidVideoFile, setInvalidVideoFile] = useState(false);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [helpVideoDuration, setHelpVideoDuration] = useState(0);
  const [
    savingOrUpdatingHelpReference,
    setSavingOrUpdatingHelpReference,
  ] = useState(false);

  const [fetchingQuestionFramework, setFetchingQuestionFramework] = useState(
    false,
  );
  const [helpSections, setHelpSections] = useState([]);
  const [helpSectionId, setHelpSectionId] = useState('');

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleSubmitHelpText = () => {
    setSavingOrUpdatingHelpReference(true);
    if (showAddHelpReference?.type === 'question') {
      if (helpSectionId === 'new') {
        dispatch(
          createHelpSection(
            {
              contentType: helpVideo?.type?.split(';')?.[0],
              duration: Math.round(helpVideoDuration * 1000),
              sizeInBytes: helpVideo?.size,
              text: helpText,
              title: helpSectionTitle,
            },
            (data) => {
              if (helpVideo && typeof helpVideo !== 'string') {
                dispatch(
                  uploadHelpVideo(data?.uploadUrlRes?.url, helpVideo, () => {
                    dispatch(
                      verifyHelpVideoUpload(
                        data?.uploadUrlRes?.url && data?.uploadUrlRes?.videoId,
                        null,
                        () => {
                          dispatch(
                            updateQuestion(
                              showAddHelpReference?.data?.id,
                              {
                                helpSectionId: data?.helpSectionId,
                              },
                              () => {
                                setHelpText('');
                                setHelpSectionTitle('');
                                setHelpVideo(null);
                                setShowAddHelpReference(null);
                                setSavingOrUpdatingHelpReference(false);
                                setFetchingQuestionFramework(true);
                                dispatch(
                                  getQuestionFramework(null, (data) => {
                                    console.log(data);
                                    setFetchingQuestionFramework(false);
                                    setQuestionFramework(data?.questions);
                                  }),
                                );
                              },
                              () => {
                                setSavingOrUpdatingHelpReference(false);
                                dispatch(
                                  showAlert({
                                    message: 'Failed to update help reference.',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 2000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                              },
                            ),
                          );
                        },
                      ),
                    );
                  }),
                );
              } else {
                dispatch(
                  updateQuestion(
                    showAddHelpReference?.data?.id,
                    {
                      helpSectionId: data?.helpSectionId,
                    },
                    () => {
                      setHelpText('');
                      setHelpSectionTitle('');
                      setHelpVideo(null);
                      setShowAddHelpReference(null);
                      setSavingOrUpdatingHelpReference(false);
                      setFetchingQuestionFramework(true);
                      dispatch(
                        getQuestionFramework(null, (data) => {
                          console.log(data);
                          setFetchingQuestionFramework(false);
                          setQuestionFramework(data?.questions);
                        }),
                      );
                    },
                    () => {
                      setSavingOrUpdatingHelpReference(false);
                      dispatch(
                        showAlert({
                          message: 'Failed to update help reference.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }
            },
            () => {
              setSavingOrUpdatingHelpReference(false);
              setShowAddHelpReference(null);
              dispatch(
                showAlert({
                  message: 'Failed to update help reference.',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            },
          ),
        );
      } else {
        dispatch(
          updateQuestion(
            showAddHelpReference?.data?.id,
            {
              helpSectionId: helpSectionId,
            },
            () => {
              setHelpText('');
              setHelpVideo(null);
              setShowAddHelpReference(null);
              setSavingOrUpdatingHelpReference(false);
              setFetchingQuestionFramework(true);
              dispatch(
                getQuestionFramework(null, (data) => {
                  console.log(data);
                  setFetchingQuestionFramework(false);
                  setQuestionFramework(data?.questions);
                }),
              );
            },
            () => {
              setSavingOrUpdatingHelpReference(false);
              dispatch(
                showAlert({
                  message: 'Failed to update help reference.',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            },
          ),
        );
      }
    } else {
      if (helpSectionId === 'new') {
        dispatch(
          createHelpSection(
            {
              contentType: helpVideo?.type?.split(';')?.[0],
              duration: Math.round(helpVideoDuration * 1000),
              sizeInBytes: helpVideo?.size,
              text: helpText,
              title: helpSectionTitle,
            },
            (data) => {
              if (helpVideo && typeof helpVideo !== 'string') {
                dispatch(
                  uploadHelpVideo(data?.uploadUrlRes?.url, helpVideo, () => {
                    dispatch(
                      verifyHelpVideoUpload(
                        data?.uploadUrlRes?.url && data?.uploadUrlRes?.videoId,
                        null,
                        () => {
                          dispatch(
                            updateQuestionFrameworkSection(
                              showAddHelpReference?.data?.id,
                              {
                                helpSectionId: data?.helpSectionId,
                              },
                              () => {
                                setHelpText('');
                                setHelpSectionTitle('');
                                setHelpVideo(null);
                                setShowAddHelpReference(null);
                                setSavingOrUpdatingHelpReference(false);
                                setFetchingQuestionFramework(true);
                                dispatch(
                                  getQuestionFramework(null, (data) => {
                                    console.log(data);
                                    setFetchingQuestionFramework(false);
                                    setQuestionFramework(data?.questions);
                                  }),
                                );
                              },
                              () => {
                                setSavingOrUpdatingHelpReference(false);
                                dispatch(
                                  showAlert({
                                    message: 'Failed to update help reference.',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 2000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                              },
                            ),
                          );
                        },
                      ),
                    );
                  }),
                );
              } else {
                dispatch(
                  updateQuestionFrameworkSection(
                    showAddHelpReference?.data?.id,
                    {
                      helpSectionId: data?.helpSectionId,
                    },
                    () => {
                      setHelpText('');
                      setHelpVideo(null);
                      setShowAddHelpReference(null);
                      setSavingOrUpdatingHelpReference(false);
                      setFetchingQuestionFramework(true);
                      dispatch(
                        getQuestionFramework(null, (data) => {
                          console.log(data);
                          setFetchingQuestionFramework(false);
                          setQuestionFramework(data?.questions);
                        }),
                      );
                    },
                    () => {
                      setSavingOrUpdatingHelpReference(false);
                      dispatch(
                        showAlert({
                          message: 'Failed to update help reference.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }
            },
            () => {
              setSavingOrUpdatingHelpReference(false);
              setShowAddHelpReference(null);
              dispatch(
                showAlert({
                  message: 'Failed to update help reference.',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            },
          ),
        );
      } else {
        dispatch(
          updateQuestionFrameworkSection(
            showAddHelpReference?.data?.id,
            {
              helpSectionId: helpSectionId,
            },
            () => {
              setHelpText('');
              setHelpVideo(null);
              setShowAddHelpReference(null);
              setSavingOrUpdatingHelpReference(false);
              setFetchingQuestionFramework(true);
              dispatch(
                getQuestionFramework(null, (data) => {
                  console.log(data);
                  setFetchingQuestionFramework(false);
                  setQuestionFramework(data?.questions);
                }),
              );
            },
            () => {
              setSavingOrUpdatingHelpReference(false);
              dispatch(
                showAlert({
                  message: 'Failed to update help reference.',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            },
          ),
        );
      }
    }
  };

  const handleVideoFilePicker = (event) => {
    handleTrackEvent(event, 'help_video_selected');
    if (
      event.target.files[0] &&
      event.target.files[0].type.includes('video') &&
      event.target.files[0]?.size <= 300 * 1000 * 1000
    ) {
      setHelpVideo(event.target.files[0]);
      setInvalidVideoFile(false);
    } else {
      helpVideoRef.current.value = null;
      setInvalidVideoFile(
        'Please select a valid video file of size not exceeding 300 MB.',
      );
    }
  };

  useEffect(() => {
    if (showAddHelpReference) {
      setHelpSectionId(showAddHelpReference?.data?.helpSectionId);
    }
  }, [showAddHelpReference]);

  useEffect(() => {
    if (
      showAddNewSectionDialog &&
      typeof showAddNewSectionDialog !== 'string'
    ) {
      setSectionTitle(
        capitalizeText(showAddNewSectionDialog?.title?.split('_')?.join(' ')),
      );
      setSectionMandatory(showAddNewSectionDialog?.mandatory);
      setSectionWeight(showAddNewSectionDialog?.scoreConfig?.weight || '10');
    }
  }, [showAddNewSectionDialog]);

  useEffect(() => {
    if (showAddNewQuestionDialog && questionLevel === 'question') {
      dispatch(
        getSectionsByLevel('element', (data) => {
          console.log(data);
          setSections(data);
        }),
      );
    }
  }, [showAddNewQuestionDialog, questionLevel]);

  useEffect(() => {
    if (showUpdateQuestionDialog) {
      setQuestionText(showUpdateQuestionDialog?.questionText);
      setQuestionMandatory(showUpdateQuestionDialog?.mandatory);
      setUpdateQuestionOptions(showUpdateQuestionDialog?.options);
      setQuestionType(showUpdateQuestionDialog?.type);
      setDeleteQuestionOptionIds([]);
      setSelectedOption(
        showUpdateQuestionDialog?.options?.findIndex(
          (op, index) => op?.selected,
        ) || 0,
      );
      setTextAnswerScore(
        showUpdateQuestionDialog?.textAnswerScoreConfig?.score,
      );
      setQuestionWeight(showUpdateQuestionDialog?.questionWeight);
    }
  }, [showUpdateQuestionDialog]);

  useEffect(() => {
    if (showUpdateOptionDialog) {
      setOptionText(showUpdateOptionDialog?.optionText);
      setOptionDefault(showUpdateOptionDialog?.optionDefault);
    }
  }, [showUpdateOptionDialog]);

  useEffect(() => {
    dispatch(
      getSectionsByLevel('null', (data) => {
        console.log(data);
        setExpandedSections(
          data?.reduce((acc, curr) => {
            acc[curr?.id] = true;
            return acc;
          }, {}),
        );
      }),
    );
    setFetchingQuestionFramework(true);
    dispatch(
      getQuestionFramework(null, (data) => {
        // console.log(data);
        setFetchingQuestionFramework(false);
        setQuestionFramework(data?.questions);
      }),
    );
    dispatch(
      getHelpSections(null, (data) => {
        // console.log(data);
        setHelpSections(data);
      }),
    );
  }, []);

  // console.log(showAddNewSectionDialog);
  // console.log(helpVideoDuration);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs ? 'auto' : 'calc(100vh - 60px)',
            padding: uId ? 0 : 2,
            paddingTop: 2,
          }}
        >
          {viewType === 'list' ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}
              gap={1}
              width={1}
              border={'1px solid #d3d3d3'}
              sx={{
                maxHeight: 'calc(100vh - 90px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
              }}
            >
              {fetchingQuestionFramework ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  minHeight={'calc(100vh - 90px)'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Fetching Question Framework ...
                  </Typography>
                </Box>
              ) : questionFramework && questionFramework.length > 0 ? (
                <Box width={1}>
                  <Box pt={1} pb={1}>
                    {questionFramework?.map((section, index) => {
                      return (
                        <>
                          <Box key={section?.id} ml={1}>
                            <Box display={'flex'} ml={1}>
                              <Questions
                                index={index}
                                section={section}
                                level={
                                  section?.level === 'level'
                                    ? 'domain'
                                    : section?.level === 'domain'
                                    ? 'discipline'
                                    : section?.level === 'discipline'
                                    ? 'element'
                                    : section?.level === 'element'
                                    ? 'process'
                                    : ''
                                }
                                sectionTitle={section?.title}
                                setShowAddNewQuestionDialog={
                                  setShowAddNewQuestionDialog
                                }
                                sections={section?.sections}
                                questions={section?.questions}
                                sectionContentType={section?.sectionContentType}
                                setQuestionLevel={setQuestionLevel}
                                setParentQuestionId={setParentQuestionId}
                                setQuestionOption={setQuestionOption}
                                setShowAddNewSectionDialog={
                                  setShowAddNewSectionDialog
                                }
                                setParentSectionId={setParentSectionId}
                                setShowConfirmDeleteSection={
                                  setShowConfirmDeleteSection
                                }
                                setShowConfirmDeleteQuestion={
                                  setShowConfirmDeleteQuestion
                                }
                                setShowUpdateQuestionDialog={
                                  setShowUpdateQuestionDialog
                                }
                                setShowConfirmDeleteOption={
                                  setShowConfirmDeleteOption
                                }
                                setShowUpdateOptionDialog={
                                  setShowUpdateOptionDialog
                                }
                                setShowAddNewOptionDialog={
                                  setShowAddNewOptionDialog
                                }
                                setSectionIds={setSectionIds}
                                isSection={true}
                                expandedSections={expandedSections}
                                setExpandedSections={setExpandedSections}
                                expandedQuestions={expandedQuestions}
                                setExpandedQuestions={setExpandedQuestions}
                                setShowAddHelpReference={
                                  setShowAddHelpReference
                                }
                              />
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <Box
                  width={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  gap={1}
                  // minHeight={150}
                  p={2}
                >
                  <Typography>No level present</Typography>
                  {/* <Button
                    startIcon={<Add />}
                    onClick={() => setShowAddNewSectionDialog('group')}
                  >
                    Add new group
                  </Button> */}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              width={1}
            ></Box>
          )}
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddNewSectionDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddNewSectionDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {typeof showAddNewSectionDialog === 'string'
                ? `Create ${capitalizeText(showAddNewSectionDialog)}`
                : `Update ${capitalizeText(showAddNewSectionDialog?.level)}`}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {showAddNewSectionDialog === 'level' ||
              showAddNewSectionDialog?.level === 'level' ? null : (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="sectionMandatory"
                      defaultChecked={sectionMandatory}
                    />
                  }
                  label={'Mandatory'}
                  onChange={(e) => {
                    setSectionMandatory(e.target.checked);
                  }}
                />
              )}
              <TextField
                autoFocus
                label={
                  typeof showAddNewSectionDialog === 'string'
                    ? `${capitalizeText(showAddNewSectionDialog)} Title`
                    : `${capitalizeText(showAddNewSectionDialog?.level)} Title`
                }
                placeholder={`Enter ${
                  typeof showAddNewSectionDialog === 'string'
                    ? `${capitalizeText(showAddNewSectionDialog)} Title`
                    : `${capitalizeText(showAddNewSectionDialog?.level)} Title`
                }`}
                variant="outlined"
                color="primary"
                size="small"
                name="sectionTitle"
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              {(typeof showAddNewSectionDialog === 'string' &&
                showAddNewSectionDialog !== 'level' &&
                showAddNewSectionDialog !== 'domain') ||
              (typeof showAddNewSectionDialog === 'object' &&
                showAddNewSectionDialog?.level !== 'level' &&
                showAddNewSectionDialog?.level !== 'domain') ? (
                <TextField
                  autoFocus
                  label="Weight"
                  placeholder="Enter Weight"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="sectionWeight"
                  value={sectionWeight}
                  onChange={(e) => setSectionWeight(e.target.value)}
                  sx={{
                    flex: 1,
                  }}
                />
              ) : null}
              {/* </>
              )} */}
              <Button
                variant="contained"
                color="primary"
                disabled={!sectionTitle?.trim()}
                onClick={() => {
                  if (typeof showAddNewSectionDialog === 'string') {
                    dispatch(
                      addQuestionFrameworkSection(
                        parentSectionId,
                        {
                          level: showAddNewSectionDialog,
                          mandatory: sectionMandatory,
                          sectionType:
                            showAddNewSectionDialog === 'section'
                              ? sectionTitle?.toLowerCase()
                              : 'sub_section',
                          title: sectionTitle,
                          sectionContentType:
                            showAddNewSectionDialog === 'domain'
                              ? 'domains'
                              : showAddNewSectionDialog === 'discipline'
                              ? 'disciplines'
                              : showAddNewSectionDialog === 'element'
                              ? 'processes'
                              : '',
                          scoreConfig: {
                            weight: sectionWeight,
                          },
                        },
                        (error) => {
                          if (error) {
                            dispatch(
                              showAlert({
                                message: 'Failed to add new section',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setFetchingQuestionFramework(true);
                            dispatch(
                              getQuestionFramework(null, (data) => {
                                setFetchingQuestionFramework(false);
                                setQuestionFramework(data?.questions);
                              }),
                            );
                          }
                        },
                      ),
                    );
                    setShowAddNewSectionDialog(false);
                    setSectionTitle('');
                  } else {
                    dispatch(
                      updateQuestionFrameworkSection(
                        showAddNewSectionDialog?.id,
                        {
                          level: showAddNewSectionDialog?.level,
                          mandatory: sectionMandatory,
                          sectionType: showAddNewSectionDialog?.sectionType,
                          sectionContentType:
                            showAddNewSectionDialog?.sectionContentType,
                          title: sectionTitle,
                          scoreConfig: {
                            weight: sectionWeight,
                          },
                        },
                        () => {
                          setFetchingQuestionFramework(true);
                          dispatch(
                            getQuestionFramework(null, (data) => {
                              setFetchingQuestionFramework(false);
                              setQuestionFramework(data?.questions);
                            }),
                          );
                        },
                        () => {
                          dispatch(
                            showAlert({
                              message: 'Failed to update section',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                    setShowAddNewSectionDialog(false);
                    setSectionTitle('');
                  }
                }}
              >
                {typeof showAddNewSectionDialog === 'string'
                  ? `Create ${capitalizeText(showAddNewSectionDialog)}`
                  : `Update ${capitalizeText(showAddNewSectionDialog?.level)}`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddNewQuestionDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddNewQuestionDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {questionOrSection === 'question'
                ? 'Create Question'
                : 'Refer Section'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              {questionLevel === 'question' ? (
                <RadioGroup
                  row
                  aria-labelledby="question-or-section-group-label"
                  name="questionOrSection"
                  value={questionOrSection}
                  onChange={(e) => {
                    setQuestionOrSection(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={'question'}
                    control={<Radio />}
                    label={'Question'}
                  />
                  <FormControlLabel
                    value={'section'}
                    control={<Radio />}
                    label={'Section'}
                  />
                </RadioGroup>
              ) : null}
              {questionOrSection === 'question' ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="questionMandatory"
                        defaultChecked={questionMandatory}
                      />
                    }
                    label={'Mandatory'}
                    onChange={(e) => {
                      setQuestionMandatory(e.target.checked);
                    }}
                  />
                  <Box dispaly={'flex'} alignItems={'center'} gap={2} width={1}>
                    <FormControl size="small" sx={{ minWidth: '250px' }}>
                      <InputLabel id="question-type-label">
                        Question Type
                      </InputLabel>
                      <Select
                        size="small"
                        label="Question Type"
                        labelId={'question-type-label'}
                        id={'questionType'}
                        name={'questionType'}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        onChange={(e) => {
                          setQuestionType(e.target.value);
                        }}
                        value={questionType}
                      >
                        {questionTypes.map((type) => (
                          <MenuItem value={type} key={type}>
                            <Typography variant="subtitle2">{type}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {questionType === 'select' ||
                    questionType === 'multiselect' ||
                    questionType === 'checkbox' ||
                    questionType === 'radio' ? (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setShowParserDialog(true);
                        }}
                        sx={{
                          marginLeft: 2,
                        }}
                      >
                        Use Parser
                      </Button>
                    ) : null}
                  </Box>
                  <TextField
                    autoFocus
                    label="Question Text"
                    placeholder="Enter Question Text"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="questionText"
                    value={questionText}
                    onChange={(e) => setQuestionText(e.target.value)}
                    sx={{
                      flex: 1,
                    }}
                  />
                  <TextField
                    autoFocus
                    label="Question Weight"
                    placeholder="Enter Question Weight"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="questionWeight"
                    value={questionWeight}
                    onChange={(e) => setQuestionWeight(e.target.value)}
                    sx={{
                      flex: 1,
                    }}
                  />
                  {questionType === 'text' || questionType === 'textarea' ? (
                    <Box>
                      <Typography variant="subtitle2" color={'text.secondary'}>
                        Score if any text typed
                      </Typography>
                      <RadioGroup
                        row
                        aria-labelledby="question-or-section-group-label"
                        name="textAnswerScore"
                        value={textAnswerScore}
                        onChange={(e) => {
                          setTextAnswerScore(e.target.value);
                        }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          marginLeft: 1,
                        }}
                      >
                        {['1', '2', '3', '4', '5']?.map((score) => {
                          return (
                            <FormControlLabel
                              sx={{
                                padding: 0,
                              }}
                              key={score}
                              value={score}
                              control={<Radio size="small" />}
                              label={score}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                  ) : null}
                  {questionType === 'select' ||
                  questionType === 'multiselect' ||
                  questionType === 'checkbox' ||
                  questionType === 'radio' ? (
                    <>
                      {questionOptions?.map((option, index) => (
                        <Box
                          key={index}
                          display={'flex'}
                          alignItems={'center'}
                          gap={1}
                        >
                          {questionType === 'radio' ? (
                            <Tooltip
                              arrow
                              placement="top"
                              title="Default Selected"
                            >
                              <Radio
                                size="small"
                                checked={selectedOption === index}
                                onChange={(e) =>
                                  setSelectedOption(
                                    e.target.checked ? index : 0,
                                  )
                                }
                              />
                            </Tooltip>
                          ) : null}
                          <TextField
                            autoFocus
                            label={`Option ${index + 1} Text`}
                            placeholder={`Option ${index + 1} Text`}
                            variant="outlined"
                            color="primary"
                            size="small"
                            name="optionText"
                            value={option}
                            onChange={(e) => {
                              setQuestionOptions((prev) => {
                                return prev.map((op, opIndex) =>
                                  opIndex === index ? e.target.value : op,
                                );
                              });
                            }}
                            sx={{
                              flex: 4,
                            }}
                          />
                          <TextField
                            autoFocus
                            label={`Score`}
                            placeholder={`Score`}
                            variant="outlined"
                            color="primary"
                            size="small"
                            name="optionScore"
                            value={optionScores?.[index]}
                            onChange={(e) => {
                              setOptionScores((prev) => {
                                return prev.map((op, opIndex) =>
                                  opIndex === index ? e.target.value : op,
                                );
                              });
                            }}
                            sx={{
                              flex: 1,
                            }}
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setQuestionOptions((prev) => {
                                return prev.filter(
                                  (op, opIndex) => opIndex !== index,
                                );
                              });
                            }}
                          >
                            Remove
                          </Button>
                        </Box>
                      ))}
                      <Button
                        startIcon={<Add />}
                        size="small"
                        variant="text"
                        onClick={() => {
                          setQuestionOptions((prev) => [...prev, '']);
                          setOptionScores((prev) => [
                            ...prev,
                            prev?.length + 1,
                          ]);
                        }}
                      >
                        Add More Option
                      </Button>
                    </>
                  ) : null}
                </>
              ) : (
                <FormControl size="small" sx={{ minWidth: '250px' }}>
                  <Select
                    size="small"
                    // label="Organization Level"
                    labelId={'sectionIds'}
                    id={'sectionIds'}
                    name={'sectionIds'}
                    multiple
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    onChange={(e) => {
                      setSectionIds(e.target.value);
                    }}
                    renderValue={(selected) => {
                      return sections
                        ?.filter((section) => selected?.includes(section?.id))
                        ?.map((section) => section?.title)
                        ?.join(', ');
                    }}
                    value={sectionIds}
                  >
                    {sections.map((section) => (
                      <MenuItem
                        disabled={questionOption?.sectionReferences
                          ?.map((ref) => ref?.id)
                          ?.includes(section?.id)}
                        value={section?.id}
                        key={section?.id}
                      >
                        <Typography variant="subtitle2">
                          {section?.title}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (questionOrSection === 'question' &&
                    (!questionText?.trim() ||
                      ((questionType === 'select' ||
                        questionType === 'multiselect' ||
                        questionType === 'checkbox' ||
                        questionType === 'radio') &&
                        questionOptions?.filter((op) => !op?.trim())?.length >
                          0))) ||
                  (questionOrSection === 'section' && sectionIds.length === 0)
                }
                onClick={() => {
                  if (questionOrSection === 'question') {
                    dispatch(
                      addSectionQuestions(
                        showAddNewQuestionDialog?.id,
                        questionLevel === 'section' ? null : parentQuestionId,
                        [
                          {
                            mandatory: questionMandatory,
                            optionId:
                              questionLevel === 'section'
                                ? null
                                : questionOption?.id,
                            selectedOption:
                              questionType === 'radio'
                                ? questionOptions?.[selectedOption]
                                : null,
                            options:
                              questionType === 'select' ||
                              questionType === 'multiselect' ||
                              questionType === 'checkbox' ||
                              questionType === 'radio'
                                ? questionOptions
                                : [],
                            questionText: questionText,
                            optionsScore:
                              questionType === 'select' ||
                              questionType === 'multiselect' ||
                              questionType === 'checkbox' ||
                              questionType === 'radio'
                                ? optionScores?.map((score) => Number(score))
                                : [],
                            textAnswerScore:
                              questionType === 'text' ||
                              questionType === 'textarea'
                                ? textAnswerScore
                                : 0,
                            textNoAnswerScore:
                              questionType === 'text' ||
                              questionType === 'textarea'
                                ? 0
                                : 0,
                            questionWeight: questionWeight,
                            type: questionType,
                          },
                        ],
                        (error) => {
                          if (error) {
                            dispatch(
                              showAlert({
                                message: 'Failed to add new question',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setFetchingQuestionFramework(true);
                            dispatch(
                              getQuestionFramework(null, (data) => {
                                setFetchingQuestionFramework(false);
                                console.log(data);
                                setQuestionFramework(data?.questions);
                              }),
                            );
                          }
                        },
                      ),
                    );
                    setShowAddNewQuestionDialog(false);
                  } else {
                    dispatch(
                      addSectionReference(
                        parentQuestionId,
                        questionOption?.id,
                        { sectionIds },
                        (error) => {
                          if (error) {
                            dispatch(
                              showAlert({
                                message: 'Failed to add section reference',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setFetchingQuestionFramework(true);
                            dispatch(
                              getQuestionFramework(null, (data) => {
                                console.log(data);
                                setFetchingQuestionFramework(false);
                                setQuestionFramework(data?.questions);
                              }),
                            );
                            setShowAddNewQuestionDialog(false);
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                {questionOrSection === 'question'
                  ? 'Add Question'
                  : 'Add Reference to Section'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showUpdateQuestionDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowUpdateQuestionDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>Update Question</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="questionMandatory"
                    defaultChecked={questionMandatory}
                  />
                }
                label={'Mandatory'}
                onChange={(e) => {
                  setQuestionMandatory(e.target.checked);
                }}
              />
              <TextField
                autoFocus
                label="Question Text"
                placeholder="Enter Question Text"
                variant="outlined"
                color="primary"
                size="small"
                name="questionText"
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <FormControl size="small" sx={{ minWidth: '250px' }}>
                <Select
                  size="small"
                  // label="Organization Level"
                  labelId={'questionType'}
                  id={'questionType'}
                  name={'questionType'}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={(e) => {
                    setQuestionType(e.target.value);
                  }}
                  value={questionType}
                >
                  {questionTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      <Typography variant="subtitle2">{type}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                autoFocus
                label="Question Weight"
                placeholder="Enter Question Weight"
                variant="outlined"
                color="primary"
                size="small"
                name="questionWeight"
                value={questionWeight}
                onChange={(e) => setQuestionWeight(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              {questionType === 'text' || questionType === 'textarea' ? (
                <Box>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Score if any text typed
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="question-or-section-group-label"
                    name="textAnswerScore"
                    value={textAnswerScore}
                    onChange={(e) => {
                      setTextAnswerScore(e.target.value);
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      marginLeft: 1,
                    }}
                  >
                    {['1', '2', '3', '4', '5']?.map((score) => {
                      return (
                        <FormControlLabel
                          sx={{
                            padding: 0,
                          }}
                          key={score}
                          value={score}
                          control={<Radio size="small" />}
                          label={score}
                        />
                      );
                    })}
                  </RadioGroup>
                </Box>
              ) : null}
              {questionType === 'select' ||
              questionType === 'multiselect' ||
              questionType === 'checkbox' ||
              questionType === 'radio' ? (
                <>
                  {updateQuestionOptions
                    ?.sort((a, b) => {
                      if (a.order > b.order) {
                        return 1;
                      } else if (a.order < b.order) {
                        return -1;
                      } else {
                        return 0;
                      }
                    })
                    ?.map((option, index) => (
                      <Box
                        key={index}
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}
                      >
                        {questionType === 'radio' ? (
                          <Tooltip
                            arrow
                            placement="top"
                            title="Default Selected"
                          >
                            <Radio
                              size="small"
                              checked={selectedOption === index}
                              onChange={(e) =>
                                setSelectedOption(e.target.checked ? index : 0)
                              }
                            />
                          </Tooltip>
                        ) : null}
                        <TextField
                          autoFocus
                          label={`Option ${index + 1} Text`}
                          placeholder={`Option ${index + 1} Text`}
                          variant="outlined"
                          color="primary"
                          size="small"
                          name="optionText"
                          value={option?.text}
                          onChange={(e) => {
                            setUpdateQuestionOptions((prev) => {
                              return prev.map((op, opIndex) =>
                                opIndex === index
                                  ? {
                                      ...op,
                                      text: e.target.value,
                                    }
                                  : op,
                              );
                            });
                          }}
                          sx={{
                            flex: 4,
                          }}
                        />
                        <TextField
                          autoFocus
                          label={`Score`}
                          placeholder={`Score`}
                          variant="outlined"
                          color="primary"
                          size="small"
                          name="optionScore"
                          value={option?.scoreConfig?.score}
                          onChange={(e) => {
                            setUpdateQuestionOptions((prev) => {
                              return prev.map((op, opIndex) =>
                                opIndex === index
                                  ? {
                                      ...op,
                                      scoreConfig: {
                                        ...op.scoreConfig,
                                        score: e.target.value,
                                      },
                                    }
                                  : op,
                              );
                            });
                          }}
                          sx={{
                            flex: 1,
                          }}
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setUpdateQuestionOptions((prev) => {
                              return prev.filter(
                                (op, opIndex) => opIndex !== index,
                              );
                            });
                            if (option?.id) {
                              setDeleteQuestionOptionIds((prev) => [
                                ...prev,
                                option?.id,
                              ]);
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                    ))}
                  <Button
                    startIcon={<Add />}
                    size="small"
                    variant="text"
                    onClick={() => {
                      setUpdateQuestionOptions((prev) => [
                        ...prev,
                        {
                          selected: false,
                          text: '',
                        },
                      ]);
                    }}
                  >
                    Add More Option
                  </Button>
                </>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                disabled={!questionText?.trim()}
                onClick={() => {
                  dispatch(
                    updateQuestion(
                      showUpdateQuestionDialog?.id,
                      {
                        type: questionType,
                        mandatory: questionMandatory,
                        questionText,
                        textAnswerScoreConfig: {
                          ...showUpdateQuestionDialog?.textAnswerScoreConfig,
                          score: textAnswerScore,
                        },
                        questionWeight: questionWeight,
                        deleteOptionRequestDto:
                          questionType !== 'text' && questionType !== 'textarea'
                            ? deleteQuestionOptionIds
                            : [],
                        updateOptionsRequestDto:
                          questionType !== 'text' && questionType !== 'textarea'
                            ? updateQuestionOptions?.map((option) => {
                                return {
                                  ...option,
                                  optionId: option?.id,
                                };
                              })
                            : null,
                      },
                      () => {
                        setFetchingQuestionFramework(true);
                        dispatch(
                          getQuestionFramework(null, (data) => {
                            console.log(data);
                            setFetchingQuestionFramework(false);
                            setQuestionFramework(data?.questions);
                          }),
                        );
                      },
                      () => {
                        dispatch(
                          showAlert({
                            message: 'Failed to update question',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                  setShowUpdateQuestionDialog(false);
                }}
              >
                Update Question
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showParserDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '30vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowParserDialog(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>Parse Question</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <TextField
                autoFocus
                label="Enter Text"
                placeholder="Enter Text"
                variant="outlined"
                color="primary"
                size="small"
                multiline
                rows={5}
                name="textToBeParsed"
                value={textToBeParsed}
                onChange={(e) => setTextToBeParsed(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!textToBeParsed?.trim()}
                onClick={() => {
                  // console.log(textToBeParsed?.split('\n')?.[0]);
                  // console.log(
                  //   textToBeParsed
                  //     ?.split('\n')
                  //     ?.slice(1, textToBeParsed?.split('\n')?.length),
                  // );
                  setQuestionText(
                    textToBeParsed?.split('\n')?.[0]?.trim() || '',
                  );
                  setQuestionOptions(
                    textToBeParsed
                      ?.split('\n')
                      ?.slice(1, textToBeParsed?.split('\n')?.length)
                      ?.map((text) => text?.trim()),
                  );
                  setShowParserDialog(false);
                  setTimeout(() => {
                    setTextToBeParsed('');
                  }, 200);
                }}
              >
                Parse and fill question data
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddNewOptionDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddNewOptionDialog(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>Add Option</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {showAddNewOptionDialog?.questionType === 'radio' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="optionDefault"
                      defaultChecked={optionDefault}
                    />
                  }
                  label={'Default Selected'}
                  onChange={(e) => {
                    setOptionDefault(e.target.checked);
                  }}
                />
              ) : null}
              <TextField
                autoFocus
                label="Option Text"
                placeholder="Enter Option Text"
                variant="outlined"
                color="primary"
                size="small"
                name="optionText"
                value={optionText}
                onChange={(e) => setOptionText(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!optionText?.trim()}
                onClick={() => {
                  dispatch(
                    addOption(
                      showAddNewOptionDialog?.questionId,
                      {
                        // optionId: showAddNewOptionDialog?.optionId,
                        selected:
                          showAddNewOptionDialog?.questionType === 'radio'
                            ? optionDefault
                            : false,
                        text: optionText,
                      },
                      () => {
                        setFetchingQuestionFramework(true);
                        dispatch(
                          getQuestionFramework(null, (data) => {
                            setFetchingQuestionFramework(false);
                            console.log(data);
                            setQuestionFramework(data?.questions);
                          }),
                        );
                      },
                      () => {
                        dispatch(
                          showAlert({
                            message: 'Failed to add new option',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                  setShowAddNewOptionDialog(null);
                }}
              >
                Add Option
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showUpdateOptionDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowUpdateOptionDialog(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>Update Option</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {showUpdateOptionDialog?.questionType === 'radio' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      name="optionDefault"
                      defaultChecked={optionDefault}
                    />
                  }
                  label={'Default Selected'}
                  onChange={(e) => {
                    setOptionDefault(e.target.checked);
                  }}
                />
              ) : null}
              <TextField
                autoFocus
                label="Option Text"
                placeholder="Enter Option Text"
                variant="outlined"
                color="primary"
                size="small"
                name="optionText"
                value={optionText}
                onChange={(e) => setOptionText(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!optionText?.trim()}
                onClick={() => {
                  dispatch(
                    updateOption(
                      null,
                      showUpdateOptionDialog?.questionId,
                      [
                        {
                          optionId: showUpdateOptionDialog?.optionId,
                          selected:
                            showUpdateOptionDialog?.questionType === 'radio'
                              ? optionDefault
                              : false,
                          text: optionText,
                        },
                      ],
                      () => {
                        setFetchingQuestionFramework(true);
                        dispatch(
                          getQuestionFramework(null, (data) => {
                            setFetchingQuestionFramework(false);
                            console.log(data);
                            setQuestionFramework(data?.questions);
                          }),
                        );
                      },
                      () => {
                        dispatch(
                          showAlert({
                            message: 'Failed to update option',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                  setShowUpdateOptionDialog(null);
                }}
              >
                Update Option
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddHelpReference)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddHelpReference(null);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {showAddHelpReference?.data?.helpText ||
              showAddHelpReference?.data?.helpVideoUrl
                ? 'Update Help Reference'
                : 'Add Help Reference'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
              },
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <FormControl fullWidth>
                <InputLabel id="help-reference-label">
                  Select Help Reference
                </InputLabel>
                <Select
                  label="Select Help Reference"
                  labelId={'help-reference-label'}
                  id={'helpSectionId'}
                  name={'helpSectionId'}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={(e) => {
                    setHelpSectionId(e.target.value);
                  }}
                  value={helpSectionId}
                >
                  {helpSections.map((section) => (
                    <MenuItem value={section?.id} key={section?.id}>
                      <Typography variant="subtitle2">
                        {section?.title}
                      </Typography>
                    </MenuItem>
                  ))}
                  <MenuItem value={'new'}>
                    <Typography variant="subtitle2">+ Add new</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              {helpSectionId === 'new' ? (
                <>
                  <TextField
                    autoFocus
                    label="Help Section Title"
                    placeholder="Enter Help Section Title"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="helpSectionTitle"
                    value={helpSectionTitle}
                    onChange={(e) => setHelpSectionTitle(e.target.value)}
                    sx={{
                      flex: 1,
                    }}
                  />
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                    alignItems={'stretch'}
                  >
                    <Box flex={2}>
                      <RichTextEditor
                        value={helpText}
                        onChange={setHelpText}
                        placeholder="Enter Help Text"
                      />
                    </Box>
                    <Box flex={1}>
                      <InputLabel
                        htmlFor="videoFile"
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          style={{ display: 'none' }}
                          id="videoFile"
                          ref={helpVideoRef}
                          type="file"
                          accept="video/*"
                          onChange={(e) => {
                            handleVideoFilePicker(e);
                          }}
                        />
                        <Box
                          sx={{
                            border: '1px dashed #d3d3d3',
                            borderRadius: 4,
                            p: 2,
                            textAlign: 'center',
                          }}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          gap={1}
                        >
                          {helpVideo ? (
                            <Box>
                              <Typography
                                color={'#677788'}
                                variant="caption"
                                sx={{ whiteSpace: 'wrap' }}
                              >
                                {helpVideo?.name}
                              </Typography>
                              <VideoPreview
                                video={helpVideo}
                                onDurationChange={(e) => {
                                  setHelpVideoDuration(e.target.duration);
                                }}
                              />
                            </Box>
                          ) : (
                            <CloudUpload
                              sx={{
                                fontSize: 20,
                                color: theme.palette.primary.main,
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              background: theme.palette.primary.main,
                              borderRadius: 1,
                              padding: '4px 8px',
                            }}
                          >
                            <Typography variant="subtitle2" color={'#fff'}>
                              {helpVideo
                                ? 'Change Help Video'
                                : 'Choose Help Video'}
                            </Typography>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            {invalidVideoFile ? (
                              <Alert severity="error">
                                <Typography
                                  variant="caption"
                                  sx={{
                                    whiteSpace: 'pre-wrap',
                                    color: theme.palette.error.main,
                                  }}
                                >
                                  {invalidVideoFile}
                                </Typography>
                              </Alert>
                            ) : null}
                            <Typography
                              variant="caption"
                              sx={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              (Common video formats supported)(Optional)
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              Upload upto 300 MB of video
                            </Typography>
                          </Box>
                        </Box>
                      </InputLabel>
                      {/* )} */}
                    </Box>
                  </Box>
                </>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  savingOrUpdatingHelpReference ? (
                    <CircularProgress size={20} />
                  ) : null
                }
                disabled={
                  (helpSectionId === 'new' &&
                    (!helpText || !helpVideo || !helpSectionTitle)) ||
                  (helpSectionId !== 'new' && !helpSectionId) ||
                  savingOrUpdatingHelpReference
                }
                onClick={() => {
                  handleSubmitHelpText();
                }}
                sx={{
                  mt: helpSectionId === 'new' ? 4 : 0,
                }}
              >
                {showAddHelpReference?.data?.helpText ||
                showAddHelpReference?.data?.helpVideoUrl
                  ? 'Update'
                  : 'Add'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmDeleteSection)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this{' '}
              {showConfirmDeleteSection?.type}?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteSection(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteSection(showConfirmDeleteSection?.sectionId, (data) => {
                  setShowConfirmDeleteSection(false);
                  dispatch(
                    showAlert({
                      message: 'Section deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  setFetchingQuestionFramework(true);
                  dispatch(
                    getQuestionFramework(null, (data) => {
                      console.log(data);
                      setFetchingQuestionFramework(false);
                      setQuestionFramework(data?.questions);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteQuestion)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this question?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteQuestion(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteQuestion(showConfirmDeleteQuestion, (data) => {
                  setShowConfirmDeleteQuestion(false);
                  dispatch(
                    showAlert({
                      message: 'Question deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  setFetchingQuestionFramework(true);
                  dispatch(
                    getQuestionFramework(null, (data) => {
                      console.log(data);
                      setFetchingQuestionFramework(false);
                      setQuestionFramework(data?.questions);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteOption)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this option?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteOption(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteOption(
                  showConfirmDeleteOption?.questionId,
                  showConfirmDeleteOption?.optionId,
                  (data) => {
                    setShowConfirmDeleteOption(null);
                    dispatch(
                      showAlert({
                        message: 'Option deleted successfully.',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    setFetchingQuestionFramework(true);
                    dispatch(
                      getQuestionFramework(null, (data) => {
                        console.log(data);
                        setFetchingQuestionFramework(true);
                        setQuestionFramework(data?.questions);
                      }),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

QuestionsFramework.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

Questions.propTypes = {
  index: PropTypes.number,
  questions: PropTypes.array,
  section: PropTypes.object,
  sectionTitle: PropTypes.string,
  setShowAddNewQuestionDialog: PropTypes.func,
  level: PropTypes.string,
  setQuestionLevel: PropTypes.func,
  parentQuestionId: PropTypes.string,
  setParentQuestionId: PropTypes.func,
  questionOption: PropTypes.object,
  setQuestionOption: PropTypes.func,
  sections: PropTypes.array,
  setShowAddNewSectionDialog: PropTypes.func,
  setParentSectionId: PropTypes.func,
  setShowConfirmDeleteSection: PropTypes.func,
  setShowConfirmDeleteQuestion: PropTypes.func,
  setShowUpdateQuestionDialog: PropTypes.func,
  setShowConfirmDeleteOption: PropTypes.func,
  setShowUpdateOptionDialog: PropTypes.func,
  setShowAddNewOptionDialog: PropTypes.func,
  setSectionIds: PropTypes.func,
  sectionContentType: PropTypes.string,
  isSection: PropTypes.bool,
  expandedSections: PropTypes.bool,
  setExpandedSections: PropTypes.func,
  expandedQuestions: PropTypes.bool,
  setExpandedQuestions: PropTypes.func,
  setShowAddHelpReference: PropTypes.func,
};

VideoPreview.propTypes = {
  video: PropTypes.any,
  onDurationChange: PropTypes.func,
};

export default QuestionsFramework;
